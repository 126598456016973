import React from "react"

function DefaultUserIcon() {
  return (
    <span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        // width={width}
        // height={height}
        fill="none"
        viewBox="0 0 24 24  "
      >
        <rect width="24" height="24" rx="12" fill="#8083A3" />
        <path
          d="M12.0935 11.3412C12.0352 11.3353 11.9652 11.3353 11.901 11.3412C10.5127 11.2945 9.41016 10.157 9.41016 8.75699C9.41016 7.32783 10.5652 6.16699 12.0002 6.16699C13.4293 6.16699 14.5902 7.32783 14.5902 8.75699C14.5843 10.157 13.4818 11.2945 12.0935 11.3412Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.17789 13.493C7.76622 14.438 7.76622 15.978 9.17789 16.9172C10.7821 17.9905 13.4129 17.9905 15.0171 16.9172C16.4287 15.9722 16.4287 14.4322 15.0171 13.493C13.4187 12.4255 10.7879 12.4255 9.17789 13.493Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  )
}

export default DefaultUserIcon
