import React, { useRef, useState } from "react"
import { SunnyAIProvider } from "./context/SunnyAIContext"

const SunnyAIProviderWrapper = ({ children }) => {
  const [selectedRoom, setSelectedRoom] = useState({})
  const [roomMessages, setRoomMessages] = useState([])
  const selectedRoomRef = useRef(null)
  const loadingRef = useRef(null)
  const [allAIRoomsObj, setAllAIRoomsObj] = useState({})
  const [isAIChatLoading, setIsAIChatLoading] = useState(true)
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false)
  return (
    <SunnyAIProvider
      value={{
        selectedRoom,
        setSelectedRoom,
        selectedRoomRef,
        loadingRef,
        roomMessages,
        setRoomMessages,
        isAIChatLoading,
        setIsAIChatLoading,
        allAIRoomsObj,
        setAllAIRoomsObj,
        isWaitingForResponse,
        setIsWaitingForResponse
      }}
    >
      {children}
    </SunnyAIProvider>
  )
}

export default SunnyAIProviderWrapper
