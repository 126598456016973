import styled from "styled-components"
import Button from "../../../components/Button/Button.tsx"
import { rgba } from "../../../config/variable"

export const IncomingCallList = styled.div`
  position: fixed;
  z-index: 1050;
  top: 115px;
  right: 32px;
`
export const IncomingCallCard = styled.div`
  background-color: ${(props) => props.theme.bgcolordarkblue};
  border-radius: 10px;
  width: 240px;
  max-width: calc(100vw - 48px);
  margin-bottom: 10px;
`
export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 12px;
`
export const CardFooter = styled.div`
  border-top: 1px solid ${(props) => rgba(props.theme.bgcolordwhite, 0.1)};
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 12px;
`
export const CallDetails = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  min-width: 0;
  gap: 3px;
`
export const CallType = styled.div`
  color: ${(props) => props.theme.textcolordwhite3};
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  display: flex;
  align-items: center;
  gap: 5px;
`
export const UserName = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const CallTime = styled.div`
  color: ${(props) => props.theme.bgcolordlightblue1};
  font-size: 10px;
  line-height: 13px;
`
export const CallButtonStyle = styled(Button)`
  &.ant-btn-circle {
    height: 26px;
    min-width: unset;
    padding: 0 8px;
    border-radius: 50px;
    flex-shrink: 0;
    font-size: 10px;
    font-weight: 700;
    line-height: 13px;
    &.ant-btn {
      padding: 0 8px;
    }
    &.ant-btn-icon-only > span {
      transform: none;
    }
    & > .anticon + span {
      margin-left: 5px;
    }
    & > .ant-btn-icon {
      margin-right: 5px !important;
    }
  }
`
export const CallMuteButton = styled(CallButtonStyle)`
  border-color: ${(props) => rgba(props.theme.textcolordwhite3, 0.2)};
  background-color: transparent;
  color: ${(props) => props.theme.textcolordwhite3};
  &:hover {
    background-color: transparent;
  }
  &:focus {
    background-color: transparent;
    color: ${(props) => props.theme.textcolordwhite3};
    border-color: ${(props) => rgba(props.theme.textcolordwhite3, 0.2)};
  }
`
export const CallEndButton = styled(CallButtonStyle)``
export const CallButton = styled(CallButtonStyle)`
  &.ant-btn-success {
    background-color: ${(props) => props.theme.colorsuccess};
    &:hover {
      background-color: ${(props) => rgba(props.theme.colorsuccess, 0.85)};
    }
    &:active {
      background-color: ${(props) => props.theme.colorsuccess};
    }
  }
`
