import Editor from "@draft-js-plugins/editor"
import { defaultSuggestionsFilter } from "@draft-js-plugins/mention"
import {
  DraftHandleValue,
  EditorState,
  getDefaultKeyBinding,
  Modifier
} from "draft-js"
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from "react"
import MentionSuggestionList from "./MentionSuggestionList/MentionSuggestionList.js"
import { RteEditorStyle } from "./RteEditor.style.ts"
import "draft-js/dist/Draft.css"

import { stateToHTML } from "draft-js-export-html"

const options = {
  defaultBlockTag: "span",
  entityStyleFn: (entity) => {
    const entityType = entity.get("type")?.toLowerCase()
    if (entityType === "mention") {
      const { mention } = entity.get("data")
      return {
        element: "span",
        attributes: {
          type: "mentions",
          id: mention.user_id
        }
      }
    }
    // else{
    //   return {
    //     element: "",
    //   }
    // }
  }
}

const MAX_CHARACTERS = 2500

const RteEditor = (
  {
    id,
    mentions,
    onChange,
    handleSend,
    value,
    inputRef,
    onFormattedChange,
    plugins,
    MentionSuggestions,
    editorState,
    setEditorState,
    draftConvertToRaw,
    handleFileChange,
    isEditorDraggable
  },
  editorRef
) => {
  const ref = useRef<Editor>(null)

  const [open, setOpen] = useState(false)
  const [isDragging, setIsDragging] = useState(false)
  const [suggestions, setSuggestions] = useState(
    mentions
      ?.filter((data) => data.displayname)
      ?.map((data) => ({
        ...data,
        name: data.displayname,
        avatar: data.avatar_url
      }))
  )
  const [dropdownSuggestions, setDropdownSuggestions] = useState([])
  // const contextMenuRef = useRef(null)
  // const [contextMenuPosition, setContextMenuPosition] = useState({
  //   x: 0,
  //   y: 0
  // })
  // const [selectedText, setSelectedText] = useState()

  // useEffect(() => {
  //   document.addEventListener("click", handleClickOutside)

  //   return () => {
  //     document.removeEventListener("click", handleClickOutside)
  //   }
  // }, [])

  useEffect(() => {
    // stateToHtml(editorState)
    // onFormattedChange(ref.current?.editor?.editor?.innerHTML)
    draftConvertToRaw(editorState?.getCurrentContent())
    onFormattedChange(stateToHTML(editorState.getCurrentContent(), options))
    onChange(editorState?.getCurrentContent()?.getPlainText())
  }, [editorState])

  useEffect(() => {
    setSuggestions(
      mentions
        ?.filter((data) => data.displayname)
        ?.map((data) => ({
          ...data,
          name: data.displayname,
          avatar: data.avatar_url
        }))
    )
  }, [mentions, id])

  const onOpenChange = useCallback((_open: boolean) => {
    setOpen(_open)
  }, [])
  const onSearchChange = useCallback(
    ({ value }: { value: string }) => {
      setDropdownSuggestions(defaultSuggestionsFilter(value, suggestions))
      // setDropdownSuggestions(suggestions?.filter((data) => data.name?.includes(value)))
    },
    [suggestions]
  )

  // const onSearchChange = ({value})=>{
  //   // setSuggestions(defaultSuggestionsFilter(value, suggestions));
  //   setDropdownSuggestions(suggestions?.filter((data) => data.name?.includes(value)))
  // };

  const handleEditorStateChange = (es) => {
    setEditorState(es)
    onChange(es.getCurrentContent().getPlainText())
    // onFormattedChange(ref.current?.editor?.editor?.innerHTML)
  }

  const keyBindingFunction = (e) => {
    if ((e.key === "Enter" || e.key === "enter") && !e.shiftKey) {
      return "custom-enter"
    }
    return getDefaultKeyBinding(e)
  }

  const editorFocus = () => {
    setTimeout(() => {
      ref.current!.focus()
    }, 1000)
  }

  const handleKeyCommand = (command) => {
    if (command === "custom-enter") {
      ref.current!.blur()

      handleSend({ editorFocus })

      return "handled"
    }
    return "not-handled"
  }

  useImperativeHandle(editorRef, () => ({
    focusEditor() {
      ref.current!.focus()
    }
  }))

  const handleBeforeInput = (
    text: string,
    state: EditorState
  ): DraftHandleValue => {
    const totalLength =
      state.getCurrentContent().getPlainText().length + text.length
    return totalLength > MAX_CHARACTERS ? "handled" : "not-handled"
  }

  const handlePastedFiles = (files: Array<Blob>) => {
    handleFileChange(false, files)
  }

  // const handleDroppedFiles = (selection, files: Array<Blob>) => {
  //   handleFileChange(false, files)
  // }

  // const handleClickOutside = (event) => {
  //   if (
  //     contextMenuRef.current &&
  //     !contextMenuRef.current.contains(event.target)
  //   ) {
  //     setContextMenuPosition({ x: 0, y: 0 })
  //   }
  // }

  // const handleContextMenu = (event) => {
  //   event.preventDefault()

  //   const selection = window.getSelection()
  //   const selectedText = selection?.toString().trim()
  //   // if (selectedText !== "") {
  //   const range = selection?.getRangeAt(0).getBoundingClientRect()
  //   setContextMenuPosition({
  //     x: range?.x + window.scrollX,
  //     y: range?.y + window.scrollY
  //   })
  //   setSelectedText(selectedText)
  //   // } else {
  //   // setContextMenuPosition({ x: 0, y: 0 })
  //   // }
  // }

  // const handleCopyCommand = () => {
  //   navigator.clipboard.writeText(selectedText)
  //   setContextMenuPosition({ x: 0, y: 0 })
  // }

  // const handlePasteCommand = async () => {
  //   const pastedText = await navigator.clipboard.readText()
  //   const currentContent = editorState.getCurrentContent()
  //   const selection = editorState.getSelection()
  //   let updatedText
  //   if (!selection.isCollapsed()) {
  //     updatedText = Modifier.replaceText(currentContent, selection, pastedText)
  //   } else {
  //     updatedText = Modifier.insertText(currentContent, selection, pastedText)
  //   }
  //   const newEditorState = EditorState.push(
  //     editorState,
  //     updatedText,
  //     "insert-characters"
  //   )
  //   setEditorState(newEditorState)
  //   setContextMenuPosition({ x: 0, y: 0 })
  // }
  return (
    <RteEditorStyle
      onClick={() => {
        ref.current!.focus()
      }}
      className={isDragging ? "dragging-item" : ""}
      // contextMenuRef={contextMenuRef}
      // onContextMenu={(event) => handleContextMenu(event)}
      // draggable
      onDrop={(e) => {
        e.preventDefault()
        if (isEditorDraggable) {
          setIsDragging(false)
          // inputRef.current.fileT
          // setInputValue(e.dataTransfer.files)
          handleFileChange(false, e.dataTransfer.files)
          ref.current!.focus()
        }
      }}
      onDragEnter={(e) => {
        e.preventDefault()
        if (isEditorDraggable) {
          setIsDragging(true)
        }
      }}
      onDragLeave={(e) => {
        e.preventDefault()
        if (isEditorDraggable) {
          setIsDragging(false)
        }
      }}
      onDragOver={(e) => {
        e.preventDefault()
        if (isEditorDraggable) {
          setIsDragging(true)
        }
      }}
    >
      {/* {contextMenuPosition.x !== 0 && (
        <div ref={contextMenuRef} className="copy-context">
          {" "}
          {Boolean(selectedText) && (
            <button onClick={handleCopyCommand}>Copy</button>
          )}
          <button onClick={handlePasteCommand}>Paste</button>
        </div>
      )} */}
      <Editor
        editorKey="editor"
        spellCheck
        editorState={editorState}
        onChange={handleEditorStateChange}
        handleBeforeInput={handleBeforeInput}
        plugins={plugins}
        ref={ref}
        keyBindingFn={keyBindingFunction}
        handleKeyCommand={handleKeyCommand}
        defaultKeyBindings={false}
        placeholder="Type a new message"
        stripPastedStyles
        handlePastedFiles={handlePastedFiles}
        // handleDroppedFiles={handleDroppedFiles}
      />
      <div className="msg-sug">
        <MentionSuggestions
          open={open}
          onOpenChange={onOpenChange}
          suggestions={dropdownSuggestions}
          onSearchChange={onSearchChange}
          onAddMention={() => {
            // get the mention object selected
          }}
          entryComponent={MentionSuggestionList}
        />
      </div>
      {/* <div className="drop-file">Drop your fies here</div> */}
    </RteEditorStyle>
  )
}

export default forwardRef(RteEditor)
