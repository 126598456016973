/* eslint-disable @typescript-eslint/no-explicit-any */
import { Table, Typography } from "antd"
import { TableLocale } from "antd/es/table/interface"
import ErrorFound from "../../EmptyData/ErrorFound"
import CallQueueTableStyle from "./CallQueue.style"

const localeText = {
  emptyText: <ErrorFound />
}

interface TableProps {
  data: any
  columns: any
  pagination?: boolean
  locale?: TableLocale | undefined
  loading?: boolean
  pageSize?: number
  total?: number
  handlePageChange?: any
  currentPage?: number
}

const CallQueueTable = (props: TableProps) => {
  const {
    data,
    columns,
    pagination = true,
    locale = localeText,
    loading = false,
    pageSize = 12,
    total,
    handlePageChange,
    currentPage = 1,
    heading
  } = props

  return (
    <CallQueueTableStyle>
      <div className="voicemail-table">
        {" "}
        {heading !== "" && (
          <div className="voicemail-table__header">
            <Typography.Title level={5}>{heading}</Typography.Title>
          </div>
        )}
        <div className="voicemail-table__block">
          <Table
            loading={loading}
            locale={locale}
            columns={columns}
            dataSource={data}
            onChange={handlePageChange}
            pagination={
              pagination
                ? {
                    pageSize,
                    current: currentPage,
                    total,
                    showTotal: (total, range) => `${range[1]}/ ${total}`,
                    showSizeChanger: false
                  }
                : false
            }
          />
        </div>
      </div>
    </CallQueueTableStyle>
  )
}

export default CallQueueTable
