import React from "react"

function NetworkTestCollapse() {
  return (
    <span className="anticon anticon-copylink">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 20 21"
        fill="none"
      >
        <path
          d="M10.0013 18.8337C14.6037 18.8337 18.3346 15.1027 18.3346 10.5003C18.3346 5.89795 14.6037 2.16699 10.0013 2.16699C5.39893 2.16699 1.66797 5.89795 1.66797 10.5003C1.66797 15.1027 5.39893 18.8337 10.0013 18.8337Z"
          stroke="#38A6DE"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.05859 9.4502L10.0003 12.3835L12.9419 9.4502"
          stroke="#38A6DE"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  )
}

export default NetworkTestCollapse
