import React from "react"

function ArrowSelectIconDark() {
  return (
    <span className="anticon anticon-apple ">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 9"
      >
        <path
          d="M14.6004 1.45825L9.16706 6.89159C8.52539 7.53325 7.47539 7.53325 6.83372 6.89159L1.40039 1.45825"
          stroke="currentcolor"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  )
}

export default ArrowSelectIconDark
