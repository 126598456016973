import React from "react"

const SunnyAIContext = React.createContext({})

export const SunnyAIProvider = ({ children, value }) => {
  return (
    <SunnyAIContext.Provider value={value}>{children}</SunnyAIContext.Provider>
  )
}

export default SunnyAIContext
