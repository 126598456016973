import Paragraph from "antd/es/typography/Paragraph"
import styled from "styled-components"
import { rem } from "../../config/variable"

const ParagraphInfo = styled(Paragraph)`
  &.ant-typography {
    font-style: normal;
    font-weight: 400;
    font-size: ${rem(14)};
    line-height: 16px;
    color: ${(props) => props.theme.textcolordarkgrey};
    margin: 0;
    .ant-typography {
      font-style: normal;
      font-weight: 400;
      font-size: ${rem(14)};
      line-height: 16px;
      color: ${(props) => props.theme.textcolordarkgrey};
      margin: 0;
    }
  }
`
export default ParagraphInfo
