import { ParagraphProps } from "antd/es/typography/Paragraph"
import React, { FC } from "react"
import ParagraphInfo from "./Paragraph.style"

interface Paragraphprops {
  children?: React.ReactNode
}
const Paragraph: FC<ParagraphProps> = (props: Paragraphprops) => {
  const { children } = props
  return <ParagraphInfo>{children}</ParagraphInfo>
}

export default Paragraph
