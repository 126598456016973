import { useEffect, useState } from "react"
import { iceCandidateFilter, parseCandidate } from "../utils/callerFn"
import { turnConfig } from "../utils/iceServersConfig"

const useNetworkTest = (protocol: string) => {
  const [message, setMessage] = useState<string[]>([])

  const [errorMessage, setErrorMessage] = useState<string[]>([])

  useEffect(() => {
    const run = async () => {
      try {
        const result = start()
      } catch (error) {
        setErrorMessage((prevState) => [...prevState, ...[`Error: ${error}`]])
      }
    }

    const start = () => {
      filterConfig(turnConfig, protocol)
      gatherCandidates(turnConfig)
    }

    const filterConfig = (config: any, protocol: string) => {
      const transport = `transport=${protocol}`
      const newIceServers = []
      for (let i = 0; i < config.iceServers?.length; ++i) {
        const iceServer = config.iceServers[i]
        const newUrls = []
        for (let j = 0; j < iceServer.urls.length; ++j) {
          const uri = iceServer.urls[j]
          if (uri.indexOf(transport) !== -1) {
            newUrls.push(uri)
          } else if (
            uri.indexOf("?transport=") === -1 &&
            uri.startsWith("turn")
          ) {
            newUrls.push(`${uri}?${transport}`)
          }
        }
        if (newUrls.length !== 0) {
          iceServer.urls = newUrls
          newIceServers.push(iceServer)
        }
      }
      config.iceServers = newIceServers
    }

    const gatherCandidates = (config: RTCConfiguration) => {
      let pc: RTCPeerConnection | null
      try {
        pc = new RTCPeerConnection(config)
      } catch (error) {
        setErrorMessage((prevState) => [
          ...prevState,
          ...[`Failed to create peer connection: ${error}`]
        ])
        // setMessage((prevState) => [...prevState, ...["Test done: "]]);
        return
      }

      pc.addEventListener("icecandidate", (e: RTCPeerConnectionIceEvent) => {
        const pcEvent = e.target as RTCPeerConnection

        if (pcEvent && pcEvent.signalingState === "closed") {
          return
        }

        if (e.candidate) {
          const parsed = parseCandidate(e.candidate.candidate)
          if (iceCandidateFilter(parsed, "host")) {
            setMessage((prevState) => [
              ...prevState,
              ...[
                `Gathered candidate of Type: ${parsed.type} Protocol: ${parsed.protocol} Address: ${parsed.address}`
              ]
            ])

            if (pc) pc.close()
            pc = null
            // setMessage((prevState) => [...prevState, ...["Test done: "]]);
          }
        } else {
          if (pc) pc.close()
          pc = null

          setErrorMessage((prevState) => [
            ...prevState,
            ...["Failed to gather specified candidates"]
          ])
          // setMessage((prevState) => [...prevState, ...["Test done: "]]);
        }
      })

      createAudioOnlyReceiveOffer(pc)
    }

    const createAudioOnlyReceiveOffer = (pc: any) => {
      const createOfferParams = { offerToReceiveAudio: 1 }
      pc.createOffer(createOfferParams).then((offer: any) => {
        pc.setLocalDescription(offer).then(noop, noop)
      }, noop)
      function noop() {}
    }

    run()

    return () => {}
  }, [])
  return { message, errorMessage }
}

export default useNetworkTest
