import { useEffect, useRef, useState } from "react"

interface audioType {
  peakLevel: number
  rms: number
  clipDetected: boolean
  isMono: boolean
}
const AudioAnalyzer = ({ setLogs, setErrorLogs, setIsLoading }) => {
  const audioContextRef = useRef<AudioContext>(new AudioContext())
  const [errorMessage, setErrorMessage] = useState("")
  const [audioResult, setAudioResult] = useState<audioType>({} as audioType)

  useEffect(() => {
    setErrorLogs((prevState) => ({
      ...prevState,
      audio: errorMessage
    }))
  }, [errorMessage])

  useEffect(() => {
    setLogs((prevState) => ({
      ...prevState,
      audio: audioResult
    }))
  }, [audioResult])

  useEffect(() => {
    const initializeAudio = async () => {
      try {
        const audioContext = new window.AudioContext()
        audioContextRef.current = audioContext

        // Capture audio from the user's microphone using WebRTC
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true
        })
        const microphoneSource = audioContext.createMediaStreamSource(stream)

        // Analyser node for processing audio data
        const analyserNode = audioContext.createAnalyser()
        microphoneSource.connect(analyserNode)

        // Set up the analyser parameters
        analyserNode.fftSize = 2048
        const bufferLength = analyserNode.frequencyBinCount
        const dataArray = new Uint8Array(bufferLength)

        // Your audio processing logic goes here
        const processAudio = () => {
          analyserNode.getByteTimeDomainData(dataArray)

          // Compute peak level
          const peakLevel = Math.max(...dataArray)

          // Compute maximum RMS
          const rms = Math.sqrt(
            dataArray.reduce((acc, value) => acc + value * value, 0) /
              bufferLength
          )

          // Clip detection (example threshold: adjust as needed)
          const clipDetected = peakLevel >= 250

          // Mono mic detection (example: check if the number of channels is 1)
          const isMono =
            stream.getAudioTracks()[0].getSettings().channelCount === 1
          setAudioResult({
            peakLevel,
            rms,
            clipDetected,
            isMono
          })
        }

        processAudio()
      } catch (error) {
        setErrorMessage(`Error initializing audio:, ${error}`)
      } finally {
        setIsLoading((prevState) => ({
          ...prevState,
          audio: false
        }))

        if (audioContextRef.current) {
          try {
            audioContextRef.current.close()
          } catch (error) {}
        }
      }
    }

    initializeAudio()

    // Cleanup function if needed
    return () => {
      if (audioContextRef.current) {
        try {
          audioContextRef.current.close()
        } catch (error) {}
      }
    }
  }, []) // Empty dependency array ensures useEffect runs only once on mount

  return (
    <>
      <h2>Audio test</h2>
      <div>
        {/* {errorMessage ? (
          <div>{errorMessage}</div>
        ) : (
          <>
            <div>Peak Level: {audioResult.peakLevel}</div>
            <div>Maximum RMS: {audioResult.rms}</div>
            <div>Clip Detected: {audioResult.clipDetected ? "yes" : "no"}</div>
            <div>Mono Mic Detection: {audioResult.isMono ? "yes" : "no"}</div>
          </>
        )} */}
      </div>
    </>
  )
}

export default AudioAnalyzer
