/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react"
import { withTheme } from "styled-components"

interface CallSlashProps {
  className?: string
  width?: string
  height?: string
  theme: any
}

function CallSlash(props: CallSlashProps) {
  const { className, width, height, theme: themeColor } = props
  const cssClassName = className || ""
  const cssWidth = width || "18"
  const cssHeight = height || "18"
  return (
    <span className="anticon">
      <svg
        className={cssClassName}
        width={cssWidth}
        height={cssHeight}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.77344 14.1095C7.8761 14.1901 7.97877 14.2635 8.08877 14.3441C8.92477 14.9528 9.7681 15.4368 10.6114 15.7961C11.4548 16.1555 12.2541 16.3315 13.0021 16.3315C13.5154 16.3315 13.9921 16.2361 14.4321 16.0528C14.8794 15.8695 15.2754 15.5835 15.6348 15.1875C15.8474 14.9528 16.0088 14.7035 16.1334 14.4395C16.2581 14.1755 16.3168 13.9041 16.3168 13.6401C16.3168 13.4348 16.2728 13.2515 16.1994 13.0681C16.1188 12.8848 15.9868 12.7308 15.7961 12.5988L13.3688 10.8755C13.1854 10.7508 13.0168 10.6555 12.8554 10.5895C12.6941 10.5235 12.5474 10.4941 12.4081 10.4941C12.2248 10.4941 12.0561 10.5455 11.8874 10.6481C11.7188 10.7361 11.5428 10.8755 11.3594 11.0588L10.8021 11.6088C10.7214 11.6895 10.6261 11.7335 10.5014 11.7335C10.4354 11.7335 10.3768 11.7261 10.3181 11.7041C10.2668 11.6821 10.2228 11.6601 10.1861 11.6455C10.0468 11.5721 9.88544 11.4695 9.70944 11.3375"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.07387 9.89402C7.69254 9.50535 7.34054 9.11668 7.01054 8.73535C6.68787 8.35402 6.46054 8.03868 6.32854 7.79668C6.31387 7.75268 6.29187 7.70868 6.26987 7.65002C6.25521 7.59135 6.24787 7.54002 6.24787 7.48135C6.24787 7.36402 6.28454 7.26868 6.36521 7.18802L6.92254 6.60868C7.09854 6.43268 7.23787 6.25668 7.33321 6.08802C7.43587 5.91935 7.48721 5.74335 7.48721 5.56735C7.48721 5.42802 7.45054 5.27402 7.38454 5.12002C7.31854 4.95868 7.22321 4.79002 7.09121 4.60668L5.38987 2.20868C5.25787 2.01802 5.08921 1.88602 4.89854 1.79802C4.71521 1.71002 4.50987 1.66602 4.30454 1.66602C3.76187 1.66602 3.24854 1.89335 2.77921 2.35535C2.39054 2.72202 2.11187 3.13268 1.93587 3.58002C1.75987 4.02002 1.67188 4.49668 1.67188 4.99535C1.67188 5.75802 1.84788 6.55735 2.19987 7.39335C2.55187 8.22202 3.03587 9.05802 3.63721 9.89402C4.24587 10.73 4.92787 11.5293 5.68321 12.292"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.7267 1.27633C16.9415 1.49111 16.9415 1.83935 16.7267 2.05414L2.06 16.7208C1.84521 16.9356 1.49697 16.9356 1.28219 16.7208C1.0674 16.506 1.0674 16.1578 1.28219 15.943L15.9489 1.27633C16.1636 1.06154 16.5119 1.06154 16.7267 1.27633Z"
          fill="currentColor"
        />
        <path
          d="M13.7698 7.53151C13.7698 7.09151 13.4251 6.41684 12.9118 5.86684C12.4425 5.36084 11.8191 4.96484 11.2031 4.96484"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.3365 7.53177C16.3365 4.69377 14.0411 2.39844 11.2031 2.39844"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.5392 2.00875C17.754 2.22354 17.754 2.57178 17.5392 2.78657L2.8725 17.4532C2.65771 17.668 2.30947 17.668 2.09469 17.4532C1.8799 17.2384 1.8799 16.8902 2.09469 16.6754L16.7614 2.00875C16.9761 1.79396 17.3244 1.79396 17.5392 2.00875Z"
          fill={themeColor.bgcolordarkblue}
        />
      </svg>
    </span>
  )
}

export default withTheme(CallSlash)
