import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { VoicemailState } from "./type"
import { getNewVoicemailsCounts } from "./voicemailAPI"

const initialState: VoicemailState = {
  newVoicemailCount: 0
}
const voicemailSlice = createSlice({
  name: "voicemailSlice",
  initialState,
  reducers: {
    decreaseNewVoicemailCount: (state) => {
      if (state.newVoicemailCount > 0) {
        state.newVoicemailCount -= 1
      }
    },
    setVoicemailCount: (state, { payload }) => {
      state.newVoicemailCount = payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(
      getNewVoicemailsCounts.fulfilled,
      (state, action: PayloadAction<number>) => {
        state.newVoicemailCount = action.payload
      }
    )
  }
})
export const { decreaseNewVoicemailCount, setVoicemailCount } =
  voicemailSlice.actions
const voicemailReducer = voicemailSlice.reducer
export default voicemailReducer
