import React from "react"

function ProfileCover() {
  return (
    <span className="anticon anticon-profilecover ">
      {" "}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 14 14  "
      >
        {" "}
        <path
          d="M7.09349 6.34091C7.03516 6.33508 6.96516 6.33508 6.90099 6.34091C5.51266 6.29425 4.41016 5.15675 4.41016 3.75675C4.41016 2.32758 5.56516 1.16675 7.00016 1.16675C8.42932 1.16675 9.59016 2.32758 9.59016 3.75675C9.58432 5.15675 8.48182 6.29425 7.09349 6.34091Z"
          stroke="currentcolor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />{" "}
        <path
          d="M4.17691 8.49325C2.76525 9.43825 2.76525 10.9783 4.17691 11.9174C5.78108 12.9908 8.41191 12.9908 10.0161 11.9174C11.4277 10.9724 11.4277 9.43242 10.0161 8.49325C8.41775 7.42575 5.78691 7.42575 4.17691 8.49325Z"
          stroke="currentcolor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />{" "}
      </svg>{" "}
    </span>
  )
}
export default ProfileCover
