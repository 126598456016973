import styled from "styled-components"

const SunnyAISidebarWrapper = styled.div`
  height: 100%;
  .new-chat {
    border: none !important;
    .ant-btn {
      width: 100%;
    }
  }
  .previous-day {
    font-size: 14px;
    color: ${(props) => props.theme.textcolordarkgrey};
    margin-bottom: 20px;
  }
`
export default SunnyAISidebarWrapper
