import styled, { css } from "styled-components"
import { md } from "../../../config/variable"

export const AvatarStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  .ant-avatar {
    background: ${(props) => props.theme.primary};
    color: ${(props) => props.theme.textcolordwhite3};
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 26px;
  }
`

export const MinHeightContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 225px);
`

export const TableHeaderStyle = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  cursor: pointer;
`

const VoicemailsStyle = styled.div`
  .voicemail-table {
    display: flex;
    width: 100%;
    border-radius: 10px;
    padding: 17px 24px;
    background: ${(props) => props.theme.colordwhitedark};
    flex-direction: column;

    .voicemail-table {
      padding: 0;
    }
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 16px;
      ${md(css`
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
      `)}
      .ant-typography {
        margin: 0;
        font-size: 16px;
        font-weight: 500;
      }

      .action-wrapper {
        display: flex;
        justify-content: flex-start;
        .anticon {
          cursor: pointer;
        }
        .anticon-delete {
          color: ${(props) => props.theme.colordanger};
        }
        &.w-100 {
          max-width: 100px;
        }
      }
    }
  }
`
export default VoicemailsStyle
