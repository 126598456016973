import React from "react"

function ArchiveIcons() {
  return (
    <span className="anticon anticon-archive ">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 14 14"
      >
        <path
          d="M11.375 5.96191V11.0836C11.375 12.2502 11.0833 12.8336 9.625 12.8336H4.375C2.91667 12.8336 2.625 12.2502 2.625 11.0836V5.96191"
          stroke="#FF455C"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.91602 1.16699H11.0827C12.2493 1.16699 12.8327 1.75033 12.8327 2.91699V4.08366C12.8327 5.25033 12.2493 5.83366 11.0827 5.83366H2.91602C1.74935 5.83366 1.16602 5.25033 1.16602 4.08366V2.91699C1.16602 1.75033 1.74935 1.16699 2.91602 1.16699Z"
          stroke="#FF455C"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  )
}

export default ArchiveIcons
