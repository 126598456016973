/* eslint-disable */
import auththumb from "../images/auththumb.png"
import redirectlogin from "../images/redirect-login.png"
import loginimg from "../images/login-img.png"
import loginbg from "../images/oc-login_background.png"
import brandlogo from "../images/brandlogo.png"
import bgvector from "../images/bgvector.png"
import profile from "../images/profile.jpeg"
import profilepic from "../images/profilepic.png"
import qr from "../images/qr.png"
import videopreview from "../images/videoface.png"
import mediaimg from "../images/media.jpeg"
import transcriptimg from "../images/transcript1.png"
import none from "../images/None.png"
import lightblur from "../images/Light Blur.png"
import blur from "../images/Blur.png"
import day from "../images/Day.png"
import night from "../images/Night.png"
import black from "../images/Black.png"
import day2 from "../images/Day 2.png"
import branding from "../images/Branding.png"
import addcircle from "../images/add-circle.png"
import deskpermission from "../images/deskpermission.jpeg"
import permissions from "../images/permissions.png"
import chatperson from "../images/Chatperson.png"
import flashbanner from "../images/flashbanner.png"
import firstmsgicon from "../images/firstmsg.svg"
import welcomebannerimg from "../images/welcomebanner.png"
// import ellipsebg from "../images/ellipse-bg.svg"
import ellipsebg from "../images/ellipse-bg-tr.png"
import micAccess from "../images/micAccess.png"

const Images = {
  auththumb,
  redirectlogin,
  loginimg,
  loginbg,
  brandlogo,
  bgvector,
  profile,
  profilepic,
  qr,
  videopreview,
  mediaimg,
  transcriptimg,
  none,
  lightblur,
  blur,
  day,
  night,
  black,
  day2,
  branding,
  addcircle,
  deskpermission,
  permissions,
  chatperson,
  flashbanner,
  firstmsgicon,
  welcomebannerimg,
  ellipsebg,
  micAccess
}
export default Images
