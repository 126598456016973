import React from "react"

function NetworkSuccess() {
  return (
    <span className="anticon anticon-successnetwork">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 20 20"
        fill="none"
      >
        <g clipPath="url(#clip0_29889_183163)">
          <path
            d="M4.16797 9.99967L8.33464 14.1663L16.668 5.83301"
            stroke="#60B527"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_29889_183163">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </span>
  )
}

export default NetworkSuccess
