import styled, { createGlobalStyle, css } from "styled-components"
import { lg, rgba } from "./variable"
import "@draft-js-plugins/mention/lib/plugin.css"
import "@draft-js-plugins/emoji/lib/plugin.css"

export const GlobalStyle = createGlobalStyle`
*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.full-splash{
  background: rgba(0,0,0,0.85);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    p{
      color:${(props) => props.theme.textcolordwhite3};
      
    }
    .ant-btn{
      width:auto;
      background-color:transparent;
      margin-top:40px;
    }
    .loading {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    transform: scale(0.63);
    margin-bottom: 20px;
  }
  .loading div {
    position: absolute;
    width: 10px;
    height: 10px;
    background: transparent;
    border-radius: 50%;
    animation: lds-default 1.2s linear infinite;
    border: 1px solid #fff;
  }
  .loading div:nth-child(1) {
    animation-delay: 0s;
    top: 37px;
    left: 66px;
  }
  .loading div:nth-child(2) {
    animation-delay: -0.1s;
    top: 22px;
    left: 62px;
  }
  .loading div:nth-child(3) {
    animation-delay: -0.2s;
    top: 11px;
    left: 52px;
  }
  .loading div:nth-child(4) {
    animation-delay: -0.3s;
    top: 7px;
    left: 37px;
  }
  .loading div:nth-child(5) {
    animation-delay: -0.4s;
    top: 11px;
    left: 22px;
  }
  .loading div:nth-child(6) {
    animation-delay: -0.5s;
    top: 22px;
    left: 11px;
  }
  .loading div:nth-child(7) {
    animation-delay: -0.6s;
    top: 37px;
    left: 7px;
  }
  .loading div:nth-child(8) {
    animation-delay: -0.7s;
    top: 52px;
    left: 11px;
  }
  .loading div:nth-child(9) {
    animation-delay: -0.8s;
    top: 62px;
    left: 22px;
  }
  .loading div:nth-child(10) {
    animation-delay: -0.9s;
    top: 66px;
    left: 37px;
  }
  .loading div:nth-child(11) {
    animation-delay: -1s;
    top: 62px;
    left: 52px;
  }
  .loading div:nth-child(12) {
    animation-delay: -1.1s;
    top: 52px;
    left: 62px;
  }
  @keyframes lds-default {
    0%,
    20%,
    80%,
    100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1);
      background: #fff;
    }
  }
}

.absolute-emoji{
    position: absolute;
    bottom: 100%;
    right: 0;
}
body {
  margin: 0;
  padding: 0;
  position: relative;
  font-family: "Roboto", sans-serif;
}

  a {
    text-decoration: none;
  }

  [type~="mentions"]{
font-size: 14px;
color: ${(props) => props.theme.primary};
font-weight: 600;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.d-flex {
  display: flex;
}

.cursor-pointer {
  cursor: pointer;
}

.justify-content-end {
  justify-content: flex-end;
}

.align-items-center {
  align-items: center;
}

.border-0 {
  border: none;
}

.rotate-135 {
  transform: rotate(135deg);
}

.rotate-90 {
  transform: rotate(90deg);
}

.rotate-minus-90 {
  transform: rotate(-90deg);
}

.opacity-50 {
  opacity: 0.5;
}

.font-7{
  font-size:7px
}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.position-relative {
  position: relative;
  .head-call{
    display: flex;
    align-items: center;
  }
}

.w-100 {
  width: 100%;
}

.max-w-180 {
  max-width: 180px;
}

.max-w-135 {
  max-width: 135px;
}
.max-w-145 {
  max-width: 145px;
}
.max-w-160 {
  max-width: 160px;
}
.install-app{
    background-color: ${(props) => props.theme.bgcolordarkblue}; 
    color: ${(props) => props.theme.textcolordwhite3}; 
    font-size: 14px;
    font-style: normal;
    padding: 15px;
    position: fixed;
    right: 15px;
    bottom: 15px;
    width: 330px;
    z-index: 1;
    border-radius: 10px;
    p{
      margin-bottom: 15px;
    }
    .install-actions{
      display: flex;
      gap: 15px;
      .grey-btntext{
        color: ${(props) => props.theme.textcolordwhite3};
      }
    }
}
.foreground-notification{
  background-color: ${(props) => props.theme.bgcolordarkblue}; 
    color: ${(props) => props.theme.textcolordwhite3}; 
    font-size: 14px;
    font-style: normal; 
    position: fixed;
    right: 15px;
    bottom: 15px;
    width: 330px;
    min-height: 135px;
    z-index: 1;
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    z-index: 3;
    &__left{
      background: #1E61A9;
      padding: 12px;
      font-size: 24px;
      display: flex;
      align-items: center;
    }
    &__right{
      padding: 16px;
      margin-right: 30px;
      .name{
        font-size: 14px; 
        font-weight: 700;
      }
      .msg{
        font-size: 14px;
        font-style: normal; 
        margin-top: 4px;
      }
      .remove-notification{
        position: absolute;
        right: 17px;
        top: 17px;
        font-size: 18px;
        cursor: pointer;
      }
    }
}
${lg(css`
  .d-lg-down-none {
    display: none;
  }
`)}
.ant-modal-middle-space {
  .ant-modal-content {
    padding: 24px;
    .ant-modal-header {
      margin-bottom: 24px;
    }
    .ant-modal-title {
      font-size: 20px;
      line-height: 32px;
    }
  }
}
.delete-contact-desc {
  font-weight: 500;
  span {
    font-weight: 700;
  }
}


.multi-window-title {
  .anticon-infoicon {
    align-self: flex-start;
  }
}

.multi-window-desc {
  line-height: 20px;
  font-weight: 500;
  span {
    display: block;
    margin-top: 20px;
  }
}

.multi-window-button {
  background: ${(props) => rgba(props.theme.colorlightblack, 0.5)};
  height: 40px;
  width:40px!important;
  &:hover {
    background: ${(props) => rgba(props.theme.colorlightblack, 0.5)} !important;
  }
}

.multi-window-tooltip { 
  .ant-tooltip-inner {
    width: 359px;
    span {
      display: block;
      margin-top: 10px;
    }
  }
}
.ant-pagination {
  .ant-pagination-jump-prev, 
  .ant-pagination-jump-next {
    .ant-pagination-item-container {
      .ant-pagination-item-ellipsis {
        color: ${(props) => props.theme.textcolordarkgrey};
      }
    } 
  } 
}

.ant-modal-root {
  .ant-modal-mask {
   background-color: ${(props) => rgba(props.theme.bgcolordarkblue, 0.6)}
  }
}

.ant-picker-dropdown {
  .ant-picker-date-panel {
    .ant-picker-cell-in-view {
      &.ant-picker-cell-in-range {
        &.ant-picker-cell-range-hover,
        &.ant-picker-cell-range-hover-start,
        &.ant-picker-cell-range-hover-end {
          color: ${(props) => props.theme.colordblack};
        }
      }
    }
  }
}

.ant-empty-normal  {
  .ant-empty-description {
    color: ${(props) => rgba(props.theme.textcolordblack, 0.25)};
  }
}

.star-filled {
  fill: ${(props) => props.theme.primary};
  * {
    stroke: ${(props) => props.theme.primary};
  }
}
.star-not-filled {
  * {
    stroke: ${(props) => props.theme.textcolordarkgrey};
  }
}
.uc-icon {
  * {
    stroke: ${(props) => props.theme.textcolordarkgrey};
  }
}
.uc-icon-fill {
  * {
    fill: ${(props) => props.theme.textcolordarkgrey};
  }
}
.uc-icon-fill-stroke {
  * {
    fill: ${(props) => props.theme.textcolordarkgrey};
    stroke: ${(props) => props.theme.textcolordarkgrey};
  }
}

.ant-picker {
  .ant-picker-clear {
    &:hover {
      color: ${(props) => rgba(props.theme.textcolordblack, 0.45)};
    }
  }
}

.ant-modal-range-picker {
  .ant-modal-close {
    position: static;
    display: block;
    margin: -12px -12px 10px auto;
    color: ${(props) => props.theme.textcolordarkgrey};
    &:hover {
      background-color: transparent;
    }
  }
}

// Customize scrollbar - Firefox 
* { 
  scrollbar-width: thin; 
  scrollbar-color: ${(props) => props.theme.colorlightgrey} transparent; 
} 
// Customize scrollbar - Chrome 
::-webkit-scrollbar { 
  width: 8px; 
  height: 8px;
} 
::-webkit-scrollbar-track { 
  background: transparent; 
} 
::-webkit-scrollbar-thumb { 
  background: ${(props) => props.theme.colorlightgrey}; 
  border-radius: 8px; 
}
/* style for join meeting modal */

 /* style for test speaker modal */
 .testspeaker,.logoutmodal,.desktop-permission,.bulk-registration,.registration-modal,.chat-add-contact,.img-popup-modal,.dial-phone{
  textarea,.img-popup-modal{
    background-color: transparent;
  }
  .channeltype-name{
    position: relative;
     .error{
      position: absolute;
      bottom: -20px;
      font-size: 12px;
      color: ${(props) => props.theme.colordanger}!important;
      
    }
  }
  .channel-title{
    display: flex;
    align-items: center;
    .profilecover-icon-wrapper{
       display: flex;
      align-items: center;
      width: 24px;
      height: 24px;
      overflow: hidden;
      border-radius: 100%;
      margin-right: 16px;
      background: transparent;
      font-size: 12px;
      color: ${(props) => props.theme.textcolordwhite3};
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      font-size: 6px;
       .bg-color {
          width: 100%;
          height: 100%;
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
        }
    }
   .badge-wrapper {
        position: relative;
        .presence-room {
          position: absolute;
          bottom: 0;
          right: 13px;
          display: flex;
          .anticon {
            font-size: 10px !important;
            background: ${(props) => props.theme.colordwhitedark};
            color: ${(props) => props.theme.colordwhitedark};
            border-radius: 100%;
          }
        }
      }
      .ant-avatar {
        font-size: 11px;
        font-weight: normal;
        background-color: transparent;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
  }
  .chanel-type{ 
    margin-bottom: 24px;
    margin-top: 24px;
    color: ${(props) => props.theme.textcolordblack};
     .ant-radio-wrapper-disabled{
        opacity: 0.5;
      }
    .ant-radio{
      color: ${(props) => props.theme.textcolordblack};
    }
    label{
      margin-right: 24px;
      font-size: 14px;
      color: ${(props) => props.theme.textcolordblack};
    }
  }
  .avtar-wrapper{
    position: relative;
    display: inline-block;
    .profile-pic{
          width: 100px;
            height: 100px;
            border-radius: 100%;
            overflow: hidden;
            display: flex;
            align-items: center; 
            justify-content: center;
            .bg-color {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
        }
            .ant-avatar{
              width: 100%;
              height: 100%;
              border-radius: 100%;
              overflow: hidden;
              border: none;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 34px;
            }
          .ant-btn{
                width: 33px!important;
                height: 33px!important;
                border-radius: 100%;
                position: absolute;
                right: 0;
                bottom: 0;
                border: 2px solid ${(props) => props.theme.bgcolordwhite};
                .anticon{
                      transform: scale(1);
                } 
        }
    }
      
  }

  .channel-topic{
    border-top: 1px solid ${(props) => props.theme.colorbluegreylight};
    padding: 16px 0;
    .data{
      color: ${(props) => props.theme.textcolordarkgrey};
      margin-top: 10px;
    }

  }
  .add-member-channel{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .download-title{
    display: flex;
    justify-content: space-between;
    .download-list{ 
       color: ${(props) => props.theme.primary};
       font-size: 14px;
    }
    &.delete-webinar-title{
      font-size: 16px;
      justify-content: flex-start;
      .anticon{
        font-size: 20px;
        margin-right: 18px;
         color: ${(props) => props.theme.colordanger};
      }
    }
  }
  .admin-member{
    font-size: 14px;
    color: ${(props) => props.theme.textcolordarkgrey};
    padding: 24px 0 0 40px;
  }
  .logout-title{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .anticon{
      margin-top: 3px;
      margin-right: 16px;
    }
  }
  .dialphone{
    font-size:20px;
    display: block;
    vertical-align: top;
    font-weight: 500;
    margin-bottom:15px;
  }
  .logouttext{
    margin-left: 4px;
    display: inline-block;
    vertical-align: top;
    font-weight: 500;
  }
  .remove-webinar{
     color: ${(props) => props.theme.textcolordarkgrey};
     font-size: 14px;
     margin: -15px 0 0 38px;
  }
  .share-webinar-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .modal-details{
      font-size: 12px;
      font-weight: normal;
      color: ${(props) => props.theme.textcolordarkgrey};
      margin-top:4px;
    }
     .ant-btn {
        border-color: transparent;
        padding: 0 15px;
        background-color: transparent;
        outline: none;
        box-shadow: none;
        cursor: pointer;
        .anticon-copylink{
          font-size: 20px;
        }
      }
  }
  .share-webinar-wrap{
    margin-top: -10px;
    .mail-validate{
      font-size: 12px;
      color: ${(props) => props.theme.colorlightgrey}!important;
      margin-top: 3px;
    }
    .error{
      font-size: 12px;
      color: ${(props) => props.theme.colordanger}!important;
    }
    .ant-form-item{
      margin-bottom: 0;
    }
    .mail-chips{
      list-style: none;
      border-radius: 8px;
      border: 1px solid ${(props) => props.theme.bgborderlight};
      padding: 12px;
      margin-top: 12px;
      background-color: ${(props) => props.theme.bglightblue};
       height: 132px;
    overflow: auto;
      li{
        display: inline;
      }
      .ant-tag{
          border-radius: 50px;
          height: 32px; 
          display: inline-flex;
          align-items: center;
          margin: 3px 5px;
          overflow: hidden;
          white-space: pre;
          text-overflow: ellipsis;
          background-color: ${(props) => props.theme.multipleselectcolor};
          border-color: ${(props) => props.theme.multipleselectcolor};
          span{
            display: flex;
            align-items: center;
          }
          .anticon{
                font-size: 17px;
              margin-left: 8px;
              cursor: pointer;
              color: ${(props) => props.theme.colorlightblack};
          }
      }
    }
  }
   .ant-modal-content{
      padding: 24px;
      .ant-modal-header{
        margin-bottom: 22px;
        .ant-modal-title{
              font-size: 20px;
              color: ${(props) => props.theme.textcolordblack};
              .subtitle{
                font-size: 14px;
                font-weight: normal;
                color: ${(props) => props.theme.colorbluegrey};
                margin-top: 5px;
              }
              .refresh-wrapper{
                position: relative;
              }
              .refresh-list{
                cursor: pointer;
                  position: absolute;
                  top: 0;
                  right: 0;
                  color: ${(props) => props.theme.textcolordarkgrey};
              }
        }
      }
      .ant-modal-footer{
        .footerleft{
          display: flex;
          align-items: center;
          height: 100%;
          }
        .footerright {
              display: flex;
              justify-content: flex-end;
          }
      }
      .ant-modal-close {
          display: none;
      }
       .ant-modal-body{
        .ant-btn{
          padding: 0 12px;
          width: 100%;

        }
        .select-tag{
          list-style: none;
              max-height: 400px;
              overflow: auto;
          li{
            padding: 12px;
            display: flex;
            align-items: center;
            margin-bottom: 6px;
            background: ${(props) => props.theme.bglightblue};
            font-size: 14px;
            justify-content: space-between;
            border-radius: 8px;
            
          &:hover{
             background: ${(props) => props.theme.bgcolordarkblue};
             color: ${(props) => props.theme.textcolordwhite3};
            .remove-tag{
              opacity: 1;
            }
          }
          .tag-detail{
            display: flex;
            align-items: center;
             span{
            display: flex;
            align-items: center;
            margin-right: 12px;
            }
          }
          .remove-tag{
            display: flex;
            align-items: center;
            opacity: 0;
            color: ${(props) => props.theme.colordanger};
          }
         

          }
        }
        .testblock{
          padding: 24px;
          background: ${(props) => props.theme.testblock};
          border: 1px solid  ${(props) => props.theme.colorbluegreylight};
          border-radius: 8px;
          background: ${(props) => props.theme.testblock};
          &:first-child{
            margin-bottom: 24px;
          }
          .ant-typography{
            color: ${(props) => props.theme.textcolordblack};
            margin-bottom: 20px;

          }
        }
        .ant-select{
          width: 100%;
        }
        .bulk-csv-upload{
          .csv-wrapper{
               display: flex;
                align-items: center;
                color: ${(props) => props.theme.textcolordblack};
                position: relative;
                padding-bottom: 40px;
                margin-bottom: 20px;
                font-size: 14px;
                .anticon-alertcircleicon{
                  font-size: 14px;
                  color: ${(props) => props.theme.primary};
                }
                .ant-upload-wrapper{
                  margin: 0 18px;
                  .ant-upload-list-item-progress{
                    display: none;
                  }
                  .ant-upload-list{
                        position: absolute;
                        left: 0;
                        display: flex;
                        align-items: center;
                        bottom: 0;
                        background-color: transparent!important;
                        .ant-upload-list-item{
                          background-color: transparent!important;
                          .ant-upload-list-item-name{
                            font-size: 14px;
                            max-width: 120px;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                          }
                          .ant-upload-list-item-actions{
                            .ant-btn{
                              text-align: left;
                              background-color: transparent;
                            }
                          }
                        }
                        .ant-upload-list-item-name{
                          padding-left: 0;
                        }
                        .ant-upload-icon{
                          display: none;
                        }
                  }

                  .ant-btn{
                    min-width: 112px;
                  }
                }
                .ant-btn{
                  width: auto;
                }
          }
          .wrong-value{
            p{
              font-size: 16px;
              font-weight: 500;
                margin-bottom: 16px;
                &.notice{
                  font-size: 14px;
                  font-weight: normal;
                  color: ${(props) => props.theme.colorbluegrey};
                }
              .anticon-alertcircleicon{
                font-size: 20px;
                margin-right: 10px;
                 color: ${(props) => props.theme.colordanger};
                 display: inline-block;
                 vertical-align: middle;
               
              }
            }
          }
        }
        .person-search{
          position: relative;
          .personsearch{
            .ant-input{
              padding-left: 56px;
              background-color: transparent;
              border-radius: 8px;
            }
            .ant-btn{
              background-color: transparent;
              color: ${(props) => props.theme.textcolordblack};  
            }
            
            
          }
          .ant-input-group-addon{
            display: none;
          }
          .search-icon{
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-right: 1px solid ${(props) =>
                  props.theme.textcolordarkgrey}; 
                margin-right: 10px;
                padding-right: 10px;
          }
          .ant-select-selection-placeholder,.ant-select-selector{
            padding-left: 42px;
          }
        }
        .person-search-result{
          max-height: 140px;
          overflow: auto;
          background: ${(props) => props.theme.bglightblue};
          border: 1px solid ${(props) => props.theme.bordercolordlightblue};
          margin-top: 20px;
          margin-bottom: 20px;
          border-radius: 8px;
          padding: 0 12px 12px;
          .space-wrap{
            gap: 0!important;
            flex-wrap: wrap;
          }
        }
        .person-suggestions{
          margin-top: 24px;
          .loader-wrapper{
            height: 355px;
            > div{
              height: 100%;
            }
          }

          ul{
             list-style: none;
              height: 425px;
              overflow: auto;
              margin: 15px 0;
            li{
                padding: 12px;  
                display: flex;
                align-items: center;
                margin-bottom: 6px;
                background: ${(props) => props.theme.bglightblue};
                font-size: 14px; 
                border-radius: 8px;
                justify-content: space-between;
                .username{
                  font-size: 14px; 
                 > span{
                    margin: 0;
                    margin-right: 5px;
                    white-space: pre-wrap;
                    span{
                      margin: 0;
                    }
                  }
                }
                .mail{
                  font-size: 12px;
                  color: ${(props) => props.theme.textcolordarkgrey};
                }
                .person-name{
                  display: flex;
                  align-items: center;
                   .presence-room {
                         position: absolute;
                         top: 23px;
                          right: 0px;
                      .anticon {
                        font-size: 12px !important;
                        background: ${(props) => props.theme.colordwhitedark};
                        color: ${(props) => props.theme.colordwhitedark};
                        border-radius: 100%;
                        margin: 0;
                      }
                    }
                  .profile{
                    width: 32px;
                    height: 32px; 
                    border-radius: 100%;
                    position: relative;
                    .ant-avatar{
                      margin: 0;
                      border-radius: 0;
                      width: 100%;
                      height: 100%;
                      border-radius: 100%;
                      overflow: hidden;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      font-size: 15px;
                      color: ${(props) => props.theme.textcolordwhite3};
                      border: none;
                      .bg-color {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        overflow: hidden;
                      }
                    }
                    img{
                      width: 100%;
                      height: 100%;
                    }
                  }
                }
                .action{
                  display: flex;
                  .remove-person{
                    &:first-child{
                      margin-right: 10px;
                    }
                  }
                }
                .admin-lable{
                  font-size: 12px;
                  color: ${(props) => props.theme.primary};
                }
                .remove-person{ 
                   font-size: 22px;
                   color: ${(props) => props.theme.colordanger};
                   cursor: pointer;
                }
                button,.remove-person{
                  opacity: 0;
                  visibility: hidden;
                  span{
                    margin: 0;
                  }
                }
                span{
                  display: flex;
                  margin-right: 12px;
                }
                 &:hover{
                background: ${(props) => props.theme.bgcolordarkblue};
                color: ${(props) => props.theme.textcolordwhite3};
                .admin-lable{
                       color: ${(props) => props.theme.textcolordwhite3};
                }
                button,.remove-person{
                  opacity: 1;
                  visibility: visible;
                }
                  .mail{
                  font-size: 12px;
                  color: ${(props) => props.theme.colorlightgrey};
                }
                .remove-tag{
                  opacity: 1;
                }
                 .tag-detail{
                  display: flex;
                  align-items: center;
                  span{
                  display: flex;
                  align-items: center;
                  margin-right: 12px;
                  }
                }
                .remove-tag{
                  display: flex;
                  align-items: center;
                  opacity: 0;
                  color: ${(props) => props.theme.colordanger};
                }
              }
            }
          }
          .leave-group {
            display: inline-block;
            text-align: left;
            width: auto;
          }
          .danger{
            color: ${(props) => props.theme.colordanger}
          }
        }
       }
    }
    .desk-permission{
      .title{
        display: flex;
        align-items: center;
        margin-bottom: 24px;
        .anticon{
          font-size: 32px;
          margin-right: 13px;
        }
      }
      .img-wrapper{
        text-align: center;
        margin-bottom: 24px;
      }
      .check-wrapper{
        margin-bottom: 24px;
      }
    }

 }
 .img-popup-modal{
  .ant-modal{ 
    .ant-modal-body{
      justify-content:center;
      img{ 
          width:auto!important;
            max-height: 90vh;
                max-width: 100%;
      }
    }
  }
  .ant-modal-content{
    padding: 0;
    background-color: transparent;
    .ant-modal-body{
      display: flex;
    }
  }
 }
  .bg-effect-dropdown {
    .ant-space-item {
      width: 100%;
    }
  }
 .audiotest-wrapper{
      margin: 15px 0;

  &__label{
    font-size: 12px;
    line-height: 18px;
    color: ${(props) => props.theme.textcolordblack};
    font-weight: 700;
  }
  .audio-test, .audio-speaker-test{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    div{
      width: 34px;
      height: 11px;
      border-radius: 2px;
      background:  ${(props) => props.theme.audiosound};
      margin-right: 1px;
      transition: 0.2s ease all;
      &:hover{
        background: ${(props) => props.theme.colorsuccess} ;
      }
    }
  }
 }
 .disable{
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;
 }

 .pointer-auto{
  pointer-events: auto;
 }
 /* select default style override */
 .ant-select-item{
  &.ant-select-item-option{ 
    &.ant-select-item-option-selected{ 
      color: ${(props) => props.theme.textcolordblack}!important;
          background-color: transparent; 
      }
       &.ant-select-item-option-active{
        color: ${(props) => props.theme.textcolordwhite3};
       }
    }
 }
 .ant-select-dropdown{
   background-color: ${(props) => props.theme.colordwhitedark};
   &.ant-select-dropdown-m-w-150 {
    min-width: 155px !important;
   }
   &.all-days{
    .rc-virtual-list-holder{
      max-height:306px!important;
    }
   }
   .ant-select-item {
    &.ant-select-item-group {
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      color: ${(props) => props.theme.colorlightblack};
    }
  }
 }
  .ant-select-dropdown-bordered,
  .ant-dropdown-bordered .ant-dropdown-menu,
  .ant-dropdown-menu-submenu .ant-dropdown-menu {
  padding: 0;
  border-radius: 8px;
  box-shadow: 0px 7px 33px -2px ${(props) =>
    rgba(props.theme.colortileblack, 0.05)};
  .ant-select-item,
  .ant-dropdown-menu-item,.ant-dropdown-menu-submenu {
    padding: 9px 12px;
    border-radius: 0;
    font-size: 12px;
    min-height: 38px;
    .ant-dropdown-menu-submenu-title{
      padding:0;
    font-size: 12px;

    }
    &:not(:last-child) {
      border-bottom: 1px solid ${(props) => props.theme.colorbluegreylight}; 
    }
  }
 }
 .ant-dropdown-bordered .ant-dropdown-menu,
 .ant-dropdown-menu-submenu .ant-dropdown-menu {
    overflow: auto;
    .dropdown-item-custom-range {
      color: ${(props) => props.theme.primary};
    }
    .ant-dropdown-menu-item:hover {
      background-color: ${(props) => props.theme.bgcolordarkblue}; 
      color: ${(props) => props.theme.textcolordwhite3};
      .dropdown-item-custom-range {
        color: ${(props) => props.theme.textcolordwhite3};
      } 
      .uc-icon-fill {
        * {
          fill: ${(props) => props.theme.textcolordwhite3};
        }
      }
      .uc-icon-fill-stroke {
        * {
          fill: ${(props) => props.theme.textcolordwhite3};
          stroke: ${(props) => props.theme.textcolordwhite3};
        }
      }
      .uc-icon {
        * {
          stroke: ${(props) => props.theme.textcolordwhite3};
        }
      }
      .star-not-filled {
        * {
          stroke: ${(props) => props.theme.textcolordwhite3};
        }
      }
    }
    .ant-dropdown-menu-item-disabled {
      color: ${(props) => rgba(props.theme.textcolordblack, 0.25)};  
    }
 }
 /* Tool-Tip Component CSS Started */
 .ant-tooltip {
  .ant-tooltip-inner {
    border-radius: 2px;
    font-size: 14px;
    background-color: ${(props) => props.theme.bgcolordblack} ;
    color: ${(props) => props.theme.textcolordwhite};
  }
  .ant-tooltip-arrow{
    &::before{
       background-color: ${(props) => props.theme.bgcolordblack} ;
    }
  }
  .net-test{
    min-width:180px;
    ul{
      li{
        display:flex;
        justify-content:space-between;
        font-size:12px;
        .bad {
        color: ${(props) => props.theme.colordanger};
      }
      .good {
        color: ${(props) => props.theme.orangeopacity};
      }
        b{
          font-size:14px;
        }
      }
    }
  }
  }
  /* style for select time picker */
  .ant-picker-dropdown {
    .ant-picker-time-panel-column{
      &::after{
        content: none;
  }
    }
    .ant-btn-primary{
      font-size: 14px;
      height: auto;
      padding: 6px 13px;
      box-shadow: none;
      &:disabled{
        color: ${(props) => props.theme.textcolordwhite3};
        border-color: transparent;
        background-color: ${(props) => props.theme.primary};
        opacity: 0.5;
      }
    }
  }
  /* style for modal share recording modal */
  .sharerecordingmodal,.registration-modal{
    .registration-details{
      .meeting-table{
            max-height: 400px;
            overflow: auto;
      }
    }
    .connection-head{
      display: flex;
      align-items: center;
      .anticon{
        font-size: 18px;
      }
      .title{
        font-size: 17px;
        color: ${(props) => props.theme.colorlightblack};
        margin-left: 11px;
      }
    }
    .connection-allow{
      font-weight: 500;
      font-size: 16px;  
      line-height: 19px;
      margin: 18px 0;
    }
    .connection-name{
      padding-top: 10px;
      border-bottom: 1px solid ${(props) => props.theme.colorsharpgrey};
        .person-wrap {
    display: flex;
    justify-content: space-between;
    font-weight: normal;
    padding: 8px 0;
    margin-bottom: 5px;
    &__left {
      display: flex;
      align-items: center;
      span { 
        font-size: 14px;
      }
      .details{
        display: block;
         color: ${(props) => props.theme.textcolordarkgrey};
      }
    }
    &__right {
      font-size: 12px;
      display: flex;
      align-items: flex-start; 
      flex-direction: column;
      margin-left: 10px;
    }
  }
    }

    .connection-permission{
      font-size: 14px;
      color: ${(props) => props.theme.colorlightblack};
      font-weight: 700;
      .check{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 15px 0;
      }
      > span{
        font-size: 13px;
        display: block;
        font-weight: normal;
        color: ${(props) => props.theme.colorbluegrey};
        margin-top: 6px;
        margin-bottom: 20px;
      }
    }
    .password-label{
      span{
        color: ${(props) => props.theme.textcolordarkgrey};
      }
    }
    .copylink-btn{
          word-wrap: break-word;
        white-space: normal;
        width: 100%;
        display: flex;
        word-break: break-all;
        text-align: left;
        margin-bottom: 15px;
        margin-top: 15px;
        height: auto;
        padding: 0;
        align-items: flex-start;
    }
      .share-email-button-wrapper{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        max-width: 404px;
        position: relative;
        .ant-form-item{
          margin-right: 10px;
        }
        .error{
          position: absolute;
          bottom: -18px;
          font-size: 11px;
          color: ${(props) => props.theme.colordanger};
        }
      }
      .ant-modal-header{
        margin-bottom: 25px;
        .ant-modal-title{
          font-size: 20px;
          font-weight: 700;
        }
      }
      .ant-modal-close{
        display: none;
      }
      .ant-modal-body{
        .download-recordings{
          .meeting-table{
            padding: 0;
          }
          .ant-table { 
              .ant-table-cell{
                    padding: 12px 17px;
                    p{
                          max-width: 160px;
                    }
              } 
          }
        }
        .ant-tabs-nav{
          &:before{
            border-bottom-color: ${(props) => props.theme.multipleselectcolor};
          }
        }
        .ant-tabs-tab{
          font-size: 14px;
        }
        .email-wrapper{
          .label{
            font-size: 12px;
          color: ${(props) => props.theme.textcolordarkgrey};
          margin-bottom: 10px;
          }
          &.w-full{
            .ant-form-item-control-input{
              max-width: 100%;
            }
             .ant-input-prefix {
            border-right: 1px solid ${(props) =>
              props.theme.colorbluegreylight};
            border-radius: 2px;
            padding-right: 12px;
            margin-right: 12px;
           }
          }
          .ant-form-item-control-input{
            max-width: 350px;
          }

        }
        .contacts-head{
          font-size: 16px;
          font-weight: 700;
          margin-bottom: 21px;
          margin-top:15px;
        }
        .contacts-list{
          list-style: none;
          &.attendees{
                max-height: 290px;
               overflow: auto;
            .contacts-list__right{
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: center; 
                

              .name{
                display: block;
                font-size: 14px;
              }
              .attendee{
                font-size: 12px;
                display: block;
                color: ${(props) => props.theme.textcolordarkgrey}; 
              }
            }
            .contacts-list__block{
                  justify-content: flex-start;
                  padding: 12px;
                  margin-bottom: 6px;
                 
              .profile-pick{
                width: 32px;
                height: 32px;
                border-radius: 100%;
                background:${(props) => props.theme.primary};
                font-size: 12px;
                color: ${(props) => props.theme.textcolordwhite3}; 
                display: flex; 
                justify-content: center; 
                align-items: center;
                border: 1px solid ${(props) => props.theme.textcolordwhite3};
                overflow: hidden;
                .anticon{
                      font-size: 16px;
                }
              }
            }
          }
          &__block{
            display: flex;
            justify-content: space-between;
            padding:0 20px;
            background: ${(props) => props.theme.contactlist};
            border-radius: 8px;
            margin-bottom: 20px;
            min-height: 56px;
            .anticon{
              font-size: 24px;
            }
          }
          &__left{
            display: flex;
            align-items: center;
            .img-wrapper{
              width: 32px;
              height: 32px;
              border-radius: 50%;
              overflow: hidden;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: 10px;
            }
          }
          &__right{
            display: flex;
            align-items: center;
          }
        }
      }
      .ant-modal-footer{
        .footerright{
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
      }
  }
  /* style for modal download recording modal */
  .downloadrecording{
    .form-group{
      position: relative;
       .error{
       font-size: 12px;
    color: ${(props) => props.theme.colorlightgrey};
    position: absolute;
    bottom: -23px;
    &.danger {
      color: ${(props) => props.theme.colordanger};
    }
    }
    }
   
    .ant-modal-content{
      padding: 0;
       .ant-modal-close{
          display: none;
        }
    }
     .ant-modal-header{
      margin-bottom: 0;
        padding: 33px 33px 25px;
        border-bottom: 1px solid ${(props) => props.theme.colorbluegreylight};
        .anticon{
          margin-right: 30px;
        }
        .ant-modal-title{
          font-size: 32px;
          font-weight: 700;
        }
        .title-wrapper{
          display: flex;
        }

      }
      .ant-modal-body{
        padding: 40px 33px;
        .title-wrapper{
          display: flex;
          justify-content: space-between;
          font-size: 16px;
          font-weight: 700;
          flex-wrap: wrap;
          color:  ${(props) => props.theme.colorbluegrey};
          margin-bottom: 16px;
          span{
             color:  ${(props) => props.theme.colorlightblack};
          }
        }
      }
    .ant-modal-footer{
      padding:0 33px 40px 33px;
        .footerright{
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
      }
  }
  /* style for modal set frequency */
  .setfrequency{
    .ant-modal-header{
      margin-bottom: 0;
      .anticon{
          margin-right: 30px;
        }
        .ant-modal-title{
          font-size: 20px;
          font-weight: 700;
        }
        .title-wrapper{
          display: flex;
        }

      }
      .ant-modal-footer{
        .footerright{
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
      }
      .ant-modal-close-x{
        display: none;
      }
      .ant-modal-body{
        padding: 24px 0 0;
          .form-group {
          display: flex;
          flex-direction: column;
          margin-bottom: 24px;
           label {
          font-weight: 400;
          font-size: 14px;
          margin-right: 15px;
          color: ${(props) => props.theme.textcolordblack};
        }
        }
        .repeat-time{
        display: flex;
        justify-content: space-between;
        gap: 6px;
        .repeat-title{
          font-size: 14px;
        }
        > div{
          display: flex;
          align-items: center;
        }
        .input-wrap{
          max-width: 42px;
          .ant-input-number{
            background-color: transparent;
            height: 42px;
            display: flex;
            align-items: center;
            &.ant-input-number-disabled{
              color: ${(props) => props.theme.textcolordarkgrey};
            }
          }
          .ant-form-item{
            margin: 0;
          }

        }
        }
        .month-day{
          width:100%;
        }
        .repeat-lable{
          margin-top: 24px;
          font-size: 14px;
        }

      }
      .day-select,.month-select,.year-select {
        margin-top: 24px;
        ul {
            display: flex;
            flex-wrap: nowrap;
            font-size: 16px;
            justify-content: space-between;
            .form-group{
              margin-bottom: 0;
              position: relative;
              input{
                padding: 0;
                height: initial;
                width: initial;
                margin-bottom: 0;
                cursor: pointer;
                position: absolute;
                opacity: 0;
                &:checked{
                  + label{
                    border: 1px solid ${(props) =>
                      props.theme.bgcolordlightblue};
                    background-color: ${(props) => props.theme.bglightblue};
                  }
                }
              }
              .day-select__name{
                width: 35px;
                height: 35px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 8px;
                position: relative;
                cursor: pointer;
                margin: 0;
              }
            }
        }
      }
      .ant-picker-input{
        .ant-picker-suffix {
          font-size: 14px;
          color: ${(props) => props.theme.colorbluegrey};
        }
      }
      

  }
  /* popconfirm popover */
  .ant-popover{
    .ant-popover-inner{
       background: ${(props) => props.theme.colordwhitedark};
       .ant-popconfirm-message-title{
        font-size: 14px;
       }
       .ant-btn-default{
        background-color: transparent;
       }
    }
    .ant-popover-arrow{
      &:after{
        background: ${(props) => props.theme.colordwhitedark};
      }
    }
    &.profile-popover{
      .ant-popover-inner{
        padding: 15px 24px;
        min-width:284px;
        .ant-popover-title{
          font-size:20px;
        }
      } 
        .person-wrap {
    display: flex;
    justify-content: space-between;
    font-weight: normal;
    padding: 8px 0;
    &__left {
      display: flex;
      align-items: center;
       .subdetails {
        margin-left: 12px;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        width: auto;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        span {
          width: auto;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          &:first-child{
            font-weight: 600;
          }
          &.mail {
            color: ${(props) => props.theme.socialbtncolor};
          }
        }
      }
    }
    &__right {
      font-size: 12px;
      display: flex;
      align-items: center;
      color: ${(props) => props.theme.primary};
      opacity: 0;
      display: none;
    }
  }
    }
    &.chatprofilecard,&.network-test-block{
      .network-test{
        background-color:#000000;
        padding:8px;
        border-radius:2px
      }
      .ant-popover-inner{
          padding: 0;
        background: transparent;
        box-shadow: none;
      }
        
    }
       &.emoji-picker-popover{
      .ant-popover-inner{
        padding: 0;
        .EmojiPickerReact{
          background-color: ${(props) => props.theme.colordwhitedark};
          li{
            &.epr-emoji-category{
              >.epr-emoji-category-label{
                background-color: ${(props) => props.theme.colordwhitedark};
              }
            }
          }
        }
      }
    }
      &.emoji-blocks{
        ul{
          list-style:none;
          .chat-action__item{
            span{
              cursor:pointer; 
            }
          }
        }
      }

      &.gifpopover{
        .ant-popover-inner{
              height: 400px;
    width: 400px;
     .person-search{
          position: relative;
          .personsearch{
            .ant-input{
              padding-left: 56px;
              background-color: transparent;
              border-radius: 8px;
            }
            .ant-btn{
              background-color: transparent;
              color: ${(props) => props.theme.textcolordblack};  
            }
            
            
          }
          .ant-input-group-addon{
            display: none;
          }
          .search-icon{
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-right: 1px solid ${(props) =>
                  props.theme.textcolordarkgrey}; 
                margin-right: 10px;
                padding-right: 10px;
          }
          .ant-select-selection-placeholder,.ant-select-selector{
            padding-left: 42px;
          }
        }
        }
        .gif-blocks{
          height:310px;
          overflow:auto;
          margin-top:12px;
          border-radius:4px;
        }
      }
  }
  
  /* ant message */
  .ant-message-notice {
    .ant-message-notice-content{
       background-color: ${(props) =>
         props.theme.bgcolordwhiteblack}!important ;
       color: ${(props) => props.theme.textcolordblack};
  }
  }
  /* ant dropdown menu */
  .ant-dropdown ,.ant-dropdown-menu-submenu{
    .ant-dropdown-menu{
        background-color: ${(props) => props.theme.bgcolordwhiteblack} ;
        .ant-dropdown-menu-item-selected{
           color: ${(props) => props.theme.textcolordwhite3};
        }
    }
    &.chatmenu-dropdown,&.presence-indicator,&.attachment-donwload{
    
      .items-wrap{
        display: flex;
        align-items: center;
        .anticon{
          margin-right: 7px!important;
        }
      }
      .ant-dropdown-menu{
          background-color: ${(props) => props.theme.bgcolordwhiteblack};
          min-width :163px ;
          padding: 0;
          border-radius: 8px;
          li{
            border-bottom: 1px solid ${(props) =>
              props.theme.colorbluegreylight};
            border-radius: 0;
            min-height: 34px;
            padding-left: 11px;
            &.active-filter {background-color: ${(props) =>
              props.theme.colorbluegreylight};}
            &.ant-dropdown-menu-item-selected{
              &:hover{
                background-color: ${(props) => props.theme.colorbluegreylight};
              }

            }
            .indicators{
              .anticon{
                margin-right: 5px;
                 background: transparent;
                 color: ${(props) => props.theme.colordwhitedark};
              }
            }
            .anticon{
              margin-right: 12px;
               color: ${(props) => props.theme.textcolordarkgrey};
               &.anticon-archive{
                color: ${(props) => props.theme.colordanger}
               }
               &.anticon-copylink{
                font-size: 16px;
               }
            }
            .danger{
               color: ${(props) => props.theme.colordanger}

            }
            &:last-child{
              border-bottom: none;
            }
            .ant-btn{
                  padding: 0;
                  border: none;
                  background: transparent;
                  height: auto;
                  span{
                    margin: 0;
                  }
                  .anticon{
                    margin-right: 12px;
                    color: ${(props) => props.theme.textcolordarkgrey};
                     &.anticon-archive, &.anticon-leavegroup{
                      color: ${(props) => props.theme.colordanger}
                    }
                  }
            }
          }
        }
          .ant-dropdown-menu-item {
                color: ${(props) => props.theme.textcolordblack}!important;
                font-size: 14px;
              }
    }
    &.userprofile-dropdown{
      z-index: 999;
    } 
  }
  /* style for copy-context */
  .copy-context{
        position: absolute;
    z-index: 1;
    border-radius: 5px;
    overflow: hidden;
     padding: 0;
         width: 100px;
  border-radius: 8px;
  box-shadow: 0px 7px 33px -2px ${(props) =>
    rgba(props.theme.colortileblack, 0.05)};
    background-color: ${(props) => props.theme.bgcolordwhiteblack};
    padding:5px 0;
    button{
         padding: 9px 12px;
    border-radius: 0;
    font-size: 12px;
    min-height: 34px;
    border:none;
    background-color: ${(props) => props.theme.bgcolordwhiteblack};
     color: ${(props) => props.theme.textcolordblack};
        width: 100%;
        cursor: pointer;
        &:hover {
            background: ${(props) => props.theme.colorsharpgrey};
             color: ${(props) => props.theme.colordblack};
          }
    }
  }
  /* style for mandatory star * */
  .mandatory{
    color: ${(props) => props.theme.colordanger};
    margin: 0 6px;
    &.small
      {
        margin: 0px 3px;
        font-size: 12px;
      }
  }
  .golive-wrap{
        background: ${(props) => props.theme.textcolordwhite3};
    position: absolute;
    top: 50px;
    left: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 2px 6px ${(props) => props.theme.bordercolorlightgrey};
      
    p{
      font-size: 14px;
      margin-bottom: 5px;
    }
    .golive-meeting{ 
      margin-top: 10px;
  }
  }

  .invite-wrap{
        background: ${(props) => props.theme.textcolordwhite3};
    position: absolute;
    bottom: 50px;
    right: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 2px 6px ${(props) => props.theme.bordercolorlightgrey};
      
    p{
      font-size: 14px;
      margin-bottom: 5px;
    }
    .golive-meeting{ 
      margin-top: 10px;
  }
  }
  .golive-wrapper{
    border-radius: 4px;
    background:  ${(props) => props.theme.textcolordarkblue}; 
    box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.20), 0px 4px 8px 0px rgba(0, 0, 0, 0.30);
    position:absolute;
    bottom:50px;
    right:50px;
    display:flex;
    height:131px;
    overflow:hidden;
    min-width: 330px;
    &__left{
      background: #1E61A9;
      display:flex;
      justify-content:center;
      align-items:center;
      padding:0 12px;
      font-size:24px;
       color:  ${(props) => props.theme.textcolordwhite3}; 
    }
    &__right{
      display:flex;
      padding:12px;
      font-size:14px;
      flex-direction:column;
      justify-content:center;
      align-items:flex-start;
      color:  ${(props) => props.theme.textcolordwhite3}; 
      .cross-icon{
        position: absolute;
    right: 10px;
    top: 10px;
    font-size: 16px;
    cursor: pointer;
      }
      button{
        margin-top:16px;
        &:focus{
          background-color:#1E61A9;
        }
      }
    }
  }
  /* style for msg suggestion */
 .msg-sug {
      > div {
        padding: 0px;
        box-shadow: 0px 2px 6px
          ${(props) => rgba(props.theme.bgcolordblack, 0.24)};
        background-color: ${(props) => props.theme.colordwhitedark};
        border-radius: 8px;
        width: 213px;
        border: transparent;
        max-height: 326px;
        overflow: auto;
        > div {
          &:not(:first-child) {
            border-top: 1px solid ${(props) => props.theme.colorbluegreylight};
          }
        }
      }
    }

  /* style for badges */
  .ant-badge{
    &.small{
      &.hidden{
        .ant-scroll-number{ 
   visibility: hidden;
   opacity: 0;
  }     
      }
         .ant-scroll-number{ 
    min-width: 8px;
    height: 8px;  
    font-size: 10px;
    line-height: 13px;    
    box-shadow: none; 
    .ant-scroll-number-only-unit{
          font-size: 10px!important;
    }
  }     
    }
    .ant-scroll-number{ 
    min-width: 14px;
    height: 14px;  
    font-size: 10px;
    line-height: 13px;    
    box-shadow: none; 
    .ant-scroll-number-only-unit{
          font-size: 10px!important;
    }
  }
}
  /* style for ant-warning */
  .connecting-warning{
    background-color: #fef2e1;
    color:${(props) => props.theme.orangeopacity};
    padding:9px 12px;
    font-size:12px;
    /* &.softphone{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index:99;
      opacity: 0.8; 
      .ant-alert-close-icon{
        margin-left:auto;
        svg{
          path{
            stroke:#D98103;
 
          }
        }
      }
    } */
    .ant-alert-close-icon{
      margin-left:auto;
      .anticon-close:hover{color: #D98103}
        svg{
          path{
            stroke:#D98103;
          }
        }
      }
    .ant-alert-content{
      flex:none;
      margin-right:10px;
    }
    .anticon-alertcircleicon{
      font-size:20px;
      margin-right:10px;
    }
  }
  .ant-modal-middle-space {
    .ant-modal-content {
      padding: 24px;
      .ant-modal-header {
        margin-bottom: 24px;
      }
    }
  }
  /* style for driver popover */
  div{
    &.driver-popover{
       border-radius: 2px;
      font-size: 14px;
      background-color: ${(props) => props.theme.bgcolordblack};
      padding: 12px;
      .driver-popover-description{
        color: ${(props) => props.theme.textcolordwhite};
        font-size:14px;
      }
      .driver-popover-close-btn{
            color: ${(props) => props.theme.textcolordarkgrey};
      }
      .driver-popover-footer {
        button{
          &.driver-popover-prev-btn{
            color:${(props) => props.theme.colortextgray};
            background-color:transparent!important;
            border:none!important;
            text-shadow: none;
          }
          &.driver-popover-next-btn{
            color:${(props) => props.theme.primary};
            background-color:transparent!important;
            border:1px solid ${(props) => props.theme.primary};
            border-radius:4px;
            text-shadow: none;
          }
        }
      }
    }
  }

  .disabled-icon{
    cursor: not-allowed;

    span,svg{
      user-select:none;
      cursor:not-allowed
    }
   
  }
  .ant-picker-time-panel-cell-disabled{
    div{
      color: #eee !important;
      border-radius:3px;  
    }  
  }

.incoming-call-enter {
  opacity: 0;
  transform: translateX(100%);
}
.incoming-call-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: all 0.4s;
}
.incoming-call-enter-done {
  opacity: 1;
}

.incoming-call-exit {
  opacity: 1;
}
.incoming-call-exit-active {
  opacity: 0;
  transform: translateX(100%);
  transition: all 0.4s;
}
.incoming-call-exit-done {
  opacity: 0;
  transform: translateX(100%);
}
`
/* Tool-Tip Component CSS Ended */

export const AuthPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
  height: 100%;
  /* overflow: auto; */
  background-color: ${(props) => props.theme.colordwhitedark};
`
interface ContainerProps {
  width?: string
}
export const AuthFormContainer = styled.div<ContainerProps>`
  max-width: ${(props) => props.width};
  width: 100%;
  margin: auto;
  .btnlink {
    text-decoration: underline;
  }
`
interface HeadingProps {
  paddingBottom?: string
}
export const AuthPageHeading = styled.div<HeadingProps>`
  text-align: center;
  padding-bottom: ${(props) => props.paddingBottom};
  .otpLabel {
    span {
      padding-left: 3px;
      font-weight: 700;
      color: ${(props) => props.theme.textcolordblack};
    }
  }
`
export const Heading3 = styled.h3`
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  color: ${(props) => props.theme.textcolordblack};
`

export const Heading4 = styled.h4`
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  color: ${(props) => props.theme.textcolordblack};
`
export const Heading5 = styled.h5`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.textcolordblack};
`
export const Heading6 = styled.h6`
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: ${(props) => props.theme.textcolordblack};
`
export const ButtonWrapper = styled.div`
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  &.social-login {
    flex-direction: row;
    padding-top: 9px;
    .ant-btn {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      background: ${(props) => props.theme.textcolordwhite3};
      border: 1px solid transparent;
      border-radius: 2px;
      box-shadow: 0px 0px 3px 1px
        ${(props) => rgba(props.theme.bgcolordblack, 0.16)};
      transition: 0.2s ease all;
      &:hover {
        background: ${(props) => props.theme.colorlightgrey};
      }
      &:last-child {
        margin-right: 0;
        border: 1px solid transparent;
      }
    }
  }
`
export const InnerLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5px;
  .ant-btn {
    height: auto;
    width: auto;
    max-width: none;
    margin: 0 0 0 5px;
    text-decoration: none;
  }
  &.mb-10 {
    margin-bottom: 10px;
  }
`

export const LabelStyle = styled.span`
  font-size: 14px;
  height: auto;
  color: ${(props) => props.theme.textcolordblack};
`
export const AccInfoWrapper = styled.div`
  background-color: ${(props) => props.theme.bgcolordwhiteblack};
  border-radius: 10px;
  padding: 24px;
  /* height: 100%; */
  &.accountsettings {
    height: 100vh;
  }

  .heading5 {
    padding-bottom: 24px;
  }
`
export const FormFooter = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  padding-top: 24px;
  border-top: 1px solid ${(props) => props.theme.colorbluegreylight};
`

export const HeadBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const CardWrap = styled.div`
  border: 1px solid ${(props) => props.theme.multipleselectcolor};
  border-radius: 8px;
  padding: 12px;

  .cardrow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${(props) => props.theme.multipleselectcolor};
    padding-bottom: 12px;
    margin-bottom: 12px;
    .ant-btn {
      min-width: 243px;
    }
    &:last-child {
      border: none;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
`
export const PStyle = styled.p`
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props.theme.colorlightblack};
`

export const SpanStyle = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: ${(props) => props.theme.colorbluegrey};
`

export const SpanBold = styled.span`
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: ${(props) => props.theme.textcolordblack};
`
export const Customtagbar = styled.span`
  display: block;
  margin-top: 10px;
  .ant-tag {
    border-radius: 50px;
    height: 32px;
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    margin: 3px 5px;
    overflow: hidden;
    white-space: pre;
    text-overflow: ellipsis;
    background-color: ${(props) => props.theme.multipleselectcolor};
    border-color: ${(props) => props.theme.multipleselectcolor};
    &.primary {
      background-color: ${(props) => props.theme.primary};
      color: ${(props) => props.theme.textcolordwhite3};
    }
    .data {
      max-width: 184px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .profilecover-icon-wrapper {
      display: flex;
      align-items: center;
      width: 24px;
      height: 24px;
      overflow: hidden;
      border-radius: 100%;
      margin-right: 6px;
      background-color: ${(props) => props.theme.primary};
      font-size: 12px;
      color: ${(props) => props.theme.textcolordwhite3};
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      font-size: 12px;
      .ant-avatar {
        font-size: 11px;
        font-weight: normal;
        background-color: transparent;
        width: 100%;
        height: 100%;
        border-radius: 100%;
        border: none;
        .bg-color {
          width: 100%;
          height: 100%;
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
        }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .ant-tag-close-icon {
      font-size: 15px;
      margin-left: 7px;
    }
  }
`
