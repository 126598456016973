import React from "react"

function ArrowLeftIcon() {
  return (
    <span className="anticon anticon-arrowleft">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        fill="none"
        viewBox="0 0 12 12"
      >
        <path
          stroke="Currentcolor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M7.5 9.96L4.24 6.7a.993.993 0 010-1.4L7.5 2.04"
        />
      </svg>
    </span>
  )
}

export default ArrowLeftIcon
