import { createAsyncThunk } from "@reduxjs/toolkit"
import { message } from "antd"
import { get } from "../../../../services/api"

const VoicemailActionAPI = {
  GET_NEW_VOICEMAIL_COUNTS: "GET_NEW_VOICEMAIL_COUNTS"
}

export const voicemailSlicePrefix = (value: string) => `voicemail/${value}`

// eslint-disable-next-line import/prefer-default-export
export const getNewVoicemailsCountsAPI = async () => {
  try {
    const { data } = await get(
      "/api/v1/soft-phone/voicemail/?v_type=vmail/new&offset=0&limit=1"
    )
    return data.data.count
  } catch (error) {
    message.error("Something went wrong while getting voicemail count")
    return ""
  }
}
export const getNewVoicemailsCounts = createAsyncThunk(
  voicemailSlicePrefix(VoicemailActionAPI.GET_NEW_VOICEMAIL_COUNTS),
  getNewVoicemailsCountsAPI
)
