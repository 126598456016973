import { useEffect } from "react"
import useNetworkTest from "../../hooks/useNetworkTest"

const UDP = ({ setLogs, setErrorLogs, setIsLoading }) => {
  const udpMessage = useNetworkTest("udp")

  useEffect(() => {
    setLogs((prevState) => ({
      ...prevState,
      networkUdp: udpMessage?.message
    }))
    if (udpMessage?.message) {
      setIsLoading((prevState) => ({
        ...prevState,
        networkUdp: false
      }))
    }
  }, [udpMessage?.message])

  useEffect(() => {
    setErrorLogs((prevState) => ({
      ...prevState,
      networkUdp: udpMessage?.errorMessage
    }))
  }, [udpMessage?.errorMessage])

  return (
    <div>
      <h2>UDP</h2>
      {/* {udpMessage?.message?.map((mes) => (
        <div>{mes}</div>
      ))} */}
    </div>
  )
}

export default UDP
