import { useEffect, useRef, useState } from "react"
import useVideoBandwidth from "../../hooks/useVideoBandwidth"

const VideoBandwidth = ({ setLogs, setErrorLogs, setIsLoading }) => {
  const [isChange, setIsChange] = useState(false)
  const streamRef = useRef(null)
  const gatheredRef = useRef(null)
  // gatheredRef.current
  let gatheredStatsReport = useVideoBandwidth({
    videoStream: null,
    audioStream: null,
    localStream: streamRef.current,
    isChange,
    setIsChange
  })
  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({
        audio: true,
        video: true
      })
      .then((stream) => {
        streamRef.current = stream
        setIsChange(true)
      })
  }, [])

  // let gatheredStatsReport = useVideoBandwidth()
  useEffect(() => {
    setLogs((prevState) => ({
      ...prevState,
      videoBandwidth: gatheredStatsReport
    }))

    if (gatheredStatsReport !== null) {
      setIsLoading((prevState) => ({
        ...prevState,
        videoBandwidth: false
      }))
      const tracks = streamRef.current.getTracks()
      tracks.forEach((track) => track.stop())
      streamRef.current = null
      gatheredStatsReport = null
    }
  }, [gatheredStatsReport])

  return (
    <div>
      <h1>Video Bandwidth</h1>
      {/* {gatheredStatsReport ? (
        <ul>
          <li>Round Trip Time: {gatheredStatsReport.RoundTripTime} sec</li>
          <li>
            Bandwidth Estimate Average:{" "}
            {gatheredStatsReport.bandwidth_estimate_average} kbps
          </li>
          <li>
            Bandwidth Estimate Max: {gatheredStatsReport.bandwidth_estimate_max}{" "}
            kbps
          </li>
          <li>Packets Sent: {gatheredStatsReport.packetsSent}</li>
          <li>Packets Received: {gatheredStatsReport.packetsReceived}</li>
          <li>NACK Count: {gatheredStatsReport.nackCount}</li>
          <li>PLI Count: {gatheredStatsReport.pliCount}</li>
          <li>QP Sum: {gatheredStatsReport.qpSum}</li>
          <li>Frames Encoded: {gatheredStatsReport.framesEncoded}</li>
          <li>Frames Decoded: {gatheredStatsReport.framesDecoded}</li>
        </ul>
      ) : null} */}
    </div>
  )
}

export default VideoBandwidth
