/* eslint-disable no-bitwise */
const classNames = (classname: string, obj: Record<string, boolean>) => {
  return `${Object.keys(obj).reduce((classes, classEnabled) => {
    if (obj[classEnabled]) classes += classEnabled
    return classes
  }, "")} ${classname}`
}

export default classNames

export function generateLightColor(firstName: string | number): string {
  const specialCharacterPattern = /^[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/
  const colorsequence = [
    "#e91e63",
    "#c2185b",
    "#9c27b0",
    "#5727b0",
    "#272ab0",
    "#276bb0",
    "#57acdc",
    "#57dcbe",
    "#60c689"
  ]

  const colorIndex =
    (String(firstName)?.charCodeAt(0) - 49) % colorsequence.length // Wrap around to 0 if sequence length exceeds the number of colors

  return specialCharacterPattern.test(String(firstName))
    ? colorsequence[0]
    : colorsequence[colorIndex]
}
