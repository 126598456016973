/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react"
import { withTheme } from "styled-components"

function ChatScheduleDemoIcon({ theme: themeColor }: any) {
  return (
    <span className="anticon anticon-scheduledemo ">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="151"
        height="122"
        viewBox="0 0 151 122"
        fill="none"
      >
        <path
          d="M28.7698 22.6855H28.7061V22.7373H28.7698V22.6855Z"
          fill="black"
        />
        <path
          d="M57.3822 34.6426H57.3403V34.6924H57.3822V34.6426Z"
          fill="black"
        />
        <path
          d="M138.92 88.231V18.7061C138.92 17.1324 137.637 15.8574 136.053 15.8574H14.5581C12.9743 15.8574 11.6914 17.1324 11.6914 18.7061V88.231C11.6914 89.8048 12.9743 91.0797 14.5581 91.0797H136.035C137.617 91.0797 138.92 89.8048 138.92 88.231ZM14.7374 18.0089H135.854C136.334 18.0089 136.737 18.3874 136.737 18.8854V88.0517C136.737 88.5298 136.356 88.9282 135.854 88.9282H14.7374C14.2573 88.9282 13.8548 88.5497 13.8548 88.0517V18.8854C13.8568 18.4073 14.2573 18.0089 14.7374 18.0089Z"
          fill="#0C0C0C"
        />
        <g opacity="0.7">
          <path
            opacity="0.0323"
            d="M95.6688 108.809C95.6688 108.809 95.6688 108.789 95.6489 108.769L95.629 108.749C95.629 108.749 95.5692 108.689 95.388 108.67L93.1428 108.391H58.0298L55.6851 108.65C55.5038 108.689 55.4241 108.729 55.4241 108.729C55.4241 108.729 55.4042 108.749 55.4042 108.769L55.3843 108.789V108.849C55.3843 108.849 55.3843 108.928 55.6054 109.028C55.7249 109.088 56.5477 109.506 57.8704 109.506C59.6534 109.506 62.02 109.566 62.02 109.566L75.7298 109.626L89.4395 109.566C89.4395 109.566 91.5631 109.506 93.348 109.506C94.6907 109.506 95.3322 109.088 95.4517 109.048C95.6728 108.968 95.6728 108.928 95.6728 108.928L95.6688 108.809Z"
            fill="#F7F7F7"
          />
          <path
            opacity="0.0645"
            d="M95.6687 108.809C95.6687 108.809 95.6687 108.789 95.6487 108.769L95.6288 108.749C95.6288 108.749 95.569 108.689 95.3878 108.67L93.1427 108.391H58.0495L55.7247 108.65C55.5435 108.689 55.4837 108.729 55.4837 108.729C55.4837 108.729 55.4638 108.749 55.4638 108.769L55.4438 108.789V108.869C55.4438 108.869 55.4439 108.948 55.665 109.048C55.7845 109.108 56.5873 109.526 57.93 109.526C59.713 109.526 62.0597 109.586 62.0597 109.586L75.7495 109.646L89.4393 109.586C89.4393 109.586 91.5629 109.526 93.3478 109.526C94.6905 109.526 95.332 109.128 95.4515 109.088C95.6726 109.008 95.6726 108.968 95.6726 108.968L95.6687 108.809Z"
            fill="#EFEFEF"
          />
          <path
            opacity="0.0968"
            d="M95.6687 108.809C95.6687 108.809 95.6687 108.789 95.6487 108.769L95.6288 108.749C95.6288 108.749 95.5691 108.689 95.3878 108.67L93.1427 108.391H58.0495L55.7247 108.65C55.5435 108.689 55.4837 108.729 55.4837 108.729C55.4837 108.729 55.4638 108.749 55.4638 108.769L55.4438 108.789V108.869C55.4438 108.869 55.4439 108.929 55.665 109.048C55.7845 109.108 56.5873 109.506 57.93 109.506C59.713 109.506 62.0597 109.566 62.0597 109.566L75.7495 109.626L89.4393 109.566C89.4393 109.566 91.5629 109.506 93.3678 109.506C94.7105 109.506 95.3519 109.108 95.4714 109.068C95.6926 108.988 95.6926 108.948 95.6926 108.948V108.809H95.6687Z"
            fill="#E6E6E6"
          />
          <path
            opacity="0.129"
            d="M95.6688 108.809C95.6688 108.809 95.6688 108.789 95.6488 108.769L95.6289 108.749C95.6289 108.749 95.5691 108.689 95.3879 108.67L93.1428 108.391H58.0696L55.7448 108.65C55.5635 108.689 55.5037 108.729 55.5037 108.729C55.5037 108.729 55.4838 108.749 55.4838 108.769L55.4639 108.789V108.869C55.4639 108.869 55.4639 108.929 55.685 109.048C55.8045 109.108 56.6073 109.486 57.95 109.486C59.733 109.486 62.0797 109.546 62.0797 109.546L75.7695 109.606L89.4593 109.546C89.4593 109.546 91.5829 109.486 93.3878 109.486C94.7305 109.486 95.3719 109.108 95.4915 109.068C95.7126 108.988 95.7126 108.948 95.7126 108.948V108.809H95.6688Z"
            fill="#DEDEDE"
          />
          <path
            opacity="0.1613"
            d="M95.6688 108.809C95.6688 108.809 95.6688 108.789 95.6488 108.789L95.6289 108.769C95.6289 108.769 95.5691 108.709 95.3879 108.689L93.1428 108.391H58.0696L55.7448 108.67C55.5635 108.709 55.5037 108.749 55.5037 108.749C55.5037 108.749 55.4838 108.769 55.4838 108.789L55.4639 108.809V108.889C55.4639 108.889 55.4639 108.948 55.685 109.048C55.8045 109.108 56.6073 109.486 57.95 109.486C59.733 109.486 62.0797 109.546 62.0797 109.546L75.7695 109.606L89.4593 109.546C89.4593 109.546 91.5829 109.486 93.3878 109.486C94.7305 109.486 95.3918 109.108 95.4915 109.068C95.7126 108.988 95.7126 108.948 95.7126 108.948V108.809H95.6688Z"
            fill="#D6D6D6"
          />
          <path
            opacity="0.1935"
            d="M95.6688 108.809C95.6688 108.809 95.6688 108.789 95.6488 108.789L95.6289 108.769C95.6289 108.769 95.5691 108.709 95.3879 108.689L93.1428 108.391H58.0696L55.7448 108.67C55.5635 108.709 55.5037 108.749 55.5037 108.749C55.5037 108.749 55.4838 108.769 55.4838 108.789L55.4639 108.809V108.909C55.4639 108.909 55.4639 108.968 55.685 109.068C55.8045 109.128 56.5874 109.486 57.9301 109.486C59.7131 109.486 62.0379 109.546 62.0379 109.546L75.7277 109.606L89.4175 109.546C89.4175 109.546 91.563 109.486 93.3459 109.486C94.6886 109.486 95.35 109.128 95.4496 109.088C95.6707 109.008 95.6707 108.968 95.6707 108.968L95.6688 108.809Z"
            fill="#CECECE"
          />
          <path
            opacity="0.2258"
            d="M95.6488 108.789C95.6488 108.789 95.6488 108.77 95.6289 108.77L95.609 108.75C95.609 108.75 95.5492 108.69 95.3679 108.67L93.1228 108.371H58.0696L55.7448 108.65C55.5635 108.69 55.5037 108.73 55.5037 108.73C55.5037 108.73 55.4838 108.75 55.4838 108.77L55.4639 108.789V108.889C55.4639 108.889 55.4639 108.949 55.685 109.048C55.8045 109.108 56.5874 109.447 57.9301 109.447C59.7131 109.447 62.0379 109.487 62.0379 109.487L75.7277 109.546L89.4175 109.487C89.4175 109.487 91.563 109.427 93.3459 109.447C94.6886 109.447 95.35 109.108 95.4496 109.068C95.6707 108.989 95.6707 108.949 95.6707 108.949V108.789H95.6488Z"
            fill="#C5C5C5"
          />
          <path
            opacity="0.2581"
            d="M95.6489 108.789C95.6489 108.789 95.6489 108.77 95.629 108.77L95.6091 108.75C95.6091 108.75 95.5493 108.69 95.368 108.67L93.1229 108.371H58.0697L55.7648 108.65C55.5835 108.69 55.5237 108.73 55.5237 108.73C55.5237 108.73 55.5038 108.75 55.5038 108.77L55.4839 108.789V108.889C55.4839 108.889 55.4839 108.949 55.705 109.048C55.8245 109.108 56.6074 109.447 57.9501 109.447C59.7331 109.447 62.0579 109.487 62.0579 109.487L75.7477 109.546L89.4375 109.487C89.4375 109.487 91.583 109.427 93.366 109.447C94.7087 109.447 95.37 109.108 95.4696 109.068C95.6908 108.989 95.6908 108.949 95.6908 108.949V108.789H95.6489Z"
            fill="#BDBDBD"
          />
          <path
            opacity="0.2903"
            d="M95.6489 108.789C95.6489 108.789 95.6489 108.77 95.629 108.77L95.6091 108.75C95.6091 108.75 95.5493 108.69 95.368 108.67L93.1229 108.371H58.0697L55.7648 108.65C55.5835 108.69 55.5237 108.73 55.5237 108.73C55.5237 108.73 55.5038 108.75 55.5038 108.77L55.4839 108.789V108.889C55.4839 108.889 55.4839 108.949 55.705 109.048C55.8245 109.108 56.6074 109.427 57.9501 109.427C59.7331 109.427 62.0579 109.467 62.0579 109.467L75.7477 109.527L89.4375 109.467C89.4375 109.467 91.583 109.427 93.366 109.427C94.7087 109.427 95.37 109.108 95.4696 109.068C95.6908 108.989 95.6908 108.949 95.6908 108.949V108.789H95.6489Z"
            fill="#B5B5B5"
          />
          <path
            opacity="0.3226"
            d="M95.6488 108.79C95.6488 108.79 95.6488 108.77 95.6289 108.77L95.609 108.73C95.609 108.73 95.5492 108.67 95.3679 108.65L93.1228 108.352H58.0696L55.7647 108.63C55.5834 108.67 55.5236 108.71 55.5236 108.71C55.5236 108.71 55.5037 108.73 55.4838 108.75L55.4639 108.77V108.889C55.4639 108.889 55.4639 108.949 55.685 109.049C55.8045 109.089 56.5675 109.407 57.9102 109.407C59.6931 109.407 62.0179 109.447 62.0179 109.447L75.6858 109.507L89.3537 109.447C89.3537 109.447 91.4993 109.407 93.3021 109.407C94.6448 109.407 95.3062 109.089 95.4058 109.069C95.6269 108.989 95.6269 108.949 95.6269 108.949V108.79H95.6488Z"
            fill="#ADADAD"
          />
          <path
            opacity="0.3548"
            d="M95.6489 108.79C95.6489 108.79 95.6489 108.77 95.629 108.77L95.6091 108.73C95.6091 108.73 95.5493 108.67 95.368 108.65L93.1229 108.352H58.0697L55.7648 108.65C55.5835 108.69 55.5237 108.73 55.5237 108.73C55.5237 108.73 55.5038 108.75 55.4839 108.77V108.79V108.909C55.4839 108.909 55.4839 108.969 55.705 109.069C55.8245 109.109 56.5875 109.427 57.9302 109.407C59.7131 109.407 62.018 109.447 62.018 109.447L75.6859 109.507L89.3538 109.447C89.3538 109.447 91.4994 109.407 93.3022 109.407C94.6449 109.407 95.3063 109.109 95.4059 109.069C95.627 109.009 95.627 108.949 95.627 108.949V108.79H95.6489Z"
            fill="#A5A5A5"
          />
          <path
            opacity="0.3871"
            d="M95.649 108.788C95.649 108.788 95.649 108.788 95.6291 108.768C95.6291 108.748 95.6092 108.729 95.6092 108.729C95.6092 108.729 95.5494 108.689 95.3681 108.649L93.123 108.33H58.0897L55.7848 108.629C55.6035 108.649 55.5437 108.709 55.5437 108.709C55.5437 108.709 55.5238 108.729 55.5039 108.748V108.768V108.888C55.5039 108.888 55.5039 108.948 55.725 109.027C55.8446 109.067 56.6075 109.366 57.9502 109.366C59.7332 109.366 62.038 109.406 62.038 109.406L75.706 109.466L89.3739 109.406C89.3739 109.406 91.5393 109.366 93.3222 109.366C94.6649 109.366 95.3263 109.087 95.4259 109.047C95.647 108.987 95.647 108.928 95.647 108.928V108.788H95.649Z"
            fill="#9C9C9C"
          />
          <path
            opacity="0.4194"
            d="M95.6291 108.788C95.6291 108.788 95.6291 108.788 95.6092 108.768C95.6092 108.748 95.5693 108.729 95.5693 108.729C95.5693 108.729 95.5096 108.689 95.3283 108.649L93.0832 108.33H58.0897L55.7848 108.629C55.6035 108.649 55.5437 108.709 55.5437 108.709C55.5437 108.709 55.5238 108.729 55.5039 108.748V108.768V108.888C55.5039 108.888 55.5039 108.948 55.725 109.027C55.8446 109.067 56.6075 109.346 57.9502 109.346C59.7332 109.346 62.038 109.386 62.038 109.386L75.706 109.446L89.3739 109.386C89.3739 109.386 91.5393 109.346 93.3222 109.346C94.6649 109.346 95.3263 109.067 95.4259 109.047C95.647 108.987 95.647 108.928 95.647 108.928V108.788H95.6291Z"
            fill="#949494"
          />
          <path
            opacity="0.4516"
            d="M95.6292 108.788C95.6292 108.748 95.5894 108.729 95.5894 108.729C95.5894 108.729 95.5296 108.689 95.3483 108.649L93.1032 108.33H58.0898L55.8048 108.629C55.6235 108.649 55.5638 108.709 55.5638 108.709C55.5638 108.709 55.5438 108.729 55.5239 108.748V108.768V108.908C55.5239 108.908 55.5239 108.968 55.745 109.047C55.8646 109.087 56.6276 109.366 57.9503 109.346C59.7333 109.346 62.0381 109.386 62.0381 109.386L75.7061 109.446L89.374 109.386C89.374 109.386 91.5394 109.346 93.3223 109.346C94.665 109.346 95.3264 109.087 95.426 109.047C95.6471 108.987 95.6471 108.928 95.6471 108.928V108.788H95.6292Z"
            fill="#8C8C8C"
          />
          <path
            opacity="0.4839"
            d="M95.6292 108.788C95.6292 108.748 95.5894 108.729 95.5894 108.729C95.5894 108.729 95.5296 108.689 95.3483 108.649L93.1032 108.33H58.0898L55.8048 108.629C55.6235 108.649 55.5638 108.709 55.5638 108.709C55.5638 108.709 55.5438 108.729 55.5239 108.748V108.768V108.908C55.5239 108.908 55.5239 108.968 55.745 109.047C55.8646 109.087 56.6076 109.346 57.9503 109.346C59.7333 109.346 62.0182 109.386 62.0182 109.386L75.6861 109.446L89.354 109.386C89.354 109.386 91.5195 109.346 93.3024 109.346C94.6451 109.346 95.3065 109.087 95.4061 109.067C95.6272 109.007 95.6272 108.948 95.6272 108.948L95.6292 108.788Z"
            fill="#848484"
          />
          <path
            opacity="0.5161"
            d="M95.6292 108.788C95.6292 108.748 95.5894 108.729 95.5894 108.729C95.5894 108.729 95.5296 108.689 95.3483 108.649L93.1032 108.33H58.0898L55.8048 108.649C55.6235 108.669 55.5638 108.729 55.5638 108.729C55.5638 108.729 55.5438 108.748 55.5239 108.768V108.788V108.928C55.5239 108.928 55.5239 108.987 55.745 109.067C55.8646 109.107 56.6076 109.346 57.9503 109.346C59.7333 109.346 62.0182 109.386 62.0182 109.386L75.6861 109.446L89.354 109.386C89.354 109.386 91.5195 109.346 93.3024 109.346C94.6451 109.346 95.3065 109.107 95.426 109.087C95.6471 109.027 95.6471 108.987 95.6471 108.987V108.788H95.6292Z"
            fill="#7B7B7B"
          />
          <path
            opacity="0.5484"
            d="M95.6288 108.769C95.6288 108.749 95.589 108.709 95.589 108.709C95.589 108.709 95.5292 108.669 95.3479 108.629L93.1028 108.311H58.1093L55.8243 108.629C55.6431 108.649 55.5833 108.709 55.5833 108.709C55.5833 108.709 55.5634 108.729 55.5435 108.749V108.888C55.5435 108.888 55.5435 108.948 55.7646 109.028C55.8841 109.068 56.6272 109.307 57.9699 109.287C59.7528 109.287 62.0378 109.327 62.0378 109.327L75.6857 109.386L89.3337 109.327C89.3337 109.327 91.4992 109.287 93.302 109.287C94.6447 109.287 95.3061 109.068 95.4256 109.028C95.6467 108.968 95.6468 108.928 95.6468 108.928V108.769H95.6288Z"
            fill="#737373"
          />
          <path
            opacity="0.5806"
            d="M95.6089 108.769C95.6089 108.749 95.5691 108.709 95.5691 108.709C95.5691 108.709 95.5093 108.669 95.328 108.629L93.0829 108.311H58.1093L55.8243 108.629C55.6431 108.649 55.5833 108.709 55.5833 108.709C55.5833 108.709 55.5634 108.729 55.5435 108.749V108.908C55.5435 108.908 55.5435 108.968 55.7646 109.048C55.8642 109.087 56.6272 109.307 57.9499 109.307C59.7329 109.307 62.0178 109.327 62.0178 109.327L75.6658 109.386L89.3138 109.327C89.3138 109.327 91.4992 109.287 93.2821 109.307C94.6248 109.307 95.3061 109.087 95.4057 109.068C95.6268 109.008 95.6268 108.968 95.6268 108.968V108.769H95.6089Z"
            fill="#6B6B6B"
          />
          <path
            opacity="0.6129"
            d="M95.6088 108.769C95.6088 108.749 95.569 108.709 95.569 108.709C95.569 108.709 95.5092 108.67 95.3279 108.63L93.0828 108.291H58.1092L55.8243 108.61C55.643 108.63 55.5832 108.689 55.5832 108.689C55.5832 108.689 55.5633 108.709 55.5434 108.729V108.889C55.5434 108.889 55.5235 108.948 55.7645 109.008C55.8641 109.048 56.6072 109.247 57.9498 109.247C59.7328 109.247 61.9978 109.267 61.9978 109.267L75.6458 109.327L89.2938 109.267C89.2938 109.267 91.4792 109.227 93.2621 109.247C94.6048 109.247 95.2861 109.048 95.3857 109.008C95.6267 108.948 95.6068 108.909 95.6068 108.909V108.769H95.6088Z"
            fill="#636363"
          />
          <path
            opacity="0.6452"
            d="M95.6089 108.769C95.6089 108.749 95.5691 108.729 95.5691 108.729C95.5691 108.729 95.5093 108.689 95.328 108.649L93.0829 108.311H58.1093L55.8443 108.629C55.663 108.649 55.6032 108.709 55.6032 108.709C55.6032 108.709 55.5634 108.729 55.5634 108.749V108.908C55.5634 108.908 55.5435 108.968 55.7845 109.028C55.8841 109.068 56.6272 109.247 57.9699 109.247C59.7528 109.247 62.0178 109.267 62.0178 109.267L75.6658 109.327L89.3138 109.267C89.3138 109.267 91.4992 109.247 93.2821 109.247C94.6248 109.247 95.3061 109.048 95.4057 109.028C95.6468 108.968 95.6268 108.928 95.6268 108.928V108.769H95.6089Z"
            fill="#5A5A5A"
          />
          <path
            opacity="0.6774"
            d="M95.6089 108.769C95.6089 108.749 95.5691 108.729 95.5691 108.729C95.5691 108.729 95.5093 108.689 95.328 108.649L93.0829 108.311H58.1093L55.8443 108.629C55.663 108.649 55.6032 108.709 55.6032 108.709C55.6032 108.709 55.5634 108.729 55.5634 108.749V108.908C55.5634 108.908 55.5435 108.968 55.7845 109.028C55.8841 109.068 56.6272 109.247 57.9699 109.247C59.7528 109.247 62.0178 109.267 62.0178 109.267L75.6658 109.307L89.3138 109.267C89.3138 109.267 91.4992 109.247 93.2821 109.247C94.6248 109.247 95.3061 109.068 95.4057 109.048C95.6468 108.988 95.6268 108.948 95.6268 108.948V108.769H95.6089Z"
            fill="#525252"
          />
          <path
            opacity="0.7097"
            d="M95.609 108.769C95.609 108.749 95.5692 108.729 95.5692 108.729C95.5692 108.729 95.5094 108.689 95.3281 108.649L93.083 108.311H58.1293L55.8643 108.649C55.683 108.669 55.6233 108.729 55.6233 108.729C55.6233 108.729 55.5834 108.749 55.5834 108.769V108.948C55.5834 108.948 55.5635 109.008 55.8045 109.068C55.9041 109.087 56.6472 109.267 57.97 109.267C59.7529 109.267 62.0179 109.287 62.0179 109.287L75.6659 109.327L89.3139 109.287C89.3139 109.287 91.4993 109.267 93.2822 109.267C94.6249 109.267 95.3062 109.107 95.4058 109.068C95.6469 109.008 95.6269 108.968 95.6269 108.968V108.769H95.609Z"
            fill="#4A4A4A"
          />
          <path
            opacity="0.7419"
            d="M95.609 108.769C95.609 108.749 95.5692 108.729 95.5692 108.729C95.5692 108.729 95.5094 108.689 95.3281 108.649L93.083 108.311H58.1293L55.8643 108.649C55.683 108.669 55.6233 108.729 55.6233 108.729C55.6233 108.729 55.5834 108.749 55.5834 108.769V108.948C55.5834 108.948 55.5635 109.008 55.8045 109.068C55.9041 109.087 56.6472 109.247 57.97 109.247C59.7529 109.247 62.0179 109.267 62.0179 109.267L75.6659 109.307L89.3139 109.267C89.3139 109.267 91.4993 109.247 93.3021 109.247C94.6448 109.247 95.3261 109.087 95.4257 109.068C95.6668 109.008 95.6469 108.968 95.6469 108.968V108.769H95.609Z"
            fill="#424242"
          />
          <path
            opacity="0.7742"
            d="M95.5891 108.769C95.5891 108.749 95.5492 108.729 95.5492 108.729C95.5492 108.729 95.4895 108.689 95.3082 108.649L93.0631 108.311H58.1293L55.8643 108.649C55.683 108.669 55.6233 108.729 55.6233 108.729C55.6233 108.729 55.5834 108.749 55.5834 108.769V108.948C55.5834 108.948 55.5635 109.008 55.8045 109.068C55.9041 109.087 56.6253 109.247 57.97 109.247C59.7529 109.247 61.998 109.267 61.998 109.267L75.6261 109.307L89.2542 109.267C89.2542 109.267 91.4594 109.247 93.2424 109.247C94.5851 109.247 95.2664 109.107 95.366 109.087C95.607 109.048 95.5871 108.988 95.5871 108.988V108.769H95.5891Z"
            fill="#3A3A3A"
          />
          <path
            opacity="0.8065"
            d="M95.5891 108.769C95.5891 108.749 95.5492 108.729 95.5492 108.729C95.5492 108.729 95.4895 108.689 95.3082 108.649L93.0631 108.311H58.1293L55.8643 108.649C55.683 108.669 55.6233 108.729 55.6233 108.729C55.6233 108.729 55.5834 108.749 55.5834 108.769V108.948C55.5834 108.948 55.5635 109.008 55.8045 109.068C55.9041 109.087 56.6253 109.227 57.97 109.227C59.7529 109.227 61.998 109.247 61.998 109.247L75.6261 109.287L89.2542 109.247C89.2542 109.247 91.4594 109.227 93.2424 109.227C94.5851 109.227 95.2664 109.087 95.366 109.068C95.607 109.028 95.5871 108.968 95.5871 108.968V108.769H95.5891Z"
            fill="#313131"
          />
          <path
            opacity="0.8387"
            d="M95.5887 108.769C95.5887 108.749 95.5489 108.729 95.5489 108.729C95.5489 108.729 95.4891 108.689 95.3078 108.65L93.0627 108.291H58.1289L55.8838 108.63C55.7025 108.65 55.6428 108.709 55.6428 108.709C55.6428 108.709 55.6029 108.729 55.6029 108.749V108.928C55.6029 108.928 55.583 108.988 55.8241 109.028C55.9237 109.048 56.6448 109.168 57.9696 109.168C59.7525 109.168 61.9976 109.187 61.9976 109.187L75.6257 109.227L89.2538 109.187C89.2538 109.187 91.459 109.168 93.242 109.168C94.5847 109.168 95.266 109.048 95.3656 109.028C95.6066 108.988 95.5867 108.928 95.5867 108.928V108.769H95.5887Z"
            fill="#292929"
          />
          <path
            opacity="0.871"
            d="M95.5888 108.749C95.5888 108.729 95.549 108.709 95.549 108.709C95.549 108.709 95.4892 108.67 95.3079 108.65L93.0628 108.291H58.149L55.9038 108.63C55.7226 108.65 55.6628 108.689 55.6628 108.689C55.6628 108.689 55.623 108.709 55.623 108.729V108.928C55.623 108.928 55.603 108.988 55.8441 109.028C55.9437 109.048 56.6648 109.168 57.9896 109.168C59.7725 109.168 62.0176 109.187 62.0176 109.187L75.6457 109.227L89.2738 109.187C89.2738 109.187 91.4791 109.168 93.262 109.168C94.6047 109.168 95.286 109.068 95.3856 109.048C95.6266 109.008 95.6067 108.948 95.6067 108.948V108.749H95.5888Z"
            fill="#212121"
          />
          <path
            opacity="0.9032"
            d="M95.5888 108.749C95.5888 108.729 95.549 108.709 95.549 108.709C95.549 108.709 95.4892 108.67 95.3079 108.65L93.0628 108.291H58.149L55.9038 108.65C55.7226 108.67 55.6628 108.709 55.6628 108.709C55.6628 108.709 55.623 108.729 55.623 108.749V108.948C55.623 108.948 55.603 109.008 55.8441 109.048C55.9437 109.068 56.6449 109.168 57.9896 109.168C59.7725 109.168 61.9977 109.168 61.9977 109.168L75.6258 109.207L89.2539 109.168C89.2539 109.168 91.4591 109.148 93.2421 109.168C94.5848 109.168 95.2661 109.068 95.3657 109.048C95.6067 109.008 95.5868 108.948 95.5868 108.948V108.749H95.5888Z"
            fill="#191919"
          />
          <path
            opacity="0.9355"
            d="M95.5888 108.749C95.5888 108.729 95.549 108.709 95.549 108.709C95.549 108.709 95.4892 108.67 95.3079 108.65L93.0628 108.291H58.149L55.9038 108.65C55.7226 108.67 55.6628 108.709 55.6628 108.709C55.6628 108.709 55.623 108.729 55.623 108.749V108.948C55.623 108.948 55.603 109.008 55.8441 109.048C55.9437 109.068 56.6449 109.148 57.9896 109.148C59.7725 109.148 61.9977 109.148 61.9977 109.148L75.6258 109.187L89.2539 109.148C89.2539 109.148 91.4591 109.128 93.2421 109.148C94.5848 109.148 95.2661 109.068 95.3657 109.048C95.6067 109.008 95.5868 108.948 95.5868 108.948V108.749H95.5888Z"
            fill="#101010"
          />
          <path
            opacity="0.9677"
            d="M95.5689 108.749C95.5689 108.729 95.529 108.709 95.529 108.709C95.529 108.709 95.4693 108.67 95.288 108.65L93.0429 108.291H58.149L55.9038 108.65C55.7226 108.67 55.6628 108.709 55.6628 108.709C55.6628 108.709 55.623 108.729 55.623 108.749V108.948C55.623 108.948 55.603 109.008 55.8441 109.048C55.9437 109.068 56.6449 109.148 57.9896 109.128C59.7725 109.128 61.9977 109.128 61.9977 109.128L75.6258 109.168L89.2539 109.128C89.2539 109.128 91.4791 109.128 93.262 109.128C94.6047 109.128 95.3059 109.048 95.3856 109.048C95.6266 109.008 95.6067 108.948 95.6067 108.948V108.749H95.5689Z"
            fill="#080808"
          />
          <path
            d="M95.5689 108.749C95.5689 108.729 95.529 108.709 95.529 108.709C95.529 108.709 95.4693 108.67 95.288 108.65L93.0429 108.291H58.149L55.9038 108.65C55.7226 108.67 55.6628 108.709 55.6628 108.709C55.6628 108.709 55.623 108.729 55.623 108.749V108.968C55.623 108.968 55.603 109.028 55.8441 109.068C55.9437 109.088 56.6449 109.148 57.9677 109.148C59.7506 109.148 61.9758 109.148 61.9758 109.148L75.6039 109.187L89.2319 109.148C89.2319 109.148 91.4571 109.148 93.2401 109.148C94.5828 109.148 95.284 109.088 95.3637 109.068C95.6047 109.028 95.5848 108.968 95.5848 108.968V108.749H95.5689Z"
            fill="black"
          />
        </g>
        <path
          d="M55.3845 108.152C55.2251 108.411 55.2451 108.71 55.2849 108.81C55.3248 108.929 55.4861 109.188 56.3268 109.188C56.9882 109.188 66.3671 109.168 75.7878 109.168L75.8077 108.611C66.4288 108.63 57.0878 108.65 56.4264 108.65C55.6634 108.67 55.524 108.292 55.3845 108.152Z"
          fill="url(#paint0_linear_35078_342586)"
        />
        <path
          d="M95.7884 108.152C95.9478 108.411 95.9279 108.71 95.888 108.81C95.8482 108.929 95.6868 109.188 94.8461 109.188C94.1848 109.188 84.8059 109.168 75.3852 109.168L75.3652 108.611C84.7441 108.63 94.0852 108.65 94.7465 108.65C95.5075 108.67 95.649 108.292 95.7884 108.152Z"
          fill="url(#paint1_linear_35078_342586)"
        />
        <path
          d="M95.5898 107.933L92.0618 105.662C90.1573 104.487 89.9581 100.861 89.8366 99.8255L89.0556 91.0801H75.5869H62.0983L61.3174 99.8255C61.2178 100.861 61.0166 104.487 59.0922 105.662L55.5642 107.933C55.4048 108.033 55.3032 108.192 55.3032 108.372C55.3032 108.591 55.4626 108.83 56.2057 108.83C57.5284 108.83 75.5869 108.83 75.5869 108.83C75.5869 108.83 93.6455 108.83 94.9682 108.83C95.6894 108.83 95.8707 108.591 95.8707 108.372C95.8488 108.192 95.7492 108.033 95.5898 107.933Z"
          fill="url(#paint2_linear_35078_342586)"
        />
        <path
          d="M100.012 52.1216C100.012 38.8714 89.2702 28.1299 76.0199 28.1299C62.7697 28.1299 52.0283 38.8714 52.0283 52.1216C52.0283 65.3717 62.7697 76.1133 76.0199 76.1133C89.2702 76.1133 100.012 65.3717 100.012 52.1216Z"
          stroke="#8083A3"
        />
        <path
          d="M90.9737 51.9715C90.9794 54.9395 90.0959 57.8425 88.435 60.313C86.7741 62.7835 84.4105 64.7105 81.6433 65.8502C78.8762 66.9898 75.8298 67.2908 72.8898 66.7151C69.9498 66.1394 67.2484 64.7129 65.1274 62.6161C63.0064 60.5192 61.5611 57.8464 60.9746 54.9358C60.388 52.0252 60.6865 49.0076 61.8323 46.2651C62.9781 43.5226 64.9197 41.1784 67.4113 39.5291C69.9029 37.8798 72.8325 36.9997 75.8294 37C79.8423 36.9967 83.6921 38.5721 86.5322 41.3798C89.3722 44.1874 90.9698 47.9973 90.9737 51.9715Z"
          fill="#38A6DE"
        />
        <path
          d="M76.0593 43.6813C77.7342 43.6465 79.3707 44.1799 80.6973 45.193C82.024 46.206 82.961 47.6379 83.3529 49.2509L83.7589 51.1367L85.8292 51.2751C86.8157 51.308 87.7528 51.7105 88.4511 52.4014C89.1494 53.0923 89.5567 54.02 89.5908 54.9969C89.5534 56.0288 89.1057 57.0043 88.3453 57.7108C87.5849 58.4172 86.5734 58.7975 85.5311 58.7686H67.9406C66.5497 58.8115 65.1985 58.306 64.1836 57.3631C63.1688 56.4202 62.5732 55.1169 62.5278 53.7395C62.5587 52.463 63.0697 51.2438 63.9608 50.3209C64.8518 49.398 66.0587 48.8379 67.345 48.7502L68.7928 48.6118L69.4693 47.4173C70.1306 46.2685 71.0907 45.3162 72.2497 44.6594C73.4087 44.0027 74.7242 43.6655 76.0593 43.6831"
          fill="white"
        />
        <path
          d="M75.8333 43.7726C77.4663 43.7387 79.062 44.2588 80.3554 45.2466C81.6489 46.2344 82.5625 47.6305 82.9445 49.2032L83.3404 51.0445L85.3591 51.1794C86.3207 51.2111 87.2342 51.6031 87.9152 52.2762C88.5962 52.9493 88.9938 53.8533 89.0278 54.8055C88.9915 55.8118 88.555 56.763 87.8136 57.4519C87.0721 58.1408 86.0857 58.5116 85.0694 58.4834H67.9167C66.5601 58.5253 65.2423 58.0322 64.2526 57.1123C63.263 56.1925 62.6826 54.9212 62.6389 53.5778C62.6685 52.3328 63.1665 51.1436 64.0353 50.2433C64.9041 49.3431 66.081 48.7965 67.3355 48.7108L68.7473 48.576L69.407 47.4113C70.0523 46.2915 70.9887 45.3634 72.1188 44.7234C73.249 44.0835 74.5316 43.7552 75.8333 43.7726ZM75.8333 41.3207C74.0583 41.299 72.3095 41.7463 70.7672 42.6166C69.2248 43.4869 67.9446 44.7487 67.0584 46.272C63.0875 46.6658 60 49.7913 60 53.5778C60 57.6358 63.5493 60.9329 67.9167 60.9329H85.0694C88.7105 60.9329 91.6667 58.187 91.6667 54.8031C91.6667 51.5666 88.9618 48.9437 85.5318 48.7227C84.6322 44.4937 80.6373 41.3184 75.8345 41.3184"
          fill="#18508D"
        />
        <path
          d="M82.7143 26.6428C82.7143 23.1318 79.8682 20.2857 76.3572 20.2857C72.8463 20.2857 70 23.1318 70 26.6428C70 30.1537 72.8463 33 76.3572 33C79.8682 33 82.7143 30.1537 82.7143 26.6428Z"
          fill="#64C4F5"
        />
        <path
          d="M72.281 29V28.4718L73.5151 27.3292C73.6201 27.2276 73.7081 27.1362 73.7792 27.0549C73.8514 26.9737 73.9062 26.8941 73.9434 26.8162C73.9806 26.7372 73.9993 26.652 73.9993 26.5606C73.9993 26.459 73.9761 26.3716 73.9299 26.2982C73.8836 26.2237 73.8204 26.1667 73.7403 26.1272C73.6601 26.0866 73.5693 26.0663 73.4677 26.0663C73.3616 26.0663 73.2691 26.0877 73.1901 26.1306C73.1111 26.1735 73.0501 26.235 73.0072 26.3151C72.9644 26.3953 72.9429 26.4906 72.9429 26.6012H72.2472C72.2472 26.3744 72.2985 26.1774 72.4012 26.0104C72.5039 25.8434 72.6478 25.7142 72.8329 25.6228C73.018 25.5313 73.2313 25.4856 73.4728 25.4856C73.7211 25.4856 73.9372 25.5297 74.1211 25.6177C74.3062 25.7046 74.4501 25.8253 74.5528 25.9799C74.6555 26.1346 74.7069 26.3117 74.7069 26.5115C74.7069 26.6424 74.6809 26.7716 74.629 26.8992C74.5782 27.0267 74.4874 27.1683 74.3565 27.3241C74.2255 27.4787 74.041 27.6643 73.8029 27.881L73.2967 28.377V28.4007H74.7526V29H72.281ZM75.1567 28.3906V27.8133L76.6041 25.533H77.1018V26.3321H76.8073L75.8948 27.7761V27.8032H77.9516V28.3906H75.1567ZM76.8208 29V28.2145L76.8344 27.9589V25.533H77.5217V29H76.8208Z"
          fill="white"
        />
        <path
          d="M79.497 25.6483L78.6829 28.6729H78.2302L79.0443 25.6483H79.497ZM79.8452 28.293L80.8925 26.2206V26.2033H79.6725V25.7667H81.4451V26.2095L80.3966 28.293H79.8452Z"
          fill="white"
        />
        <path
          d="M74.9688 76.5898C73.556 76.5301 72.1755 76.351 70.8345 76.0619C71.6692 74.4464 73.1764 73.2347 74.9857 72.7969C74.9868 74.7859 74.983 76.3888 74.9688 76.5898Z"
          fill="#33326F"
        />
        <path
          d="M74.9854 76.5918C74.9804 76.5916 74.9741 76.5913 74.9692 76.5912C74.9834 76.3902 74.9872 74.7872 74.9861 72.7982C74.9864 72.7981 74.9866 72.7981 74.9871 72.7979L74.9854 76.5918Z"
          fill="#33326F"
        />
        <path
          d="M75.8708 74.2969C75.687 74.2969 75.5381 74.1478 75.5381 73.9642C75.5381 73.7805 75.687 73.6316 75.8708 73.6316C76.0544 73.6316 76.2034 73.7805 76.2034 73.9642C76.2034 74.1478 76.0544 74.2969 75.8708 74.2969Z"
          fill="#DCD9D7"
        />
        <path
          d="M82.7143 78.6429C82.7143 75.1318 79.8682 72.2857 76.3572 72.2857C72.8463 72.2857 70 75.1318 70 78.6429C70 82.1539 72.8463 85 76.3572 85C79.8682 85 82.7143 82.1539 82.7143 78.6429Z"
          fill="#64C4F5"
        />
        <path
          d="M76.8467 77.2183C76.6453 77.2183 76.4816 77.0548 76.4816 76.8532C76.4816 76.6517 76.6453 76.4884 76.8467 76.4884C77.0482 76.4884 77.2115 76.6517 77.2115 76.8532C77.2115 77.0548 77.0482 77.2183 76.8467 77.2183ZM75.6051 77.2183C75.4035 77.2183 75.2403 77.0548 75.2403 76.8532C75.2403 76.6517 75.4035 76.4884 75.6051 76.4884C75.8067 76.4884 75.97 76.6517 75.97 76.8532C75.97 77.0548 75.8067 77.2183 75.6051 77.2183ZM74.3868 77.2183C74.1852 77.2183 74.0218 77.0548 74.0218 76.8532C74.0218 76.6517 74.1852 76.4884 74.3868 76.4884C74.5882 76.4884 74.7516 76.6517 74.7516 76.8532C74.7516 77.0548 74.5882 77.2183 74.3868 77.2183ZM77.5093 75.2931H73.7011C73.3153 75.2931 73 75.6088 73 75.9942V77.2676V78.0495V79.623L73.8527 78.5417C73.917 78.4605 74.0144 78.4133 74.1178 78.4133H77.5093C77.8949 78.4133 78.2102 78.0978 78.2102 77.7122V75.9942C78.2102 75.6088 77.8949 75.2931 77.5093 75.2931Z"
          fill="white"
        />
        <path
          d="M76.8956 80.1294C76.8956 79.9933 77.006 79.883 77.1421 79.883C77.2782 79.883 77.3885 79.9933 77.3885 80.1294C77.3885 80.2657 77.2782 80.3759 77.1421 80.3759C77.006 80.3759 76.8956 80.2657 76.8956 80.1294ZM77.7341 80.1294C77.7341 79.9933 77.8444 79.883 77.9803 79.883C78.1166 79.883 78.2268 79.9933 78.2268 80.1294C78.2268 80.2657 78.1166 80.3759 77.9803 80.3759C77.8444 80.3759 77.7341 80.2657 77.7341 80.1294ZM78.5569 80.1294C78.5569 79.9933 78.6673 79.883 78.8034 79.883C78.9395 79.883 79.0499 79.9933 79.0499 80.1294C79.0499 80.2657 78.9395 80.3759 78.8034 80.3759C78.6673 80.3759 78.5569 80.2657 78.5569 80.1294ZM76.2212 79.5494V80.7094C76.2212 80.97 76.4342 81.1828 76.6946 81.1828H78.9849C79.0547 81.1828 79.1205 81.2149 79.164 81.2698L79.7398 82V80.9373V80.4093V79.5494C79.7398 79.289 79.5268 79.076 79.2664 79.076H76.6946C76.4342 79.076 76.2212 79.289 76.2212 79.5494Z"
          fill="white"
        />
        <path
          d="M56.7143 52.6429C56.7143 49.132 53.868 46.2857 50.3572 46.2857C46.8462 46.2857 44 49.132 44 52.6429C44 56.1539 46.8462 59 50.3572 59C53.868 59 56.7143 56.1539 56.7143 52.6429Z"
          fill="#64C4F5"
        />
        <path
          d="M51.0123 51.3131C51.0123 51.7337 50.8489 52.1307 50.5676 52.4277C51.1766 52.7628 51.6421 53.3449 51.8706 54.0352H53.3628C53.3628 52.8478 52.6395 51.8476 51.6509 51.5313C52.0668 51.3013 52.3489 50.8583 52.3489 50.3494C52.3489 49.6035 51.7444 48.999 50.9985 48.999C50.4706 48.999 50.0146 49.303 49.7925 49.7446C50.4926 49.924 51.0123 50.5582 51.0123 51.3131Z"
          fill="white"
        />
        <path
          d="M50.0439 52.496C50.4598 52.2659 50.7415 51.823 50.7415 51.3141C50.7415 50.5683 50.1372 49.9636 49.3914 49.9636C48.6456 49.9636 48.041 50.5683 48.041 51.3141C48.041 51.8194 48.3191 52.2593 48.7303 52.4907C47.7322 52.8005 47 53.8053 47 55H51.7554C51.7554 53.8126 51.0325 52.8123 50.0439 52.496Z"
          fill="white"
        />
        <path
          d="M108.04 52.1214C108.04 48.6105 105.194 45.7642 101.683 45.7642C98.172 45.7642 95.3257 48.6105 95.3257 52.1214C95.3257 55.6324 98.172 58.4785 101.683 58.4785C105.194 58.4785 108.04 55.6324 108.04 52.1214Z"
          fill="#64C4F5"
        />
        <path
          d="M98.1416 55.4707H105.224V51.0318H98.1416V55.4707Z"
          fill="#E2F6FF"
        />
        <path
          d="M99.0391 54.4375H104.326V48.6221H99.0391V54.4375Z"
          fill="white"
        />
        <path
          d="M101.314 49.5363H99.7855C99.7319 49.5363 99.6885 49.5797 99.6885 49.6333V49.6374C99.6885 49.691 99.7319 49.7344 99.7855 49.7344H101.314C101.368 49.7344 101.412 49.691 101.412 49.6374V49.6333C101.412 49.5797 101.368 49.5363 101.314 49.5363Z"
          fill="#99B7DC"
        />
        <path
          d="M103.612 53.8545H102.083C102.029 53.8545 101.986 53.898 101.986 53.9516V53.9556C101.986 54.0093 102.029 54.0527 102.083 54.0527H103.612C103.666 54.0527 103.709 54.0093 103.709 53.9556V53.9516C103.709 53.898 103.666 53.8545 103.612 53.8545Z"
          fill="#99B7DC"
        />
        <path
          d="M103.382 50.9599H99.7855C99.7319 50.9599 99.6885 51.0035 99.6885 51.0571V51.0611C99.6885 51.1148 99.7319 51.1582 99.7855 51.1582H103.382C103.436 51.1582 103.479 51.1148 103.479 51.0611V51.0571C103.479 51.0035 103.436 50.9599 103.382 50.9599Z"
          fill="#99B7DC"
        />
        <path
          d="M103.382 51.5635H99.7855C99.7319 51.5635 99.6885 51.607 99.6885 51.6606V51.6647C99.6885 51.7183 99.7319 51.7617 99.7855 51.7617H103.382C103.436 51.7617 103.479 51.7183 103.479 51.6647V51.6606C103.479 51.607 103.436 51.5635 103.382 51.5635Z"
          fill="#99B7DC"
        />
        <path
          d="M103.382 52.1572H99.7855C99.7319 52.1572 99.6885 52.2008 99.6885 52.2544V52.2585C99.6885 52.3121 99.7319 52.3555 99.7855 52.3555H103.382C103.436 52.3555 103.479 52.3121 103.479 52.2585V52.2544C103.479 52.2008 103.436 52.1572 103.382 52.1572Z"
          fill="#99B7DC"
        />
        <path
          d="M103.382 52.7685H99.7855C99.7319 52.7685 99.6885 52.8119 99.6885 52.8657V52.8696C99.6885 52.9232 99.7319 52.9668 99.7855 52.9668H103.382C103.436 52.9668 103.479 52.9232 103.479 52.8696V52.8657C103.479 52.8119 103.436 52.7685 103.382 52.7685Z"
          fill="#99B7DC"
        />
        <path
          d="M100.548 50.0322H99.7855C99.7319 50.0322 99.6885 50.0758 99.6885 50.1294V50.1335C99.6885 50.1871 99.7319 50.2305 99.7855 50.2305H100.548C100.601 50.2305 100.645 50.1871 100.645 50.1335V50.1294C100.645 50.0758 100.601 50.0322 100.548 50.0322Z"
          fill="#99B7DC"
        />
        <path
          d="M105.224 55.6191H98.1416V51.1801L101.683 53.3996L105.224 51.1801V55.6191Z"
          fill="#A8E4FF"
        />
        <path
          d="M105.224 55.6191H98.1416L101.683 53.3996L105.224 55.6191Z"
          fill="#8BDBFF"
        />
        <defs>
          <linearGradient
            id="paint0_linear_35078_342586"
            x1="55.2553"
            y1="108.67"
            x2="75.6645"
            y2="108.67"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.00140944" stop-color="#595759" />
            <stop offset="0.00262294" stop-color="#636163" />
            <stop offset="0.00867688" stop-color="#8F8E8F" />
            <stop offset="0.0134" stop-color="#ABABAB" />
            <stop offset="0.0161" stop-color="#B5B5B5" />
            <stop offset="0.0218" stop-color="#BCBCBC" />
            <stop offset="0.0259" stop-color="#C4C4C4" />
            <stop offset="0.0309" stop-color="#CCCCCC" />
            <stop offset="0.0358" stop-color="#C4C4C4" />
            <stop offset="0.0478" stop-color="#7D7D7D" />
            <stop offset="0.0558" stop-color="#605F60" />
            <stop offset="0.0645" stop-color="#454245" />
            <stop offset="0.08" stop-color="#323133" />
            <stop offset="1" stop-color="#3F3D40" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_35078_342586"
            x1="95.9174"
            y1="108.67"
            x2="75.5082"
            y2="108.67"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.00140944" stop-color="#595759" />
            <stop offset="0.00262294" stop-color="#636163" />
            <stop offset="0.00867688" stop-color="#8F8E8F" />
            <stop offset="0.0134" stop-color="#ABABAB" />
            <stop offset="0.0161" stop-color="#B5B5B5" />
            <stop offset="0.0218" stop-color="#BCBCBC" />
            <stop offset="0.0259" stop-color="#C4C4C4" />
            <stop offset="0.0309" stop-color="#CCCCCC" />
            <stop offset="0.0358" stop-color="#C4C4C4" />
            <stop offset="0.0478" stop-color="#7D7D7D" />
            <stop offset="0.0558" stop-color="#605F60" />
            <stop offset="0.0645" stop-color="#454245" />
            <stop offset="0.08" stop-color="#323133" />
            <stop offset="1" stop-color="#3F3D40" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_35078_342586"
            x1="75.5869"
            y1="91.0801"
            x2="75.5869"
            y2="108.705"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.0471" stop-color="#1A1A1C" />
            <stop offset="0.2435" stop-color="#2D2B2E" />
            <stop offset="0.3028" stop-color="#333133" />
            <stop offset="0.3801" stop-color="#424143" />
            <stop offset="0.4672" stop-color="#5C5C5C" />
            <stop offset="0.4813" stop-color="#616161" />
            <stop offset="0.5715" stop-color="#858585" />
            <stop offset="0.5942" stop-color="#858585" />
            <stop offset="0.6151" stop-color="#7E7E7F" />
            <stop offset="0.6455" stop-color="#6C6A6D" />
            <stop offset="0.6559" stop-color="#646266" />
            <stop offset="0.6724" stop-color="#565558" />
            <stop offset="0.7058" stop-color="#403F41" />
            <stop offset="0.7373" stop-color="#333233" />
            <stop offset="0.7646" stop-color="#2E2D2E" />
            <stop offset="0.8196" stop-color="#3A3A3B" />
            <stop offset="1" stop-color="#787878" />
          </linearGradient>
        </defs>
      </svg>
    </span>
  )
}
export default withTheme(ChatScheduleDemoIcon)
