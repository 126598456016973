import styled from "styled-components"

const SunnyAIChatCardStyle = styled.div`
  .chatmsg {
    display: flex;
    margin-bottom: 8px;
    max-width: 100%;
    &.emoji {
      margin-bottom: 21px;
    }
    .badge-wrapper {
      position: relative;
      .presence-room {
        position: absolute;
        top: 27px;
        right: 10px;
        .anticon {
          font-size: 12px !important;
          background: ${(props) => props.theme.colordwhitedark};
          color: ${(props) => props.theme.colordwhitedark};
          border-radius: 100%;
        }
      }
    }
    &__pic {
      width: 40px;
      height: 40px;
      flex: 0 0 40px;
      overflow: hidden;
      border-radius: 100%;
      margin-right: 10px;
      background: transparent;
      font-size: 12px;
      color: ${(props) => props.theme.textcolordwhite3};
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid ${(props) => props.theme.bgcolordwhiteblack};
      overflow: hidden;
      font-size: 6px;
      .ant-avatar {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        background-color: transparent;
        overflow: hidden;
        border-radius: 100%;
        .bg-color {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          border-radius: 100%;
          background-color: ${(props) => props.theme.primary};
        }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__data-wrapper {
      flex: 1 1 auto;
      display: flex;
      align-items: flex-end;
      gap: 5px;
      max-width: calc(100% - 50px);
    }
    &__data {
      border: 1px solid ${(props) => props.theme.colorbluegreylight};
      padding: 8px 15px 8px;
      max-width: 680px;
      border-radius: 4px;
      position: relative;
      min-width: 0;
      img {
        max-width: 100%;
      }
      &.original-msg-focus {
        background-color: ${(props) =>
          props.theme.bgcolordlightblue} !important;
      }
      .reaction-msg-wrapper {
        margin-bottom: -25px;
      }
    }
    &__name {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 5px;
      &-left {
        display: flex;
        align-items: center;
        span {
          display: flex;
          align-items: center;
        }
      }
      .pinchat-block {
        color: ${(props) => props.theme.primary};
        margin-left: 15px;
      }
      label {
        font-weight: 500;
        font-size: 12px;

        margin-right: 8px;
      }

      .time {
        color: ${(props) => props.theme.textcolordarkgrey};
        font-size: 12px;
      }
    }
    &__text {
      font-size: 14px;
      font-weight: normal;
      color: ${(props) => props.theme.colorlightblack};
      word-break: break-word;
      white-space: pre-wrap;
      .delted-msg {
        display: flex;
        align-items: center;
        .deleted-icon {
          display: flex;
          align-items: center;
          margin-right: 6px;
          color: ${(props) => props.theme.textcolordarkgrey};
        }
        .deleted-msg {
          font-style: italic;
          display: flex;
          align-items: center;
        }
      }
    }
  }

  &.selfmsg {
    display: flex;
    justify-content: flex-end;
    .chatmsg {
      &__pic {
        display: none;
      }
      &__data {
        background: ${(props) => props.theme.bgcolordlightblue};
        border: 1px solid ${(props) => props.theme.bgcolordlightblue};
      }
      .ReplyMsg__data {
        background: ${(props) => props.theme.colordwhitedark};
      }
      &__data-wrapper {
        max-width: 100%;
      }
    }
  }
`
export default SunnyAIChatCardStyle

export const ChatActionWrapper = styled.div`
  position: relative;
  .chatmsg__data {
    &:hover {
      .chathoverstyle {
        opacity: 1;
      }
    }
  }
`

export const ChatHoverStyle = styled.div`
  position: absolute;
  right: 0px;
  top: -19px;
  background: ${(props) => props.theme.bgcolordwhiteblack};
  opacity: 0;
  border-radius: 6px;
  .chat-action {
    display: flex;
    border: 1px solid ${(props) => props.theme.bordercolorgrey};
    border-radius: 6px;
    width: 134px;
    height: 38px;
    padding: 6px 11px;
    justify-content: space-between;
    align-items: center;
    .chat-action__item {
      cursor: pointer;
      color: ${(props) => props.theme.textcolordarkblue};
      .anticon-replyicon {
        color: ${(props) => props.theme.textcolordarkgrey};
      }
    }
  }
`
