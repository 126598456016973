import styled from "styled-components"

const MentionSuggestionListStyle = styled.div`
  .suggestion-list {
    list-style: none;
    display: flex;
    align-items: center;
    padding: 8px 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:not(:first-child) {
      border-top: 1px solid ${(props) => props.theme.colorbluegreylight};
    }
    .img-wrapper {
      width: 32px;
      min-width: 32px;
      height: 32px;
      border-radius: 100%;
      overflow: hidden;
      margin-right: 6px;
      background: transparent;
      font-size: 10px;
      color: ${(props) => props.theme.textcolordwhite3};
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .ant-avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border: none;
        font-size: 9px;
        border-radius: 100%;
        .bg-color {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          border-radius: 100%;
        }
      }
    }
    .profile-data {
      max-width: 160px;
      .name {
        font-size: 10px;
        color: ${(props) => props.theme.textcolordblack};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
      }
      .time {
        font-size: 9px;
        color: ${(props) => props.theme.textcolordarkgrey};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
`
export default MentionSuggestionListStyle
