import styled from "styled-components"

const LoadingStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  background-color: ${(props) => props.theme.colordwhitedark};
  .testspeaker {
    background-color: transparent;
  }
  .ant-typography {
    margin: 49px 0 20px;
  }
  .brandlogo {
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
    text-align: center;
  }
  .mt {
    margin-top: 36px;
  }
`
export default LoadingStyle
