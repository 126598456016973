import { Avatar } from "antd"
import styled, { css } from "styled-components"
import { md } from "../../config/variable"

export const AvatarStyle = styled(Avatar)``
export const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  .badge-wrapper {
    position: relative;
    .presence-room {
      position: absolute;
      top: 27px;
      right: -3px;
      .anticon {
        font-size: 19px !important;
        background: ${(props) => props.theme.colordwhitedark};
        color: ${(props) => props.theme.colordwhitedark};
        border-radius: 100%;
      }
    }
  }
`
export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;
  ${md(css`
    display: none;
  `)}
`
export const ParaStyle = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: ${(props) => props.theme.textcolordblack};
`
