import BrandLogoIcon from "../Icons/BrandLogo"
import LogoIcon from "../Icons/LogoIcon"

interface LogoProps {
  logoIcon?: boolean
}

function BrandLogo(props: LogoProps) {
  const { logoIcon } = props
  return (
    <div className="brandlogo">
      {!logoIcon ? <BrandLogoIcon /> : <LogoIcon />}
    </div>
  )
}

export default BrandLogo
