import React from "react"
import { withTheme } from "styled-components"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function ErrorIcon(props: any) {
  const { theme: Error } = props
  return (
    <span className="anticon anticon-erroricon ">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 272 272 "
      >
        <g clipPath="url(#clip0_1066_76824)">
          <path
            d="M144.931 238.145C193.124 238.145 232.191 199.078 232.191 150.886C232.191 102.693 193.124 63.626 144.931 63.626C96.7393 63.626 57.6719 102.693 57.6719 150.886C57.6719 199.078 96.7393 238.145 144.931 238.145Z"
            fill="url(#paint0_linear_1066_76824)"
          />
          <g filter="url(#filter0_d_1066_76824)">
            <path
              d="M194.961 113.655H94.9032C91.6904 113.655 89.0859 116.259 89.0859 119.472V241.636C89.0859 244.848 91.6904 247.453 94.9032 247.453H194.961C198.174 247.453 200.778 244.848 200.778 241.636V119.472C200.778 116.259 198.174 113.655 194.961 113.655Z"
              fill="white"
            />
          </g>
          <g opacity="0.7">
            <path
              d="M123.791 189.785H104.254C103.009 189.785 102 191.219 102 192.987C102 194.755 103.009 196.189 104.254 196.189H123.791C125.036 196.189 126.045 194.755 126.045 192.987C126.045 191.219 125.036 189.785 123.791 189.785Z"
              fill={Error.textcolordlightblue}
            />
          </g>
          <g opacity="0.7">
            <path
              d="M155.9 189.785H136.364C135.119 189.785 134.109 191.219 134.109 192.987C134.109 194.755 135.119 196.189 136.364 196.189H155.9C157.145 196.189 158.155 194.755 158.155 192.987C158.155 191.219 157.145 189.785 155.9 189.785Z"
              fill={Error.textcolordlightblue}
            />
          </g>
          <g opacity="0.7">
            <path
              d="M188.006 189.785H168.469C167.224 189.785 166.215 191.219 166.215 192.987C166.215 194.755 167.224 196.189 168.469 196.189H188.006C189.251 196.189 190.26 194.755 190.26 192.987C190.26 191.219 189.251 189.785 188.006 189.785Z"
              fill={Error.textcolordlightblue}
            />
          </g>
          <path
            d="M123.791 174.19H104.254C103.009 174.19 102 175.624 102 177.392C102 179.161 103.009 180.594 104.254 180.594H123.791C125.036 180.594 126.045 179.161 126.045 177.392C126.045 175.624 125.036 174.19 123.791 174.19Z"
            fill={Error.textcolordlightblue}
          />
          <path
            d="M155.9 174.19H136.364C135.119 174.19 134.109 175.624 134.109 177.392C134.109 179.161 135.119 180.594 136.364 180.594H155.9C157.145 180.594 158.155 179.161 158.155 177.392C158.155 175.624 157.145 174.19 155.9 174.19Z"
            fill={Error.textcolordlightblue}
          />
          <path
            d="M188.006 174.19H168.469C167.224 174.19 166.215 175.624 166.215 177.392C166.215 179.161 167.224 180.594 168.469 180.594H188.006C189.251 180.594 190.26 179.161 190.26 177.392C190.26 175.624 189.251 174.19 188.006 174.19Z"
            fill={Error.textcolordlightblue}
          />
          <path
            d="M123.791 158.595H104.254C103.009 158.595 102 160.028 102 161.797C102 163.565 103.009 164.999 104.254 164.999H123.791C125.036 164.999 126.045 163.565 126.045 161.797C126.045 160.028 125.036 158.595 123.791 158.595Z"
            fill={Error.textcolordlightblue}
          />
          <path
            d="M155.9 158.595H136.364C135.119 158.595 134.109 160.028 134.109 161.797C134.109 163.565 135.119 164.999 136.364 164.999H155.9C157.145 164.999 158.155 163.565 158.155 161.797C158.155 160.028 157.145 158.595 155.9 158.595Z"
            fill={Error.textcolordlightblue}
          />
          <path
            d="M188.006 158.595H168.469C167.224 158.595 166.215 160.028 166.215 161.797C166.215 163.565 167.224 164.999 168.469 164.999H188.006C189.251 164.999 190.26 163.565 190.26 161.797C190.26 160.028 189.251 158.595 188.006 158.595Z"
            fill={Error.textcolordlightblue}
          />
          <path
            d="M123.791 143H104.254C103.009 143 102 144.434 102 146.202C102 147.97 103.009 149.404 104.254 149.404H123.791C125.036 149.404 126.045 147.97 126.045 146.202C126.045 144.434 125.036 143 123.791 143Z"
            fill={Error.textcolordlightblue}
          />
          <path
            d="M155.9 143H136.364C135.119 143 134.109 144.434 134.109 146.202C134.109 147.97 135.119 149.404 136.364 149.404H155.9C157.145 149.404 158.155 147.97 158.155 146.202C158.155 144.434 157.145 143 155.9 143Z"
            fill={Error.textcolordlightblue}
          />
          <path
            d="M188.006 143H168.469C167.224 143 166.215 144.434 166.215 146.202C166.215 147.97 167.224 149.404 168.469 149.404H188.006C189.251 149.404 190.26 147.97 190.26 146.202C190.26 144.434 189.251 143 188.006 143Z"
            fill={Error.textcolordlightblue}
          />
          <g opacity="0.6">
            <path
              d="M123.791 205.38H104.254C103.009 205.38 102 206.813 102 208.582C102 210.35 103.009 211.784 104.254 211.784H123.791C125.036 211.784 126.045 210.35 126.045 208.582C126.045 206.813 125.036 205.38 123.791 205.38Z"
              fill={Error.textcolordlightblue}
            />
          </g>
          <g opacity="0.6">
            <path
              d="M155.9 205.38H136.364C135.119 205.38 134.109 206.813 134.109 208.582C134.109 210.35 135.119 211.784 136.364 211.784H155.9C157.145 211.784 158.155 210.35 158.155 208.582C158.155 206.813 157.145 205.38 155.9 205.38Z"
              fill={Error.textcolordlightblue}
            />
          </g>
          <g opacity="0.6">
            <path
              d="M188.006 205.38H168.469C167.224 205.38 166.215 206.813 166.215 208.582C166.215 210.35 167.224 211.784 168.469 211.784H188.006C189.251 211.784 190.26 210.35 190.26 208.582C190.26 206.813 189.251 205.38 188.006 205.38Z"
              fill={Error.textcolordlightblue}
            />
          </g>
          <g opacity="0.3">
            <path
              d="M123.791 220.976H104.254C103.009 220.976 102 222.409 102 224.178C102 225.946 103.009 227.379 104.254 227.379H123.791C125.036 227.379 126.045 225.946 126.045 224.178C126.045 222.409 125.036 220.976 123.791 220.976Z"
              fill={Error.textcolordlightblue}
            />
          </g>
          <g opacity="0.3">
            <path
              d="M155.9 220.976H136.364C135.119 220.976 134.109 222.409 134.109 224.178C134.109 225.946 135.119 227.379 136.364 227.379H155.9C157.145 227.379 158.155 225.946 158.155 224.178C158.155 222.409 157.145 220.976 155.9 220.976Z"
              fill={Error.textcolordlightblue}
            />
          </g>
          <g opacity="0.3">
            <path
              d="M188.006 220.976H168.469C167.224 220.976 166.215 222.409 166.215 224.178C166.215 225.946 167.224 227.379 168.469 227.379H188.006C189.251 227.379 190.26 225.946 190.26 224.178C190.26 222.409 189.251 220.976 188.006 220.976Z"
              fill={Error.textcolordlightblue}
            />
          </g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M28.2812 105.309V107.583V105.309Z"
            fill="#DDE9EE"
          />
          <path
            d="M28.2812 105.309V107.583"
            stroke="#DDE9EE"
            strokeWidth="2.921"
            strokeLinecap="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.1719 106.541L25.0303 108.454L23.1719 106.541Z"
            fill="#DDE9EE"
          />
          <path
            d="M23.1719 106.541L25.0303 108.454"
            stroke="#DDE9EE"
            strokeWidth="2.921"
            strokeLinecap="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21 111.633H23.6277H21Z"
            fill="#DDE9EE"
          />
          <path
            d="M21 111.633H23.6277"
            stroke="#DDE9EE"
            strokeWidth="2.921"
            strokeLinecap="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.9609 116.817L24.8193 114.904L22.9609 116.817Z"
            fill="#DDE9EE"
          />
          <path
            d="M22.9609 116.817L24.8193 114.904"
            stroke="#DDE9EE"
            strokeWidth="2.921"
            strokeLinecap="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M28.2812 119.438V117.164V119.438Z"
            fill="#DDE9EE"
          />
          <path
            d="M28.2812 119.438V117.164"
            stroke="#DDE9EE"
            strokeWidth="2.921"
            strokeLinecap="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M34.0709 117.922L32.5547 116.406L34.0709 117.922Z"
            fill="#DDE9EE"
          />
          <path
            d="M34.0709 117.922L32.5547 116.406"
            stroke="#DDE9EE"
            strokeWidth="2.921"
            strokeLinecap="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M35.1063 112.132H32.832H35.1063Z"
            fill="#DDE9EE"
          />
          <path
            d="M35.1063 112.132H32.832"
            stroke="#DDE9EE"
            strokeWidth="2.921"
            strokeLinecap="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M33.5865 106.825L32.0703 108.341L33.5865 106.825Z"
            fill="#DDE9EE"
          />
          <path
            d="M33.5865 106.825L32.0703 108.341"
            stroke="#DDE9EE"
            strokeWidth="2.921"
            strokeLinecap="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M150.332 6V7.51617V6Z"
            fill="#DDE9EE"
          />
          <path
            d="M150.332 6V7.51617"
            stroke="#DDE9EE"
            strokeWidth="2.921"
            strokeLinecap="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M145.785 10.5483H147.301H145.785Z"
            fill="#DDE9EE"
          />
          <path
            d="M145.785 10.5483H147.301"
            stroke="#DDE9EE"
            strokeWidth="2.921"
            strokeLinecap="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M150.332 15.0972V13.5811V15.0972Z"
            fill="#DDE9EE"
          />
          <path
            d="M150.332 15.0972V13.5811"
            stroke="#DDE9EE"
            strokeWidth="2.921"
            strokeLinecap="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M154.126 10.5483H152.609H154.126Z"
            fill="#DDE9EE"
          />
          <path
            d="M154.126 10.5483H152.609"
            stroke="#DDE9EE"
            strokeWidth="2.921"
            strokeLinecap="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M240.59 122.565C242.538 121.958 245.524 119.575 246.215 117.012C246.842 119.278 249.47 121.958 251.841 122.16C249.172 123.105 246.62 125.913 246.215 128.118C245.94 125.87 242.411 122.893 240.59 122.565Z"
            fill="#DDE9EE"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M40.6172 151.329C41.5916 151.025 43.084 149.834 43.4296 148.552C43.7431 149.685 45.0566 151.025 46.2421 151.127C44.9079 151.598 43.6324 153.002 43.4296 154.105C43.2919 152.981 41.5272 151.492 40.6172 151.329Z"
            fill="#DDE9EE"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M58.6975 45.8729C58.6975 47.2788 57.5584 48.4176 56.1535 48.4176C54.7479 48.4176 53.6094 47.2788 53.6094 45.8729C53.6094 44.4676 54.7479 43.3281 56.1535 43.3281C57.5584 43.3281 58.6975 44.4676 58.6975 45.8729Z"
            fill="#DDE9EE"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M228.583 52.7059C228.583 55.0549 226.679 56.9592 224.331 56.9592C221.982 56.9592 220.078 55.0549 220.078 52.7059C220.078 50.3568 221.982 48.4526 224.331 48.4526C226.679 48.4526 228.583 50.3568 228.583 52.7059Z"
            fill="#DDE9EE"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M117.569 50.9979C117.569 52.4038 116.429 53.5426 115.025 53.5426C113.62 53.5426 112.48 52.4038 112.48 50.9979C112.48 49.5926 113.62 48.4531 115.025 48.4531C116.429 48.4531 117.569 49.5926 117.569 50.9979Z"
            fill="#DDE9EE"
            stroke="#DDE9EE"
            strokeWidth="2.921"
            strokeLinecap="round"
          />
          <g clipPath="url(#clip1_1066_76824)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M190.418 238.701C197.172 238.701 203.929 236.719 209.737 232.756L234.361 257.381L243.42 248.322L218.797 223.697C222.76 217.889 224.742 211.133 224.742 204.379C224.742 185.421 209.374 170.054 190.418 170.054C181.633 170.054 172.847 173.406 166.147 180.108C159.445 186.81 156.094 195.595 156.094 204.378C156.094 223.336 171.462 238.703 190.418 238.703L190.418 238.701ZM210.198 224.157C221.118 213.237 221.118 195.515 210.198 184.595C199.277 173.674 181.556 173.674 170.637 184.595C159.723 195.511 159.721 213.241 170.637 224.157C181.557 235.077 199.279 235.077 210.198 224.157Z"
              fill={Error.bgcolordskyblue}
            />
            <g filter="url(#filter1_b_1066_76824)">
              <path
                d="M210.278 224.227C199.3 235.204 181.511 235.204 170.535 224.227C159.556 213.249 159.556 195.46 170.535 184.482C181.505 173.51 199.298 173.502 210.278 184.482C221.255 195.46 221.255 213.249 210.278 224.227Z"
                fill={Error.textcolordlightblue}
              />
            </g>
            <path
              d="M186.846 204.696L181.731 199.601C181.251 199.097 180.989 198.427 180.999 197.733C181.009 197.04 181.29 196.377 181.784 195.887C182.277 195.397 182.943 195.117 183.641 195.107C184.339 195.097 185.013 195.358 185.52 195.834L190.647 200.93L195.762 195.834C196.008 195.576 196.304 195.368 196.632 195.225C196.96 195.082 197.314 195.005 197.672 195C198.031 194.995 198.386 195.062 198.718 195.196C199.05 195.33 199.352 195.528 199.606 195.78C199.859 196.032 200.059 196.332 200.194 196.662C200.329 196.992 200.395 197.346 200.39 197.702C200.385 198.058 200.308 198.41 200.164 198.736C200.02 199.062 199.812 199.356 199.551 199.601L194.427 204.696L199.551 209.792C199.812 210.036 200.02 210.33 200.164 210.656C200.308 210.982 200.385 211.334 200.39 211.69C200.395 212.046 200.329 212.4 200.194 212.73C200.059 213.06 199.859 213.36 199.606 213.612C199.352 213.864 199.05 214.063 198.718 214.196C198.386 214.33 198.031 214.397 197.672 214.392C197.314 214.387 196.96 214.31 196.632 214.167C196.304 214.024 196.008 213.817 195.762 213.558L190.635 208.462L185.508 213.558C184.997 214.006 184.332 214.243 183.65 214.221C182.968 214.199 182.32 213.92 181.839 213.439C181.357 212.959 181.077 212.315 181.057 211.637C181.037 210.959 181.278 210.299 181.731 209.792L186.846 204.696Z"
              fill={Error.bgcolordarkblue}
            />
            <path
              d="M237.184 260.202L246.243 251.144L250.083 254.981C251.274 256.173 251.954 257.828 251.954 259.511C251.954 261.152 251.331 262.794 250.083 264.042C247.597 266.528 243.515 266.52 241.027 264.037L237.184 260.202Z"
              fill={Error.bgcolordarkblue}
            />
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_1066_76824"
            x="58.0859"
            y="89.6548"
            width="173.692"
            height="195.798"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feMorphology
              radius="2"
              operator="erode"
              in="SourceAlpha"
              result="effect1_dropShadow_1066_76824"
            />
            <feOffset dy="7" />
            <feGaussianBlur stdDeviation="16.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.109804 0 0 0 0 0.152941 0 0 0 0 0.192157 0 0 0 0.05 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_1066_76824"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_1066_76824"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_b_1066_76824"
            x="162"
            y="176"
            width="55.9648"
            height="55.9634"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
            <feComposite
              in2="SourceAlpha"
              operator="in"
              result="effect1_backgroundBlur_1066_76824"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_backgroundBlur_1066_76824"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_1066_76824"
            x1="144.931"
            y1="63.626"
            x2="144.931"
            y2="238.145"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={Error.bgcolordlightblue} />
            <stop
              offset="1"
              stopColor={Error.logocongratulationoffcolor}
              stopOpacity="0"
            />
          </linearGradient>
          <clipPath id="clip0_1066_76824">
            <rect width="272" height="272" fill="white" />
          </clipPath>
          <clipPath id="clip1_1066_76824">
            <rect
              width="96"
              height="96"
              fill="white"
              transform="translate(156 170)"
            />
          </clipPath>
        </defs>
      </svg>
    </span>
  )
}

export default withTheme(ErrorIcon)
