/* eslint-disable */

export const getColor = (themeColor: any) => ({
  primary: themeColor.primary,
  bgcolorskyblue: themeColor.bgcolorskyblue,

  textcolordarkblue: themeColor.textcolordarkblue,
  bgcolordarkblue: themeColor.bgcolordarkblue,
  bordercolordarkblue: themeColor.bordercolordarkblue,
  colordarkblue: themeColor.colordarkblue,
  colordarkblue1: themeColor.colordarkblue1,
  bordercolorblue1: themeColor.bordercolorblue1,

  textcolordskyblue: themeColor.textcolordskyblue,
  bgcolordskyblue: themeColor.bgcolordskyblue,
  bordercolordskyblue: themeColor.bordercolordskyblue,
  widgetbgcolorskyblue: themeColor.widgetbgcolorskyblue,

  textcolordlightblue: themeColor.textcolordlightblue,
  bgcolordlightblue: themeColor.bgcolordlightblue,
  bgcolordlightblue1: themeColor.bgcolordlightblue1,
  bordercolordlightblue: themeColor.bordercolordlightblue,

  textcolordwhite: themeColor.textcolordwhite,
  textcolordwhite2: themeColor.textcolordwhite2,
  textcolordwhite3: themeColor.textcolordwhite3,
  bgcolordwhite: themeColor.bgcolordwhite,
  bgcolordwhiteblack: themeColor.bgcolordwhiteblack,
  colordwhitedark: themeColor.colordwhitedark,
  bordercolordwhite: themeColor.bordercolordwhite,
  blockcolororange: themeColor.blockcolororange,

  textcolordblack: themeColor.textcolordblack,
  bgcolordblack: themeColor.bgcolordblack,
  bordercolordblack: themeColor.bordercolordblack,
  colordblack: themeColor.colordblack,
  textcolorlightblack: themeColor.textcolorlightblack,

  textcolorlightgrey: themeColor.textcolorlightgrey,
  textcolordarkgrey: themeColor.textcolordarkgrey,
  bordercolorgrey: themeColor.bordercolorgrey,
  bordercolorsharpgrey: themeColor.bordercolorsharpgrey,

  colorlightgrey: themeColor.colorlightgrey,
  colorlightblack: themeColor.colorlightblack,
  colordanger: themeColor.colordanger,
  colordangerhover: themeColor.colordangerhover,
  colorsuccess: themeColor.colorsuccess,
  colordarkgrey: themeColor.colordarkgrey,
  colorsharpgrey: themeColor.colorsharpgrey,
  colorbluegrey: themeColor.colorbluegrey,
  colorbluegreylight: themeColor.colorbluegreylight,
  colortileblack: themeColor.colortileblack,

  logotextcolor: themeColor.logotextcolor,
  logoiconcolor: themeColor.logoiconcolor,

  bglightblue: themeColor.bglightblue,
  bgborderlight: themeColor.bgborderlight,
  subtext: themeColor.subtext,
  testblock: themeColor.testblock,
  audiosound: themeColor.audiosound,
  camscreen: themeColor.camscreen,
  multipleselectcolor: themeColor.multipleselectcolor,
  customselect: themeColor.customselect,
  customtabtext: themeColor.customtabtext,
  logocongratulationcolor: themeColor.logocongratulationcolor,
  logocongratulationstopcolor: themeColor.logocongratulationstopcolor,
  logocongratulationoffcolor: themeColor.logocongratulationoffcolor,
  borderorange: themeColor.borderorange,
  bordercolorlightgrey: themeColor.bordercolorlightgrey,
  bordercolordanger: themeColor.bordercolordanger,
  contactlist: themeColor.contactlist,
  orangeopacity: themeColor.orangeopacity,
  blueopacity: themeColor.blueopacity,
  greenopacity: themeColor.greenopacity,
  socialbtnbg: themeColor.socialbtnbg,
  socialbtncolor: themeColor.socialbtncolor,
  colortextgray: themeColor.colortextgray,
  colorbluegray: themeColor.colorbluegray,
  colorgrayblue: themeColor.colorgrayblue
})

export const getAntTheme = (themeColor: any) => ({
  colorPrimary: themeColor.primary,
  colorPrimaryBg: themeColor.primary,
  colorBorderSecondary: themeColor.bordercolordwhite,
  controlPaddingHorizontal: 20,
  paddingContentVertical: 50,
  padding: 50,
  fontSize: 16,
  fontFamily: '"Roboto", sans-serif',
  borderRadius: 8,
  colorText: themeColor.textcolordblack,
  colorLink: themeColor.primary
})

export const getComponent = (themeColor: any) => ({
  Button: {
    controlHeight: 51,
    controlHeightSX: 50,
    controlHeightSM: 38,
    controlHeightLG: 60,
    colorBorder: themeColor.primary,
    colorPrimaryBgHover: themeColor.primary,
    colorPrimaryText: themeColor.primary,
    colorText: themeColor.primary,
    colorBgTextHover: themeColor.bgcolordwhite,
    colorBgTextActive: themeColor.bgcolordwhite
  },
  Input: {
    colorText: themeColor.colorlightblack,
    // colorBgContainer: themeColor.secondary,
    colorTextPlaceholder: themeColor.textcolorlightgrey,
    fontSize: 14,
    controlHeight: 42,
    controlHeightSX: 40,
    controlHeightSM: 38,
    controlHeightLG: 48,
    colorPrimaryHover: themeColor.colorlightblack,
    colorError: themeColor.colordanger,
    colorErrorBorder: themeColor.colordanger,
    colorErrorBorderHover: themeColor.colordanger,
    inputPaddingVerticalLG: 13,
    inputPaddingHorizontalLG: 13,
    colorBorder: themeColor.bordercolorsharpgrey,
    colorIcon: themeColor.colorbluegrey,
    activeShadow: "none",
    activeBorderColor: themeColor.colorlightblack
  },
  InputNumber: {
    colorBorder: themeColor.bordercolorsharpgrey,
    colorTextPlaceholder: themeColor.textcolorlightgrey,
    activeShadow: "none",
    activeBorderColor: themeColor.colorlightblack,
    colorPrimaryHover: themeColor.colorlightblack
  },
  Checkbox: {
    colorText: themeColor.colorlightblack,
    fontSize: 14,
    checkboxSize: 40,
    controlHeight: 42
  },
  Form: {
    fontSize: 14
  },
  Select: {
    controlHeight: 42,
    controlHeightSM: 28,
    controlHeightLG: 42,
    colorBgContainer: themeColor.colordwhitedark,
    colorBorder: themeColor.bordercolorsharpgrey,
    colorText: themeColor.textcolordblack,
    colorPrimaryHover: themeColor.colorlightblack,
    controlOutlineWidth: 0,
    colorTextDisabled: themeColor.textcolordarkgrey,
    colorTextPlaceholder: themeColor.red,
    colorPrimary: themeColor.colorlightblack,
    fontSize: 14,
    controlItemBgHover: themeColor.bordercolordarkblue,
    controlItemColorHover: themeColor.colordwhitedark,
    controlItemBgActive: themeColor.bordercolordarkblue
  },
  Modal: {
    colorBgElevated: themeColor.colordwhitedark,
    modalHeaderBg: themeColor.colordwhitedark,
    colorTextHeading: themeColor.bgcolordblack,
    paddingMD: 32,
    paddingContentHorizontalLG: 32
  },
  Radio: {
    radioSize: 18
  },
  Switch: {
    controlHeight: 36,
    switchMinWidthSM: 36
  },
  Table: {
    fontSize: 12,
    colorBgContainer: themeColor.colordwhitedark,
    tableBorderColor: themeColor.bgcolordblack,
    tableHeaderTextColor: themeColor.bgcolordarkblue,
    cellPaddingInline: 12,
    cellPaddingBlock: 16
  },
  DatePicker: {
    controlHeight: 42,
    fontSize: 14,
    colorSplit: themeColor.colorbluegreylight,
    colorBgContainerDisabled: "transparent",
    controlItemBgHover: themeColor.colorbluegreylight,
    controlItemBgActive: themeColor.bgborderlight,
    padding: 16,
    colorBgContainer: themeColor.colordwhitedark,
    colorBorder: themeColor.bordercolorsharpgrey,
    colorTextPlaceholder: themeColor.bgbordergrey,
    colorText: themeColor.textcolordblack,
    colorBgElevated: themeColor.colordwhitedark,
    colorPrimaryHover: themeColor.colorlightblack,
    colorIcon: themeColor.textcolordblack,
    colorTextDisabled: themeColor.colorlightgrey,
    activeBorderColor: themeColor.colorlightblack,
    activeShadow: "none"
  },
  Dropdown: {
    colorError: themeColor.colordanger
  },
  Breadcrumb: {
    fontSize: 14,
    breadcrumbSeparatorColor: themeColor.primary
  }
})
