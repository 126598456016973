import { EntryComponentProps } from "@draft-js-plugins/mention/lib/MentionSuggestions/Entry/Entry"
import React, { ReactElement } from "react"
import { AvatarStyle } from "../../Avatar/Avatar.style"
import getNameInitials from "../../../utils/helper"
import { generateLightColor } from "../../../utils/style"
import MentionSuggestionListStyle from "./MentionSuggestionList.style"

const MentionSuggestionList = (props: EntryComponentProps): ReactElement => {
  const {
    mention,
    theme,
    searchValue, // eslint-disable-line @typescript-eslint/no-unused-vars
    isFocused, // eslint-disable-line @typescript-eslint/no-unused-vars
    ...parentProps
  } = props

  return (
    <MentionSuggestionListStyle>
      <div {...parentProps} className="suggestion-list-wrap">
        <div style={{ display: "flex" }} className="suggestion-list">
          <div className="img-wrapper">
            <AvatarStyle
              style={{
                width: "30px",
                height: "30px"
              }}
              icon={
                <div
                  className="bg-color"
                  style={{
                    backgroundColor: generateLightColor(
                      getNameInitials(mention.name!)
                    )
                  }}
                >
                  {getNameInitials(mention.name!)}
                </div>
              }
              size="small"
              shape="square"
              src={mention.avatar}
            />
            {/* <img
              src={mention.avatar}
              className={theme?.mentionSuggestionsEntryAvatar}
              role="presentation"
            /> */}
          </div>

          <div className="profile-data">
            <div className="name">{mention.name}</div>

            <div className="time">{mention.title}</div>
          </div>
        </div>
      </div>
    </MentionSuggestionListStyle>
  )
}

export default MentionSuggestionList
