/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react"
import { withTheme } from "styled-components"

function ChatJoinVideoIcon({ theme: themeColor }: any) {
  return (
    <span className="anticon anticon-JoinVideo ">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="151"
        height="122"
        viewBox="0 0 151 122"
        fill="none"
      >
        <path
          d="M28.7698 22.6855H28.7061V22.7373H28.7698V22.6855Z"
          fill="black"
        />
        <path
          d="M57.3822 34.6426H57.3403V34.6924H57.3822V34.6426Z"
          fill="black"
        />
        <path
          d="M138.92 88.231V18.7061C138.92 17.1324 137.637 15.8574 136.053 15.8574H14.5581C12.9743 15.8574 11.6914 17.1324 11.6914 18.7061V88.231C11.6914 89.8048 12.9743 91.0797 14.5581 91.0797H136.035C137.617 91.0797 138.92 89.8048 138.92 88.231ZM14.7374 18.0089H135.854C136.334 18.0089 136.737 18.3874 136.737 18.8854V88.0517C136.737 88.5298 136.356 88.9282 135.854 88.9282H14.7374C14.2573 88.9282 13.8548 88.5497 13.8548 88.0517V18.8854C13.8568 18.4073 14.2573 18.0089 14.7374 18.0089Z"
          fill="#0C0C0C"
        />
        <path
          d="M20.8613 46.7621C20.8613 47.2209 21.233 47.5926 21.6918 47.5926H39.3057C39.7645 47.5926 40.1362 47.2209 40.1362 46.7621C40.1362 46.3033 39.7645 45.9316 39.3057 45.9316H21.6918C21.2333 45.9316 20.8613 46.3036 20.8613 46.7621Z"
          fill="#D4DBDF"
        />
        <path
          d="M20.8613 51.0004C20.8613 51.4592 21.233 51.8309 21.6918 51.8309H39.3057C39.7645 51.8309 40.1362 51.4592 40.1362 51.0004C40.1362 50.5416 39.7645 50.1699 39.3057 50.1699H21.6918C21.2333 50.1699 20.8613 50.5416 20.8613 51.0004Z"
          fill="#D4DBDF"
        />
        <path
          d="M20.8613 55.1251C20.8613 55.5208 21.2282 55.8419 21.6806 55.8419H39.0561C39.5085 55.8419 39.8754 55.5212 39.8754 55.1251C39.8754 54.7293 39.5085 54.4082 39.0561 54.4082H21.6806C21.2282 54.4082 20.8613 54.7293 20.8613 55.1251Z"
          fill="#D4DBDF"
        />
        <path
          d="M20.8613 61.4594C20.8613 61.9182 21.233 62.2898 21.6918 62.2898H39.3057C39.7645 62.2898 40.1362 61.9182 40.1362 61.4594C40.1362 61.0006 39.7645 60.6289 39.3057 60.6289H21.6918C21.2333 60.6289 20.8613 61.0009 20.8613 61.4594Z"
          fill="#D4DBDF"
        />
        <path
          d="M20.8613 65.6977C20.8613 66.1565 21.233 66.5281 21.6918 66.5281H39.3057C39.7645 66.5281 40.1362 66.1565 40.1362 65.6977C40.1362 65.2389 39.7645 64.8672 39.3057 64.8672H21.6918C21.2333 64.8672 20.8613 65.2389 20.8613 65.6977Z"
          fill="#D4DBDF"
        />
        <path
          d="M20.8613 69.9359C20.8613 70.3948 21.233 70.7664 21.6918 70.7664H39.3057C39.7645 70.7664 40.1362 70.3948 40.1362 69.9359C40.1362 69.4771 39.7645 69.1055 39.3057 69.1055H21.6918C21.2333 69.1055 20.8613 69.4775 20.8613 69.9359Z"
          fill="#D4DBDF"
        />
        <path
          d="M110.725 46.7621C110.725 47.2209 111.096 47.5926 111.555 47.5926H129.169C129.628 47.5926 129.999 47.2209 129.999 46.7621C129.999 46.3033 129.628 45.9316 129.169 45.9316H111.555C111.097 45.9316 110.725 46.3036 110.725 46.7621Z"
          fill="#D4DBDF"
        />
        <path
          d="M110.725 51.0004C110.725 51.4592 111.096 51.8309 111.555 51.8309H129.169C129.628 51.8309 129.999 51.4592 129.999 51.0004C129.999 50.5416 129.628 50.1699 129.169 50.1699H111.555C111.097 50.1699 110.725 50.5416 110.725 51.0004Z"
          fill="#D4DBDF"
        />
        <path
          d="M110.725 55.1251C110.725 55.5208 111.092 55.8419 111.544 55.8419H128.919C129.372 55.8419 129.739 55.5212 129.739 55.1251C129.739 54.7293 129.372 54.4082 128.919 54.4082H111.544C111.092 54.4082 110.725 54.7293 110.725 55.1251Z"
          fill="#D4DBDF"
        />
        <path
          d="M110.725 61.4594C110.725 61.9182 111.096 62.2898 111.555 62.2898H129.169C129.628 62.2898 129.999 61.9182 129.999 61.4594C129.999 61.0006 129.628 60.6289 129.169 60.6289H111.555C111.097 60.6289 110.725 61.0009 110.725 61.4594Z"
          fill="#D4DBDF"
        />
        <path
          d="M110.725 65.6977C110.725 66.1565 111.096 66.5281 111.555 66.5281H129.169C129.628 66.5281 129.999 66.1565 129.999 65.6977C129.999 65.2389 129.628 64.8672 129.169 64.8672H111.555C111.097 64.8672 110.725 65.2389 110.725 65.6977Z"
          fill="#D4DBDF"
        />
        <path
          d="M110.725 69.9359C110.725 70.3948 111.096 70.7664 111.555 70.7664H129.169C129.628 70.7664 129.999 70.3948 129.999 69.9359C129.999 69.4771 129.628 69.1055 129.169 69.1055H111.555C111.097 69.1055 110.725 69.4775 110.725 69.9359Z"
          fill="#D4DBDF"
        />
        <path
          d="M104.003 38.8145H47.6592V77.7352H104.003V38.8145Z"
          fill="#38A6DE"
        />
        <path
          d="M104.003 37.3887H47.6592V76.3094H104.003V37.3887Z"
          fill="#64C4F5"
        />
        <path
          d="M70.4035 57.5315L49.2913 76.3099H47.6592V75.3177L69.1898 56.168L70.4035 57.5315Z"
          fill="#38A6DE"
        />
        <path
          d="M104.004 75.4496V76.3099H102.224L81.1118 57.5315L82.3255 56.168L104.004 75.4496Z"
          fill="#38A6DE"
        />
        <path
          d="M104.002 37.3887L95.4661 44.9803L77.6867 60.7925C76.628 61.7349 75.0318 61.7349 73.9725 60.7925L56.1968 44.9827L47.6577 37.3887H104.002Z"
          fill="#38A6DE"
        />
        <path
          d="M95.8533 31.5723V45.3818L90.4361 50.1995L88.569 51.8605L85.6709 54.4377L83.8038 56.0986L80.9058 58.6758L79.0387 60.3368L78.0743 61.1944C77.0156 62.1368 75.4194 62.1368 74.36 61.1944L73.3956 60.3368L71.5285 58.6758L68.6304 56.0986L66.763 54.4377L63.865 51.8605L61.9978 50.1995L56.584 45.3845V31.5723C56.584 30.3285 57.5918 29.3203 58.836 29.3203H93.6017C94.8455 29.3203 95.8533 30.3285 95.8533 31.5723Z"
          fill="#A8E4FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M68.6199 50.4482H70.4711V53.4082C70.4711 53.6591 70.5708 53.8999 70.7483 54.0773C70.9258 54.2548 71.1665 54.3545 71.4174 54.3545H80.238C80.489 54.3545 80.7296 54.2548 80.9071 54.0773C81.0844 53.8999 81.1842 53.6591 81.1842 53.4082V50.4482H83.0356C83.43 50.4482 83.8082 50.2915 84.0872 50.0126C84.3661 49.7337 84.5228 49.3554 84.5228 48.961V42.3003C84.5228 41.9058 84.3661 41.5276 84.0872 41.2486C83.8082 40.9697 83.43 40.813 83.0356 40.813H81.1801V37.3018C81.1805 37.051 81.0814 36.8103 80.9042 36.6327C80.7271 36.4552 80.4867 36.3555 80.2358 36.3555H71.4215C71.1705 36.3555 70.9298 36.4552 70.7524 36.6327C70.5749 36.81 70.4752 37.0508 70.4752 37.3018V40.8172H68.6239C68.2295 40.8172 67.8511 40.9739 67.5723 41.2528C67.2934 41.5316 67.1367 41.91 67.1367 42.3044V48.9607C67.1367 49.3545 67.2928 49.7321 67.5708 50.0109C67.8488 50.2897 68.2261 50.4471 68.6199 50.4482ZM69.299 42.9789C69.4036 42.8731 69.5461 42.8135 69.695 42.8135C69.8438 42.8135 69.9863 42.8731 70.0909 42.9789L70.0936 42.9817C70.194 43.0868 70.2506 43.2259 70.2527 43.3706L70.2528 43.3753C70.2509 43.6809 70.0058 43.9293 69.7001 43.9348L69.692 43.9349C69.3868 43.9295 69.1406 43.6832 69.1352 43.378L69.135 43.3714C69.1367 43.2244 69.1952 43.0837 69.299 42.9789ZM73.314 46.9222C73.011 46.9222 72.7653 47.1669 72.7653 47.4688C72.7653 47.7707 73.0109 48.0155 73.314 48.0155H78.3727C78.6757 48.0155 78.9212 47.7707 78.9212 47.4688C78.9212 47.1669 78.6756 46.9222 78.3727 46.9222H73.314ZM72.7653 49.4852C72.7653 49.1833 73.011 48.9387 73.314 48.9387H78.3727C78.6756 48.9387 78.9212 49.1834 78.9212 49.4852C78.9212 49.7872 78.6757 50.0319 78.3727 50.0319H73.314C73.0109 50.0319 72.7653 49.7872 72.7653 49.4852ZM73.314 50.9552C73.011 50.9552 72.7653 51.1998 72.7653 51.5017C72.7653 51.8037 73.0109 52.0484 73.314 52.0484H78.3727C78.6757 52.0484 78.9212 51.8036 78.9212 51.5017C78.9212 51.1999 78.6756 50.9552 78.3727 50.9552H73.314Z"
          fill="#18508D"
        />
        <g opacity="0.7">
          <path
            opacity="0.0323"
            d="M95.6688 108.809C95.6688 108.809 95.6688 108.789 95.6489 108.769L95.629 108.749C95.629 108.749 95.5692 108.689 95.388 108.67L93.1428 108.391H58.0298L55.6851 108.65C55.5038 108.689 55.4241 108.729 55.4241 108.729C55.4241 108.729 55.4042 108.749 55.4042 108.769L55.3843 108.789V108.849C55.3843 108.849 55.3843 108.928 55.6054 109.028C55.7249 109.088 56.5477 109.506 57.8704 109.506C59.6534 109.506 62.02 109.566 62.02 109.566L75.7298 109.626L89.4395 109.566C89.4395 109.566 91.5631 109.506 93.348 109.506C94.6907 109.506 95.3322 109.088 95.4517 109.048C95.6728 108.968 95.6728 108.928 95.6728 108.928L95.6688 108.809Z"
            fill="#F7F7F7"
          />
          <path
            opacity="0.0645"
            d="M95.6687 108.809C95.6687 108.809 95.6687 108.789 95.6487 108.769L95.6288 108.749C95.6288 108.749 95.569 108.689 95.3878 108.67L93.1427 108.391H58.0495L55.7247 108.65C55.5435 108.689 55.4837 108.729 55.4837 108.729C55.4837 108.729 55.4638 108.749 55.4638 108.769L55.4438 108.789V108.869C55.4438 108.869 55.4439 108.948 55.665 109.048C55.7845 109.108 56.5873 109.526 57.93 109.526C59.713 109.526 62.0597 109.586 62.0597 109.586L75.7495 109.646L89.4393 109.586C89.4393 109.586 91.5629 109.526 93.3478 109.526C94.6905 109.526 95.332 109.128 95.4515 109.088C95.6726 109.008 95.6726 108.968 95.6726 108.968L95.6687 108.809Z"
            fill="#EFEFEF"
          />
          <path
            opacity="0.0968"
            d="M95.6687 108.809C95.6687 108.809 95.6687 108.789 95.6487 108.769L95.6288 108.749C95.6288 108.749 95.5691 108.689 95.3878 108.67L93.1427 108.391H58.0495L55.7247 108.65C55.5435 108.689 55.4837 108.729 55.4837 108.729C55.4837 108.729 55.4638 108.749 55.4638 108.769L55.4438 108.789V108.869C55.4438 108.869 55.4439 108.929 55.665 109.048C55.7845 109.108 56.5873 109.506 57.93 109.506C59.713 109.506 62.0597 109.566 62.0597 109.566L75.7495 109.626L89.4393 109.566C89.4393 109.566 91.5629 109.506 93.3678 109.506C94.7105 109.506 95.3519 109.108 95.4714 109.068C95.6926 108.988 95.6926 108.948 95.6926 108.948V108.809H95.6687Z"
            fill="#E6E6E6"
          />
          <path
            opacity="0.129"
            d="M95.6688 108.809C95.6688 108.809 95.6688 108.789 95.6488 108.769L95.6289 108.749C95.6289 108.749 95.5691 108.689 95.3879 108.67L93.1428 108.391H58.0696L55.7448 108.65C55.5635 108.689 55.5037 108.729 55.5037 108.729C55.5037 108.729 55.4838 108.749 55.4838 108.769L55.4639 108.789V108.869C55.4639 108.869 55.4639 108.929 55.685 109.048C55.8045 109.108 56.6073 109.486 57.95 109.486C59.733 109.486 62.0797 109.546 62.0797 109.546L75.7695 109.606L89.4593 109.546C89.4593 109.546 91.5829 109.486 93.3878 109.486C94.7305 109.486 95.3719 109.108 95.4915 109.068C95.7126 108.988 95.7126 108.948 95.7126 108.948V108.809H95.6688Z"
            fill="#DEDEDE"
          />
          <path
            opacity="0.1613"
            d="M95.6688 108.809C95.6688 108.809 95.6688 108.789 95.6488 108.789L95.6289 108.769C95.6289 108.769 95.5691 108.709 95.3879 108.689L93.1428 108.391H58.0696L55.7448 108.67C55.5635 108.709 55.5037 108.749 55.5037 108.749C55.5037 108.749 55.4838 108.769 55.4838 108.789L55.4639 108.809V108.889C55.4639 108.889 55.4639 108.948 55.685 109.048C55.8045 109.108 56.6073 109.486 57.95 109.486C59.733 109.486 62.0797 109.546 62.0797 109.546L75.7695 109.606L89.4593 109.546C89.4593 109.546 91.5829 109.486 93.3878 109.486C94.7305 109.486 95.3918 109.108 95.4915 109.068C95.7126 108.988 95.7126 108.948 95.7126 108.948V108.809H95.6688Z"
            fill="#D6D6D6"
          />
          <path
            opacity="0.1935"
            d="M95.6688 108.809C95.6688 108.809 95.6688 108.789 95.6488 108.789L95.6289 108.769C95.6289 108.769 95.5691 108.709 95.3879 108.689L93.1428 108.391H58.0696L55.7448 108.67C55.5635 108.709 55.5037 108.749 55.5037 108.749C55.5037 108.749 55.4838 108.769 55.4838 108.789L55.4639 108.809V108.909C55.4639 108.909 55.4639 108.968 55.685 109.068C55.8045 109.128 56.5874 109.486 57.9301 109.486C59.7131 109.486 62.0379 109.546 62.0379 109.546L75.7277 109.606L89.4175 109.546C89.4175 109.546 91.563 109.486 93.3459 109.486C94.6886 109.486 95.35 109.128 95.4496 109.088C95.6707 109.008 95.6707 108.968 95.6707 108.968L95.6688 108.809Z"
            fill="#CECECE"
          />
          <path
            opacity="0.2258"
            d="M95.6488 108.789C95.6488 108.789 95.6488 108.77 95.6289 108.77L95.609 108.75C95.609 108.75 95.5492 108.69 95.3679 108.67L93.1228 108.371H58.0696L55.7448 108.65C55.5635 108.69 55.5037 108.73 55.5037 108.73C55.5037 108.73 55.4838 108.75 55.4838 108.77L55.4639 108.789V108.889C55.4639 108.889 55.4639 108.949 55.685 109.048C55.8045 109.108 56.5874 109.447 57.9301 109.447C59.7131 109.447 62.0379 109.487 62.0379 109.487L75.7277 109.546L89.4175 109.487C89.4175 109.487 91.563 109.427 93.3459 109.447C94.6886 109.447 95.35 109.108 95.4496 109.068C95.6707 108.989 95.6707 108.949 95.6707 108.949V108.789H95.6488Z"
            fill="#C5C5C5"
          />
          <path
            opacity="0.2581"
            d="M95.6489 108.789C95.6489 108.789 95.6489 108.77 95.629 108.77L95.6091 108.75C95.6091 108.75 95.5493 108.69 95.368 108.67L93.1229 108.371H58.0697L55.7648 108.65C55.5835 108.69 55.5237 108.73 55.5237 108.73C55.5237 108.73 55.5038 108.75 55.5038 108.77L55.4839 108.789V108.889C55.4839 108.889 55.4839 108.949 55.705 109.048C55.8245 109.108 56.6074 109.447 57.9501 109.447C59.7331 109.447 62.0579 109.487 62.0579 109.487L75.7477 109.546L89.4375 109.487C89.4375 109.487 91.583 109.427 93.366 109.447C94.7087 109.447 95.37 109.108 95.4696 109.068C95.6908 108.989 95.6908 108.949 95.6908 108.949V108.789H95.6489Z"
            fill="#BDBDBD"
          />
          <path
            opacity="0.2903"
            d="M95.6489 108.789C95.6489 108.789 95.6489 108.77 95.629 108.77L95.6091 108.75C95.6091 108.75 95.5493 108.69 95.368 108.67L93.1229 108.371H58.0697L55.7648 108.65C55.5835 108.69 55.5237 108.73 55.5237 108.73C55.5237 108.73 55.5038 108.75 55.5038 108.77L55.4839 108.789V108.889C55.4839 108.889 55.4839 108.949 55.705 109.048C55.8245 109.108 56.6074 109.427 57.9501 109.427C59.7331 109.427 62.0579 109.467 62.0579 109.467L75.7477 109.527L89.4375 109.467C89.4375 109.467 91.583 109.427 93.366 109.427C94.7087 109.427 95.37 109.108 95.4696 109.068C95.6908 108.989 95.6908 108.949 95.6908 108.949V108.789H95.6489Z"
            fill="#B5B5B5"
          />
          <path
            opacity="0.3226"
            d="M95.6488 108.79C95.6488 108.79 95.6488 108.77 95.6289 108.77L95.609 108.73C95.609 108.73 95.5492 108.67 95.3679 108.65L93.1228 108.352H58.0696L55.7647 108.63C55.5834 108.67 55.5236 108.71 55.5236 108.71C55.5236 108.71 55.5037 108.73 55.4838 108.75L55.4639 108.77V108.889C55.4639 108.889 55.4639 108.949 55.685 109.049C55.8045 109.089 56.5675 109.407 57.9102 109.407C59.6931 109.407 62.0179 109.447 62.0179 109.447L75.6858 109.507L89.3537 109.447C89.3537 109.447 91.4993 109.407 93.3021 109.407C94.6448 109.407 95.3062 109.089 95.4058 109.069C95.6269 108.989 95.6269 108.949 95.6269 108.949V108.79H95.6488Z"
            fill="#ADADAD"
          />
          <path
            opacity="0.3548"
            d="M95.6489 108.79C95.6489 108.79 95.6489 108.77 95.629 108.77L95.6091 108.73C95.6091 108.73 95.5493 108.67 95.368 108.65L93.1229 108.352H58.0697L55.7648 108.65C55.5835 108.69 55.5237 108.73 55.5237 108.73C55.5237 108.73 55.5038 108.75 55.4839 108.77V108.79V108.909C55.4839 108.909 55.4839 108.969 55.705 109.069C55.8245 109.109 56.5875 109.427 57.9302 109.407C59.7131 109.407 62.018 109.447 62.018 109.447L75.6859 109.507L89.3538 109.447C89.3538 109.447 91.4994 109.407 93.3022 109.407C94.6449 109.407 95.3063 109.109 95.4059 109.069C95.627 109.009 95.627 108.949 95.627 108.949V108.79H95.6489Z"
            fill="#A5A5A5"
          />
          <path
            opacity="0.3871"
            d="M95.649 108.788C95.649 108.788 95.649 108.788 95.6291 108.768C95.6291 108.748 95.6092 108.729 95.6092 108.729C95.6092 108.729 95.5494 108.689 95.3681 108.649L93.123 108.33H58.0897L55.7848 108.629C55.6035 108.649 55.5437 108.709 55.5437 108.709C55.5437 108.709 55.5238 108.729 55.5039 108.748V108.768V108.888C55.5039 108.888 55.5039 108.948 55.725 109.027C55.8446 109.067 56.6075 109.366 57.9502 109.366C59.7332 109.366 62.038 109.406 62.038 109.406L75.706 109.466L89.3739 109.406C89.3739 109.406 91.5393 109.366 93.3222 109.366C94.6649 109.366 95.3263 109.087 95.4259 109.047C95.647 108.987 95.647 108.928 95.647 108.928V108.788H95.649Z"
            fill="#9C9C9C"
          />
          <path
            opacity="0.4194"
            d="M95.6291 108.788C95.6291 108.788 95.6291 108.788 95.6092 108.768C95.6092 108.748 95.5693 108.729 95.5693 108.729C95.5693 108.729 95.5096 108.689 95.3283 108.649L93.0832 108.33H58.0897L55.7848 108.629C55.6035 108.649 55.5437 108.709 55.5437 108.709C55.5437 108.709 55.5238 108.729 55.5039 108.748V108.768V108.888C55.5039 108.888 55.5039 108.948 55.725 109.027C55.8446 109.067 56.6075 109.346 57.9502 109.346C59.7332 109.346 62.038 109.386 62.038 109.386L75.706 109.446L89.3739 109.386C89.3739 109.386 91.5393 109.346 93.3222 109.346C94.6649 109.346 95.3263 109.067 95.4259 109.047C95.647 108.987 95.647 108.928 95.647 108.928V108.788H95.6291Z"
            fill="#949494"
          />
          <path
            opacity="0.4516"
            d="M95.6292 108.788C95.6292 108.748 95.5894 108.729 95.5894 108.729C95.5894 108.729 95.5296 108.689 95.3483 108.649L93.1032 108.33H58.0898L55.8048 108.629C55.6235 108.649 55.5638 108.709 55.5638 108.709C55.5638 108.709 55.5438 108.729 55.5239 108.748V108.768V108.908C55.5239 108.908 55.5239 108.968 55.745 109.047C55.8646 109.087 56.6276 109.366 57.9503 109.346C59.7333 109.346 62.0381 109.386 62.0381 109.386L75.7061 109.446L89.374 109.386C89.374 109.386 91.5394 109.346 93.3223 109.346C94.665 109.346 95.3264 109.087 95.426 109.047C95.6471 108.987 95.6471 108.928 95.6471 108.928V108.788H95.6292Z"
            fill="#8C8C8C"
          />
          <path
            opacity="0.4839"
            d="M95.6292 108.788C95.6292 108.748 95.5894 108.729 95.5894 108.729C95.5894 108.729 95.5296 108.689 95.3483 108.649L93.1032 108.33H58.0898L55.8048 108.629C55.6235 108.649 55.5638 108.709 55.5638 108.709C55.5638 108.709 55.5438 108.729 55.5239 108.748V108.768V108.908C55.5239 108.908 55.5239 108.968 55.745 109.047C55.8646 109.087 56.6076 109.346 57.9503 109.346C59.7333 109.346 62.0182 109.386 62.0182 109.386L75.6861 109.446L89.354 109.386C89.354 109.386 91.5195 109.346 93.3024 109.346C94.6451 109.346 95.3065 109.087 95.4061 109.067C95.6272 109.007 95.6272 108.948 95.6272 108.948L95.6292 108.788Z"
            fill="#848484"
          />
          <path
            opacity="0.5161"
            d="M95.6292 108.788C95.6292 108.748 95.5894 108.729 95.5894 108.729C95.5894 108.729 95.5296 108.689 95.3483 108.649L93.1032 108.33H58.0898L55.8048 108.649C55.6235 108.669 55.5638 108.729 55.5638 108.729C55.5638 108.729 55.5438 108.748 55.5239 108.768V108.788V108.928C55.5239 108.928 55.5239 108.987 55.745 109.067C55.8646 109.107 56.6076 109.346 57.9503 109.346C59.7333 109.346 62.0182 109.386 62.0182 109.386L75.6861 109.446L89.354 109.386C89.354 109.386 91.5195 109.346 93.3024 109.346C94.6451 109.346 95.3065 109.107 95.426 109.087C95.6471 109.027 95.6471 108.987 95.6471 108.987V108.788H95.6292Z"
            fill="#7B7B7B"
          />
          <path
            opacity="0.5484"
            d="M95.6288 108.769C95.6288 108.749 95.589 108.709 95.589 108.709C95.589 108.709 95.5292 108.669 95.3479 108.629L93.1028 108.311H58.1093L55.8243 108.629C55.6431 108.649 55.5833 108.709 55.5833 108.709C55.5833 108.709 55.5634 108.729 55.5435 108.749V108.888C55.5435 108.888 55.5435 108.948 55.7646 109.028C55.8841 109.068 56.6272 109.307 57.9699 109.287C59.7528 109.287 62.0378 109.327 62.0378 109.327L75.6857 109.386L89.3337 109.327C89.3337 109.327 91.4992 109.287 93.302 109.287C94.6447 109.287 95.3061 109.068 95.4256 109.028C95.6467 108.968 95.6468 108.928 95.6468 108.928V108.769H95.6288Z"
            fill="#737373"
          />
          <path
            opacity="0.5806"
            d="M95.6089 108.769C95.6089 108.749 95.5691 108.709 95.5691 108.709C95.5691 108.709 95.5093 108.669 95.328 108.629L93.0829 108.311H58.1093L55.8243 108.629C55.6431 108.649 55.5833 108.709 55.5833 108.709C55.5833 108.709 55.5634 108.729 55.5435 108.749V108.908C55.5435 108.908 55.5435 108.968 55.7646 109.048C55.8642 109.087 56.6272 109.307 57.9499 109.307C59.7329 109.307 62.0178 109.327 62.0178 109.327L75.6658 109.386L89.3138 109.327C89.3138 109.327 91.4992 109.287 93.2821 109.307C94.6248 109.307 95.3061 109.087 95.4057 109.068C95.6268 109.008 95.6268 108.968 95.6268 108.968V108.769H95.6089Z"
            fill="#6B6B6B"
          />
          <path
            opacity="0.6129"
            d="M95.6088 108.769C95.6088 108.749 95.569 108.709 95.569 108.709C95.569 108.709 95.5092 108.67 95.3279 108.63L93.0828 108.291H58.1092L55.8243 108.61C55.643 108.63 55.5832 108.689 55.5832 108.689C55.5832 108.689 55.5633 108.709 55.5434 108.729V108.889C55.5434 108.889 55.5235 108.948 55.7645 109.008C55.8641 109.048 56.6072 109.247 57.9498 109.247C59.7328 109.247 61.9978 109.267 61.9978 109.267L75.6458 109.327L89.2938 109.267C89.2938 109.267 91.4792 109.227 93.2621 109.247C94.6048 109.247 95.2861 109.048 95.3857 109.008C95.6267 108.948 95.6068 108.909 95.6068 108.909V108.769H95.6088Z"
            fill="#636363"
          />
          <path
            opacity="0.6452"
            d="M95.6089 108.769C95.6089 108.749 95.5691 108.729 95.5691 108.729C95.5691 108.729 95.5093 108.689 95.328 108.649L93.0829 108.311H58.1093L55.8443 108.629C55.663 108.649 55.6032 108.709 55.6032 108.709C55.6032 108.709 55.5634 108.729 55.5634 108.749V108.908C55.5634 108.908 55.5435 108.968 55.7845 109.028C55.8841 109.068 56.6272 109.247 57.9699 109.247C59.7528 109.247 62.0178 109.267 62.0178 109.267L75.6658 109.327L89.3138 109.267C89.3138 109.267 91.4992 109.247 93.2821 109.247C94.6248 109.247 95.3061 109.048 95.4057 109.028C95.6468 108.968 95.6268 108.928 95.6268 108.928V108.769H95.6089Z"
            fill="#5A5A5A"
          />
          <path
            opacity="0.6774"
            d="M95.6089 108.769C95.6089 108.749 95.5691 108.729 95.5691 108.729C95.5691 108.729 95.5093 108.689 95.328 108.649L93.0829 108.311H58.1093L55.8443 108.629C55.663 108.649 55.6032 108.709 55.6032 108.709C55.6032 108.709 55.5634 108.729 55.5634 108.749V108.908C55.5634 108.908 55.5435 108.968 55.7845 109.028C55.8841 109.068 56.6272 109.247 57.9699 109.247C59.7528 109.247 62.0178 109.267 62.0178 109.267L75.6658 109.307L89.3138 109.267C89.3138 109.267 91.4992 109.247 93.2821 109.247C94.6248 109.247 95.3061 109.068 95.4057 109.048C95.6468 108.988 95.6268 108.948 95.6268 108.948V108.769H95.6089Z"
            fill="#525252"
          />
          <path
            opacity="0.7097"
            d="M95.609 108.769C95.609 108.749 95.5692 108.729 95.5692 108.729C95.5692 108.729 95.5094 108.689 95.3281 108.649L93.083 108.311H58.1293L55.8643 108.649C55.683 108.669 55.6233 108.729 55.6233 108.729C55.6233 108.729 55.5834 108.749 55.5834 108.769V108.948C55.5834 108.948 55.5635 109.008 55.8045 109.068C55.9041 109.087 56.6472 109.267 57.97 109.267C59.7529 109.267 62.0179 109.287 62.0179 109.287L75.6659 109.327L89.3139 109.287C89.3139 109.287 91.4993 109.267 93.2822 109.267C94.6249 109.267 95.3062 109.107 95.4058 109.068C95.6469 109.008 95.6269 108.968 95.6269 108.968V108.769H95.609Z"
            fill="#4A4A4A"
          />
          <path
            opacity="0.7419"
            d="M95.609 108.769C95.609 108.749 95.5692 108.729 95.5692 108.729C95.5692 108.729 95.5094 108.689 95.3281 108.649L93.083 108.311H58.1293L55.8643 108.649C55.683 108.669 55.6233 108.729 55.6233 108.729C55.6233 108.729 55.5834 108.749 55.5834 108.769V108.948C55.5834 108.948 55.5635 109.008 55.8045 109.068C55.9041 109.087 56.6472 109.247 57.97 109.247C59.7529 109.247 62.0179 109.267 62.0179 109.267L75.6659 109.307L89.3139 109.267C89.3139 109.267 91.4993 109.247 93.3021 109.247C94.6448 109.247 95.3261 109.087 95.4257 109.068C95.6668 109.008 95.6469 108.968 95.6469 108.968V108.769H95.609Z"
            fill="#424242"
          />
          <path
            opacity="0.7742"
            d="M95.5891 108.769C95.5891 108.749 95.5492 108.729 95.5492 108.729C95.5492 108.729 95.4895 108.689 95.3082 108.649L93.0631 108.311H58.1293L55.8643 108.649C55.683 108.669 55.6233 108.729 55.6233 108.729C55.6233 108.729 55.5834 108.749 55.5834 108.769V108.948C55.5834 108.948 55.5635 109.008 55.8045 109.068C55.9041 109.087 56.6253 109.247 57.97 109.247C59.7529 109.247 61.998 109.267 61.998 109.267L75.6261 109.307L89.2542 109.267C89.2542 109.267 91.4594 109.247 93.2424 109.247C94.5851 109.247 95.2664 109.107 95.366 109.087C95.607 109.048 95.5871 108.988 95.5871 108.988V108.769H95.5891Z"
            fill="#3A3A3A"
          />
          <path
            opacity="0.8065"
            d="M95.5891 108.769C95.5891 108.749 95.5492 108.729 95.5492 108.729C95.5492 108.729 95.4895 108.689 95.3082 108.649L93.0631 108.311H58.1293L55.8643 108.649C55.683 108.669 55.6233 108.729 55.6233 108.729C55.6233 108.729 55.5834 108.749 55.5834 108.769V108.948C55.5834 108.948 55.5635 109.008 55.8045 109.068C55.9041 109.087 56.6253 109.227 57.97 109.227C59.7529 109.227 61.998 109.247 61.998 109.247L75.6261 109.287L89.2542 109.247C89.2542 109.247 91.4594 109.227 93.2424 109.227C94.5851 109.227 95.2664 109.087 95.366 109.068C95.607 109.028 95.5871 108.968 95.5871 108.968V108.769H95.5891Z"
            fill="#313131"
          />
          <path
            opacity="0.8387"
            d="M95.5887 108.769C95.5887 108.749 95.5489 108.729 95.5489 108.729C95.5489 108.729 95.4891 108.689 95.3078 108.65L93.0627 108.291H58.1289L55.8838 108.63C55.7025 108.65 55.6428 108.709 55.6428 108.709C55.6428 108.709 55.6029 108.729 55.6029 108.749V108.928C55.6029 108.928 55.583 108.988 55.8241 109.028C55.9237 109.048 56.6448 109.168 57.9696 109.168C59.7525 109.168 61.9976 109.187 61.9976 109.187L75.6257 109.227L89.2538 109.187C89.2538 109.187 91.459 109.168 93.242 109.168C94.5847 109.168 95.266 109.048 95.3656 109.028C95.6066 108.988 95.5867 108.928 95.5867 108.928V108.769H95.5887Z"
            fill="#292929"
          />
          <path
            opacity="0.871"
            d="M95.5888 108.749C95.5888 108.729 95.549 108.709 95.549 108.709C95.549 108.709 95.4892 108.67 95.3079 108.65L93.0628 108.291H58.149L55.9038 108.63C55.7226 108.65 55.6628 108.689 55.6628 108.689C55.6628 108.689 55.623 108.709 55.623 108.729V108.928C55.623 108.928 55.603 108.988 55.8441 109.028C55.9437 109.048 56.6648 109.168 57.9896 109.168C59.7725 109.168 62.0176 109.187 62.0176 109.187L75.6457 109.227L89.2738 109.187C89.2738 109.187 91.4791 109.168 93.262 109.168C94.6047 109.168 95.286 109.068 95.3856 109.048C95.6266 109.008 95.6067 108.948 95.6067 108.948V108.749H95.5888Z"
            fill="#212121"
          />
          <path
            opacity="0.9032"
            d="M95.5888 108.749C95.5888 108.729 95.549 108.709 95.549 108.709C95.549 108.709 95.4892 108.67 95.3079 108.65L93.0628 108.291H58.149L55.9038 108.65C55.7226 108.67 55.6628 108.709 55.6628 108.709C55.6628 108.709 55.623 108.729 55.623 108.749V108.948C55.623 108.948 55.603 109.008 55.8441 109.048C55.9437 109.068 56.6449 109.168 57.9896 109.168C59.7725 109.168 61.9977 109.168 61.9977 109.168L75.6258 109.207L89.2539 109.168C89.2539 109.168 91.4591 109.148 93.2421 109.168C94.5848 109.168 95.2661 109.068 95.3657 109.048C95.6067 109.008 95.5868 108.948 95.5868 108.948V108.749H95.5888Z"
            fill="#191919"
          />
          <path
            opacity="0.9355"
            d="M95.5888 108.749C95.5888 108.729 95.549 108.709 95.549 108.709C95.549 108.709 95.4892 108.67 95.3079 108.65L93.0628 108.291H58.149L55.9038 108.65C55.7226 108.67 55.6628 108.709 55.6628 108.709C55.6628 108.709 55.623 108.729 55.623 108.749V108.948C55.623 108.948 55.603 109.008 55.8441 109.048C55.9437 109.068 56.6449 109.148 57.9896 109.148C59.7725 109.148 61.9977 109.148 61.9977 109.148L75.6258 109.187L89.2539 109.148C89.2539 109.148 91.4591 109.128 93.2421 109.148C94.5848 109.148 95.2661 109.068 95.3657 109.048C95.6067 109.008 95.5868 108.948 95.5868 108.948V108.749H95.5888Z"
            fill="#101010"
          />
          <path
            opacity="0.9677"
            d="M95.5689 108.749C95.5689 108.729 95.529 108.709 95.529 108.709C95.529 108.709 95.4693 108.67 95.288 108.65L93.0429 108.291H58.149L55.9038 108.65C55.7226 108.67 55.6628 108.709 55.6628 108.709C55.6628 108.709 55.623 108.729 55.623 108.749V108.948C55.623 108.948 55.603 109.008 55.8441 109.048C55.9437 109.068 56.6449 109.148 57.9896 109.128C59.7725 109.128 61.9977 109.128 61.9977 109.128L75.6258 109.168L89.2539 109.128C89.2539 109.128 91.4791 109.128 93.262 109.128C94.6047 109.128 95.3059 109.048 95.3856 109.048C95.6266 109.008 95.6067 108.948 95.6067 108.948V108.749H95.5689Z"
            fill="#080808"
          />
          <path
            d="M95.5689 108.749C95.5689 108.729 95.529 108.709 95.529 108.709C95.529 108.709 95.4693 108.67 95.288 108.65L93.0429 108.291H58.149L55.9038 108.65C55.7226 108.67 55.6628 108.709 55.6628 108.709C55.6628 108.709 55.623 108.729 55.623 108.749V108.968C55.623 108.968 55.603 109.028 55.8441 109.068C55.9437 109.088 56.6449 109.148 57.9677 109.148C59.7506 109.148 61.9758 109.148 61.9758 109.148L75.6039 109.187L89.2319 109.148C89.2319 109.148 91.4571 109.148 93.2401 109.148C94.5828 109.148 95.284 109.088 95.3637 109.068C95.6047 109.028 95.5848 108.968 95.5848 108.968V108.749H95.5689Z"
            fill="black"
          />
        </g>
        <path
          d="M55.3845 108.152C55.2251 108.411 55.2451 108.71 55.2849 108.81C55.3248 108.929 55.4861 109.188 56.3268 109.188C56.9882 109.188 66.3671 109.168 75.7878 109.168L75.8077 108.611C66.4288 108.63 57.0878 108.65 56.4264 108.65C55.6634 108.67 55.524 108.292 55.3845 108.152Z"
          fill="url(#paint0_linear_35078_340588)"
        />
        <path
          d="M95.7884 108.152C95.9478 108.411 95.9279 108.71 95.888 108.81C95.8482 108.929 95.6868 109.188 94.8461 109.188C94.1848 109.188 84.8059 109.168 75.3852 109.168L75.3652 108.611C84.7441 108.63 94.0852 108.65 94.7465 108.65C95.5075 108.67 95.649 108.292 95.7884 108.152Z"
          fill="url(#paint1_linear_35078_340588)"
        />
        <path
          d="M95.5898 107.933L92.0618 105.662C90.1573 104.487 89.9581 100.861 89.8366 99.8255L89.0556 91.0801H75.5869H62.0983L61.3174 99.8255C61.2178 100.861 61.0166 104.487 59.0922 105.662L55.5642 107.933C55.4048 108.033 55.3032 108.192 55.3032 108.372C55.3032 108.591 55.4626 108.83 56.2057 108.83C57.5284 108.83 75.5869 108.83 75.5869 108.83C75.5869 108.83 93.6455 108.83 94.9682 108.83C95.6894 108.83 95.8707 108.591 95.8707 108.372C95.8488 108.192 95.7492 108.033 95.5898 107.933Z"
          fill="url(#paint2_linear_35078_340588)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_35078_340588"
            x1="55.2553"
            y1="108.67"
            x2="75.6645"
            y2="108.67"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.00140944" stopColor="#595759" />
            <stop offset="0.00262294" stopColor="#636163" />
            <stop offset="0.00867688" stopColor="#8F8E8F" />
            <stop offset="0.0134" stopColor="#ABABAB" />
            <stop offset="0.0161" stopColor="#B5B5B5" />
            <stop offset="0.0218" stopColor="#BCBCBC" />
            <stop offset="0.0259" stopColor="#C4C4C4" />
            <stop offset="0.0309" stopColor="#CCCCCC" />
            <stop offset="0.0358" stopColor="#C4C4C4" />
            <stop offset="0.0478" stopColor="#7D7D7D" />
            <stop offset="0.0558" stopColor="#605F60" />
            <stop offset="0.0645" stopColor="#454245" />
            <stop offset="0.08" stopColor="#323133" />
            <stop offset="1" stopColor="#3F3D40" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_35078_340588"
            x1="95.9174"
            y1="108.67"
            x2="75.5082"
            y2="108.67"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.00140944" stopColor="#595759" />
            <stop offset="0.00262294" stopColor="#636163" />
            <stop offset="0.00867688" stopColor="#8F8E8F" />
            <stop offset="0.0134" stopColor="#ABABAB" />
            <stop offset="0.0161" stopColor="#B5B5B5" />
            <stop offset="0.0218" stopColor="#BCBCBC" />
            <stop offset="0.0259" stopColor="#C4C4C4" />
            <stop offset="0.0309" stopColor="#CCCCCC" />
            <stop offset="0.0358" stopColor="#C4C4C4" />
            <stop offset="0.0478" stopColor="#7D7D7D" />
            <stop offset="0.0558" stopColor="#605F60" />
            <stop offset="0.0645" stopColor="#454245" />
            <stop offset="0.08" stopColor="#323133" />
            <stop offset="1" stopColor="#3F3D40" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_35078_340588"
            x1="75.5869"
            y1="91.0801"
            x2="75.5869"
            y2="108.705"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.0471" stopColor="#1A1A1C" />
            <stop offset="0.2435" stopColor="#2D2B2E" />
            <stop offset="0.3028" stopColor="#333133" />
            <stop offset="0.3801" stopColor="#424143" />
            <stop offset="0.4672" stopColor="#5C5C5C" />
            <stop offset="0.4813" stopColor="#616161" />
            <stop offset="0.5715" stopColor="#858585" />
            <stop offset="0.5942" stopColor="#858585" />
            <stop offset="0.6151" stopColor="#7E7E7F" />
            <stop offset="0.6455" stopColor="#6C6A6D" />
            <stop offset="0.6559" stopColor="#646266" />
            <stop offset="0.6724" stopColor="#565558" />
            <stop offset="0.7058" stopColor="#403F41" />
            <stop offset="0.7373" stopColor="#333233" />
            <stop offset="0.7646" stopColor="#2E2D2E" />
            <stop offset="0.8196" stopColor="#3A3A3B" />
            <stop offset="1" stopColor="#787878" />
          </linearGradient>
        </defs>
      </svg>
    </span>
  )
}
export default withTheme(ChatJoinVideoIcon)
