const getNameInitials = (name: string) => {
  const splittedName = name?.split(" ")
  if (splittedName?.length >= 2) {
    return `${splittedName[0][0]}${splittedName[1][0]}`
  }
  return `${splittedName?.[0][0]}`
}
export default getNameInitials

export const flags = {
  DebugProd: false,
  appleLogin: true,
  appleLoginDesktop: true,
  callModule: true,
  channelModule: true,
  chatModule: true,
  googleLogin: true,
  matrixUserPresenceStatus: true,
  microsoftLogin: true,
  webPhone: true,
  webinar: true
}
