import { useEffect } from "react"
import useConnectivityTest from "../../hooks/useConnectivityTest"

const Relay = ({ setLogs, setErrorLogs, setIsLoading }) => {
  const relayMessage = useConnectivityTest("relay")

  useEffect(() => {
    setLogs((prevState) => ({
      ...prevState,
      connectivityRelay: relayMessage?.message
    }))
    if (relayMessage?.message) {
      setIsLoading((prevState) => ({
        ...prevState,
        connectivityRelay: false
      }))
    }
  }, [relayMessage?.message])

  useEffect(() => {
    setErrorLogs((prevState) => ({
      ...prevState,
      connectivityRelay: relayMessage?.errorMessage
    }))
  }, [relayMessage?.errorMessage])

  return (
    <>
      <div>Relay</div>
      {/* {relayMessage?.message.map((mes) => (
        <div>{mes}</div>
      ))} */}
    </>
  )
}

export default Relay
