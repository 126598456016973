import LoaderStyle from "./Loader.style"

function Loader() {
  return (
    <LoaderStyle>
      <div className="loading">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </LoaderStyle>
  )
}

export default Loader
