import { ConfigProvider } from "antd"
import { lazy, memo, Suspense, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ThemeProvider } from "styled-components"
// import { useLDClient } from "launchdarkly-react-client-sdk"
import * as Sentry from "@sentry/react"
import "./App.scss"
import "./utils/i18"
// import { Replay } from "@sentry/browser"
import { useLocation, useNavigate } from "react-router-dom"
import { getAntTheme, getColor, getComponent } from "./config/ThemeVariable"
// import Onboarding from './pages/Onboarding/Onboarding';
import { GlobalStyle } from "./config/global.style"
// import AudioSettings from './pages/Accounts/AudioSettings/AudioSettings';
import { AccessMicTitle } from "./components/AllowAccess/AllowMicAccessModal.style"

import Button from "./components/Button/Button"
import OneCloudIcon from "./components/Icons/Onecloud"
import SplashLoader from "./components/SplasLoader/SplasLoader"
import CustomTheme from "./config/themeColor"
import { changeTheme } from "./pages/Accounts/SelectTheme/ThemeSlice"
import Loading from "./pages/Loading/Loading"
import { setHideMeeting } from "./pages/MeetingWrapper/MeetingSlice"
import { RootState } from "./store/store"
import RoutesArr from "./utils/routes"
import settings from "./components/Chat/client/state/settings"

const AllowMicAccessModal = lazy(
  () => import("./components/AllowAccess/AllowMicAccessModal")
)

const DownloadMobileApp = lazy(
  () => import("./components/DownloadMobileApp/DownloadMobileApp")
)

Sentry.init({
  dsn: import.meta.env?.VITE_REACT_SENTRY_DSN,
  debug: true,
  // attachStacktrace: true,
  autoSessionTracking: false,
  // integrations: [new Replay({ maskAllText: true, blockAllMedia: true })],
  // tracesSampleRate: 1.0,
  // replaysSessionSampleRate: 0.1,
  // replaysOnErrorSampleRate: 1.0,
  environment: import.meta.env?.VITE_ENV
})

const flags = {
  DebugProd: false,
  appleLogin: true,
  appleLoginDesktop: true,
  callModule: true,
  channelModule: true,
  chatModule: true,
  googleLogin: true,
  matrixUserPresenceStatus: true,
  microsoftLogin: true,
  webPhone: true,
  webinar: true
}
function App() {
  /* eslint-disable-next-line */
  // const userLoggedin = localStorage.getItem('access-token');

  // const ldClient = useLDClient()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()

  // const flags = AppConstants.convertToCamelCase(ldClient?.allFlags())
  const { auth } = useSelector((state: RootState) => state)
  const theme = useSelector((state: RootState) => state.theme.theme as string)
  const currentTheme = useSelector(
    (state: RootState) => state.theme.currentTheme as string
  )

  const showMeeting = useSelector(
    (state: RootState) => state.meetingdata.showMeeting
  )

  const hideMeeting = useSelector(
    (state: RootState) => state.meetingdata.hideMeeting
  )

  const [allowMic, setAllowMic] = useState(true)
  const [installPrompt, setInstallPrompt] = useState<any>(null)

  useEffect(() => {
    if (
      location.pathname == "/meetings" ||
      location.pathname == "/guestmeeting"
    ) {
      if (showMeeting) {
        dispatch(setHideMeeting(false))
      }
      // dispatch(setShowMeeting(true))
    } else {
      dispatch(setHideMeeting(true))
    }
  }, [location])
  useEffect(() => {
    if (showMeeting) {
      dispatch(setHideMeeting(false))
    } else {
      dispatch(setHideMeeting(true))
    }
  }, [showMeeting])

  // useEffect(() => {
  //   if (auth.userProfile.email) {
  //     // eslint-disable-next-line @typescript-eslint/naming-convention
  //     const { email, first_name, last_name } = auth.userProfile
  //     ldClient?.identify({
  //       kind: "user",
  //       key: auth?.userProfile.email,
  //       name: `${first_name} ${last_name}`.trim(),
  //       firstName: first_name,
  //       lastName: last_name,
  //       platform: "web",
  //       email
  //     })
  //   }
  // }, [auth.userProfile, ldClient])

  useEffect(() => {
    window
      .matchMedia("(prefers-color-scheme: light)")
      .addEventListener("change", (e) => {
        const themes = e.matches ? "default" : "darkmode"
        e.matches ? settings.setTheme(0) : settings.setTheme(2)
        if (currentTheme === "SYSTEM_PREFRENCE") {
          dispatch(
            changeTheme({ theme: themes, currentTheme: "SYSTEM_PREFRENCE" })
          )
        }
      })
  }, [currentTheme, dispatch])

  // const checkPermissions = async () => {
  //   try {
  //     // await navigator.mediaDevices.getUserMedia({ audio: true })
  //     const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
  //     setAllowMic(true)
  //     stream.getTracks().forEach((track) => track.stop())
  //   } catch (error) {
  //     setAllowMic(false)
  //   }
  // }

  // useEffect(() => {
  //   if (auth.userProfile.email) {
  //     checkPermissions()
  //   }
  // }, [auth.userProfile.email])

  useEffect(() => {
    const systemTheme = window.matchMedia(
      "(prefers-color-scheme: light)"
    ).matches
    if (systemTheme) {
      dispatch(
        changeTheme({ theme: "default", currentTheme: "SYSTEM_PREFRENCE" })
      )
      settings.setTheme(0)
    } else {
      dispatch(
        changeTheme({ theme: "darkmode", currentTheme: "SYSTEM_PREFRENCE" })
      )
      settings.setTheme(2)
    }

    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener("message", function (event) {
        if (event?.data?.url) {
          const parsedUrl = new URL(event.data.url)
          const pathWithoutHost = parsedUrl.pathname + parsedUrl.search
          navigate(pathWithoutHost, { replace: true })
        }
      })
      return navigator.serviceWorker.removeEventListener("message", () => {})
    }
  }, [])

  // function disableInAppInstallPrompt() {
  //   setInstallPrompt(null)
  // }

  // useEffect(() => {
  //   window?.addEventListener("beforeinstallprompt", (event) => {
  //     event.preventDefault()
  //     if (localStorage.getItem("isInstallPrompt") !== "false") {
  //       setInstallPrompt(event)
  //     } else {
  //       setInstallPrompt(null)
  //     }
  //   })

  //   return window.removeEventListener("beforeinstallprompt", () => {})
  // }, [])

  // const handleClick = async () => {
  //   if (!installPrompt) {
  //     return
  //   }
  //   if (installPrompt) {
  //     await installPrompt.prompt()
  //     const choiceResult = await installPrompt.userChoice
  //     if (choiceResult.outcome === "accepted") {
  //       console.log("User accepted the PWA installation")
  //       disableInAppInstallPrompt()
  //     } else {
  //       console.log("User declined the PWA installation")
  //       disableInAppInstallPrompt()
  //       localStorage.setItem("isInstallPrompt", "false")
  //     }
  //   }
  // }

  // const handleCancel = async () => {
  //   disableInAppInstallPrompt()
  //   localStorage.setItem("isInstallPrompt", "false")
  // }

  // useEffect(() => {
  //   window?.addEventListener("appinstalled", () => {
  //     disableInAppInstallPrompt()
  //   })

  //   return window.removeEventListener("appinstalled", () => {})
  // }, [])

  const resolvedTheme =
    location.pathname == "/meetings" || location.pathname == "/guestmeeting"
      ? CustomTheme.DarkMode
      : theme

  return (
    <ThemeProvider theme={getColor(resolvedTheme)}>
      <Suspense fallback={<SplashLoader />}>
        {/* {installPrompt && (
        <div className="install-app">
          <p>Do you want to install OneCloud App ?</p>
          <div className="install-actions">
            <Button
              hidden
              onClick={() => handleClick()}
              type="default"
              size="middle"
            >
              Install
            </Button>
            <Button
              hidden
              onClick={() => handleCancel()}
              type="text"
              className="grey-btntext"
            >
              Cancel
            </Button>
          </div>
        </div>
      )} */}

        <ConfigProvider
          theme={{
            token: getAntTheme(resolvedTheme),
            inherit: false,
            components: getComponent(resolvedTheme)
          }}
        >
          <GlobalStyle />
          {/* <AllowMicAccessModal
            open={!allowMic}
            closable={false}
            width={516}
            centered
            wrapClassName="ant-modal-middle-space"
            title={[
              <AccessMicTitle>
                <OneCloudIcon height="33px" width="33px" />
                Allow OneCloud to access your microphone
              </AccessMicTitle>
            ]}
            footer={[
              <div className="d-flex justify-content-end" key={0}>
                <Button
                  type="primary"
                  size="small"
                  onClick={() => setAllowMic(true)}
                >
                  OK
                </Button>
              </div>
            ]}
          /> */}
          <DownloadMobileApp />
          <Suspense fallback={<Loading />}>
            <RoutesArr />
          </Suspense>
        </ConfigProvider>
      </Suspense>
    </ThemeProvider>
  )
}
export default memo(App)
