import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface MeetingData {
  hasJoined: boolean
  meetingToken: string
  meetingId: string
  startMeeting: boolean
  recordingToken: string
  recordingId: string
  scheduleId: string
  isModerator: boolean
  showMeeting: boolean
  hideMeeting: boolean
  isFromJoinMeeting: boolean
  jitsiLoading: boolean
  config: any
  jitsiApi: boolean | null
}

const initialState: MeetingData = {
  hasJoined: false,
  meetingToken: "",
  meetingId: "",
  startMeeting: false,
  recordingToken: "",
  recordingId: "",
  scheduleId: "",
  isModerator: false,
  showMeeting: false,
  hideMeeting: true,
  isFromJoinMeeting: false,
  jitsiLoading: true,
  config: {},
  jitsiApi: null
}

export const meetingSlice = createSlice({
  name: "meetingSlice",
  initialState,
  reducers: {
    setHasJoined: (state, action: PayloadAction<boolean>) => {
      state.hasJoined = action.payload
    },
    setMeetingToken: (state, action: PayloadAction<string>) => {
      state.meetingToken = action.payload
    },
    setMeetingId: (state, action: PayloadAction<string>) => {
      state.meetingId = action.payload
    },
    setRecordingToken: (state, action: PayloadAction<string>) => {
      state.recordingToken = action.payload
    },
    setRecordingId: (state, action: PayloadAction<string>) => {
      state.recordingId = action.payload
    },
    setStartMeeting: (state, action: PayloadAction<boolean>) => {
      state.startMeeting = action.payload
    },
    setIsModerator: (state, action: PayloadAction<boolean>) => {
      state.isModerator = action.payload
    },
    setShowMeeting: (state, action: PayloadAction<boolean>) => {
      state.showMeeting = action.payload
    },
    setHideMeeting: (state, action: PayloadAction<boolean>) => {
      state.hideMeeting = action.payload
    },
    setIsFromJoinMeeting: (state, action: PayloadAction<boolean>) => {
      state.isFromJoinMeeting = action.payload
    },
    setScheduleId: (state, action: PayloadAction<string>) => {
      state.scheduleId = action.payload
    },
    setConfig: (state, action: PayloadAction<any>) => {
      state.config = action.payload
    },
    setJitsiApi: (state, action: PayloadAction<boolean>) => {
      state.jitsiApi = action.payload
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.jitsiLoading = action.payload
    }
  }
})

export const {
  setHasJoined,
  setMeetingToken,
  setMeetingId,
  setStartMeeting,
  setRecordingToken,
  setRecordingId,
  setScheduleId,
  setIsModerator,
  setConfig,
  setShowMeeting,
  setIsFromJoinMeeting,
  setHideMeeting,
  setJitsiApi,
  setLoading
} = meetingSlice.actions

export default meetingSlice.reducer
