/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react"
import { withTheme } from "styled-components"

function AvatarSmsIcon({ theme: themeColor }: any) {
  return (
    <span className="anticon">
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.54427 7.91732H3.33594C1.66927 7.91732 0.835938 7.50065 0.835938 5.41732V3.33398C0.835938 1.66732 1.66927 0.833984 3.33594 0.833984H6.66927C8.33594 0.833984 9.16927 1.66732 9.16927 3.33398V5.41732C9.16927 7.08398 8.33594 7.91732 6.66927 7.91732H6.46094C6.33177 7.91732 6.20677 7.97982 6.1276 8.08398L5.5026 8.91732C5.2276 9.28398 4.7776 9.28398 4.5026 8.91732L3.8776 8.08398C3.81094 7.99232 3.65677 7.91732 3.54427 7.91732Z"
          stroke="currentcolor"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.66698 4.58333H6.67072"
          stroke="currentcolor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.99511 4.58333H4.99885"
          stroke="currentcolor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.33104 4.58333H3.33479"
          stroke="currentcolor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  )
}

export default withTheme(AvatarSmsIcon)
