import styled, { css } from "styled-components"
import { md } from "../../config/variable"

export const ChatDemoWidgetStyle = styled.div`
  margin-bottom: 24px;
  .ant-col {
    display: flex;
    justify-content: center;
  }
  .widget-block {
    width: 100%;
    transition: 0.2s ease all;
    border-radius: 16px;
    padding: 12px;
    text-align: center;
    background-color: ${(props) => props.theme.testblock};
    min-height: 225px;
    .details-chat {
      font-size: 12px;
    }
    h5 {
      &.ant-typography {
        font-size: 16px;
        margin-bottom: 19px;
        margin-top: 8px;
        margin-bottom: 8px;
      }
    }
    .ant-typography {
      margin: 10px 0 20px;
      font-size: 12px;
    }
    ${md(css`
      width: 100%;
    `)}
  }
`
export const ChatDemoWidgetTitleStyle = styled.div`
  text-align: center;
  margin-bottom: 40px;
  p {
    color: #8083a3;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    text-align: center;
  }
`
