import React, { useEffect, useRef } from "react"    

const ScrollToBottomContainer = ({ children }) => {
  const containerRef = useRef(null)
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight
    }
  }, [children])
  return (
    <div
      ref={containerRef}
      style={{overflowY: "auto", height: "100%"}} 
    >
      {" "}
      {children}{" "}
    </div>
  )
}
export default ScrollToBottomContainer
