import { useEffect } from "react"
import useNetworkTest from "../../hooks/useNetworkTest"

const TCP = ({ setLogs, setErrorLogs, setIsLoading }) => {
  const tcpMessage = useNetworkTest("tcp")

  useEffect(() => {
    setLogs((prevState) => ({
      ...prevState,
      networkTcp: tcpMessage?.message
    }))

    if (tcpMessage?.message) {
      setIsLoading((prevState) => ({
        ...prevState,
        networkTcp: false
      }))
    }
  }, [tcpMessage?.message])

  useEffect(() => {
    setErrorLogs((prevState) => ({
      ...prevState,
      networkTcp: tcpMessage?.errorMessage
    }))
  }, [tcpMessage?.errorMessage])

  return (
    <div>
      <div>
        <h2>TCP</h2>
        {/* {tcpMessage?.message?.map((mes) => (
          <div>{mes}</div>
        ))} */}
      </div>
    </div>
  )
}

export default TCP
