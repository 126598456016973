import { Select } from "antd"
import styled from "styled-components"

export const SelectWrapperStyle = styled.div`
  .profilecover-icon-wrapper {
    margin-right: 5px;
  }

  .pointer {
    cursor: pointer;
  }
`

export const ModalContentWrapperStyle = styled.div`
  .pointer {
    cursor: pointer;
  }
`

const SelectBarStyle = styled(Select)`
  display: block;
  .ant-select-selection-placeholder {
    color: ${(props) => props.theme.colorlightblack};
    opacity: 0.6;
  }

  .ant-select-arrow {
    .anticon {
      pointer-events: none !important;
    }
  }

  .anticon {
    font-size: 14px;
    color: ${(props) => props.theme.colorbluegrey};
  }
  &.h-48 {
    &.ant-select {
      height: 48px;
    }
    .ant-select-selector {
      height: 48px;
      align-items: center;
    }
  }
  &.h-42 {
    .ant-select-selector {
      height: 42px;
      align-items: center;
    }
  }
  &.multiple {
    .ant-select-selection-item {
      border-radius: 50px;
      height: 32px;
      align-items: center;
      display: flex;
      margin: 3px 5px;
      background-color: ${(props) => props.theme.cardborder};
    }
  }
`
export default SelectBarStyle
