import styled from "styled-components"
import { rgba } from "../../../config/variable"

const InputEditorStyle = styled.div`
  margin-bottom: 24px;
  border-radius: 8px;
  overflow: hidden;
  .typig-person {
    display: flex;
    align-items: center;
    margin: 6px 0;
    .profile-pic-wrapper {
      display: flex;
      margin-right: 6px;
    }
    .profile-pic {
      background: transparent;
      font-size: 8px;
      color: ${(props) => props.theme.textcolordwhite3};
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
      border-radius: 100%;
      border: 1px solid ${(props) => props.theme.bgcolordwhiteblack};
      display: flex;
      align-items: center;
      .ant-avatar {
        font-size: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        background-color: transparent;
        border-radius: 100%;
        .bg-color {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
        }
      }
      &:not(:first-child) {
        margin-left: -6px;
        z-index: 1;
      }
    }
    .person-name {
      font-weight: 700;
      margin-right: 3px;
    }
    .person-name,
    .person-typing {
      color: ${(props) => props.theme.textcolordarkgrey};
      font-size: 12px;
    }
  }
  .writemsg {
    .typing_block {
      color: ${(props) => props.theme.colorlightblack};
      font-size: 12px;
      padding: 10px 15px;
    }
  }
  .input_container {
    border: 1px solid ${(props) => props.theme.colorbluegreylight};
    border-radius: 8px;
    overflow: hidden;
    textarea {
      box-shadow: none !important;
      border: transparent;
      background-color: transparent;
      &:hover {
        border-color: transparent;
      }
    }
    .attachment-wrapper {
      padding: 10px;
    }
    .attachement-wrap {
      display: flex;
      flex-wrap: wrap;
      padding-left: 10px;
      max-height: 241px;
      overflow: auto;
    }
    .typemsg_block {
      background-color: ${(props) => props.theme.bgcolordlightblue};
      color: ${(props) => props.theme.colorlightblack};
      font-size: 12px;
      padding: 10px 15px;
    }
    .replymsg-wrapper {
      padding: 5px 10px;
    }
    .msg-sug {
      > div {
        padding: 0px;
        box-shadow: 0px 2px 6px
          ${(props) => rgba(props.theme.bgcolordblack, 0.24)};
        background-color: ${(props) => props.theme.colordwhitedark};
        border-radius: 8px;
        width: 213px;
        border: transparent;
        max-height: 326px;
        overflow: auto;
        > div {
          &:not(:first-child) {
            border-top: 1px solid ${(props) => props.theme.colorbluegreylight};
          }
        }
      }
    }
  }
  .msgactions {
    background-color: ${(props) => props.theme.testblock};
    display: flex;
    justify-content: space-between;
    padding: 10px;
    .anticon {
      color: ${(props) => props.theme.textcolordarkgrey};
      font-size: 18px;
      cursor: pointer;
    }
    .msgactions__left {
      > ul {
        display: flex;
        > li {
          margin-right: 16px;
          span {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          &:last-child {
            margin: 0;
          }
          &.emoji-wrap {
            button {
              padding: 0;
              border: none;
              background: transparent;
              height: auto;
              width: auto;
              display: flex;
              align-items: center;
              justify-content: center;
              line-height: 20px;
            }
          }
        }
      }
    }
    .msgactions__right {
      > div {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
`
export default InputEditorStyle
