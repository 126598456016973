/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react"
import { withTheme } from "styled-components"

function ChatStartVideoIcon({ theme: themeColor }: any) {
  return (
    <span className="anticon anticon-startvideo ">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="152"
        height="122"
        viewBox="0 0 152 122"
      >
        <path
          d="M29.2698 22.6855H29.2061V22.7373H29.2698V22.6855Z"
          fill="black"
        />
        <path
          d="M57.8822 34.6426H57.8403V34.6924H57.8822V34.6426Z"
          fill="black"
        />
        <path
          d="M139.42 88.231V18.7061C139.42 17.1324 138.137 15.8574 136.553 15.8574H15.0581C13.4743 15.8574 12.1914 17.1324 12.1914 18.7061V88.231C12.1914 89.8048 13.4743 91.0797 15.0581 91.0797H136.535C138.117 91.0797 139.42 89.8048 139.42 88.231ZM15.2374 18.0089H136.354C136.834 18.0089 137.237 18.3874 137.237 18.8854V88.0517C137.237 88.5298 136.856 88.9282 136.354 88.9282H15.2374C14.7573 88.9282 14.3548 88.5497 14.3548 88.0517V18.8854C14.3568 18.4073 14.7573 18.0089 15.2374 18.0089Z"
          fill="#0C0C0C"
        />
        <g opacity="0.7">
          <path
            opacity="0.0323"
            d="M96.1688 108.809C96.1688 108.809 96.1688 108.789 96.1489 108.769L96.129 108.749C96.129 108.749 96.0692 108.689 95.888 108.67L93.6428 108.391H58.5298L56.1851 108.65C56.0038 108.689 55.9241 108.729 55.9241 108.729C55.9241 108.729 55.9042 108.749 55.9042 108.769L55.8843 108.789V108.849C55.8843 108.849 55.8843 108.928 56.1054 109.028C56.2249 109.088 57.0477 109.506 58.3704 109.506C60.1534 109.506 62.52 109.566 62.52 109.566L76.2298 109.626L89.9395 109.566C89.9395 109.566 92.0631 109.506 93.848 109.506C95.1907 109.506 95.8322 109.088 95.9517 109.048C96.1728 108.968 96.1728 108.928 96.1728 108.928L96.1688 108.809Z"
            fill="#F7F7F7"
          />
          <path
            opacity="0.0645"
            d="M96.1687 108.809C96.1687 108.809 96.1687 108.789 96.1487 108.769L96.1288 108.749C96.1288 108.749 96.069 108.689 95.8878 108.67L93.6427 108.391H58.5495L56.2247 108.65C56.0435 108.689 55.9837 108.729 55.9837 108.729C55.9837 108.729 55.9638 108.749 55.9638 108.769L55.9438 108.789V108.869C55.9438 108.869 55.9439 108.948 56.165 109.048C56.2845 109.108 57.0873 109.526 58.43 109.526C60.213 109.526 62.5597 109.586 62.5597 109.586L76.2495 109.646L89.9393 109.586C89.9393 109.586 92.0629 109.526 93.8478 109.526C95.1905 109.526 95.832 109.128 95.9515 109.088C96.1726 109.008 96.1726 108.968 96.1726 108.968L96.1687 108.809Z"
            fill="#EFEFEF"
          />
          <path
            opacity="0.0968"
            d="M96.1687 108.809C96.1687 108.809 96.1687 108.789 96.1487 108.769L96.1288 108.749C96.1288 108.749 96.0691 108.689 95.8878 108.67L93.6427 108.391H58.5495L56.2247 108.65C56.0435 108.689 55.9837 108.729 55.9837 108.729C55.9837 108.729 55.9638 108.749 55.9638 108.769L55.9438 108.789V108.869C55.9438 108.869 55.9439 108.929 56.165 109.048C56.2845 109.108 57.0873 109.506 58.43 109.506C60.213 109.506 62.5597 109.566 62.5597 109.566L76.2495 109.626L89.9393 109.566C89.9393 109.566 92.0629 109.506 93.8678 109.506C95.2105 109.506 95.8519 109.108 95.9714 109.068C96.1926 108.988 96.1926 108.948 96.1926 108.948V108.809H96.1687Z"
            fill="#E6E6E6"
          />
          <path
            opacity="0.129"
            d="M96.1688 108.809C96.1688 108.809 96.1688 108.789 96.1488 108.769L96.1289 108.749C96.1289 108.749 96.0691 108.689 95.8879 108.67L93.6428 108.391H58.5696L56.2448 108.65C56.0635 108.689 56.0037 108.729 56.0037 108.729C56.0037 108.729 55.9838 108.749 55.9838 108.769L55.9639 108.789V108.869C55.9639 108.869 55.9639 108.929 56.185 109.048C56.3045 109.108 57.1073 109.486 58.45 109.486C60.233 109.486 62.5797 109.546 62.5797 109.546L76.2695 109.606L89.9593 109.546C89.9593 109.546 92.0829 109.486 93.8878 109.486C95.2305 109.486 95.8719 109.108 95.9915 109.068C96.2126 108.988 96.2126 108.948 96.2126 108.948V108.809H96.1688Z"
            fill="#DEDEDE"
          />
          <path
            opacity="0.1613"
            d="M96.1688 108.809C96.1688 108.809 96.1688 108.789 96.1488 108.789L96.1289 108.769C96.1289 108.769 96.0691 108.709 95.8879 108.689L93.6428 108.391H58.5696L56.2448 108.67C56.0635 108.709 56.0037 108.749 56.0037 108.749C56.0037 108.749 55.9838 108.769 55.9838 108.789L55.9639 108.809V108.889C55.9639 108.889 55.9639 108.948 56.185 109.048C56.3045 109.108 57.1073 109.486 58.45 109.486C60.233 109.486 62.5797 109.546 62.5797 109.546L76.2695 109.606L89.9593 109.546C89.9593 109.546 92.0829 109.486 93.8878 109.486C95.2305 109.486 95.8918 109.108 95.9915 109.068C96.2126 108.988 96.2126 108.948 96.2126 108.948V108.809H96.1688Z"
            fill="#D6D6D6"
          />
          <path
            opacity="0.1935"
            d="M96.1688 108.809C96.1688 108.809 96.1688 108.789 96.1488 108.789L96.1289 108.769C96.1289 108.769 96.0691 108.709 95.8879 108.689L93.6428 108.391H58.5696L56.2448 108.67C56.0635 108.709 56.0037 108.749 56.0037 108.749C56.0037 108.749 55.9838 108.769 55.9838 108.789L55.9639 108.809V108.909C55.9639 108.909 55.9639 108.968 56.185 109.068C56.3045 109.128 57.0874 109.486 58.4301 109.486C60.2131 109.486 62.5379 109.546 62.5379 109.546L76.2277 109.606L89.9175 109.546C89.9175 109.546 92.063 109.486 93.8459 109.486C95.1886 109.486 95.85 109.128 95.9496 109.088C96.1707 109.008 96.1707 108.968 96.1707 108.968L96.1688 108.809Z"
            fill="#CECECE"
          />
          <path
            opacity="0.2258"
            d="M96.1488 108.789C96.1488 108.789 96.1488 108.77 96.1289 108.77L96.109 108.75C96.109 108.75 96.0492 108.69 95.8679 108.67L93.6228 108.371H58.5696L56.2448 108.65C56.0635 108.69 56.0037 108.73 56.0037 108.73C56.0037 108.73 55.9838 108.75 55.9838 108.77L55.9639 108.789V108.889C55.9639 108.889 55.9639 108.949 56.185 109.048C56.3045 109.108 57.0874 109.447 58.4301 109.447C60.2131 109.447 62.5379 109.487 62.5379 109.487L76.2277 109.546L89.9175 109.487C89.9175 109.487 92.063 109.427 93.8459 109.447C95.1886 109.447 95.85 109.108 95.9496 109.068C96.1707 108.989 96.1707 108.949 96.1707 108.949V108.789H96.1488Z"
            fill="#C5C5C5"
          />
          <path
            opacity="0.2581"
            d="M96.1489 108.789C96.1489 108.789 96.1489 108.77 96.129 108.77L96.1091 108.75C96.1091 108.75 96.0493 108.69 95.868 108.67L93.6229 108.371H58.5697L56.2648 108.65C56.0835 108.69 56.0237 108.73 56.0237 108.73C56.0237 108.73 56.0038 108.75 56.0038 108.77L55.9839 108.789V108.889C55.9839 108.889 55.9839 108.949 56.205 109.048C56.3245 109.108 57.1074 109.447 58.4501 109.447C60.2331 109.447 62.5579 109.487 62.5579 109.487L76.2477 109.546L89.9375 109.487C89.9375 109.487 92.083 109.427 93.866 109.447C95.2087 109.447 95.87 109.108 95.9696 109.068C96.1908 108.989 96.1908 108.949 96.1908 108.949V108.789H96.1489Z"
            fill="#BDBDBD"
          />
          <path
            opacity="0.2903"
            d="M96.1489 108.789C96.1489 108.789 96.1489 108.77 96.129 108.77L96.1091 108.75C96.1091 108.75 96.0493 108.69 95.868 108.67L93.6229 108.371H58.5697L56.2648 108.65C56.0835 108.69 56.0237 108.73 56.0237 108.73C56.0237 108.73 56.0038 108.75 56.0038 108.77L55.9839 108.789V108.889C55.9839 108.889 55.9839 108.949 56.205 109.048C56.3245 109.108 57.1074 109.427 58.4501 109.427C60.2331 109.427 62.5579 109.467 62.5579 109.467L76.2477 109.527L89.9375 109.467C89.9375 109.467 92.083 109.427 93.866 109.427C95.2087 109.427 95.87 109.108 95.9696 109.068C96.1908 108.989 96.1908 108.949 96.1908 108.949V108.789H96.1489Z"
            fill="#B5B5B5"
          />
          <path
            opacity="0.3226"
            d="M96.1488 108.79C96.1488 108.79 96.1488 108.77 96.1289 108.77L96.109 108.73C96.109 108.73 96.0492 108.67 95.8679 108.65L93.6228 108.352H58.5696L56.2647 108.63C56.0834 108.67 56.0236 108.71 56.0236 108.71C56.0236 108.71 56.0037 108.73 55.9838 108.75L55.9639 108.77V108.889C55.9639 108.889 55.9639 108.949 56.185 109.049C56.3045 109.089 57.0675 109.407 58.4102 109.407C60.1931 109.407 62.5179 109.447 62.5179 109.447L76.1858 109.507L89.8537 109.447C89.8537 109.447 91.9993 109.407 93.8021 109.407C95.1448 109.407 95.8062 109.089 95.9058 109.069C96.1269 108.989 96.1269 108.949 96.1269 108.949V108.79H96.1488Z"
            fill="#ADADAD"
          />
          <path
            opacity="0.3548"
            d="M96.1489 108.79C96.1489 108.79 96.1489 108.77 96.129 108.77L96.1091 108.73C96.1091 108.73 96.0493 108.67 95.868 108.65L93.6229 108.352H58.5697L56.2648 108.65C56.0835 108.69 56.0237 108.73 56.0237 108.73C56.0237 108.73 56.0038 108.75 55.9839 108.77V108.79V108.909C55.9839 108.909 55.9839 108.969 56.205 109.069C56.3245 109.109 57.0875 109.427 58.4302 109.407C60.2131 109.407 62.518 109.447 62.518 109.447L76.1859 109.507L89.8538 109.447C89.8538 109.447 91.9994 109.407 93.8022 109.407C95.1449 109.407 95.8063 109.109 95.9059 109.069C96.127 109.009 96.127 108.949 96.127 108.949V108.79H96.1489Z"
            fill="#A5A5A5"
          />
          <path
            opacity="0.3871"
            d="M96.149 108.788C96.149 108.788 96.149 108.788 96.1291 108.768C96.1291 108.748 96.1092 108.729 96.1092 108.729C96.1092 108.729 96.0494 108.689 95.8681 108.649L93.623 108.33H58.5897L56.2848 108.629C56.1035 108.649 56.0437 108.709 56.0437 108.709C56.0437 108.709 56.0238 108.729 56.0039 108.748V108.768V108.888C56.0039 108.888 56.0039 108.948 56.225 109.027C56.3446 109.067 57.1075 109.366 58.4502 109.366C60.2332 109.366 62.538 109.406 62.538 109.406L76.206 109.466L89.8739 109.406C89.8739 109.406 92.0393 109.366 93.8222 109.366C95.1649 109.366 95.8263 109.087 95.9259 109.047C96.147 108.987 96.147 108.928 96.147 108.928V108.788H96.149Z"
            fill="#9C9C9C"
          />
          <path
            opacity="0.4194"
            d="M96.1291 108.788C96.1291 108.788 96.1291 108.788 96.1092 108.768C96.1092 108.748 96.0693 108.729 96.0693 108.729C96.0693 108.729 96.0096 108.689 95.8283 108.649L93.5832 108.33H58.5897L56.2848 108.629C56.1035 108.649 56.0437 108.709 56.0437 108.709C56.0437 108.709 56.0238 108.729 56.0039 108.748V108.768V108.888C56.0039 108.888 56.0039 108.948 56.225 109.027C56.3446 109.067 57.1075 109.346 58.4502 109.346C60.2332 109.346 62.538 109.386 62.538 109.386L76.206 109.446L89.8739 109.386C89.8739 109.386 92.0393 109.346 93.8222 109.346C95.1649 109.346 95.8263 109.067 95.9259 109.047C96.147 108.987 96.147 108.928 96.147 108.928V108.788H96.1291Z"
            fill="#949494"
          />
          <path
            opacity="0.4516"
            d="M96.1292 108.788C96.1292 108.748 96.0894 108.729 96.0894 108.729C96.0894 108.729 96.0296 108.689 95.8483 108.649L93.6032 108.33H58.5898L56.3048 108.629C56.1235 108.649 56.0638 108.709 56.0638 108.709C56.0638 108.709 56.0438 108.729 56.0239 108.748V108.768V108.908C56.0239 108.908 56.0239 108.968 56.245 109.047C56.3646 109.087 57.1276 109.366 58.4503 109.346C60.2333 109.346 62.5381 109.386 62.5381 109.386L76.2061 109.446L89.874 109.386C89.874 109.386 92.0394 109.346 93.8223 109.346C95.165 109.346 95.8264 109.087 95.926 109.047C96.1471 108.987 96.1471 108.928 96.1471 108.928V108.788H96.1292Z"
            fill="#8C8C8C"
          />
          <path
            opacity="0.4839"
            d="M96.1292 108.788C96.1292 108.748 96.0894 108.729 96.0894 108.729C96.0894 108.729 96.0296 108.689 95.8483 108.649L93.6032 108.33H58.5898L56.3048 108.629C56.1235 108.649 56.0638 108.709 56.0638 108.709C56.0638 108.709 56.0438 108.729 56.0239 108.748V108.768V108.908C56.0239 108.908 56.0239 108.968 56.245 109.047C56.3646 109.087 57.1076 109.346 58.4503 109.346C60.2333 109.346 62.5182 109.386 62.5182 109.386L76.1861 109.446L89.854 109.386C89.854 109.386 92.0195 109.346 93.8024 109.346C95.1451 109.346 95.8065 109.087 95.9061 109.067C96.1272 109.007 96.1272 108.948 96.1272 108.948L96.1292 108.788Z"
            fill="#848484"
          />
          <path
            opacity="0.5161"
            d="M96.1292 108.788C96.1292 108.748 96.0894 108.729 96.0894 108.729C96.0894 108.729 96.0296 108.689 95.8483 108.649L93.6032 108.33H58.5898L56.3048 108.649C56.1235 108.669 56.0638 108.729 56.0638 108.729C56.0638 108.729 56.0438 108.748 56.0239 108.768V108.788V108.928C56.0239 108.928 56.0239 108.987 56.245 109.067C56.3646 109.107 57.1076 109.346 58.4503 109.346C60.2333 109.346 62.5182 109.386 62.5182 109.386L76.1861 109.446L89.854 109.386C89.854 109.386 92.0195 109.346 93.8024 109.346C95.1451 109.346 95.8065 109.107 95.926 109.087C96.1471 109.027 96.1471 108.987 96.1471 108.987V108.788H96.1292Z"
            fill="#7B7B7B"
          />
          <path
            opacity="0.5484"
            d="M96.1288 108.769C96.1288 108.749 96.089 108.709 96.089 108.709C96.089 108.709 96.0292 108.669 95.8479 108.629L93.6028 108.311H58.6093L56.3243 108.629C56.1431 108.649 56.0833 108.709 56.0833 108.709C56.0833 108.709 56.0634 108.729 56.0435 108.749V108.888C56.0435 108.888 56.0435 108.948 56.2646 109.028C56.3841 109.068 57.1272 109.307 58.4699 109.287C60.2528 109.287 62.5378 109.327 62.5378 109.327L76.1857 109.386L89.8337 109.327C89.8337 109.327 91.9992 109.287 93.802 109.287C95.1447 109.287 95.8061 109.068 95.9256 109.028C96.1467 108.968 96.1468 108.928 96.1468 108.928V108.769H96.1288Z"
            fill="#737373"
          />
          <path
            opacity="0.5806"
            d="M96.1089 108.769C96.1089 108.749 96.0691 108.709 96.0691 108.709C96.0691 108.709 96.0093 108.669 95.828 108.629L93.5829 108.311H58.6093L56.3243 108.629C56.1431 108.649 56.0833 108.709 56.0833 108.709C56.0833 108.709 56.0634 108.729 56.0435 108.749V108.908C56.0435 108.908 56.0435 108.968 56.2646 109.048C56.3642 109.087 57.1272 109.307 58.4499 109.307C60.2329 109.307 62.5178 109.327 62.5178 109.327L76.1658 109.386L89.8138 109.327C89.8138 109.327 91.9992 109.287 93.7821 109.307C95.1248 109.307 95.8061 109.087 95.9057 109.068C96.1268 109.008 96.1268 108.968 96.1268 108.968V108.769H96.1089Z"
            fill="#6B6B6B"
          />
          <path
            opacity="0.6129"
            d="M96.1088 108.769C96.1088 108.749 96.069 108.709 96.069 108.709C96.069 108.709 96.0092 108.67 95.8279 108.63L93.5828 108.291H58.6092L56.3243 108.61C56.143 108.63 56.0832 108.689 56.0832 108.689C56.0832 108.689 56.0633 108.709 56.0434 108.729V108.889C56.0434 108.889 56.0235 108.948 56.2645 109.008C56.3641 109.048 57.1072 109.247 58.4498 109.247C60.2328 109.247 62.4978 109.267 62.4978 109.267L76.1458 109.327L89.7938 109.267C89.7938 109.267 91.9792 109.227 93.7621 109.247C95.1048 109.247 95.7861 109.048 95.8857 109.008C96.1267 108.948 96.1068 108.909 96.1068 108.909V108.769H96.1088Z"
            fill="#636363"
          />
          <path
            opacity="0.6452"
            d="M96.1089 108.769C96.1089 108.749 96.0691 108.729 96.0691 108.729C96.0691 108.729 96.0093 108.689 95.828 108.649L93.5829 108.311H58.6093L56.3443 108.629C56.163 108.649 56.1032 108.709 56.1032 108.709C56.1032 108.709 56.0634 108.729 56.0634 108.749V108.908C56.0634 108.908 56.0435 108.968 56.2845 109.028C56.3841 109.068 57.1272 109.247 58.4699 109.247C60.2528 109.247 62.5178 109.267 62.5178 109.267L76.1658 109.327L89.8138 109.267C89.8138 109.267 91.9992 109.247 93.7821 109.247C95.1248 109.247 95.8061 109.048 95.9057 109.028C96.1468 108.968 96.1268 108.928 96.1268 108.928V108.769H96.1089Z"
            fill="#5A5A5A"
          />
          <path
            opacity="0.6774"
            d="M96.1089 108.769C96.1089 108.749 96.0691 108.729 96.0691 108.729C96.0691 108.729 96.0093 108.689 95.828 108.649L93.5829 108.311H58.6093L56.3443 108.629C56.163 108.649 56.1032 108.709 56.1032 108.709C56.1032 108.709 56.0634 108.729 56.0634 108.749V108.908C56.0634 108.908 56.0435 108.968 56.2845 109.028C56.3841 109.068 57.1272 109.247 58.4699 109.247C60.2528 109.247 62.5178 109.267 62.5178 109.267L76.1658 109.307L89.8138 109.267C89.8138 109.267 91.9992 109.247 93.7821 109.247C95.1248 109.247 95.8061 109.068 95.9057 109.048C96.1468 108.988 96.1268 108.948 96.1268 108.948V108.769H96.1089Z"
            fill="#525252"
          />
          <path
            opacity="0.7097"
            d="M96.109 108.769C96.109 108.749 96.0692 108.729 96.0692 108.729C96.0692 108.729 96.0094 108.689 95.8281 108.649L93.583 108.311H58.6293L56.3643 108.649C56.183 108.669 56.1233 108.729 56.1233 108.729C56.1233 108.729 56.0834 108.749 56.0834 108.769V108.948C56.0834 108.948 56.0635 109.008 56.3045 109.068C56.4041 109.087 57.1472 109.267 58.47 109.267C60.2529 109.267 62.5179 109.287 62.5179 109.287L76.1659 109.327L89.8139 109.287C89.8139 109.287 91.9993 109.267 93.7822 109.267C95.1249 109.267 95.8062 109.107 95.9058 109.068C96.1469 109.008 96.1269 108.968 96.1269 108.968V108.769H96.109Z"
            fill="#4A4A4A"
          />
          <path
            opacity="0.7419"
            d="M96.109 108.769C96.109 108.749 96.0692 108.729 96.0692 108.729C96.0692 108.729 96.0094 108.689 95.8281 108.649L93.583 108.311H58.6293L56.3643 108.649C56.183 108.669 56.1233 108.729 56.1233 108.729C56.1233 108.729 56.0834 108.749 56.0834 108.769V108.948C56.0834 108.948 56.0635 109.008 56.3045 109.068C56.4041 109.087 57.1472 109.247 58.47 109.247C60.2529 109.247 62.5179 109.267 62.5179 109.267L76.1659 109.307L89.8139 109.267C89.8139 109.267 91.9993 109.247 93.8021 109.247C95.1448 109.247 95.8261 109.087 95.9257 109.068C96.1668 109.008 96.1469 108.968 96.1469 108.968V108.769H96.109Z"
            fill="#424242"
          />
          <path
            opacity="0.7742"
            d="M96.0891 108.769C96.0891 108.749 96.0492 108.729 96.0492 108.729C96.0492 108.729 95.9895 108.689 95.8082 108.649L93.5631 108.311H58.6293L56.3643 108.649C56.183 108.669 56.1233 108.729 56.1233 108.729C56.1233 108.729 56.0834 108.749 56.0834 108.769V108.948C56.0834 108.948 56.0635 109.008 56.3045 109.068C56.4041 109.087 57.1253 109.247 58.47 109.247C60.2529 109.247 62.498 109.267 62.498 109.267L76.1261 109.307L89.7542 109.267C89.7542 109.267 91.9594 109.247 93.7424 109.247C95.0851 109.247 95.7664 109.107 95.866 109.087C96.107 109.048 96.0871 108.988 96.0871 108.988V108.769H96.0891Z"
            fill="#3A3A3A"
          />
          <path
            opacity="0.8065"
            d="M96.0891 108.769C96.0891 108.749 96.0492 108.729 96.0492 108.729C96.0492 108.729 95.9895 108.689 95.8082 108.649L93.5631 108.311H58.6293L56.3643 108.649C56.183 108.669 56.1233 108.729 56.1233 108.729C56.1233 108.729 56.0834 108.749 56.0834 108.769V108.948C56.0834 108.948 56.0635 109.008 56.3045 109.068C56.4041 109.087 57.1253 109.227 58.47 109.227C60.2529 109.227 62.498 109.247 62.498 109.247L76.1261 109.287L89.7542 109.247C89.7542 109.247 91.9594 109.227 93.7424 109.227C95.0851 109.227 95.7664 109.087 95.866 109.068C96.107 109.028 96.0871 108.968 96.0871 108.968V108.769H96.0891Z"
            fill="#313131"
          />
          <path
            opacity="0.8387"
            d="M96.0887 108.769C96.0887 108.749 96.0489 108.729 96.0489 108.729C96.0489 108.729 95.9891 108.689 95.8078 108.65L93.5627 108.291H58.6289L56.3838 108.63C56.2025 108.65 56.1428 108.709 56.1428 108.709C56.1428 108.709 56.1029 108.729 56.1029 108.749V108.928C56.1029 108.928 56.083 108.988 56.3241 109.028C56.4237 109.048 57.1448 109.168 58.4696 109.168C60.2525 109.168 62.4976 109.187 62.4976 109.187L76.1257 109.227L89.7538 109.187C89.7538 109.187 91.959 109.168 93.742 109.168C95.0847 109.168 95.766 109.048 95.8656 109.028C96.1066 108.988 96.0867 108.928 96.0867 108.928V108.769H96.0887Z"
            fill="#292929"
          />
          <path
            opacity="0.871"
            d="M96.0888 108.749C96.0888 108.729 96.049 108.709 96.049 108.709C96.049 108.709 95.9892 108.67 95.8079 108.65L93.5628 108.291H58.649L56.4038 108.63C56.2226 108.65 56.1628 108.689 56.1628 108.689C56.1628 108.689 56.123 108.709 56.123 108.729V108.928C56.123 108.928 56.103 108.988 56.3441 109.028C56.4437 109.048 57.1648 109.168 58.4896 109.168C60.2725 109.168 62.5176 109.187 62.5176 109.187L76.1457 109.227L89.7738 109.187C89.7738 109.187 91.9791 109.168 93.762 109.168C95.1047 109.168 95.786 109.068 95.8856 109.048C96.1266 109.008 96.1067 108.948 96.1067 108.948V108.749H96.0888Z"
            fill="#212121"
          />
          <path
            opacity="0.9032"
            d="M96.0888 108.749C96.0888 108.729 96.049 108.709 96.049 108.709C96.049 108.709 95.9892 108.67 95.8079 108.65L93.5628 108.291H58.649L56.4038 108.65C56.2226 108.67 56.1628 108.709 56.1628 108.709C56.1628 108.709 56.123 108.729 56.123 108.749V108.948C56.123 108.948 56.103 109.008 56.3441 109.048C56.4437 109.068 57.1449 109.168 58.4896 109.168C60.2725 109.168 62.4977 109.168 62.4977 109.168L76.1258 109.207L89.7539 109.168C89.7539 109.168 91.9591 109.148 93.7421 109.168C95.0848 109.168 95.7661 109.068 95.8657 109.048C96.1067 109.008 96.0868 108.948 96.0868 108.948V108.749H96.0888Z"
            fill="#191919"
          />
          <path
            opacity="0.9355"
            d="M96.0888 108.749C96.0888 108.729 96.049 108.709 96.049 108.709C96.049 108.709 95.9892 108.67 95.8079 108.65L93.5628 108.291H58.649L56.4038 108.65C56.2226 108.67 56.1628 108.709 56.1628 108.709C56.1628 108.709 56.123 108.729 56.123 108.749V108.948C56.123 108.948 56.103 109.008 56.3441 109.048C56.4437 109.068 57.1449 109.148 58.4896 109.148C60.2725 109.148 62.4977 109.148 62.4977 109.148L76.1258 109.187L89.7539 109.148C89.7539 109.148 91.9591 109.128 93.7421 109.148C95.0848 109.148 95.7661 109.068 95.8657 109.048C96.1067 109.008 96.0868 108.948 96.0868 108.948V108.749H96.0888Z"
            fill="#101010"
          />
          <path
            opacity="0.9677"
            d="M96.0689 108.749C96.0689 108.729 96.029 108.709 96.029 108.709C96.029 108.709 95.9693 108.67 95.788 108.65L93.5429 108.291H58.649L56.4038 108.65C56.2226 108.67 56.1628 108.709 56.1628 108.709C56.1628 108.709 56.123 108.729 56.123 108.749V108.948C56.123 108.948 56.103 109.008 56.3441 109.048C56.4437 109.068 57.1449 109.148 58.4896 109.128C60.2725 109.128 62.4977 109.128 62.4977 109.128L76.1258 109.168L89.7539 109.128C89.7539 109.128 91.9791 109.128 93.762 109.128C95.1047 109.128 95.8059 109.048 95.8856 109.048C96.1266 109.008 96.1067 108.948 96.1067 108.948V108.749H96.0689Z"
            fill="#080808"
          />
          <path
            d="M96.0689 108.749C96.0689 108.729 96.029 108.709 96.029 108.709C96.029 108.709 95.9693 108.67 95.788 108.65L93.5429 108.291H58.649L56.4038 108.65C56.2226 108.67 56.1628 108.709 56.1628 108.709C56.1628 108.709 56.123 108.729 56.123 108.749V108.968C56.123 108.968 56.103 109.028 56.3441 109.068C56.4437 109.088 57.1449 109.148 58.4677 109.148C60.2506 109.148 62.4758 109.148 62.4758 109.148L76.1039 109.187L89.7319 109.148C89.7319 109.148 91.9571 109.148 93.7401 109.148C95.0828 109.148 95.784 109.088 95.8637 109.068C96.1047 109.028 96.0848 108.968 96.0848 108.968V108.749H96.0689Z"
            fill="black"
          />
        </g>
        <path
          d="M55.8845 108.152C55.7251 108.411 55.7451 108.71 55.7849 108.81C55.8248 108.929 55.9861 109.188 56.8268 109.188C57.4882 109.188 66.8671 109.168 76.2878 109.168L76.3077 108.611C66.9288 108.63 57.5878 108.65 56.9264 108.65C56.1634 108.67 56.024 108.292 55.8845 108.152Z"
          fill="url(#paint0_linear_35078_343076)"
        />
        <path
          d="M96.2884 108.152C96.4478 108.411 96.4279 108.71 96.388 108.81C96.3482 108.929 96.1868 109.188 95.3461 109.188C94.6848 109.188 85.3059 109.168 75.8852 109.168L75.8652 108.611C85.2441 108.63 94.5852 108.65 95.2465 108.65C96.0075 108.67 96.149 108.292 96.2884 108.152Z"
          fill="url(#paint1_linear_35078_343076)"
        />
        <path
          d="M96.0898 107.933L92.5618 105.662C90.6573 104.487 90.4581 100.861 90.3366 99.8255L89.5556 91.0801H76.0869H62.5983L61.8174 99.8255C61.7178 100.861 61.5166 104.487 59.5922 105.662L56.0642 107.933C55.9048 108.033 55.8032 108.192 55.8032 108.372C55.8032 108.591 55.9626 108.83 56.7057 108.83C58.0284 108.83 76.0869 108.83 76.0869 108.83C76.0869 108.83 94.1455 108.83 95.4682 108.83C96.1894 108.83 96.3707 108.591 96.3707 108.372C96.3488 108.192 96.2492 108.033 96.0898 107.933Z"
          fill="url(#paint2_linear_35078_343076)"
        />
        <path
          d="M103.968 50.6676C103.748 47.8791 103.113 45.0427 102.079 42.2355C101.111 39.7199 99.6773 37.1838 97.8171 34.696C97.6446 34.4943 97.4728 34.2912 97.301 34.0882C96.4846 33.1238 95.7133 32.2127 94.8753 31.4232C93.6962 30.3192 92.5755 29.3947 91.4447 28.594C87.3664 25.6935 82.4783 23.8386 77.4478 23.2482V21.9785L72.5024 24.8339L77.448 27.6891V26.4039C81.9331 26.6963 86.3711 28.0993 90.1791 30.4604C91.2433 31.1178 92.3224 31.8976 93.4862 32.8518C94.3001 33.5248 94.9936 34.2438 95.6638 34.9393C95.8918 35.1757 96.1199 35.4126 96.3524 35.646L96.4257 35.7198L96.493 35.7994C98.4314 38.0919 99.8962 40.3478 100.971 42.6961L100.989 42.7349C102.141 45.3663 102.918 48.0492 103.297 50.7091L103.304 50.7633C103.64 53.5374 103.609 56.351 103.211 59.1263L103.206 59.165L103.199 59.2035C103.069 59.9508 102.911 60.6952 102.725 61.4347C102.959 60.6997 103.165 59.9578 103.343 59.2103C103.929 56.4059 104.14 53.532 103.968 50.6676Z"
          fill="#64C4F5"
        />
        <path
          d="M75.5083 33.7773V44.1907"
          stroke="white"
          stroke-width="0.545678"
          stroke-miterlimit="10"
        />
        <path
          d="M70.3018 40.9844H80.7149"
          stroke="white"
          stroke-width="0.545678"
          stroke-miterlimit="10"
        />
        <path
          d="M102.253 35.5663L101.734 34.8186C101.561 34.5698 101.393 34.3168 101.202 34.0806L100.646 33.3598C100.553 33.2405 100.463 33.118 100.367 33.0012L100.071 32.6555C99.6761 32.1948 99.2876 31.7286 98.8702 31.2883C98.4535 30.8473 98.0277 30.4151 97.5886 29.9962C95.8324 28.3216 93.8871 26.8451 91.7993 25.6082C91.4851 25.4216 91.4093 25.5532 91.7218 25.7388C93.7998 26.9699 95.7359 28.4392 97.4838 30.1061C97.9206 30.523 98.3446 30.9531 98.7592 31.3921C99.1749 31.8303 99.5614 32.2945 99.9544 32.7529L100.249 33.0971C100.345 33.2134 100.434 33.3353 100.527 33.454L101.08 34.1712C101.27 34.4064 101.437 34.658 101.61 34.9058L102.126 35.6501C102.232 35.7978 102.306 35.8584 102.339 35.8365C102.369 35.8174 102.359 35.715 102.253 35.5663Z"
          fill="#474747"
        />
        <path
          d="M103.157 37.0093C102.964 36.6988 102.834 36.7775 103.026 37.0864C103.205 37.4022 103.337 37.3277 103.157 37.0093Z"
          fill="#474747"
        />
        <path
          d="M106.398 44.6455C105.887 42.825 105.217 41.0498 104.397 39.3464C104.236 39.0184 104.1 39.0862 104.26 39.4124C105.076 41.1077 105.744 42.8745 106.252 44.6863C106.345 45.0371 106.492 44.9992 106.398 44.6455Z"
          fill="#474747"
        />
        <path
          d="M106.93 46.8504C106.853 46.4933 106.705 46.5255 106.781 46.8809C106.857 47.2358 107.006 47.2082 106.93 46.8504Z"
          fill="#474747"
        />
        <path
          d="M107.197 58.3189C107.645 55.3919 107.714 52.4024 107.351 49.4618C107.31 49.0989 107.159 49.1187 107.2 49.4799C107.562 52.4067 107.493 55.3822 107.047 58.2954C106.985 58.6529 107.134 58.6793 107.197 58.3189Z"
          fill="#474747"
        />
        <path
          d="M106.592 61.2818C106.642 61.1064 106.621 61.0071 106.585 60.9982C106.547 60.9888 106.495 61.0696 106.445 61.2444C106.349 61.5946 106.495 61.6347 106.592 61.2818Z"
          fill="#474747"
        />
        <path
          d="M101.713 71.8702L102.052 71.3862L102.221 71.1442L102.379 70.8946L103.008 69.8941C103.212 69.5572 103.397 69.209 103.593 68.8669C103.688 68.6944 103.788 68.5248 103.879 68.3503L104.143 67.8214L104.406 67.2924C104.496 67.1168 104.582 66.94 104.658 66.7581L105.133 65.6759C105.284 65.3123 105.417 64.941 105.56 64.5739C105.628 64.3892 105.705 64.2074 105.767 64.0204L105.95 63.4587C106.064 63.1114 105.919 63.0655 105.806 63.4111L105.623 63.9701C105.561 64.1562 105.486 64.3372 105.418 64.5211C105.275 64.8864 105.143 65.2559 104.993 65.6177L104.52 66.6949C104.445 66.8761 104.358 67.0521 104.269 67.2267L104.007 67.753L103.744 68.2794C103.654 68.4531 103.554 68.622 103.459 68.7935C103.264 69.1338 103.081 69.4805 102.877 69.8158L102.251 70.8116L102.094 71.0601L101.925 71.3009L101.588 71.7827C101.38 72.0799 101.503 72.1699 101.713 71.8702Z"
          fill="#474747"
        />
        <path
          d="M100.102 73.9707L100.226 73.8276C100.347 73.6909 100.37 73.5916 100.341 73.5684C100.311 73.5436 100.23 73.5947 100.109 73.7307L99.986 73.8733C99.749 74.148 99.8636 74.2479 100.102 73.9707Z"
          fill="#474747"
        />
        <path
          d="M95.5193 78.4633L96.2127 77.8925L96.8896 77.302C97.3259 76.8923 97.763 76.4826 98.1923 76.0655C98.4445 75.8013 98.3358 75.6953 98.0848 75.9581C97.6574 76.3733 97.2223 76.781 96.7881 77.189L96.1143 77.7767L95.424 78.3446C95.2845 78.4607 95.2351 78.5452 95.2584 78.5749C95.2821 78.6049 95.3786 78.5801 95.5193 78.4633Z"
          fill="#474747"
        />
        <path
          d="M94.01 79.6011C94.3065 79.3875 94.217 79.2647 93.922 79.4772C93.6274 79.6894 93.7129 79.8149 94.01 79.6011Z"
          fill="#474747"
        />
        <path
          d="M92.5975 80.5499C92.9065 80.3551 92.8237 80.2278 92.5161 80.4215C92.209 80.615 92.2878 80.7449 92.5975 80.5499Z"
          fill="#474747"
        />
        <path
          d="M85.0742 84.0497C87.2385 83.367 89.3279 82.4512 91.3019 81.3323C91.6169 81.1472 91.5401 81.0161 91.2267 81.2004C89.2619 82.3138 87.1826 83.2253 85.0285 83.9049C84.6814 84.0103 84.7242 84.1561 85.0742 84.0497Z"
          fill="#474747"
        />
        <path
          d="M83.4373 84.5137C83.7906 84.4203 83.751 84.2736 83.3994 84.3665C83.0487 84.4591 83.0836 84.607 83.4373 84.5137Z"
          fill="#474747"
        />
        <path
          d="M43.8899 60.0712C44.7103 63.9337 46.2527 67.6421 48.419 70.9444C48.578 71.1957 48.7042 71.1111 48.5461 70.8612C46.3901 67.5745 44.855 63.8839 44.0385 60.0395C43.9782 59.7505 43.8292 59.7799 43.8899 60.0712Z"
          fill="#474747"
        />
        <path
          d="M43.6307 58.7115C43.6785 59.005 43.828 58.9783 43.7805 58.6861C43.7332 58.3948 43.5829 58.4177 43.6307 58.7115Z"
          fill="#474747"
        />
        <path
          d="M43.2074 51.9674C43.147 53.5047 43.1912 55.0457 43.3395 56.5771C43.3744 56.8722 43.5253 56.8557 43.4907 56.5618C43.3432 55.0375 43.2993 53.5039 43.3593 51.9737C43.3757 51.679 43.2241 51.6701 43.2074 51.9674Z"
          fill="#474747"
        />
        <path
          d="M43.3407 50.1273C43.311 50.423 43.4623 50.4365 43.4918 50.1423C43.5212 49.8483 43.3703 49.831 43.3407 50.1273Z"
          fill="#474747"
        />
        <path
          d="M45.6008 41.0527C44.6809 43.2812 44.0307 45.6174 43.6186 47.9915C43.5743 48.2854 43.7243 48.309 43.7685 48.0166C44.1788 45.6535 44.826 43.3285 45.7414 41.1105C45.858 40.8392 45.7184 40.7792 45.6008 41.0527Z"
          fill="#474747"
        />
        <path
          d="M46.6238 38.8136C46.4942 39.0813 46.6304 39.1483 46.7593 38.8821C46.8889 38.6166 46.7542 38.546 46.6238 38.8136Z"
          fill="#474747"
        />
        <path
          d="M52.1201 31.0614C50.3335 32.9021 48.7981 34.9788 47.5031 37.1911C47.3503 37.4462 47.4826 37.5209 47.6346 37.2673C48.9235 35.0657 50.4515 32.9988 52.2297 31.1667C52.4352 30.9544 52.327 30.8475 52.1201 31.0614Z"
          fill="#474747"
        />
        <path
          d="M53.6585 29.5538L53.5465 29.6593C53.3302 29.8632 53.4342 29.974 53.6495 29.7711L53.7609 29.666C53.9759 29.4633 53.8752 29.3496 53.6585 29.5538Z"
          fill="#474747"
        />
        <path
          d="M57.785 26.3489C56.9662 26.8778 56.1827 27.4587 55.413 28.0559C55.1827 28.2438 55.2782 28.362 55.5073 28.175C56.2735 27.5805 57.0532 27.0024 57.8682 26.4761C58.1139 26.3124 58.0327 26.1839 57.785 26.3489Z"
          fill="#474747"
        />
        <path
          d="M59.092 25.5383C58.8345 25.6871 58.9128 25.8174 59.1689 25.6693C59.4247 25.5216 59.3499 25.3893 59.092 25.5383Z"
          fill="#474747"
        />
        <path
          d="M60.3012 24.8644C60.0391 25.0049 60.1118 25.1384 60.3724 24.9986C60.6328 24.8589 60.5637 24.7236 60.3012 24.8644Z"
          fill="#474747"
        />
        <path
          d="M66.5702 22.3442C66.1237 22.4619 65.6869 22.6124 65.2455 22.7474C64.8107 22.9023 64.3706 23.0425 63.9419 23.2141C63.076 23.535 62.232 23.9112 61.3979 24.3062C61.1316 24.4383 61.1989 24.5745 61.4639 24.4431C62.294 24.05 63.1341 23.6756 63.9957 23.3561C64.4224 23.1852 64.8605 23.0458 65.2931 22.8917C65.7324 22.7575 66.1672 22.6076 66.6115 22.4904C66.8962 22.4108 66.8569 22.2639 66.5702 22.3442Z"
          fill="#474747"
        />
        <path
          d="M67.9107 21.9955C67.622 22.0666 67.6585 22.2142 67.9457 22.1433C68.2324 22.0727 68.1997 21.9243 67.9107 21.9955Z"
          fill="#474747"
        />
        <path
          d="M99.6056 56.8363L99.6928 56.1496C99.7215 55.9207 99.7549 55.6923 99.7676 55.4617L99.82 54.7715C99.8279 54.6565 99.8394 54.5416 99.8443 54.4264L99.853 54.0805C99.8642 53.6193 99.8818 53.158 99.8698 52.6966C99.8587 52.2353 99.8378 51.7742 99.8028 51.3141C99.6621 49.4738 99.3136 47.6493 98.7625 45.8876C98.6799 45.6221 98.5171 45.6753 98.5991 45.9387C99.1463 47.688 99.4922 49.4997 99.632 51.3272C99.6668 51.7841 99.6875 52.242 99.6985 52.7001C99.7105 53.1585 99.6928 53.6165 99.6818 54.0745L99.6731 54.4182C99.6682 54.5326 99.6567 54.6466 99.649 54.761L99.5969 55.4463C99.5844 55.6753 99.5509 55.9021 99.5226 56.1294L99.4359 56.8112C99.4207 56.948 99.4451 57.0218 99.4891 57.0289C99.5299 57.0352 99.5901 56.9746 99.6056 56.8363Z"
          fill="#474747"
        />
        <path
          d="M99.3836 58.1109C99.4324 57.8372 99.2642 57.8053 99.2158 58.0768C99.1561 58.3455 99.3232 58.3826 99.3836 58.1109Z"
          fill="#474747"
        />
        <path
          d="M97.3302 64.0774C97.968 62.7884 98.492 61.4435 98.8951 60.0631C98.9709 59.7957 98.8059 59.75 98.7307 60.0152C98.3306 61.3859 97.8101 62.7213 97.1769 64.0012C97.0513 64.2463 97.2032 64.3252 97.3302 64.0774Z"
          fill="#474747"
        />
        <path
          d="M96.5084 65.5942C96.6469 65.3533 96.4982 65.2684 96.3607 65.5075C96.2235 65.7463 96.3696 65.8356 96.5084 65.5942Z"
          fill="#474747"
        />
        <path
          d="M90.8235 72.2155C92.5663 70.7888 94.1264 69.1339 95.4173 67.2868C95.5785 67.0603 95.437 66.9634 95.2772 67.1883C93.9952 69.0226 92.4459 70.666 90.7153 72.0828C90.499 72.2527 90.6046 72.3876 90.8235 72.2155Z"
          fill="#474747"
        />
        <path
          d="M88.974 73.5829C89.092 73.5094 89.1225 73.4309 89.0989 73.396C89.0745 73.3601 88.996 73.3674 88.8789 73.4403C88.6469 73.5892 88.7396 73.7332 88.974 73.5829Z"
          fill="#474747"
        />
        <path
          d="M80.8286 77.0859L81.2665 76.9848C81.4129 76.9529 81.5584 76.9172 81.7019 76.8737L82.5667 76.6281C82.8535 76.5415 83.1352 76.4386 83.4196 76.3448C83.5612 76.2958 83.7042 76.2509 83.8446 76.1985L84.2628 76.0337C84.5398 75.9198 84.8241 75.8229 85.0952 75.6949L85.9144 75.3245C86.185 75.1961 86.4489 75.0537 86.7164 74.9188C86.849 74.8492 86.9846 74.7851 87.1148 74.7109L87.5044 74.4868C87.7455 74.3482 87.6586 74.2006 87.4195 74.3381L87.0327 74.5606C86.9034 74.6342 86.7686 74.6978 86.6369 74.7669C86.3713 74.9007 86.1092 75.0422 85.8404 75.1697L85.0271 75.5375C84.7579 75.6645 84.4756 75.7607 84.2006 75.8738L83.7854 76.0375C83.6461 76.0895 83.5041 76.1341 83.3635 76.1827C83.081 76.2759 82.8013 76.3781 82.5165 76.464L81.6579 76.7078C81.5155 76.751 81.371 76.7866 81.2256 76.8182L80.7907 76.9185C80.5225 76.9808 80.5574 77.1484 80.8286 77.0859Z"
          fill="#474747"
        />
        <path
          d="M78.8497 77.4518L78.9924 77.4336C79.1304 77.418 79.1917 77.3597 79.1853 77.3183C79.1785 77.2751 79.1045 77.2487 78.9673 77.2643L78.8256 77.2822C78.5525 77.3168 78.5736 77.4869 78.8497 77.4518Z"
          fill="#474747"
        />
        <path
          d="M73.972 77.6602L74.6544 77.6904L75.3375 77.7011C75.7928 77.6914 76.2482 77.6824 76.7032 77.6649C76.9803 77.6439 76.969 77.473 76.6941 77.4939C76.2422 77.511 75.7899 77.5201 75.3378 77.5298L74.6594 77.5193L73.9817 77.4892C73.7068 77.4745 73.6942 77.6453 73.972 77.6602Z"
          fill="#474747"
        />
        <path
          d="M72.5398 77.537C72.8161 77.5673 72.8336 77.3967 72.5593 77.3668C72.2856 77.3369 72.2629 77.5067 72.5398 77.537Z"
          fill="#474747"
        />
        <path
          d="M71.2585 77.3548C71.5324 77.402 71.5589 77.2328 71.2871 77.1859C71.0156 77.1391 70.9839 77.3075 71.2585 77.3548Z"
          fill="#474747"
        />
        <path
          d="M65.2252 75.5058C66.7962 76.221 68.4433 76.7664 70.1287 77.1384C70.4011 77.194 70.4354 77.0262 70.1652 76.9711C68.4919 76.6017 66.8562 76.0602 65.2964 75.35C65.0469 75.2335 64.9731 75.388 65.2252 75.5058Z"
          fill="#474747"
        />
        <path
          d="M64.0629 74.9356C64.3103 75.0626 64.3875 74.9097 64.1422 74.7837C63.8974 74.6579 63.8154 74.8084 64.0629 74.9356Z"
          fill="#474747"
        />
        <path
          d="M54.1421 40.9949C52.6406 43.596 51.6257 46.4774 51.1701 49.4465C51.132 49.6695 51.3016 49.6938 51.3393 49.4726C51.7917 46.5242 52.7994 43.6633 54.2905 41.0803C54.4032 40.887 54.2562 40.7993 54.1421 40.9949Z"
          fill="#474747"
        />
        <path
          d="M54.6869 40.0946C54.5646 40.2849 54.71 40.3751 54.8315 40.1865C54.9527 39.998 54.8095 39.9042 54.6869 40.0946Z"
          fill="#474747"
        />
        <path
          d="M57.8789 36.0651C57.0625 36.9035 56.3034 37.797 55.608 38.7383C55.4775 38.9229 55.6168 39.0228 55.7462 38.8396C56.4369 37.905 57.1906 37.0176 58.0013 36.185C58.1604 36.0272 58.0397 35.9056 57.8789 36.0651Z"
          fill="#474747"
        />
        <path
          d="M58.891 35.0921C58.7237 35.2443 58.8403 35.3697 59.0063 35.2188C59.1722 35.0678 59.0586 34.9395 58.891 35.0921Z"
          fill="#474747"
        />
        <path
          d="M64.786 31.1135C63.1321 31.9055 61.5759 32.8951 60.1348 34.0277C59.9601 34.1713 60.0682 34.3042 60.2414 34.1616C61.6724 33.037 63.2178 32.0542 64.8601 31.2679C65.0637 31.1742 64.9918 31.0187 64.786 31.1135Z"
          fill="#474747"
        />
        <path
          d="M93.1683 52.9658C93.1751 56.5274 92.1149 60.0109 90.1219 62.9756C88.1288 65.9402 85.2925 68.2526 81.9719 69.6202C78.6513 70.9878 74.9957 71.349 71.4677 70.6581C67.9397 69.9673 64.698 68.2555 62.1527 65.7393C59.6075 63.2231 57.8732 60.0156 57.1694 56.5229C56.4655 53.0302 56.8237 49.4092 58.1987 46.1181C59.5736 42.8271 61.9035 40.014 64.8934 38.0349C67.8834 36.0558 71.399 34.9996 74.9952 35C79.8106 34.996 84.4305 36.8866 87.8385 40.2557C91.2466 43.6249 93.1637 48.1968 93.1683 52.9658Z"
          fill="#38A6DE"
        />
        <path
          d="M75.271 43.0175C77.2808 42.9758 79.2446 43.6159 80.8366 44.8316C82.4286 46.0472 83.553 47.7654 84.0232 49.7011L84.5105 51.9641L86.9949 52.1302C88.1787 52.1696 89.3032 52.6526 90.1411 53.4817C90.9791 54.3107 91.4679 55.424 91.5088 56.5963C91.4639 57.8346 90.9267 59.0052 90.0142 59.8529C89.1017 60.7007 87.8879 61.157 86.6372 61.1223H65.5285C63.8594 61.1738 62.2379 60.5672 61.0201 59.4357C59.8023 58.3042 59.0877 56.7403 59.0332 55.0874C59.0703 53.5556 59.6835 52.0926 60.7527 50.9851C61.822 49.8777 63.2703 49.2055 64.8138 49.1002L66.5512 48.9342L67.363 47.5008C68.1565 46.1222 69.3087 44.9794 70.6994 44.1913C72.0902 43.4032 73.6688 42.9986 75.271 43.0197"
          fill="white"
        />
        <path
          d="M75 43.1268C76.9596 43.0861 78.8744 43.7102 80.4265 44.8955C81.9787 46.0809 83.075 47.7562 83.5334 49.6435L84.0084 51.8531L86.4309 52.0149C87.5848 52.053 88.6811 52.5234 89.4983 53.3311C90.3154 54.1388 90.7926 55.2236 90.8333 56.3662C90.7898 57.5737 90.266 58.7152 89.3763 59.5419C88.4865 60.3686 87.3029 60.8135 86.0833 60.7796H65.5C63.8721 60.83 62.2907 60.2382 61.1032 59.1344C59.9156 58.0306 59.2191 56.5051 59.1667 54.893C59.2022 53.399 59.7998 51.972 60.8423 50.8916C61.8849 49.8113 63.2972 49.1554 64.8026 49.0526L66.4968 48.8908L67.2884 47.4931C68.0627 46.1494 69.1864 45.0356 70.5426 44.2677C71.8988 43.4999 73.4379 43.1059 75 43.1268ZM75 40.1845C72.87 40.1584 70.7715 40.6952 68.9206 41.7396C67.0698 42.7839 65.5335 44.2981 64.4701 46.126C59.705 46.5986 56 50.3491 56 54.893C56 59.7625 60.2592 63.719 65.5 63.719H86.0833C90.4526 63.719 94 60.424 94 56.3634C94 52.4796 90.7542 49.332 86.6382 49.0669C85.5587 43.992 80.7648 40.1816 75.0014 40.1816"
          fill="#18508D"
        />
        <path
          d="M66.5035 30.3678C66.294 30.4533 66.3577 30.6121 66.5656 30.5275C66.7732 30.4434 66.7135 30.2829 66.5035 30.3678Z"
          fill="#474747"
        />
        <path
          d="M73.5552 28.7803C71.6086 28.9118 69.6838 29.3047 67.8261 29.8963C67.6098 29.9628 67.6644 30.1251 67.8789 30.0592C69.7236 29.4718 71.6346 29.0816 73.5678 28.951C73.7913 28.9356 73.7812 28.7646 73.5552 28.7803Z"
          fill="#474747"
        />
        <path
          d="M75.1916 28.713L75.0747 28.7158C74.8485 28.7209 74.8524 28.8923 75.0768 28.8871L75.1929 28.8843C75.417 28.879 75.4181 28.7078 75.1916 28.713Z"
          fill="#474747"
        />
        <path
          d="M79.1557 29.0002C78.4236 28.8826 77.6852 28.8122 76.9462 28.7583C76.7203 28.7473 76.7113 28.9185 76.9354 28.9293C77.6692 28.9829 78.4026 29.0526 79.1295 29.1696C79.3512 29.2018 79.38 29.0329 79.1557 29.0002Z"
          fill="#474747"
        />
        <path
          d="M80.3074 29.2074C80.0861 29.1605 80.0536 29.3287 80.2731 29.3752C80.4924 29.4218 80.5293 29.2545 80.3074 29.2074Z"
          fill="#474747"
        />
        <path
          d="M81.3343 29.4403C81.1146 29.3864 81.0748 29.553 81.2927 29.6064C81.5103 29.6598 81.5543 29.4943 81.3343 29.4403Z"
          fill="#474747"
        />
        <path
          d="M86.1605 31.2056C85.8481 31.0451 85.5247 30.9077 85.2064 30.7595C84.8819 30.6259 84.5617 30.4811 84.232 30.3601C83.5792 30.1011 82.9105 29.8841 82.2379 29.6832C82.02 29.6223 81.9739 29.7874 82.19 29.8475C82.8579 30.0472 83.5219 30.2625 84.17 30.5198C84.4973 30.64 84.8152 30.7838 85.1377 30.9165C85.4536 31.0637 85.7748 31.1999 86.0849 31.3595C86.2855 31.4588 86.3634 31.3062 86.1605 31.2056Z"
          fill="#474747"
        />
        <path
          d="M87.0955 31.6908C86.896 31.584 86.8156 31.7353 87.0135 31.8411C87.211 31.9467 87.2953 31.7976 87.0955 31.6908Z"
          fill="#474747"
        />
        <path
          d="M61.9127 24.5859H54.5386V25.1113H61.9127V24.5859Z"
          fill="white"
        />
        <path
          d="M78.8602 81.4096L73.9147 78.5543V79.8396C69.4296 79.5472 64.9916 78.1442 61.1836 75.7831C60.1193 75.1257 59.0402 74.3459 57.8765 73.3917C57.0625 72.7186 56.3693 71.9996 55.6988 71.3041C55.4708 71.0677 55.2427 70.8311 55.0102 70.5974L54.9369 70.5237L54.8696 70.4441C52.9313 68.1517 51.4664 65.8957 50.3912 63.5474L50.3739 63.5085C49.2213 60.8772 48.4447 58.1943 48.0653 55.5343L48.0582 55.4801C47.7228 52.7061 47.754 49.8925 48.1512 47.1171L48.1567 47.0785L48.1634 47.04C48.2936 46.2927 48.4518 45.5483 48.6372 44.8086C48.4038 45.5436 48.1978 46.2856 48.02 47.033C47.4334 49.8376 47.2229 52.7114 47.3941 55.5757C47.6141 58.3642 48.2496 61.2006 49.2836 64.0078C50.2517 66.5235 51.6852 69.0595 53.5454 71.5473C53.7179 71.7492 53.8897 71.9521 54.0615 72.1551C54.8779 73.1195 55.6491 74.0306 56.4872 74.8201C57.6663 75.9241 58.787 76.8486 59.9177 77.6493C63.9961 80.5498 68.8842 82.4047 73.9147 82.9951V84.2648L78.8602 81.4096Z"
          fill="#64C4F5"
        />
        <defs>
          <linearGradient
            id="paint0_linear_35078_343076"
            x1="55.7553"
            y1="108.67"
            x2="76.1645"
            y2="108.67"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.00140944" stop-color="#595759" />
            <stop offset="0.00262294" stop-color="#636163" />
            <stop offset="0.00867688" stop-color="#8F8E8F" />
            <stop offset="0.0134" stop-color="#ABABAB" />
            <stop offset="0.0161" stop-color="#B5B5B5" />
            <stop offset="0.0218" stop-color="#BCBCBC" />
            <stop offset="0.0259" stop-color="#C4C4C4" />
            <stop offset="0.0309" stop-color="#CCCCCC" />
            <stop offset="0.0358" stop-color="#C4C4C4" />
            <stop offset="0.0478" stop-color="#7D7D7D" />
            <stop offset="0.0558" stop-color="#605F60" />
            <stop offset="0.0645" stop-color="#454245" />
            <stop offset="0.08" stop-color="#323133" />
            <stop offset="1" stop-color="#3F3D40" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_35078_343076"
            x1="96.4174"
            y1="108.67"
            x2="76.0082"
            y2="108.67"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.00140944" stop-color="#595759" />
            <stop offset="0.00262294" stop-color="#636163" />
            <stop offset="0.00867688" stop-color="#8F8E8F" />
            <stop offset="0.0134" stop-color="#ABABAB" />
            <stop offset="0.0161" stop-color="#B5B5B5" />
            <stop offset="0.0218" stop-color="#BCBCBC" />
            <stop offset="0.0259" stop-color="#C4C4C4" />
            <stop offset="0.0309" stop-color="#CCCCCC" />
            <stop offset="0.0358" stop-color="#C4C4C4" />
            <stop offset="0.0478" stop-color="#7D7D7D" />
            <stop offset="0.0558" stop-color="#605F60" />
            <stop offset="0.0645" stop-color="#454245" />
            <stop offset="0.08" stop-color="#323133" />
            <stop offset="1" stop-color="#3F3D40" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_35078_343076"
            x1="76.0869"
            y1="91.0801"
            x2="76.0869"
            y2="108.705"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.0471" stop-color="#1A1A1C" />
            <stop offset="0.2435" stop-color="#2D2B2E" />
            <stop offset="0.3028" stop-color="#333133" />
            <stop offset="0.3801" stop-color="#424143" />
            <stop offset="0.4672" stop-color="#5C5C5C" />
            <stop offset="0.4813" stop-color="#616161" />
            <stop offset="0.5715" stop-color="#858585" />
            <stop offset="0.5942" stop-color="#858585" />
            <stop offset="0.6151" stop-color="#7E7E7F" />
            <stop offset="0.6455" stop-color="#6C6A6D" />
            <stop offset="0.6559" stop-color="#646266" />
            <stop offset="0.6724" stop-color="#565558" />
            <stop offset="0.7058" stop-color="#403F41" />
            <stop offset="0.7373" stop-color="#333233" />
            <stop offset="0.7646" stop-color="#2E2D2E" />
            <stop offset="0.8196" stop-color="#3A3A3B" />
            <stop offset="1" stop-color="#787878" />
          </linearGradient>
        </defs>
      </svg>
    </span>
  )
}
export default withTheme(ChatStartVideoIcon)
