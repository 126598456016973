import React, { useEffect, useState } from "react"
import { Collapse, Spin } from "antd"
import type { CollapseProps } from "antd"
import AudioAnalyzer from "../../../components/NetworkTest/components/AudioDiagnostic/AudioDiagnostic"
import VideoAnalyzer from "../../../components/NetworkTest/components/VideoDiagnostic/VideoDiagnostic"
import Network from "../../../components/NetworkTest/components/network"
import Connectivity from "../../../components/NetworkTest/components/connectivity"
import Throughput from "../../../components/NetworkTest/components/bandwidth/Throughput"
import VideoBandwidth from "../../../components/NetworkTest/components/bandwidth/VideoBandwidth"
import NetworkTestStyle from "./NetworktestStyle.style"
import { Heading5 } from "../../../config/global.style"
import NetworkTestCollapse from "../../../components/Icons/NetworkCollapse"
import NetworkSuccess from "../../../components/Icons/NetworkSuccess"
import NetworkError from "../../../components/Icons/Networkerror"
import NetworkWarn from "../../../components/Icons/NetworkWarn"
import TCP from "../../../components/NetworkTest/components/network/TCP"
import UDP from "../../../components/NetworkTest/components/network/UDP"
import Host from "../../../components/NetworkTest/components/connectivity/Host"
import Reflexive from "../../../components/NetworkTest/components/connectivity/Reflexive"
import Relay from "../../../components/NetworkTest/components/connectivity/Relay"
import Button from "../../../components/Button/Button"

const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`
const { Panel } = Collapse
const items: CollapseProps["items"] = [
  {
    key: "1",
    label: "This is panel header 1",
    children: <p>{text}</p>
  },
  {
    key: "2",
    label: "This is panel header 2",
    children: <p>{text}</p>
  },
  {
    key: "3",
    label: "This is panel header 3",
    children: <p>{text}</p>
  }
]
const NetworkTest = () => {
  const [logs, setLogs] = useState({})
  const [errorLogs, setErrorLogs] = useState({})
  const [activeKey, setActiveKey] = useState(["1", "2", "3", "4", "5", "6"])

  const onChange = (key: string | string[]) => {
    console.log(key)

    setActiveKey((prevState) => {
      if (prevState?.includes(key)) {
        return prevState.filter((item) => item != key)
      }
      return [...prevState, key]
    })
  }
  const [isStarted, setIsStarted] = useState(false)
  const [isLoading, setIsLoading] = useState({
    audio: "",
    video: "",
    networkTcp: "",
    networkUdp: "",
    connectivityRelay: "",
    connectivityReflexive: "",
    connectivityHost: "",
    throughput: "",
    videoBandwidth: ""
  })

  useEffect(() => {
    return () => {
      setIsStarted(false)
      // window.location.reload()
    }
  }, [])

  return (
    <NetworkTestStyle>
      <div className="head-wrap">
        <Heading5 className="heading5">Network Test</Heading5>
        <Button
          size="small"
          type="primary"
          onClick={() => {
            setIsStarted(false)
            setLogs({})
            setErrorLogs({})
            setIsLoading({
              audio: true,
              video: true,
              networkTcp: true,
              networkUdp: true,
              connectivityRelay: true,
              connectivityReflexive: true,
              connectivityHost: true,
              throughput: true,
              videoBandwidth: true
            })
            setTimeout(() => {
              setIsStarted(true)
            }, 100)
          }}
          loading={Boolean(
            Object.values(isLoading).filter((val) => val == true)?.length
          )}
        >
          Start
        </Button>
      </div>
      <div className="network-wrap">
        <div>
          <Collapse
            bordered={false}
            expandIcon={({ isActive }) =>
              isActive ? <NetworkTestCollapse /> : <NetworkTestCollapse />
            }
            onChange={() => onChange("1")}
            className={isStarted ? (errorLogs.audio ? "error" : "success") : ""}
            activeKey={isStarted ? (activeKey?.includes("1") ? 1 : 0) : 0}
          >
            <Panel
              header={
                isStarted ? (
                  <div className="title-head">
                    <AudioAnalyzer
                      setLogs={setLogs}
                      setErrorLogs={setErrorLogs}
                      setIsLoading={setIsLoading}
                    />
                    {isLoading.audio ? (
                      <Spin />
                    ) : (
                      <div>
                        {errorLogs.audio ? (
                          <NetworkError />
                        ) : (
                          <NetworkSuccess />
                        )}
                        {/* <NetworkWarn /> */}
                      </div>
                    )}
                  </div>
                ) : (
                  <div>Audio</div>
                )
              }
              key="1"
            >
              {errorLogs.audio ? (
                errorLogs.audio
              ) : (
                <div className="nested-log">
                  <div>Peak Level: {logs.audio?.peakLevel}</div>
                  <div>Maximum RMS: {logs.audio?.rms}</div>
                  <div>
                    Clip Detected: {logs.audio?.clipDetected ? "yes" : "no"}
                  </div>
                  <div>
                    Mono Mic Detection: {logs.audio?.isMono ? "yes" : "no"}
                  </div>
                </div>
              )}
            </Panel>
          </Collapse>
          <Collapse
            bordered={false}
            expandIcon={({ isActive }) =>
              isActive ? <NetworkTestCollapse /> : <NetworkTestCollapse />
            }
            onChange={() => onChange("2")}
            className={isStarted ? (errorLogs.video ? "error" : "success") : ""}
            activeKey={isStarted ? (activeKey?.includes("2") ? 2 : 0) : 0}
          >
            <Panel
              header={
                isStarted ? (
                  <div className="title-head">
                    {" "}
                    <VideoAnalyzer
                      setLogs={setLogs}
                      setErrorLogs={setErrorLogs}
                      setIsLoading={setIsLoading}
                    />
                    {isLoading.video ? (
                      <Spin />
                    ) : (
                      <div>
                        {errorLogs.video ? (
                          <NetworkError />
                        ) : (
                          <NetworkSuccess />
                        )}
                        {/* <NetworkWarn /> */}
                      </div>
                    )}
                  </div>
                ) : (
                  <div>Video Analysis</div>
                )
              }
              key="2"
            >
              {errorLogs.video ? (
                errorLogs.video
              ) : (
                <div className="nested-log">
                  <p>
                    Supported Resolutions:{" "}
                    {logs.videoSupportedResolutions?.join(", ")}
                  </p>
                  <ul>
                    {Object.entries(logs?.videoResolutionChecks || {}).map(
                      ([resolution, isSupported]) => (
                        <li key={resolution}>
                          {resolution}:{" "}
                          {isSupported ? "Supported" : "Not Supported"}
                        </li>
                      )
                    )}
                  </ul>
                </div>
              )}
            </Panel>
          </Collapse>
          <Collapse
            bordered={false}
            expandIcon={({ isActive }) =>
              isActive ? <NetworkTestCollapse /> : <NetworkTestCollapse />
            }
            onChange={() => onChange("3")}
            className={
              isStarted
                ? errorLogs.networkTcp?.length && errorLogs.networkUdp?.length
                  ? "error"
                  : "success"
                : ""
            }
            activeKey={isStarted ? (activeKey?.includes("3") ? 3 : 0) : 0}
          >
            <Panel
              header={
                isStarted ? (
                  <div className="title-head">
                    {" "}
                    <span>Network</span>
                    {isLoading.networkTcp || isLoading?.networkUdp ? (
                      <Spin />
                    ) : (
                      <div>
                        {errorLogs.networkTcp?.length ||
                        errorLogs.networkUdp?.length ? (
                          <NetworkError />
                        ) : (
                          <NetworkSuccess />
                        )}
                        {/* <NetworkWarn /> */}
                      </div>
                    )}
                  </div>
                ) : (
                  <div>Network</div>
                )
              }
              key="3"
            >
              <Collapse
                bordered={false}
                expandIconPosition="end"
                collapsible="icon"
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <span className="link">View Log</span>
                  ) : (
                    <span className="link">View Log</span>
                  )
                }
                onChange={onChange}
              >
                <Panel
                  header={
                    <div className="title-head">
                      {isStarted && (
                        <TCP
                          setLogs={setLogs}
                          setErrorLogs={setErrorLogs}
                          setIsLoading={setIsLoading}
                        />
                      )}
                    </div>
                  }
                  key="9"
                >
                  {errorLogs.networkTcp?.length ||
                  errorLogs.networkUdp?.length ? (
                    <div className="nested-log">
                      <div>
                        {errorLogs?.networkTcp?.map((mes) => (
                          <div>{mes}</div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div className="nested-log">
                      <div>
                        {logs?.networkTcp?.map((mes) => (
                          <div>{mes}</div>
                        ))}
                      </div>
                    </div>
                  )}
                </Panel>
              </Collapse>
              <Collapse
                bordered={false}
                expandIconPosition="end"
                collapsible="icon"
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <span className="link">View Log</span>
                  ) : (
                    <span className="link">View Log</span>
                  )
                }
                onChange={onChange}
              >
                <Panel
                  header={
                    <div className="title-head">
                      {isStarted && (
                        <UDP
                          setLogs={setLogs}
                          setErrorLogs={setErrorLogs}
                          setIsLoading={setIsLoading}
                        />
                      )}
                    </div>
                  }
                  key="9"
                >
                  {errorLogs.networkTcp?.length ||
                  errorLogs.networkUdp?.length ? (
                    <div className="nested-log">
                      <div>
                        {errorLogs?.networkUdp?.map((mes) => (
                          <div>{mes}</div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div className="nested-log">
                      <div>
                        {logs?.networkUdp?.map((mes) => (
                          <div>{mes}</div>
                        ))}
                      </div>
                    </div>
                  )}
                </Panel>
              </Collapse>
            </Panel>
          </Collapse>
          <Collapse
            bordered={false}
            expandIcon={({ isActive }) =>
              isActive ? <NetworkTestCollapse /> : <NetworkTestCollapse />
            }
            onChange={() => onChange("4")}
            className={
              isStarted
                ? errorLogs.connectivityRelay?.length &&
                  errorLogs.connectivityReflexive?.length &&
                  errorLogs.connectivityHost?.length
                  ? "error"
                  : "success"
                : ""
            }
            activeKey={isStarted ? (activeKey?.includes("4") ? 4 : 0) : 0}
          >
            <Panel
              header={
                isStarted ? (
                  <div className="title-head">
                    {" "}
                    <span>Connectivity</span>
                    {isLoading.connectivityRelay ||
                    isLoading?.connectivityReflexive ||
                    isLoading?.connectivityHost ? (
                      <Spin />
                    ) : (
                      <div>
                        {errorLogs.connectivityRelay?.length ||
                        errorLogs.connectivityReflexive?.length ||
                        errorLogs.connectivityHost?.length ? (
                          <NetworkError />
                        ) : (
                          <NetworkSuccess />
                        )}
                      </div>
                    )}
                  </div>
                ) : (
                  <div>Connectivity</div>
                )
              }
              key="4"
            >
              <Collapse
                bordered={false}
                expandIconPosition="end"
                collapsible="icon"
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <span className="link">View Log</span>
                  ) : (
                    <span className="link">View Log</span>
                  )
                }
                onChange={onChange}
              >
                <Panel
                  header={
                    <div className="title-head">
                      {isStarted && (
                        <Relay
                          setLogs={setLogs}
                          setErrorLogs={setErrorLogs}
                          setIsLoading={setIsLoading}
                        />
                      )}
                    </div>
                  }
                  key="10"
                >
                  {errorLogs.connectivityRelay?.length ||
                  errorLogs.connectivityReflexive?.length ||
                  errorLogs.connectivityHost?.length ? (
                    <div className="nested-log">
                      <div>
                        {errorLogs?.connectivityRelay?.map((mes) => (
                          <div>{mes}</div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div className="nested-log">
                      <div>
                        {logs?.connectivityRelay?.map((mes) => (
                          <div>{mes}</div>
                        ))}
                      </div>
                    </div>
                  )}
                </Panel>
              </Collapse>
              <Collapse
                bordered={false}
                expandIconPosition="end"
                collapsible="icon"
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <span className="link">View Log</span>
                  ) : (
                    <span className="link">View Log</span>
                  )
                }
                onChange={onChange}
              >
                <Panel
                  header={
                    <div className="title-head">
                      {isStarted && (
                        <Reflexive
                          setLogs={setLogs}
                          setErrorLogs={setErrorLogs}
                          setIsLoading={setIsLoading}
                        />
                      )}
                    </div>
                  }
                  key="10"
                >
                  {errorLogs.connectivityRelay?.length ||
                  errorLogs.connectivityReflexive?.length ||
                  errorLogs.connectivityHost?.length ? (
                    <div className="nested-log">
                      <div>
                        {errorLogs?.connectivityReflexive?.map((mes) => (
                          <div>{mes}</div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div className="nested-log">
                      <div>
                        {logs?.connectivityReflexive?.map((mes) => (
                          <div>{mes}</div>
                        ))}
                      </div>
                    </div>
                  )}
                </Panel>
              </Collapse>
              <Collapse
                bordered={false}
                expandIconPosition="end"
                collapsible="icon"
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <span className="link">View Log</span>
                  ) : (
                    <span className="link">View Log</span>
                  )
                }
                onChange={onChange}
              >
                <Panel
                  header={
                    <div className="title-head">
                      {isStarted && (
                        <Host
                          setLogs={setLogs}
                          setErrorLogs={setErrorLogs}
                          setIsLoading={setIsLoading}
                        />
                      )}
                    </div>
                  }
                  key="10"
                >
                  {errorLogs.connectivityRelay?.length ||
                  errorLogs.connectivityReflexive?.length ||
                  errorLogs.connectivityHost?.length ? (
                    <div className="nested-log">
                      <div>
                        {errorLogs?.connectivityHost?.map((mes) => (
                          <div>{mes}</div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div className="nested-log">
                      <div>
                        {logs?.connectivityHost?.map((mes) => (
                          <div>{mes}</div>
                        ))}
                      </div>
                    </div>
                  )}
                </Panel>
              </Collapse>
            </Panel>
          </Collapse>
          <Collapse
            bordered={false}
            expandIcon={({ isActive }) =>
              isActive ? <NetworkTestCollapse /> : <NetworkTestCollapse />
            }
            onChange={() => onChange("5")}
            className={
              isStarted
                ? errorLogs.throughput?.length
                  ? "error"
                  : "success"
                : ""
            }
            activeKey={isStarted ? (activeKey?.includes("5") ? 5 : 0) : 0}
          >
            <Panel
              header={
                isStarted ? (
                  <div className="title-head">
                    {" "}
                    <Throughput
                      setLogs={setLogs}
                      setErrorLogs={setErrorLogs}
                      setIsLoading={setIsLoading}
                    />
                    {isLoading.throughput ? (
                      <Spin />
                    ) : (
                      <div>
                        {errorLogs.throughput?.length ? (
                          <NetworkError />
                        ) : (
                          <NetworkSuccess />
                        )}
                        {/* <NetworkWarn /> */}
                      </div>
                    )}
                  </div>
                ) : (
                  <div>Throughtput</div>
                )
              }
              key="5"
            >
              <div className="nested-log">
                <div>
                  {logs?.throughput?.map((mes) => (
                    <div>{mes}</div>
                  ))}
                </div>
              </div>
            </Panel>
          </Collapse>
          <Collapse
            bordered={false}
            expandIcon={({ isActive }) =>
              isActive ? <NetworkTestCollapse /> : <NetworkTestCollapse />
            }
            onChange={() => onChange("6")}
            className={
              isStarted
                ? errorLogs.videoBandwidth?.length
                  ? "error"
                  : "success"
                : ""
            }
            activeKey={isStarted ? (activeKey?.includes("6") ? 6 : 0) : 0}
          >
            <Panel
              header={
                isStarted ? (
                  <div className="title-head">
                    {" "}
                    <VideoBandwidth
                      setLogs={setLogs}
                      setErrorLogs={setErrorLogs}
                      setIsLoading={setIsLoading}
                    />
                    {isLoading.videoBandwidth ? (
                      <Spin />
                    ) : (
                      <div>
                        {errorLogs.videoBandwidth?.length ? (
                          <NetworkError />
                        ) : (
                          <NetworkSuccess />
                        )}
                        {/* <NetworkWarn /> */}
                      </div>
                    )}
                  </div>
                ) : (
                  <div>Video Bandwidth</div>
                )
              }
              key="6"
            >
              <div className="nested-log">
                <div>
                  <ul>
                    <li>
                      Round Trip Time: {logs.videoBandwidth?.RoundTripTime} sec
                    </li>
                    <li>
                      Bandwidth Estimate Average:{" "}
                      {(
                        (parseFloat(
                          logs.videoBandwidth?.bandwidth_estimate_average
                        ) || 0) / 1000
                      )?.toFixed(2)}{" "}
                      Mbps
                    </li>
                    <li>
                      Bandwidth Estimate Max:{" "}
                      {(
                        (parseFloat(
                          logs.videoBandwidth?.bandwidth_estimate_max
                        ) || 0) / 1000
                      )?.toFixed(2)}{" "}
                      Mbps
                    </li>
                    <li>Packets Sent: {logs.videoBandwidth?.packetsSent}</li>
                    <li>
                      Packets Received: {logs.videoBandwidth?.packetsReceived}
                    </li>
                    <li>NACK Count: {logs.videoBandwidth?.nackCount}</li>
                    <li>PLI Count: {logs.videoBandwidth?.pliCount}</li>
                    <li>QP Sum: {logs.videoBandwidth?.qpSum}</li>
                    <li>
                      Frames Encoded: {logs.videoBandwidth?.framesEncoded}
                    </li>
                    <li>
                      Frames Decoded: {logs.videoBandwidth?.framesDecoded}
                    </li>
                  </ul>
                </div>
              </div>
            </Panel>
          </Collapse>
        </div>
      </div>
    </NetworkTestStyle>
  )
}

export default NetworkTest
