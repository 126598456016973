import React from "react"

function CallQueueLogoutIcon() {
  return (
    <span className="anticon anticon-callqueuelogout">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16 "
      >
        <path
          d="M11.625 9.74732L13.3317 8.04065L11.625 6.33398"
          stroke="#FF455C"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.50781 8.03906H13.2878"
          stroke="#FF455C"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.84115 13.3327C4.89448 13.3327 2.50781 11.3327 2.50781 7.99935C2.50781 4.66602 4.89448 2.66602 7.84115 2.66602"
          stroke="#FF455C"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  )
}

export default CallQueueLogoutIcon
