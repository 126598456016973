import { useCallback, useEffect, useMemo, useState } from "react"
import { NotificationCountType, Room, RoomEvent } from "matrix-js-sdk"
import { useEventEmitter } from "./useEventEmitter"

export const getMarkedUnreadState = (room: Room): boolean | undefined => {
  const currentStateStable = room
    .getAccountData("m.marked_unread")
    ?.getContent<IMarkedUnreadEvent>()?.unread
  const currentStateUnstable = room
    .getAccountData("com.famedly.marked_unread")
    ?.getContent<IMarkedUnreadEvent>()?.unread
  return currentStateStable ?? currentStateUnstable
}

export const getUnreadNotificationCount = (
  room: Room,
  type: NotificationCountType,
  includeThreads: boolean,
  threadId?: string
): number => {
  const getCountShownForRoom = (
    r: Room,
    type: NotificationCountType
  ): number => {
    return includeThreads
      ? r.getUnreadNotificationCount(type)
      : r.getRoomUnreadNotificationCount(type)
  }

  const notificationCount = threadId
    ? room.getThreadUnreadNotificationCount(threadId, type)
    : getCountShownForRoom(room, type)

  // Check notification counts in the old room just in case there's some lost
  // there. We only go one level down to avoid performance issues, and theory
  // is that 1st generation rooms will have already been read by the 3rd generation.
  // const msc3946ProcessDynamicPredecessor = SettingsStore.getValue("feature_dynamic_room_predecessors");
  // const predecessor = room.findPredecessor(msc3946ProcessDynamicPredecessor);
  // // Exclude threadId, as the same thread can't continue over a room upgrade
  // if (!threadId && predecessor?.roomId) {
  //     const oldRoomId = predecessor.roomId;
  //     const oldRoom = room.client.getRoom(oldRoomId);
  //     if (oldRoom) {
  //         // We only ever care if there's highlights in the old room. No point in
  //         // notifying the user for unread messages because they would have extreme
  //         // difficulty changing their notification preferences away from "All Messages"
  //         // and "Noisy".
  //         notificationCount += getCountShownForRoom(oldRoom, NotificationCountType.Highlight);
  //     }
  // }

  return notificationCount
}

export const determineUnreadState = (
  room?: Room,
  threadId?: string,
  includeThreads?: boolean
): { level: NotificationLevel; symbol: string | null; count: number } => {
  if (!room) {
    return { symbol: null, count: 0, level: NotificationLevel.None }
  }

  // if (getUnsentMessages(room, threadId).length > 0) {
  //     return { symbol: "!", count: 1, level: NotificationLevel.Unsent };
  // }

  // if (getEffectiveMembership(room.getMyMembership()) === EffectiveMembership.Invite) {
  //     return { symbol: "!", count: 1, level: NotificationLevel.Highlight };
  // }

  // if (SettingsStore.getValue("feature_ask_to_join") && isKnockDenied(room)) {
  //     return { symbol: "!", count: 1, level: NotificationLevel.Highlight };
  // }

  // if (getRoomNotifsState(room.client, room.roomId) === RoomNotifState.Mute) {
  //     return { symbol: null, count: 0, level: NotificationLevel.None };
  // }

  const redNotifs = getUnreadNotificationCount(
    room,
    NotificationCountType.Highlight,
    includeThreads ?? false,
    threadId
  )
  const greyNotifs = getUnreadNotificationCount(
    room,
    NotificationCountType.Total,
    includeThreads ?? false,
    threadId
  )

  const trueCount = greyNotifs || redNotifs
  if (redNotifs > 0) {
    return {
      symbol: null,
      count: trueCount,
      level: NotificationLevel.Highlight
    }
  }

  const markedUnreadState = getMarkedUnreadState(room)
  if (greyNotifs > 0 || markedUnreadState) {
    return {
      symbol: null,
      count: trueCount,
      level: NotificationLevel.Notification
    }
  }

  // We don't have any notified messages, but we might have unread messages. Let's find out.
  // let hasUnread = false;
  // if (threadId) {
  //     const thread = room.getThread(threadId);
  //     if (thread) {
  //         hasUnread = doesRoomOrThreadHaveUnreadMessages(thread);
  //     }
  //     // If the thread does not exist, assume it contains no unreads
  // } else {
  //     hasUnread = doesRoomHaveUnreadMessages(room, includeThreads ?? false);
  // }

  return {
    symbol: null,
    count: trueCount
    // level: hasUnread ? NotificationLevel.Activity : NotificationLevel.None,
  }
}

const enum NotificationLevel {
  Muted,
  // Inverted (None -> Red) because we do integer comparisons on this
  None, // nothing special
  // TODO: Remove bold with notifications: https://github.com/vector-im/element-web/issues/14227
  Activity, // no badge, show as unread
  Notification, // unread notified messages
  Highlight, // unread pings
  Unsent // some messages failed to send
}

export const useUnreadNotifications = (
  room?: Room,
  threadId?: string
): {
  symbol: string | null
  count: number
  level: NotificationLevel
} => {
  const [symbol, setSymbol] = useState<string | null>(null)
  const [count, setCount] = useState<number>(0)
  const [level, setLevel] = useState<NotificationLevel>(NotificationLevel.None)

  useEventEmitter(
    room,
    RoomEvent.UnreadNotifications,
    (unreadNotifications: NotificationCount, evtThreadId?: string) => {
      // Discarding all events not related to the thread if one has been setup
      if (threadId && threadId !== evtThreadId) return
      updateNotificationState()
    }
  )
  useEventEmitter(room, RoomEvent.Receipt, () => updateNotificationState())
  useEventEmitter(room, RoomEvent.Timeline, () => updateNotificationState())
  useEventEmitter(room, RoomEvent.Redaction, () => updateNotificationState())
  useEventEmitter(room, RoomEvent.LocalEchoUpdated, () =>
    updateNotificationState()
  )
  useEventEmitter(room, RoomEvent.MyMembership, () => updateNotificationState())

  const updateNotificationState = useCallback(() => {
    const { symbol, count, level } = determineUnreadState(room, threadId, true)
    setSymbol(symbol)
    setCount(count)
    setLevel(level)
  }, [room, threadId])

  useEffect(() => {
    updateNotificationState()
  }, [updateNotificationState])

  return {
    symbol,
    count,
    level
  }
}
