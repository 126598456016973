export const turnConfig = {
  iceServers: [
    {
      credential: import.meta.env.VITE_TURN_PASSWORD as string,
      urls: [
        `${import.meta.env?.VITE_TURN_SERVER}:${
          import.meta.env?.VITE_TURN_PORT
        }` as string
      ] as string[],
      username: import.meta.env.VITE_TURN_USERNAME as string
    }
  ]
}
