import { useEffect } from "react"
import useConnectivityTest from "../../hooks/useConnectivityTest"

const Reflexive = ({ setLogs, setErrorLogs, setIsLoading }) => {
  const reflexiveMessage = useConnectivityTest("srflx")

  useEffect(() => {
    setLogs((prevState) => ({
      ...prevState,
      connectivityReflexive: reflexiveMessage?.message
    }))

    if (reflexiveMessage?.message) {
      setIsLoading((prevState) => ({
        ...prevState,
        connectivityReflexive: false
      }))
    }
  }, [reflexiveMessage?.message])

  useEffect(() => {
    setErrorLogs((prevState) => ({
      ...prevState,
      connectivityRelay: reflexiveMessage?.errorMessage
    }))
  }, [reflexiveMessage?.errorMessage])

  return (
    <>
      <div>Reflexive</div>
      {/* {reflexiveMessage?.message.map((mes) => (
        <div>{mes}</div>
      ))} */}
    </>
  )
}

export default Reflexive
