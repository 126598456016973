import styled from "styled-components"
import { rgba } from "../../../config/variable"

const CallQueueTableStyle = styled.div`
  .voicemail-table {
    display: flex;
    width: 100%;
    border-radius: 10px;
    padding: 17px 24px;
    background: ${(props) => props.theme.colordwhitedark};
    flex-direction: column;
    &__header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 16px;
      .ant-typography {
        margin: 0;
        font-size: 16px;
        font-weight: 500;
      }
      .connect-social {
        display: flex;
        justify-content: center;
        align-items: center;
        label {
          font-size: 14px;
          margin-right: 20px;
          color: ${(props) => props.theme.textcolordblack};
        }
        .ant-btn {
          margin-right: 10px;
          background: ${(props) => props.theme.textcolordwhite3};
          border: 1px solid ${(props) => props.theme.bordercolorblue1};
          &:last-child {
            margin-right: 0;
            border: 1px solid ${(props) => props.theme.borderorange};
          }
        }
      }
    }
    &__block {
      .ant-table {
        border: 1px solid ${(props) => props.theme.colorbluegreylight};

        overflow: auto;
        .ant-table-placeholder {
          .ant-table-cell {
            color: ${(props) => props.theme.textcolordblack};
          }
        }
        .ant-table-thead {
          .ant-table-cell {
            border-bottom: 1px solid
              ${(props) => props.theme.colorbluegreylight};
            background: ${(props) => props.theme.bgborderlight};
            white-space: nowrap;
            &.ant-table-cell-fix-right {
              display: none !important;
            }
            .action-title {
              opacity: 0;
            }
            &:before {
              content: none !important;
            }
          }
        }
        .ant-table-tbody {
          .ant-table-cell {
            border-bottom: 1px solid
              ${(props) => props.theme.colorbluegreylight};
            background: ${(props) => props.theme.colordwhitedark};
            white-space: nowrap;
            border-top: none;
            transition: none;
            .coloractive{
              color:  ${(props) => props.theme.primary};
            }
            p {
              max-width: 500px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              &.number-register {
                font-size: 12px;
                display: flex;
                align-items: center;
                span {
                  display: flex
                  align-items: center;
                  .anticon {
                    margin-left: 7px;
                    color: ${(props) => props.theme.textcolordarkgrey};
                    cursor: pointer;
                  }
                }
              }
            }

            &.ant-table-cell-fix-right {
              display: none;
              padding-top: 9px;
              padding-bottom: 9px;
              position: absolute !important;
            }
            &.ant-table-cell-row-hover {
              background-color: ${(props) =>
                props.theme.textcolordarkblue}!important;
              color: ${(props) => props.theme.textcolordwhite3};

              &.ant-table-cell-fix-right {
                display: inline-block;
              }
              .anticon {
                color: ${(props) => props.theme.textcolordwhite3}!important;
              }

              .action-wrapper {
                .ant-btn-default {
background: ${(props) => props.theme.bgcolordwhiteblack};
                }
              }
            }
            &.cursor {
              cursor: pointer;
            }
            .anticon {
              font-size: 17px;
            }
            .action-wrapper {
              .anticon {
                margin: 0 10px 0 0;
              }
            }
          }
          .ant-table-row {
            &:nth-child(even) {
              .ant-table-cell {
                background: ${(props) => props.theme.bglightblue};
              }
            }
          }
        }
      }
      .source-wrapper {
        text-align: center;
      }
      .attendees {
        display: flex;
        cursor: pointer;
        li {
          list-style: none;
          margin-left: -7px;
        }
        .profile-pick {
          width: 24px;
          min-width: 24px;
          height: 24px;
          border-radius: 100%;
          background: ${(props) => props.theme.primary};
          font-size: 12px;
          color: ${(props) => props.theme.textcolordwhite3};
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid ${(props) => props.theme.bgcolordwhiteblack};
          overflow: hidden;
        }
      }
      .host-wrapper {
        display: flex;
        align-items: center;
        &.no-attendees {
          cursor: pointer;
        }
        .profile-pic {
          display: flex;
          align-items: center;
          margin-right: 6px;
          &.without {
            width: 24px;
            height: 24px;
            min-width: 24px;
            border-radius: 100%;
            background: ${(props) => props.theme.primary};
            font-size: 12px;
            color: ${(props) => props.theme.textcolordwhite3};
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
      .action-wrapper {
        display: flex;
        justify-content: space-between;
        .ant-dropdown-trigger {
          display: flex;
          align-items: center;
        }
        .ant-btn {
          width: auto;
        }
        a {
          text-align: center;
        }
        .anticon {
          margin: 0 10px;
          color: ${(props) => props.theme.textcolordarkgrey};
          &:hover {
            color: ${(props) => props.theme.textcolordarkblue};
          }
        }
      }
      .ant-pagination {
        justify-content: flex-start;
        position: relative;
        .ant-pagination-total-text {
          position: absolute;
          right: 0;
          border: 1px solid ${(props) => props.theme.colorbluegreylight};
          padding: 7px 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          font-size: 14px;
        }
        .button {
          border-radius: 4px;
        }
        .ant-pagination-item,
        .ant-pagination-next,
        .ant-pagination-prev {
          font-size: 14px;
          color: ${(props) => props.theme.colorlightblack};
          border: 1px solid ${(props) => props.theme.colorbluegreylight};
          border-radius: 4px;
          .ant-pagination-item-link {
            color: ${(props) => props.theme.colorlightblack};
          }
          &.ant-pagination-item-active {
            border-color: ${(props) => props.theme.primary};
            background-color: transparent;
          }
        }
      }
    }
  }
`
export default CallQueueTableStyle
