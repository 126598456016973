import styled from "styled-components"

const NetworkTestStyle = styled.div`
  background: ${(props) => props.theme.colordwhitedark};
  padding: 24px;
  border-radius: 10px;
  .head-wrap {
    margin-bottom: 28px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .network-wrap {
    border: 1px solid ${(props) => props.theme.bordercolorsharpgrey};
    padding: 12px;
    border-radius: 8px;
    .ant-collapse {
      background-color: transparent;
      color: ${(props) => props.theme.colorlightblack};
      margin-bottom: 8px;
      border-radius: 0;
      border-left: 3px solid transparent;
      .ant-collapse-header {
        .ant-collapse-expand-icon {
          .anticon {
            transform: rotate(-90deg);
          }
        }
      }
      .ant-collapse-item-active {
        .ant-collapse-header {
          .ant-collapse-expand-icon {
            .anticon {
              transform: rotate(0deg);
            }
          }
        }
      }
      &.success {
        border-color: #60b527;
      }
      &.error {
        border-color: #ff455c;
      }
      &.warning {
        border-color: #f89c18;
      }
      .title-head {
        display: flex;
        justify-content: space-between;
        .error-msg {
          color: ${(props) => props.theme.colordanger};
          font-size: 12px;
          font-weight: normal;
        }
        h2,
        h1 {
          font-weight: normal;
          font-size: 14px;
          font-weight: 600;
        }
        .link {
          font-size: 12px;
          color: ${(props) => props.theme.primary};
          cursor: pointer;
        }
      }

      .ant-collapse-header {
        padding: 8px 12px;
        background: transparent;
        font-weight: 600;
        font-size: 14px;
        padding-bottom: 0;
        .ant-collapse-expand-icon {
          .link {
            font-size: 12px;
            color: ${(props) => props.theme.primary};
            cursor: pointer;
          }
        }
        .anticon {
          font-size: 20px;
        }
      }
      .ant-collapse-content-box {
        padding-left: 45px;
        padding-bottom: 0;
        .title-head {
          flex-direction: column;
        }
        .ant-collapse-content-box {
          padding-left: 0;
        }
        h2,
        h1 {
          font-weight: normal;
          font-size: 14px;
        }
        .nested-log {
          padding-left: 12px;
          font-weight: normal;
          font-size: 14px;
          color: ${(props) => props.theme.colorlightblack};
        }
        ul {
          list-style: none;
        }
      }
    }
  }
`

export default NetworkTestStyle
