import styled from "styled-components"

const TypingIndicatorStyle = styled.div`
  margin: 8px;
  .dots-3 {
    width: 30px;
    aspect-ratio: 2;
    --_g: no-repeat radial-gradient(circle closest-side, #8083a3 90%, #0000);
    --_f: no-repeat radial-gradient(circle closest-side, #cccdda 90%, #0000);
    background: var(--_g) 0% 50%, var(--_f) 50% 50%, var(--_g) 100% 50%;
    background-size: calc(100% / 3) 50%;
    animation: d3 1s infinite linear;
  }
  @keyframes d3 {
    20% {
      background-position: 0% 0%, 50% 50%, 100% 50%;
    }
    40% {
      background-position: 0% 100%, 50% 0%, 100% 50%;
    }
    60% {
      background-position: 0% 50%, 50% 100%, 100% 0%;
    }
    80% {
      background-position: 0% 50%, 50% 50%, 100% 100%;
    }
  }
`

export default TypingIndicatorStyle
