/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-children-prop */
/* eslint-disable react-hooks/exhaustive-deps */
import { formatInTimeZone } from "date-fns-tz"
import { MatrixEvent } from "matrix-js-sdk"
import { FC, useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useParams, useSearchParams } from "react-router-dom"
import { RootState } from "../../../store/store"
import AppConstants from "../../../utils/AppConstant"
import SunnyAIChatCardStyle, {
  ChatActionWrapper
} from "./SunnyAIChatCard.style"
import { AvatarStyle } from "../../../components/Avatar/Avatar.style"
import SunnyAIChatHover from "../ChatHover/SunnyAIChatHover"
import ChatBotAvatarIcon from "../../../components/Icons/ChatBotAvatarIcon"
import TypingIndicator from "../../../components/TypingLoader/TypingLoader"

interface ChatMsgprops {
  event?: MatrixEvent
  image?: string
  name?: string
  text?: string
  time?: number
  selfmsg?: string
  isEdited?: boolean
  eId?: string
  type: string
  sender?: string
  isDeleted: boolean
  content: any
  isChannel: boolean
  aiResponse: string
}

const imageExtensions = ["jpeg", "png", "jpg"]

const SunnyAIChatCard: FC<ChatMsgprops> = (props: ChatMsgprops) => {
  const {
    event,
    isChannel,
    eId,
    image,
    name,
    text,
    time,
    selfmsg,
    isEdited,
    sender,
    type,
    isDeleted,
    content,
    aiResponse
  } = props
  const { id } = useParams<string>()
  const userProfile = useSelector((state: RootState) => state.auth.userProfile)
  const matrixClient = useSelector(
    (state: RootState) => state.chat.matrixClient
  )
  const [contextMenuPosition, setContextMenuPosition] = useState({
    x: 0,
    y: 0
  })
  const [selectedText, setSelectedText] = useState()
  const [isEmoji, setIsEmoji] = useState<boolean>(false)
  // const [pinnedMassageId, setPinnedMassageId] = useState(null)
  const room = matrixClient?.getRoom(id)
  const [searchParams, setSearchParams] = useSearchParams()

  const contextMenuRef = useRef(null)

  const handleClickOutside = (event) => {
    if (
      contextMenuRef.current &&
      !contextMenuRef.current.contains(event.target)
    ) {
      setContextMenuPosition({ x: 0, y: 0 })
    }
  }
  useEffect(() => {
    document.addEventListener("click", handleClickOutside)

    return () => {
      document.removeEventListener("click", handleClickOutside)
    }
  }, [])

  const msgContextMenu = (event) => {
    event.preventDefault()

    const selection = window.getSelection()
    const selectedText = selection?.toString().trim()
    if (selectedText !== "") {
      const range = selection?.getRangeAt(0).getBoundingClientRect()
      setContextMenuPosition({
        x: range?.x,
        y: range?.y
      })
      setSelectedText(selectedText)
    } else {
      setContextMenuPosition({ x: 0, y: 0 })
    }
  }

  //   return isEdit ? (
  //     <ChatMsgEdit
  //       isChannel={false}
  //       content={content}
  //       eId={eId}
  //       text={text}
  //       onClose={() => {
  //         setIsEdit(false)
  //       }}
  //     />
  //   ) : (
  return (
    <ChatActionWrapper key={"event.getId()"}>
      <SunnyAIChatCardStyle className={selfmsg ? "selfmsg" : ""}>
        <div
          className={`chatmsg ${isEmoji ? "emoji" : ""}`}
          contextMenuRef={contextMenuRef}
          onContextMenu={(event) => msgContextMenu(event)}
        >
          <div className="chatmsg__pic">
            <AvatarStyle
              style={{
                width: "100%",
                height: "100%"
              }}
              icon={
                <div className="bg-color">
                  <ChatBotAvatarIcon />
                </div>
              }
              size="small"
              shape="square"
              src={image}
            />
          </div>
          <div className="chatmsg__data-wrapper">
            <div className="chatmsg__data" id={eId}>
              <div className="chatmsg__name">
                <div className="chatmsg__name-left">
                  {name && !selfmsg && (
                    <label htmlFor="personname">{name}</label>
                  )}

                  {!isDeleted && (
                    <span className="time">
                      {userProfile?.time_format !== "" &&
                      userProfile?.time_format === "24"
                        ? formatInTimeZone(
                            new Date(time),
                            userProfile?.time_zone || "UTC",
                            "HH:mm"
                          )
                        : formatInTimeZone(
                            new Date(time),
                            userProfile?.time_zone || "UTC",
                            "hh:mm a"
                          )}
                    </span>
                  )}
                </div>
              </div>

              {aiResponse !== "waiting for response" ? (
                <div>
                  <div
                    className="chatmsg__text"
                    style={{
                      fontSize: AppConstants.emojiRegex.test(content)
                        ? "50px"
                        : "14px"
                    }}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html:
                        content?.includes("<mx-reply>") &&
                        content?.includes("</mx-reply>")
                          ? content
                              ?.replace(AppConstants.regexForReply, "")
                              ?.replace(
                                AppConstants.regexForLink,
                                function (match) {
                                  if (
                                    match.startsWith("http://") ||
                                    match.startsWith("https://")
                                  ) {
                                    return `<a href="${match}" target="_blank">${match}</a>`
                                  }
                                  return `<a href="https://${match}" target="_blank">${match}</a>`
                                }
                              )
                          : content?.replace(
                              AppConstants.regexForLink,
                              function (match) {
                                if (
                                  match.startsWith("http://") ||
                                  match.startsWith("https://")
                                ) {
                                  return `<a href="${match}" target="_blank">${match}</a>`
                                }
                                return `<a href="https://${match}" target="_blank">${match}</a>`
                              }
                            )
                    }}
                  />
                </div>
              ) : (
                <TypingIndicator />
              )}
              {/* {<SunnyAIChatHover selfmsg={selfmsg ? "selfmsg" : ""} />} */}
            </div>
          </div>
        </div>
      </SunnyAIChatCardStyle>
    </ChatActionWrapper>
  )
}

export default SunnyAIChatCard
