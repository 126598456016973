import { createContext, useContext } from "react"

export type ClientConfig = {
  defaultHomeserver?: number
  homeserverList?: string[]
  allowCustomHomeservers?: boolean

  hashRouter?: {
    enabled?: boolean
    basename?: string
  }
}

const ClientConfigContext = createContext<ClientConfig | null>(null)

export const ClientConfigProvider = ClientConfigContext.Provider

export function useClientConfig(): ClientConfig {
  const config = useContext(ClientConfigContext)
  if (!config) throw new Error("Client config are not provided!")
  return config
}

export const clientDefaultServer = (clientConfig: ClientConfig): string =>
  clientConfig.homeserverList?.[clientConfig.defaultHomeserver ?? 0] ??
  "matrix.org"

export const clientAllowedServer = (
  clientConfig: ClientConfig,
  server: string
): boolean => {
  const { homeserverList, allowCustomHomeservers } = clientConfig

  if (allowCustomHomeservers) return true

  return homeserverList?.includes(server) === true
}
