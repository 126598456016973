import React from "react"

const SecureFax = () => {
  return (
    <iframe
      style={{ height: "100%", width: "100%", border: 0 }}
      src={`${import.meta.env.VITE_SECURE_FAX_URL}?is_iframe=true`}
    />
  )
}

export default SecureFax
