// export const getEventById = async ({eventId, room, matrixClient}) => {
//     if (!eventId) return;
//     try {
//       const eventTimelineSet = await room.getLiveTimeline()?.eventTimelineSet;

import { MatrixClientType } from "../interfaces/MatrixInterface"

//       const eTimeline = await matrixClient.getEventTimeline(
//         eventTimelineSet,
//         eventId,
//       );
//       let mEvent = await eTimeline.getTimelineSet().findEventById(eventId);

//       return mEvent;
//     } catch {}
//   };

//   export const sendEmoji = async ({roomId, eventId, emoji, matrixClient}) => {
//     const content = {
//       'm.relates_to': {
//         event_id: eventId,
//         key: emoji,
//         rel_type: 'm.annotation',
//       },
//       // shortcode: 'grinning',
//     };
//     try {
//       await matrixClient.sendEvent(roomId, 'm.reaction', content);
//     } catch {}
//   };

// eslint-disable-next-line
export const removeEvent = async (
  roomId: string,
  eventId: string,
  matrixClient: MatrixClientType,
  threadId?: string
) => {
  try {
    if (threadId) {
      await matrixClient.redactEvent(roomId, threadId, eventId)
    } else {
      await matrixClient.redactEvent(roomId, eventId)
    }
  } catch (err) {
    if (err instanceof Error) {
      console.warn(err)
    }
  }
}

let uploadingPromise

const uploadFile = async (
  file,
  matrixClient: MatrixClientType,
  setProgress
) => {
  // abortController.abort()
  if (uploadingPromise) {
    matrixClient.cancelUpload(uploadingPromise)
  }

  uploadingPromise = matrixClient.uploadContent(file, {
    includeFilename: true, // for non encrypted true , for encrypted room flase
    progressHandler: ({ loaded, total }) => {
      const percentage = (loaded / total) * 100
      setProgress(percentage)
    }
  })

  const url = await uploadingPromise
  return { url }
}

export const sendFile = async (file, matrixClient, setProgress) => {
  const info = {
    type: file.type,
    name: file.name,
    size: file.size?.toString()
  }
  const content = { info }
  let uploadData = null

  try {
    uploadData = await uploadFile(file, matrixClient, setProgress)
  } catch (e) {
    return
  }
  content.url = uploadData.url
  return content
}
