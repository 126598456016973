const AppConstants = {
  ChannelType: "Channels",
  DirectMsgType: "DirectMessage",
  GroupsType: "Groups",
  emojiRegex: /^<span>[\uD800-\uDBFF][\uDC00-\uDFFF]<\/span>$/,
  regexForReply: /<mx-reply>[\s\S]*<\/mx-reply>/,
  regexForLink:
    /\b((https?|ftp|file):\/\/|www\.)[a-zA-Z0-9-\.@:%_\+\~#=/!]{2,256}\.[a-zA-Z]{2,6}(\/[-a-zA-Z0-9@:%;_\+.~#?&//=]*)?\b/g,
  monthWithYearRegex:
    /^(January|February|March|April|May|June|July|August|September|October|November|December) \d{4}$/,
  convertToCamelCase: (obj) => {
    if (typeof obj !== "object" || obj === null) {
      return obj
    }

    const result = {}
    Object.entries(obj).forEach(([key, value]) => {
      const camelCaseKey = key.replace(/[-_]\w/g, (match) =>
        match.charAt(1).toUpperCase()
      )
      result[camelCaseKey] = value
    })

    return result
  },
  rowEmojiData: [
    { emoji: "👍", name: "Thumb" },
    { emoji: "❤️", name: "Heart" },
    { emoji: "😆", name: "Laugh" },
    { emoji: "😲", name: "Surprise" },
    { emoji: "😔", name: "Sad" },
    { emoji: "🎉", name: "Party Popper" }
  ]
}

export default AppConstants
