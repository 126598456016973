import { Col, Modal, Row, SelectProps, Space, Tag, Tooltip } from "antd"
import { useState } from "react"
import { Customtagbar } from "../../config/global.style"
import Images from "../../config/images"
import Button from "../Button/Button"
import DeleteIconLg from "../Icons/DeleteIconLg"
import ProfileCover from "../Icons/ProfileCover"
import RemoveCloseIcon from "../Icons/RemoveClose"
import SelectBarStyle, {
  ModalContentWrapperStyle,
  SelectWrapperStyle
} from "./Select.style"

const EmptyTag = () => <Tag style={{ display: "none" }} />

function SelectBar(props: SelectProps) {
  const {
    children,
    className,
    defaultValue,
    onChange,
    value,
    loading,
    options,
    title,
    hideEmails,
    ...prop
  } = props
  const [isModalOpen, setIsModalOpen] = useState(false)

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const handleDataDelete = (data: string) => {
    const newValue: string[] = value.filter(
      (arrData: string) => data !== arrData
    )

    if (onChange && options) {
      onChange(newValue, options)
    }
  }

  let resolvedOptions = options

  try {
    if (!Array.isArray(resolvedOptions)) {
      resolvedOptions = JSON.parse(resolvedOptions)
    }
  } catch (error) {
    resolvedOptions = []
  }

  return (
    <SelectWrapperStyle>
      <SelectBarStyle
        className={className}
        onChange={onChange}
        value={value}
        loading={loading}
        defaultValue={defaultValue}
        options={resolvedOptions}
        tagRender={EmptyTag}
        {...prop}
      >
        {children}
      </SelectBarStyle>
      {props?.mode === "tags" && value?.length > 0 && (
        <div className="tagbar">
          {!hideEmails && (
            <Customtagbar>
              {title === "coworkers extension" ? (
                <Space style={{ flexWrap: "wrap" }} className="space-wrap">
                  {value.map((data: string) => {
                    const [name, extension] = data.split(",")
                    const ext = extension.trim()
                    return (
                      <Tooltip title={`${name} (${ext})`}>
                        <Tag
                          closable
                          closeIcon={<RemoveCloseIcon />}
                          onClose={() => handleDataDelete(data)}
                        >
                          <span className="profilecover-icon-wrapper">
                            <ProfileCover />
                          </span>
                          <div className="data">{`${name} (${ext})`}</div>
                        </Tag>
                      </Tooltip>
                    )
                  })}
                </Space>
              ) : (
                <Space style={{ flexWrap: "wrap" }} className="space-wrap">
                  {value.slice(0, 3).map((data: string) => (
                    <Tooltip title={data}>
                      <Tag
                        closable
                        closeIcon={<RemoveCloseIcon />}
                        onClose={() => handleDataDelete(data)}
                      >
                        <span className="profilecover-icon-wrapper">
                          <ProfileCover />
                        </span>
                        <div className="data">{data}</div>
                      </Tag>
                    </Tooltip>
                  ))}
                  {value.length > 3 && (
                    <Tag className="primary pointer" onClick={showModal}>
                      +{value.length - 3}
                    </Tag>
                  )}
                </Space>
              )}
            </Customtagbar>
          )}

          <Modal
            title={title || "Attendees"}
            width={500}
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            className="testspeaker"
            footer={[
              <Row>
                <Col xs={24} lg={12} />
                <Col xs={24} lg={12}>
                  <div className="footerright">
                    <Button onClick={handleOk} type="primary" size="small">
                      OK
                    </Button>
                  </div>
                </Col>
              </Row>
            ]}
          >
            <ModalContentWrapperStyle>
              <ul className="select-tag">
                {value.map((data: string) => (
                  <li>
                    {" "}
                    <div className="tag-detail">
                      <span>
                        <img src={Images.profilepic} alt="auththumb" />
                      </span>{" "}
                      <p>{data}</p>{" "}
                    </div>
                    <div
                      className="remove-tag pointer"
                      onClick={() => handleDataDelete(data)}
                    >
                      {" "}
                      <DeleteIconLg />{" "}
                    </div>
                  </li>
                ))}
              </ul>
            </ModalContentWrapperStyle>
          </Modal>
        </div>
      )}
    </SelectWrapperStyle>
  )
}

export default SelectBar
