import styled from "styled-components"

// eslint-disable-next-line import/prefer-default-export
export const RteEditorStyle = styled.div`
  box-shadow: none !important;
  border: 2px solid transparent;
  padding: 10px 11px;
  font-size: 14px;
  line-height: 21px;
  max-height: 124px;
  overflow: auto;
  background-color: ${(props) => props.theme.bgcolordwhiteblack};
  color: ${(props) => props.theme.textcolordblack};
  .drop-file {
    display: none;
  }
  &.dragging-item {
    border: 2px solid ${(props) => props.theme.primary};
    border-radius: 8px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    .drop-file {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-weight: 500;
      color: ${(props) => props.theme.primary};
    }
  }
  .public-DraftEditorPlaceholder-root {
    position: absolute;
    color: ${(props) => props.theme.colorlightblack};
    opacity: 0.5;
  }
  &:hover {
    border-color: transparent;
  }
`
