import axios, { AxiosError } from "axios"
import {
  PayloadRequest,
  IScheduleMeetingInterface
} from "../interfaces/PayloadInterface"
import initMatrix from "../components/Chat/client/initMatrix"

const baseURL =
  import.meta.env.VITE_API_ENDPOINT || "https://sandbox-api.ocmeet.us"

const refreshToken = () => {
  return axios.post(`${baseURL}/api/v1/auth/refresh-token/`, {
    refresh_token: localStorage.getItem("refresh-token")
  })
}

axios.interceptors.response.use(
  (res) => res,
  async (err) => {
    if (err?.response && err?.response?.status === 401) {
      if (localStorage.getItem("refresh-token")) {
        try {
          const refreshTokenResposne = await refreshToken()

          localStorage.setItem(
            "access-token",
            refreshTokenResposne.data?.data?.token as string
          )
          localStorage.setItem(
            "refresh-token",
            refreshTokenResposne.data?.data?.refresh_token as string
          )
          err.response.config.headers.set(
            "Authorization",
            `Bearer ${refreshTokenResposne.data?.data?.token}`
          )

          return await axios(err?.response?.config)
        } catch (err) {
          initMatrix.weblogout()
          localStorage.removeItem("refresh-token")
          localStorage.removeItem("access-token")
          localStorage.removeItem("loginUserData")
          localStorage.removeItem("userConfig")
          localStorage.removeItem("m_device_id")
          localStorage.removeItem("last-chat-id")
          localStorage.removeItem("matrix")
          localStorage.removeItem("video-selected-device")
          localStorage.removeItem("audio-output-selected-device")
          localStorage.removeItem("audio-input-selected-device")
          localStorage.removeItem("login-time")
          localStorage.removeItem("video-preview-preference")
          localStorage.removeItem("audio-preview-preference")
          localStorage.removeItem("settings")
          localStorage.removeItem("channel_roomid")
          localStorage.removeItem("last_used_tab")
          localStorage.removeItem("dm_roomid")
          window.location.href = "/login"
          localStorage.setItem(
            "403",
            "Your session is expired please log in again"
          )
          if (err instanceof AxiosError) {
            throw new Error(err.response?.data.message)
          }
        }
      } else {
        initMatrix.weblogout()
        localStorage.removeItem("access-token")
        localStorage.removeItem("loginUserData")
        localStorage.removeItem("userConfig")
        localStorage.removeItem("m_device_id")
        localStorage.removeItem("last-chat-id")
        localStorage.removeItem("matrix")
        localStorage.removeItem("video-selected-device")
        localStorage.removeItem("audio-output-selected-device")
        localStorage.removeItem("audio-input-selected-device")
        localStorage.removeItem("login-time")
        localStorage.removeItem("video-preview-preference")
        localStorage.removeItem("audio-preview-preference")
        localStorage.removeItem("settings")
        localStorage.removeItem("channel_roomid")
        localStorage.removeItem("last_used_tab")
        localStorage.removeItem("dm_roomid")
        window.location.href = "/login"
        localStorage.setItem(
          "403",
          "Your session is expired please log in again"
        )
      }
    }

    return Promise.reject(err)
  }
)

const withLogin = axios.create({
  baseURL: import.meta.env.VITE_API_ENDPOINT || "https://sandbox-api.ocmeet.us",
  headers: {
    Authorization: `Bearer ${localStorage.getItem("access-token")}`
  }
})

const withoutLogin = axios.create({
  baseURL: import.meta.env.VITE_API_ENDPOINT || "https://sandbox-api.ocmeet.us"
})

export const get = (url: string) => {
  return axios.get(baseURL + url, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access-token")}`
    }
  })
}

export const post = (url: string, payload: PayloadRequest) => {
  return axios.post(baseURL + url, payload, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access-token")}`
    }
  })
}

export const getMedia = (url: string) => {
  return axios.get(baseURL + url, {
    responseType: "blob", // important
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access-token")}`
    }
  })
}
export const getMediaRecording = (url: string, payload: {}) => {
  return axios.post(baseURL + url, payload, {
    responseType: "blob", // important
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access-token")}`
    }
  })
}

export const downloadRecordingFromPopup = (url: string, payload: {}) => {
  return axios.post(baseURL + url, payload, {
    responseType: "blob", // important
    maxContentLength: Infinity,
    maxBodyLength: Infinity
  })
}

export const postWithFile = (
  url: string,
  payload: PayloadRequest | IScheduleMeetingInterface,
  contentType?: string
) => {
  return axios.post(baseURL + url, payload, {
    headers: {
      "Content-Type": contentType ?? "multipart/form-data",
      Authorization: `Bearer ${localStorage.getItem("access-token")}`
    }
  })
}

export const put = (url: string, payload: PayloadRequest | FormData) => {
  return axios.put(baseURL + url, payload, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access-token")}`
    }
  })
}

export const patch = (url: string, payload: PayloadRequest | FormData) => {
  return axios.patch(baseURL + url, payload, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access-token")}`
    }
  })
}

export const patchWithFile = (
  url: string,
  payload: PayloadRequest | FormData
) => {
  return axios.patch(baseURL + url, payload, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${localStorage.getItem("access-token")}`
    }
  })
}

export const putWithFile = (
  url: string,
  payload: PayloadRequest | FormData,
  contentType?: string
) => {
  return axios.put(baseURL + url, payload, {
    headers: {
      "Content-Type": contentType ?? "multipart/form-data",
      Authorization: `Bearer ${localStorage.getItem("access-token")}`
    }
  })
}

export const deleted = (url: string) => {
  return axios.delete(baseURL + url, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access-token")}`
    }
  })
}

export const getWithLogin = (url: string) => withLogin.get(url)
export const postWithLogin = (url: string, payload: PayloadRequest) =>
  withLogin.post(url, payload)
export const putWithLogin = (url: string, payload: PayloadRequest) =>
  withLogin.put(url, payload)
export const patchWithLogin = (
  url: string,
  payload: PayloadRequest | FormData
) => withLogin.patch(url, payload)
export const deleteWithLogin = (url: string) => withLogin.delete(url)

export const getWithoutLogin = (url: string) => withoutLogin.get(url)
export const postWithoutLogin = (url: string, payload: PayloadRequest) =>
  withoutLogin.post(url, payload)
export const putWithoutLogin = (url: string, payload: PayloadRequest) =>
  withoutLogin.put(url, payload)
export const deleteWithoutLogin = (url: string) => withoutLogin.delete(url)
