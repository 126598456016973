import { useEffect, useState } from "react"
import { Call, createCall } from "../utils/createCall"
import { reportStats } from "../utils/reportStats"
import { reportType } from "../utils/type"

interface Props {
  videoStream?: any
  audioStream?: any
  localStream?: any
  isChange: boolean
  setIsChange: any
}

const useVideoBandwidth = ({
  videoStream,
  audioStream,
  localStream,
  isChange,
  setIsChange
}: Props) => {
  console.log(videoStream, audioStream, "checking props")
  const [gatheredStatsReport, setGatheredStatsReport] =
    useState<reportType | null>(null)
  let Call: Call
  // let localStream: MediaStream
  let intervalId: string | number | NodeJS.Timeout | undefined
  let response1: RTCStatsReport
  let response2: RTCStatsReport

  const start = async (config: RTCConfiguration) => {
    try {
      // const stream = await navigator.mediaDevices.getUserMedia({
      //   audio: true,
      //   video: true
      // })
      // localStream = stream
      let videoTracks
      let audioTracks
      if (localStream !== null) {
        videoTracks = localStream.getVideoTracks()
        audioTracks = localStream.getAudioTracks()
      }
      if (videoStream !== null && audioStream !== null) {
        videoTracks = videoStream.getVideoTracks()
        audioTracks = audioStream.getAudioTracks()
      }

      if (videoTracks.length > 0) {
        console.log(`Using video device: ${videoTracks[0].label}`)
      }
      if (audioTracks.length > 0) {
        console.log(`Using audio device: ${audioTracks[0].label}`)
      }
      Call = createCall(config, "relay")
      Call.pc2.addEventListener("track", gotRemoteStream)
      if (videoStream !== null && audioStream !== null) {
        videoStream
          .getTracks()
          .forEach((track) =>
            Call.pc1.addTrack(track, videoStream, audioStream)
          )
        audioStream
          .getTracks()
          .forEach((track) =>
            Call.pc1.addTrack(track, videoStream, audioStream)
          )
      }
      if (localStream !== null) {
        localStream
          .getTracks()
          .forEach((track) => Call.pc1.addTrack(track, localStream))
      }

      Call.establishConnection()
      checker(Call.pc1, Call.pc2)
    } catch (e) {
      console.log("getUserMedia() error:", e)
    }
  }
  const gotRemoteStream = () => {
    console.log("pc2 received remote stream")
  }

  function checker(pc1: RTCPeerConnection, pc2: RTCPeerConnection) {
    intervalId = setInterval(() => {
      pc1.getStats().then((stats) => {
        response1 = stats
      })
      pc2.getStats().then((stats) => {
        response2 = stats
      })
    }, 1000)
  }

  const stopInterval = () => {
    clearInterval(intervalId)
    const updatedStatsReport = reportStats(response1, response2)
    setGatheredStatsReport(updatedStatsReport)

    Call.close()
  }

  useEffect(() => {
    if (
      isChange &&
      ((videoStream !== null && audioStream !== null) || localStream !== null)
    ) {
      console.log(videoStream, audioStream, localStream, "njnjnjnjnjnj 22")
      const turnConfig: RTCConfiguration = {
        iceServers: [
          {
            credential: import.meta.env.VITE_TURN_PASSWORD as string,
            urls: [
              `${import.meta.env?.VITE_TURN_SERVER}:${
                import.meta.env?.VITE_TURN_PORT
              }` as string
            ] as string[],
            username: import.meta.env.VITE_TURN_USERNAME as string
          }
        ]
      }

      start(turnConfig)
      setTimeout(() => {
        stopInterval()
        setIsChange(false)
      }, 5000)
    }
  }, [isChange, audioStream, videoStream, localStream])

  return gatheredStatsReport
}
export default useVideoBandwidth
