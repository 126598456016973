/* eslint-disable no-restricted-syntax */
import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { MatrixEvent } from "matrix-js-sdk"
import {
  MatrixClientType,
  MatrixNotification,
  MatrixProfileType,
  MatrixUserType,
  NotificationData
} from "../../interfaces/MatrixInterface"
import type { RootState } from "../../store/store"

type ContactDetails = {
  avatar: string
  cell_phone: string
  contact_id: string
  custom_user: number
  domain: string
  email: string
  fax: string
  first_name: string
  home_phone: string
  id: number
  last_name: string
  last_update: string
  matrix_user_id: string
  scope: string
  tags: []
  user: string
  work_phone: string
}

interface MatrixConfig {
  access_token: string
  device_id: string
  home_server: string
  user_id: string
}

interface ChatState {
  userMatrixInitialConfig: MatrixConfig
  isPopoverOpen: boolean
  matrixClient: MatrixClientType | null
  matrixProfile: MatrixProfileType | null
  matrixUsers: MatrixUserType[]
  notification: MatrixNotification
  reactions: Map<any, any>
  replyEvent: MatrixEvent | null
  userPresence: any
  contactDetails: ContactDetails[] | null
  emojiReaction: any
  originalMessageId: any
  pinnedMassageId: any
  isNewMessage: boolean
  isPositionBottom: boolean
  isThreadVisible: "no" | "allthreads" | "thread"
  selectedThreadEvent: MatrixEvent | null
}

const initialState: ChatState = {
  userMatrixInitialConfig: {
    access_token: "",
    device_id: "",
    home_server: "",
    user_id: ""
  },
  isPopoverOpen: false,
  matrixClient: null,
  matrixProfile: null,
  matrixUsers: [],
  notification: {},
  reactions: new Map(),
  replyEvent: null,
  userPresence: null,
  contactDetails: null,
  emojiReaction: null,
  originalMessageId: "",
  pinnedMassageId: null,
  isNewMessage: false,
  isPositionBottom: false,
  isThreadVisible: "no",
  selectedThreadEvent: null
}

function mapToObj(inputMap) {
  const obj = {}
  for (const [key, value] of inputMap) {
    obj[key] = value
  }

  return obj
}

export const chatSlice = createSlice({
  name: "popover",
  initialState,
  reducers: {
    saveUserMatrixInitialConfig: (
      state,
      action: PayloadAction<MatrixConfig>
    ) => {
      state.userMatrixInitialConfig = action.payload
    },
    changePopoverState: (state, action: PayloadAction<boolean>) => {
      state.isPopoverOpen = action.payload
    },
    newMessagesPopup: (state, action: PayloadAction<boolean>) => {
      state.isNewMessage = action.payload
    },
    isBottom: (state, action: PayloadAction<boolean>) => {
      state.isPositionBottom = action.payload
    },
    saveMatrixClient: (state, action: PayloadAction<MatrixClientType>) => {
      state.matrixClient = action.payload
    },
    saveMatrixProfile: (state, action: PayloadAction<MatrixProfileType>) => {
      state.matrixProfile = action.payload
    },
    saveMatrixUsers: (state, action: PayloadAction<MatrixUserType[]>) => {
      state.matrixUsers = action.payload
    },
    saveNotification: (state, action: PayloadAction<NotificationData>) => {
      state.notification[action.payload.roomId] =
        action.payload.roomNotificationCount
    },
    saveReactions: (state, action: PayloadAction<any>) => {
      state.reactions = action.payload
    },
    setReplyEvent: (state, action: PayloadAction<MatrixEvent | null>) => {
      state.replyEvent = action.payload
    },
    setAllUsersPresence: (state, action: PayloadAction<any>) => {
      state.userPresence = action.payload
    },
    setContactDetails: (state, action: PayloadAction<ContactDetails[]>) => {
      state.contactDetails = action.payload
    },
    setEmojiReaction: (state, action: PayloadAction<any>) => {
      state.emojiReaction = mapToObj(action.payload)
    },
    setOriginalMessageId: (state, action: PayloadAction<any>) => {
      state.originalMessageId = action.payload
    },
    setPinnedMassageId: (state, action: PayloadAction<any>) => {
      state.pinnedMassageId = action.payload
    },
    setIsThreadVisible: (state, action: PayloadAction<any>) => {
      state.isThreadVisible = action.payload
    },
    setThreadEvent: (state, action: PayloadAction<any>) => {
      state.selectedThreadEvent = action.payload
    },
    resetChatSlice: () => initialState
  }
})

export const {
  saveUserMatrixInitialConfig,
  changePopoverState,
  isBottom,
  newMessagesPopup,
  saveMatrixClient,
  saveNotification,
  saveReactions,
  setReplyEvent,
  saveMatrixProfile,
  setAllUsersPresence,
  setContactDetails,
  saveMatrixUsers,
  setEmojiReaction,
  setOriginalMessageId,
  setPinnedMassageId,
  setIsThreadVisible,
  setThreadEvent,
  resetChatSlice
} = chatSlice.actions

export const isPopoverOpen = (state: RootState) => state.chat.isPopoverOpen

export default chatSlice.reducer
