/* eslint-disable prefer-const */
/* eslint-disable no-useless-return */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import createMentionPlugin from "@draft-js-plugins/mention"
import { Dropdown, Input, MenuProps, message } from "antd"
import { ContentState, convertToRaw, EditorState } from "draft-js"
import _ from "lodash"
import { v4 as uuidv4 } from "uuid"
import { useContext, useEffect, useMemo, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { MatrixRoomType, TypingData } from "../../../interfaces/MatrixInterface"
import { RootState } from "../../../store/store"
import { sendFile } from "../../../utils/MatrixTimeline"
import ChatAttachment from "../../../components/ChatAttachment/ChatAttachment"
import AttachmentIcon from "../../../components/Icons/Attachment"
import CloseCircleIcon from "../../../components/Icons/CloseCircleIcon"
import SendIcon from "../../../components/Icons/Send"
import UploadDeviceIcon from "../../../components/Icons/UploadDevice"
import RteEditor from "../../../components/RteEditor/RteEditor"
import { post } from "../../../services/api"
import SunnyAIContext from "../context/SunnyAIContext"
import InputEditorStyle from "./InputEditor.style"

// import createDndFileUploadPlugin from '@draft-js-plugins/drag-n-drop-upload';

// eslint-disable-next-line @typescript-eslint/no-redeclare
const { TextArea } = Input

function SunnyAIInputEditor({
  onTimelineLoad,
  typing,
  isTemp
}: {
  onTimelineLoad: (room: MatrixRoomType, isInitialScroll?: boolean) => void
  typing: TypingData[]
  isTemp: boolean
}) {
  const editorRef = useRef()
  const idRef = useRef()
  const [messageApi, contextHolder] = message.useMessage()
  const inputRef = useRef<HTMLInputElement>(null)
  const {
    selectedRoom,
    setSelectedRoom,
    setRoomMessages,
    setAllAIRoomsObj,
    isWaitingForResponse,
    setIsWaitingForResponse
  } = useContext(SunnyAIContext)
  const [inputValue, setInputValue] = useState()
  const [value, setValue] = useState<string>("")
  const [formattedValue, setFormattedValue] = useState<string>("")
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  )
  const [noMsgError, setNoMsgErr] = useState<boolean>(false)
  const [fileUploadWait, setFileUploadWait] = useState<boolean>(false)
  const [draft, setDraft] = useState({})
  const [inputFile, setInputFile] = useState()
  const [roomId, setRoomId] = useState("")
  const [fileUrls, setFileUrls] = useState([])
  const [progress, setProgress] = useState(0)
  const { id } = useParams<string>()
  const dispatch = useDispatch()
  const client = useSelector((state: RootState) => state.chat.matrixClient)
  const location = useLocation()
  const currentTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  )
  const navigate = useNavigate()
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)
  const [rawDraftJs, setRawDraftJs] = useState()
  const [isGif, setIsGif] = useState(false)
  const selectedRoomRef = useRef({})
  idRef.current = id
  useEffect(() => {
    if (value && !isTemp) {
      client?.sendTyping(id, true, 5000)
    }
  }, [value, client, id, isTemp])

  useEffect(() => {
    setEditorState(draft[id]?.message || EditorState.createEmpty())
    setFileUrls(draft[id]?.fileUrls || [])
    // eslint-disable-next-line
  }, [id])

  useEffect(() => {
    const keys = Object.keys(selectedRoom)
    const id = selectedRoom[keys[0]]
    selectedRoomRef.current = id
  }, [selectedRoom])

  const handleSend = async ({ editorFocus = () => {} }) => {
    let val = value
    const regex = /(?<!\s)&nbsp;/g

    if (val && val.match(regex)?.length === 1) {
      val = val.replace("&nbsp;", " ")
    }
    if (!val?.replace(/<[^>]*>|&nbsp;|\s/g, "")?.trim() && !fileUrls?.length) {
      setNoMsgErr(true)
      setTimeout(() => {
        setNoMsgErr(false)
      }, 3000)
      return
    }
    // if (progress > 0 && progress !== 100) {
    //   setFileUploadWait(true)
    //   return
    // }
    // if (progress === 100 || progress === 0) {
    //   setFileUploadWait(false)
    // }

    const stateWithContent = EditorState.createWithContent(
      ContentState.createFromText("")
    )
    const currentSelection = editorState.getSelection()
    const stateWithContentAndSelection = EditorState.forceSelection(
      stateWithContent,
      currentSelection
    )

    setEditorState(stateWithContentAndSelection)
    editorFocus()
    setValue("")
    setRoomMessages((prevState) => [
      ...prevState,
      {
        prompt: val,
        ai_response: "waiting for response"
      }
    ])

    try {
      let uniqueRoomId
      const keys = Object.keys(selectedRoom)
      const id = selectedRoom[keys[0]]
      if (!id) {
        uniqueRoomId = uuidv4()
      }
      setIsWaitingForResponse(true)
      const response = await post("/api/v1/chat/send-ai-prompt/", {
        prompt: val,
        room_id: id ? id : uniqueRoomId
      })
      const data = response?.data?.data
      if (!id) {
        setAllAIRoomsObj((prevState) => {
          const newRoom = {
            room_id: uniqueRoomId,
            first_chat: data?.prompt
          }
          const todayRooms = prevState.today?.data
            ? { ...prevState.today, data: [newRoom, ...prevState.today.data] }
            : { data: [newRoom] }
          return {  today: todayRooms, ...prevState }
        })
        setSelectedRoom({ ["today"]: uniqueRoomId })
      }

      if (isWaitingForResponse) {
        setRoomMessages((prevState) => {
          const newState = [...prevState]
          newState[newState.length - 1].ai_response = data?.ai_response
          newState[newState.length - 1].ts = data?.ts
          return newState
        })
        setIsWaitingForResponse(false)
      }
    } catch (error) {
      console.warn(error)
      setRoomMessages((prevState) => {
        const newState = [...prevState]
        newState[newState.length - 1].ai_response =
          "Facing some error! Please try again in some time."
        newState[newState.length - 1].ts = Date.now()
        return newState
      })
      messageApi.error("Something went wrong")
    }
  }

  const handleFileChange = async (isEvent = false, eventOrFiles) => {
    const files = isEvent ? isEvent?.target.files : eventOrFiles
    const allowedInputTypes = [
      "mp4",
      "mov",
      "jpeg",
      "jpg",
      "png",
      "heif",
      "heic",
      "mp3",
      "xls",
      "xlsx",
      "doc",
      "docx",
      "ppt",
      "rtf",
      "txt",
      "m4a",
      "file",
      "gif",
      "pdf",
      "text",
      "audio",
      "mp3",
      "zip",
      "presentation",
      "spreadsheet",
      "data",
      "doc",
      "docx",
      "xml",
      "application/msword",
      "application/vnd",
      "openxmlformats-officedocument",
      "wordprocessingml.document",
      "image",
      "video"
    ]
    Array.from(files)?.map(async (file) => {
      // if (file?.type?.includes("exe")) {
      //   messageApi.error("EXE file upload is not allowed")
      // }
      if (
        !allowedInputTypes.some((value) => file.type.includes(value)) ||
        file?.type?.includes("exe")
      ) {
        messageApi.error("This file is not supported.")
      } else {
        setRoomId(id!)
        setInputFile(file)
        try {
          let fileContent = await sendFile(file, client, setProgress)
          setInputFile(null)
          setRoomId("")
          // setFileUrls((prevState) => [...prevState, fileContent])
          if (id == idRef.current) {
            setFileUrls((prevState) => [fileContent])
          }
          setDraft((prevState) => {
            const newObject = { ...prevState }
            newObject[id] = {
              ...newObject[id],
              fileUrls: [fileContent]
              // fileUrls: newObject[id]?.fileUrls ? newObject[id]?.fileUrls?.push(fileContent) : [fileContent]
            }
            return newObject
          })

          // setEditorState(EditorState.moveFocusToEnd(editorState))
        } catch (error) {
          console.warn(error)
        } finally {
          setTimeout(() => {
            editorRef.current?.focusEditor()
          }, 300)
        }
      }
    })

    isEvent ? (isEvent.target.value = "") : false
  }

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <div
          onClick={() => {
            inputRef.current.click()
          }}
        >
          <UploadDeviceIcon /> Upload from this device
        </div>
      )
    }
  ]
  const handleOnClickEmojiPicker = () => {
    setShowEmojiPicker((prev) => !prev)
  }

  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      supportWhitespace: true
    })
    // eslint-disable-next-line no-shadow
    const { MentionSuggestions } = mentionPlugin
    // eslint-disable-next-line no-shadow
    const plugins = [mentionPlugin]
    return { plugins, MentionSuggestions }
  }, [])

  useEffect(() => {
    if (progress === 100 || progress === 0) {
      setFileUploadWait(false)
    }
  }, [progress])

  useEffect(() => {
    setTimeout(() => {
      editorRef.current?.focusEditor()
    }, 300)
  }, [id])

  useEffect(() => {
    if (id && editorState) {
      setDraft((prevState) => {
        const newObject = { ...prevState }
        newObject[id] = {
          ...newObject[id],
          message: editorState
        }
        return newObject
      })
    }
  }, [editorState])

  return (
    <InputEditorStyle>
      {contextHolder}
      <input
        ref={inputRef}
        type="file"
        accept=".mp4,.mov,.jpeg,.jpg,.png,.heif,.heic,.mp3,.xls,.xlsx,.pdf,.doc,.docx,.ppt,.rtf,.txt,.m4a,.file,.gif,.pdf,.text,.audio,.mp3,.zip,.presentation,.spreadsheet,.data,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/*,video/*"
        // value={inputValue}
        // multiple
        style={{
          display: "none"
        }}
        onChange={handleFileChange}
      />

      <div className="input_container">
        {noMsgError && (
          <div className="typemsg_block">
            Please type a message to continue.
          </div>
        )}
        {fileUploadWait && (
          <div className="typemsg_block">
            Please wait while file is uploading.
          </div>
        )}

        <RteEditor
          handleFileChange={handleFileChange}
          ref={editorRef}
          inputRef={inputRef}
          id={id}
          editorState={editorState}
          setEditorState={setEditorState}
          value={value}
          draftConvertToRaw={(es) => {
            setRawDraftJs(convertToRaw(es))
          }}
          onFormattedChange={(val) => {
            setFormattedValue(val)
          }}
          onChange={(val) => {
            setValue(val)
          }}
          handleSend={handleSend}
          plugins={plugins}
          MentionSuggestions={MentionSuggestions}
          isEditorDraggable
        />

        {/* Attachments */}
        {/* <div className="attachement-wrap">
          {fileUrls?.map((data, index) => (
            <ChatAttachment
              name={data?.info?.name}
              type={data?.info?.type}
              url={client?.mxcUrlToHttp(data?.url?.content_uri)}
              onDelete={() => {
                setFileUrls((prevState) => {
                  let newState = [...prevState]
                  newState.splice(index, 1)
                  return newState
                })
                setDraft((prevState) => {
                  const newObject = { ...prevState }
                  newObject[id] = {
                    ...newObject[id],
                    fileUrls: []
                  }
                  return newObject
                })
              }}
              isType
              isGif={isGif}
            />
          ))}
        </div> 

        {inputFile && roomId === id && (
          <div className="attachment-wrapper">
            <ChatAttachment
              percent={progress}
              inputFile={inputFile}
              name={inputFile?.name}
              type={inputFile?.type}
            />
          </div>
        )} */}

        <div className="msgactions">
          <div className="msgactions__left">
            {/* <ul>
              <li>
                <Dropdown
                  menu={{ items }}
                  placement="topLeft"
                  trigger={["click"]}
                  overlayClassName="chatmenu-dropdown"
                  disabled={true}
                >
                  <span>
                    <AttachmentIcon />
                  </span>
                </Dropdown>
              </li>
            </ul> */}
          </div>
          <div className="msgactions__right">
            <div onClick={handleSend}>
              <SendIcon />
            </div>
          </div>
        </div>
      </div>
    </InputEditorStyle>
  )
}

export default SunnyAIInputEditor
