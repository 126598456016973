import React, { useEffect, useRef, useState } from "react"
import { isMeaningfulResolution } from "../../utils/resolutionChecker"

const VideoAnalyzer = ({ setLogs, setErrorLogs, setIsLoading }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const [errorMessage, setErrorMessage] = useState("")
  const [supportedResolutions, setSupportedResolutions] = useState<string[]>([])
  const [resolutionChecks, setResolutionChecks] = useState<{
    [key: string]: boolean
  }>({
    "320x240": false,
    "640x480": false,
    "1280x720": false
  })

  useEffect(() => {
    setErrorLogs((prevState) => ({
      ...prevState,
      video: errorMessage
    }))
  }, [errorMessage])

  useEffect(() => {
    setLogs((prevState) => {
      // const prevVideo = prevState?.video || {}

      return {
        ...prevState,
        videoSupportedResolutions: supportedResolutions,
        videoResolutionChecks: resolutionChecks
      }
    })
  }, [resolutionChecks, supportedResolutions])

  useEffect(() => {
    const initializeVideo = async () => {
      try {
        const constraints: MediaStreamConstraints = { video: true }
        const stream = await navigator.mediaDevices.getUserMedia(constraints)

        const videoElement = videoRef.current
        if (videoElement) {
          videoElement.srcObject = stream
        }

        await new Promise((resolve) => {
          if (videoElement) {
            videoElement.onloadedmetadata = resolve
          }
        })

        // Check supported resolutions
        const videoTrack = stream.getVideoTracks()[0]
        const resolutions = getSupportedResolutions(videoTrack)
        setSupportedResolutions(resolutions)

        checkResolution("320x240", resolutions)
        checkResolution("640x480", resolutions)
        checkResolution("1280x720", resolutions)
      } catch (error) {
        setErrorMessage(`Error initializing video:", ${error}`)
      } finally {
        setIsLoading((prevState) => ({
          ...prevState,
          video: false
        }))

        const videoElement = videoRef.current
        if (videoElement) {
          const stream = videoElement.srcObject as MediaStream
          if (stream) {
            const tracks = stream.getTracks()
            tracks.forEach((track) => track.stop())
          }
        }
      }
    }

    const getSupportedResolutions = (track: MediaStreamTrack): string[] => {
      if (track.getCapabilities) {
        // Use getCapabilities if available (for some browsers)
        const capabilities = track.getCapabilities()
        const widthRange = capabilities.width || {}
        const heightRange = capabilities.height || {}

        const resolutions: string[] = []
        for (
          let width = widthRange.min || 0;
          width <= (widthRange.max || 0);
          width += 1
        ) {
          for (
            let height = heightRange.min || 0;
            height <= (heightRange.max || 0);
            height += 1
          ) {
            if (isMeaningfulResolution(width, height))
              resolutions.push(`${width}x${height}`)
          }
        }
        return resolutions
      }
      // Fall back to getSettings (for other browsers)
      const settings = track.getSettings()
      return [`${settings.width}x${settings.height}`]
    }

    const checkResolution = (
      targetResolution: string,
      resolutions: string[]
    ) => {
      setResolutionChecks((prevChecks) => ({
        ...prevChecks,
        [targetResolution]: resolutions.some((res) =>
          res.includes(targetResolution)
        )
      }))
    }

    initializeVideo()

    // Cleanup function if needed
    return () => {
      const videoElement = videoRef.current
      if (videoElement) {
        const stream = videoElement.srcObject as MediaStream
        if (stream) {
          const tracks = stream.getTracks()
          tracks.forEach((track) => track.stop())
        }
      }
    }
  }, [])

  return (
    <div>
      <h2>Video Analysis</h2>
      {/* {errorMessage ? (
        <div className="error-msg">{errorMessage}</div>
      ) : (
        <>
          <p>Supported Resolutions: {supportedResolutions.join(", ")}</p>
          <ul>
            {Object.entries(resolutionChecks).map(
              ([resolution, isSupported]) => (
                <li key={resolution}>
                  {resolution}: {isSupported ? "Supported" : "Not Supported"}
                </li>
              )
            )}
          </ul>
        </>
      )} */}

      {!errorMessage && (
        <video
          style={{ display: "none" }}
          ref={videoRef}
          autoPlay
          playsInline
          muted
        />
      )}
    </div>
  )
}

export default VideoAnalyzer
