import React, {
  useRef,
  useLayoutEffect,
  useCallback,
  useEffect,
  Suspense,
  useContext
} from "react"
import { Box } from "folds"
import SunnyAIChatCard from "./ChatCard/SunnyAIChatCard"
import { Skeleton } from "antd"
import SunnyAIContext from "./context/SunnyAIContext"
import ChatDemoWidget from "../../components/ChatDemoWidget/ChatDemoWidget"

const SunnyAIChatContent = ({ roomMessages }) => {
  const { isAIChatLoading, selectedRoom } = useContext(SunnyAIContext)

  const renderItem = (item) => {
    return (
      <>
        {item.prompt && (
          <SunnyAIChatCard
            isChannel={false}
            event={item}
            eId={"id"}
            type={"type"}
            isEdited={false}
            name={"bot"}
            sender={"user"}
            text={item.prompt}
            content={item.prompt}
            time={item.created_at ? item.created_at : Date.now()}
            selfmsg={true}
            isDeleted={false}
          />
        )}
        {item.ai_response && (
          <SunnyAIChatCard
            isChannel={false}
            event={item}
            eId={"id"}
            type={"type"}
            isEdited={false}
            name={"Chat AI"}
            sender={"botttt"}
            text={item.ai_response}
            content={item.ai_response}
            time={item.created_at ? item.created_at : Date.now()}
            selfmsg={false}
            isDeleted={false}
            aiResponse={item.ai_response}
          />
        )}
      </>
    )
  }
  // let prevEvent
  // let isPrevRendered = false
  // let newDivider = false
  // let dayDivider = false
  const responsesRenderer = (item) => {
    if (!item) return null

    // const collapsed =
    //   isPrevRendered &&
    //   !dayDivider &&
    //   (!newDivider || mEvent.getSender() === mx.getUserId()) &&
    //   prevEvent !== undefined &&
    //   prevEvent.getSender() === mEvent.getSender() &&
    //   prevEvent.getType() === mEvent.getType() &&
    //   minuteDifference(prevEvent.getTs(), mEvent.getTs()) < 2

    const eventJSX = renderItem(item)
    // prevEvent = mEvent
    // isPrevRendered = !!eventJSX

    // const newDividerJSX =
    //   newDivider && eventJSX && mEvent.getSender() !== mx.getUserId() ? (
    //     <MessageBase space={messageSpacing}>
    //       <TimelineDivider
    //         style={{ color: color.Success.Main }}
    //         variant="Inherit"
    //       >
    //         <Badge
    //           as="span"
    //           size="500"
    //           variant="Success"
    //           fill="Solid"
    //           radii="300"
    //         >
    //           <Text size="L400">New Messages</Text>
    //         </Badge>
    //       </TimelineDivider>
    //     </MessageBase>
    //   ) : null

    // const dayDividerJSX =
    //   dayDivider && eventJSX ? (
    //     <MessageBase space={messageSpacing}>
    //       <TimelineDivider variant="Surface">
    //         <Badge
    //           as="span"
    //           size="500"
    //           variant="Secondary"
    //           fill="None"
    //           radii="300"
    //         >
    //           <Text size="L400">
    //             {(() => {
    //               if (today(mEvent.getTs())) return "Today"
    //               if (yesterday(mEvent.getTs())) return "Yesterday"
    //               return timeDayMonthYear(mEvent.getTs())
    //             })()}
    //           </Text>
    //         </Badge>
    //       </TimelineDivider>
    //     </MessageBase>
    //   ) : null

    // if (eventJSX && (newDividerJSX || dayDividerJSX)) {
    //   if (newDividerJSX) newDivider = false
    //   if (dayDividerJSX) dayDivider = false

    //   return (
    //     <React.Fragment key={mEventId}>
    //       {newDividerJSX}
    //       {dayDividerJSX}
    //       {eventJSX}
    //     </React.Fragment>
    //   )
    // }

    if (eventJSX) {
      return <React.Fragment key={"mEventId"}>{eventJSX}</React.Fragment>
    }

    return eventJSX
  }

  return (
    <>
      <Box
        direction="Column"
        justifyContent={
          !isAIChatLoading && roomMessages.length == 0 ? "Center" : "End"
        }
        style={
          !isAIChatLoading && roomMessages.length == 0
            ? { height: "100%", paddingTop: "20px" }
            : { paddingTop: "20px" }
        }
      >
        {isAIChatLoading && (
          <div className="skeleton-wrap">
            <Skeleton
              style={{ marginBottom: 25 }}
              avatar
              active
              paragraph={{ rows: 2 }}
            />
            <Skeleton
              style={{ marginBottom: 25 }}
              avatar
              active
              paragraph={{ rows: 2 }}
            />
            <Skeleton
              style={{ marginBottom: 25 }}
              avatar
              active
              paragraph={{ rows: 2 }}
            />
            <Skeleton
              style={{ marginBottom: 25 }}
              avatar
              active
              paragraph={{ rows: 2 }}
            />
          </div>
        )}

        {!isAIChatLoading &&
          roomMessages.length == 0 &&
          !Object.keys(selectedRoom).length && <ChatDemoWidget />}

        {roomMessages.length > 0 && roomMessages.map(responsesRenderer)}
      </Box>
    </>
  )
}

export default SunnyAIChatContent
