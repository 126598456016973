import React from "react"

function DeleteIconLg() {
  return (
    <span className="anticon anticon-deletelg">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke="Currentcolor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M19.5 10.22V19c0 2-.5 3-3 3h-9c-2.5 0-3-1-3-3v-8.78M4.999 2h14c2 0 3 1 3 3v2c0 2-1 3-3 3h-14c-2 0-3-1-3-3V5c0-2 1-3 3-3z"
        />
      </svg>
    </span>
  )
}

export default DeleteIconLg
