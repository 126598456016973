/* eslint-disable jsx-a11y/anchor-is-valid */
import { Col, Row, Tabs, message } from "antd"
import type { TabsProps } from "antd"
import { useContext, useEffect, useRef } from "react"
import SunnyAIChatWindowStyle from "./SunnyAIChatWindow.style"
import ArchiveIcons from "../../../components/Icons/Archive"
import SunnyAIChatContent from "../SunnyAIChatContent"
import ScrollToBottomContainer from "../ScrollToBottomContainer"
import SunnyAIInputEditor from "../InputEditor/SunnyAIInputEditor"
import SunnyAIContext from "../context/SunnyAIContext"
import { deleted } from "../../../services/api"

export default function SunnyAIChatWindow({ roomMessages, setRoomMessages }) {
  const roomRef = useRef()
  const [messageApi, contextHolder] = message.useMessage()
  const {
    selectedRoom,
    setAllAIRoomsObj,
    setSelectedRoom,
    setIsAIChatLoading
  } = useContext(SunnyAIContext)
  const keys = Object.keys(selectedRoom)
  const id = selectedRoom[keys[0]]

  const selectedRoomRef = useRef(id)

  useEffect(() => {
    const keys = Object.keys(selectedRoom)
    const id = selectedRoom[keys[0]]
    if (selectedRoomRef.current !== id) {
      selectedRoomRef.current = id
    }
  }, [selectedRoom])

  const handleDeleteRoom = async () => {
    setIsAIChatLoading(true)
    setRoomMessages([])
    try {
      const response = await deleted(
        `/api/v1/chat/airooms/${selectedRoomRef.current}/delete_room/`
      )
      if (response?.status === 200) {
        setAllAIRoomsObj((prevState) => {
          const newState = { ...prevState }
          const roomArray = newState[keys[0]]?.data
          if (!roomArray) {
            return prevState
          }

          const selectedRoomIndex = roomArray.findIndex(
            (room) => room.room_id === id
          )

          if (selectedRoomIndex === -1) {
            return prevState
          }

          const updatedRoomArray = [
            ...roomArray.slice(0, selectedRoomIndex),
            ...roomArray.slice(selectedRoomIndex + 1)
          ]

          if (updatedRoomArray.length === 0) {
            delete newState[keys[0]]
          } else {
            newState[keys[0]] = {
              ...newState[keys[0]],
              data: updatedRoomArray
            }
          }

          let newSelectedRoomId = null

          if (updatedRoomArray.length > 0) {
            const nextRoomIndex =
              selectedRoomIndex < updatedRoomArray.length
                ? selectedRoomIndex
                : updatedRoomArray.length - 1

            newSelectedRoomId = updatedRoomArray[nextRoomIndex]?.room_id
            setSelectedRoom({ [keys[0]]: newSelectedRoomId })
          } else {
            const newStatekeys = Object.keys(newState)
            const currentKeyIndex = newStatekeys.indexOf(keys[0])

            for (let i = currentKeyIndex + 1; i < newStatekeys.length; i++) {
              const nextKeyRooms = newState[newStatekeys[i]]?.data
              if (nextKeyRooms && nextKeyRooms.length > 0) {
                newSelectedRoomId = nextKeyRooms[0]?.room_id
                setSelectedRoom({ [newStatekeys[i]]: newSelectedRoomId })
                break
              }
            }
          }

          if (newSelectedRoomId === null) {
            setSelectedRoom({})
          }

          return newState
        })
        setIsAIChatLoading(false)
      } else {
        setIsAIChatLoading(false)
        messageApi.error("Something went wrong")
      }
    } catch (error) {
      setIsAIChatLoading(false)
      messageApi.error("Something went wrong")
      console.warn(error)
    }
  }

  const slot = Object.keys(selectedRoom).length && (
    <div className="right-content">
      <div className="right-detail-content">
        <div className="actions">
          <button
            style={{ cursor: "pointer" }}
            className="danger"
            onClick={handleDeleteRoom}
          >
            <ArchiveIcons />
          </button>
        </div>
      </div>
    </div>
  )

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Chat",
      children: (
        <div className="chat-wrapper">
          <div className="prev-msg-wrapper" ref={roomRef}>
            <ScrollToBottomContainer>
              <SunnyAIChatContent roomMessages={roomMessages} />
            </ScrollToBottomContainer>
          </div>
          <div className="next-msg-wrapper">
            <SunnyAIInputEditor />
          </div>
        </div>
      )
    }
  ]

  return (
    <>
      {contextHolder}
      <SunnyAIChatWindowStyle>
        <Row className="rowtest">
          <Col flex="auto" className="right-col">
            <div className="personalmsg-data">
              <Tabs
                defaultActiveKey="1"
                items={items}
                tabBarExtraContent={slot}
              />
            </div>
          </Col>
        </Row>
      </SunnyAIChatWindowStyle>
    </>
  )
}
