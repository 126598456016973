import React from "react"

function SplashLogoIcon() {
  return (
    <span className="anticon anticon-splashlogo ">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="208"
        height="46"
        fill="none"
        viewBox="0 0  208 46"
      >
        <path
          d="M23.0492 10.2578C28.3574 10.2578 32.9672 13.8199 34.0149 18.7091L34.6086 21.5728L37.7167 21.7823C40.8947 21.9569 43.3742 24.4714 43.3742 27.4398C43.3742 30.5829 40.6153 33.1671 37.2627 33.1671H10.8611C6.35609 33.1671 2.72412 29.7447 2.72412 25.5191C2.72412 21.6077 5.83224 18.325 9.95313 17.9408L12.1183 17.7313L13.1311 15.9153C15.0868 12.423 18.8934 10.2578 23.0492 10.2578Z"
          fill="white"
        />
        <path
          d="M52.3843 22.2713C52.3843 20.1759 52.7335 18.2901 53.432 16.6138C54.1304 14.9375 55.1082 13.4707 56.3655 12.2833C57.6227 11.096 59.0894 10.188 60.8007 9.55937C62.5119 8.93076 64.3977 8.61646 66.4232 8.61646C68.4487 8.61646 70.3346 8.93076 72.0458 9.55937C73.757 10.188 75.2587 11.096 76.481 12.2833C77.7382 13.4707 78.716 14.9025 79.4145 16.6138C80.1129 18.2901 80.4622 20.1759 80.4622 22.2713C80.4622 24.3666 80.1129 26.2524 79.4145 27.9287C78.716 29.605 77.7382 31.0718 76.481 32.2592C75.2238 33.4465 73.757 34.3545 72.0458 34.9831C70.3346 35.6117 68.4487 35.926 66.4232 35.926C64.3977 35.926 62.5119 35.6117 60.8007 34.9831C59.0894 34.3545 57.5878 33.4465 56.3655 32.2592C55.1082 31.0718 54.1304 29.6399 53.432 27.9287C52.7335 26.2524 52.3843 24.3666 52.3843 22.2713ZM58.3561 22.2713C58.3561 23.4935 58.5307 24.6111 58.9148 25.6238C59.299 26.6366 59.8228 27.5097 60.5562 28.278C61.2547 29.0113 62.1277 29.605 63.1056 30.0241C64.0834 30.4432 65.2009 30.6527 66.4232 30.6527C67.6455 30.6527 68.763 30.4432 69.7409 30.0241C70.7187 29.605 71.5918 29.0113 72.2902 28.278C72.9887 27.5446 73.5475 26.6366 73.9316 25.6238C74.3158 24.6111 74.4904 23.4935 74.4904 22.2713C74.4904 21.0839 74.3158 19.9663 73.9316 18.9187C73.5475 17.9059 73.0236 16.9979 72.2902 16.2645C71.5918 15.5312 70.7187 14.9375 69.7409 14.5184C68.763 14.0993 67.6455 13.8898 66.4232 13.8898C65.2009 13.8898 64.0834 14.0993 63.1056 14.5184C62.1277 14.9375 61.2547 15.5312 60.5562 16.2645C59.8577 16.9979 59.299 17.9059 58.9148 18.9187C58.5307 19.9663 58.3561 21.0839 58.3561 22.2713Z"
          fill="#38A6DE"
        />
        <path
          d="M80.9165 17.4169H86.2248V19.8265H86.2946C86.4692 19.4773 86.7137 19.1281 87.028 18.8138C87.3423 18.4646 87.7264 18.1503 88.1804 17.9058C88.6344 17.6264 89.1234 17.4169 89.6821 17.2423C90.2409 17.0676 90.8695 16.9978 91.533 16.9978C92.9299 16.9978 94.0475 17.2073 94.9205 17.6264C95.7936 18.0455 96.4571 18.6392 96.9111 19.4075C97.4 20.1758 97.7143 21.0488 97.889 22.0965C98.0636 23.1093 98.1334 24.2268 98.1334 25.4491V35.2973H92.6156V26.5666C92.6156 26.0428 92.5807 25.519 92.5458 24.9602C92.5109 24.4014 92.4061 23.9125 92.2315 23.4236C92.0569 22.9696 91.7775 22.5854 91.3933 22.2711C91.0092 21.9918 90.4853 21.8171 89.752 21.8171C89.0535 21.8171 88.4598 21.9568 88.0407 22.2013C87.6217 22.4458 87.2724 22.795 87.028 23.2141C86.7835 23.6331 86.6438 24.122 86.574 24.6808C86.5041 25.2046 86.4692 25.7983 86.4692 26.3571V35.2624H80.9514V17.4169H80.9165Z"
          fill="#38A6DE"
        />
        <path
          d="M116.154 32.1892C115.28 33.3067 114.163 34.1798 112.801 34.8084C111.439 35.437 110.042 35.7164 108.61 35.7164C107.248 35.7164 105.956 35.5069 104.734 35.0529C103.511 34.5989 102.464 33.9703 101.556 33.1671C100.648 32.3638 99.9494 31.3511 99.4255 30.1986C98.9017 29.0462 98.6572 27.754 98.6572 26.3222C98.6572 24.8904 98.9017 23.5982 99.4255 22.4458C99.9494 21.2933 100.648 20.3155 101.556 19.4773C102.464 18.6741 103.511 18.0106 104.734 17.5915C105.956 17.1375 107.248 16.928 108.61 16.928C109.867 16.928 111.055 17.1375 112.068 17.5915C113.115 18.0455 113.988 18.6741 114.722 19.4773C115.455 20.3155 116.014 21.2933 116.398 22.4458C116.782 23.5982 116.992 24.8904 116.992 26.3222V28.0334H104.21C104.419 29.0811 104.908 29.9192 105.642 30.5478C106.375 31.1765 107.283 31.4908 108.366 31.4908C109.274 31.4908 110.042 31.2812 110.671 30.8971C111.299 30.478 111.858 29.9891 112.312 29.3256L116.154 32.1892ZM111.509 24.3316C111.544 23.3887 111.229 22.6204 110.601 21.9569C109.972 21.2933 109.134 20.979 108.121 20.979C107.493 20.979 106.969 21.0838 106.515 21.2584C106.061 21.4679 105.642 21.7124 105.327 22.0267C105.013 22.341 104.734 22.6902 104.559 23.1093C104.385 23.5284 104.28 23.9475 104.245 24.3665H111.509V24.3316Z"
          fill="#38A6DE"
        />
        <path
          d="M141.682 31.4908C141.228 32.1543 140.704 32.7829 140.04 33.3766C139.377 33.9703 138.644 34.4592 137.805 34.9132C136.967 35.3323 136.059 35.6815 135.116 35.9259C134.139 36.1704 133.126 36.3101 132.043 36.3101C130.122 36.3101 128.306 35.9609 126.665 35.2973C125.024 34.5989 123.592 33.656 122.37 32.4337C121.147 31.2114 120.204 29.7795 119.506 28.1033C118.807 26.427 118.493 24.611 118.493 22.6204C118.493 20.6298 118.842 18.8138 119.506 17.1375C120.204 15.4612 121.147 14.0294 122.37 12.8071C123.592 11.5848 125.024 10.6419 126.665 9.94342C128.306 9.24497 130.122 8.93066 132.043 8.93066C133.65 8.93066 135.291 9.24497 136.897 9.87358C138.504 10.5022 139.901 11.5499 141.053 13.0515L138.748 14.7628C138.469 14.3437 138.12 13.9246 137.631 13.5055C137.177 13.0865 136.618 12.7372 136.024 12.388C135.431 12.0737 134.767 11.8293 134.104 11.6197C133.405 11.4102 132.742 11.3403 132.043 11.3403C130.367 11.3403 128.865 11.6546 127.573 12.2483C126.246 12.8769 125.128 13.6802 124.22 14.7278C123.312 15.7406 122.614 16.9629 122.09 18.3249C121.601 19.6869 121.357 21.1187 121.357 22.6204C121.357 24.1221 121.601 25.5539 122.09 26.9159C122.579 28.2779 123.278 29.4652 124.22 30.5129C125.128 31.5257 126.246 32.3638 127.573 32.9924C128.9 33.621 130.402 33.9004 132.043 33.9004C132.742 33.9004 133.405 33.8306 134.069 33.7258C134.732 33.5861 135.396 33.4115 136.059 33.0972C136.723 32.8178 137.317 32.3987 137.91 31.9098C138.469 31.4209 139.028 30.7923 139.517 30.024L141.682 31.4908Z"
          fill="white"
        />
        <path d="M144.93 35.5768H142.52V7.81323H144.93V35.5768Z" fill="white" />
        <path
          d="M164.835 26.9859C164.835 28.278 164.626 29.5003 164.172 30.5829C163.718 31.7005 163.089 32.6434 162.286 33.4466C161.483 34.2498 160.505 34.8784 159.387 35.3324C158.27 35.7864 157.048 35.996 155.686 35.996C154.359 35.996 153.136 35.7864 152.019 35.3324C150.901 34.8784 149.958 34.2498 149.12 33.4466C148.317 32.6434 147.688 31.6655 147.234 30.5829C146.78 29.4654 146.571 28.278 146.571 26.9859C146.571 25.6938 146.78 24.4715 147.234 23.3889C147.688 22.2713 148.317 21.3284 149.12 20.5252C149.923 19.722 150.901 19.0934 152.019 18.6394C153.136 18.1854 154.359 17.9758 155.686 17.9758C157.048 17.9758 158.27 18.1854 159.387 18.6394C160.505 19.0934 161.448 19.722 162.286 20.5252C163.089 21.3284 163.718 22.3062 164.172 23.3889C164.626 24.5064 164.835 25.6938 164.835 26.9859ZM162.181 26.9859C162.181 26.043 162.042 25.135 161.727 24.2968C161.413 23.4587 160.994 22.7253 160.435 22.1316C159.876 21.503 159.178 21.049 158.41 20.6649C157.606 20.3157 156.698 20.141 155.721 20.141C154.708 20.141 153.8 20.3157 153.032 20.6649C152.228 21.0141 151.565 21.503 151.006 22.1316C150.447 22.7602 150.028 23.4587 149.714 24.2968C149.4 25.135 149.26 26.0081 149.26 26.9859C149.26 27.9288 149.4 28.8368 149.714 29.6749C150.028 30.5131 150.447 31.2465 151.006 31.8402C151.565 32.4688 152.228 32.9228 153.032 33.3069C153.835 33.6561 154.743 33.8308 155.721 33.8308C156.733 33.8308 157.641 33.6561 158.41 33.3069C159.213 32.9577 159.876 32.4688 160.435 31.8402C160.994 31.2115 161.448 30.5131 161.727 29.6749C162.042 28.8368 162.181 27.9637 162.181 26.9859Z"
          fill="white"
        />
        <path
          d="M178.351 35.5768C178.316 35.1228 178.281 34.6688 178.246 34.2148C178.246 33.7608 178.211 33.3068 178.211 32.8528H178.176C177.897 33.3068 177.547 33.7608 177.163 34.1449C176.744 34.5291 176.29 34.8783 175.766 35.1577C175.243 35.4371 174.719 35.6466 174.125 35.8212C173.566 35.9958 172.973 36.0657 172.414 36.0657C170.214 36.0657 168.572 35.472 167.525 34.3195C166.477 33.1671 165.953 31.4908 165.953 29.3256V18.3948H168.363V27.9287C168.363 28.8716 168.433 29.7447 168.572 30.478C168.712 31.2114 168.956 31.84 169.341 32.329C169.69 32.8179 170.179 33.202 170.772 33.4814C171.366 33.7259 172.134 33.8656 173.042 33.8656C173.182 33.8656 173.496 33.8306 174.055 33.7259C174.614 33.6211 175.208 33.3417 175.836 32.9226C176.465 32.4686 177.024 31.8051 177.513 30.932C178.001 30.059 178.246 28.8367 178.246 27.2652V18.4297H180.656V31.84C180.656 32.294 180.691 32.8877 180.725 33.6211C180.76 34.3195 180.795 35.018 180.865 35.6466H178.351V35.5768Z"
          fill="white"
        />
        <path
          d="M200.213 35.5768H197.803V32.7131H197.733C197.349 33.3068 196.86 33.7958 196.336 34.2148C195.812 34.6339 195.219 34.9831 194.66 35.2276C194.066 35.507 193.473 35.6816 192.844 35.8213C192.215 35.961 191.622 35.9959 191.063 35.9959C189.701 35.9959 188.479 35.7863 187.361 35.3324C186.244 34.8784 185.301 34.2498 184.462 33.4465C183.659 32.6433 183.031 31.6655 182.577 30.5829C182.123 29.4653 181.913 28.278 181.913 26.9858C181.913 25.6937 182.123 24.4714 182.577 23.3888C183.031 22.2712 183.659 21.3283 184.462 20.5251C185.266 19.7219 186.244 19.0933 187.361 18.6393C188.479 18.1853 189.701 17.9757 191.063 17.9757C191.622 17.9757 192.215 18.0456 192.844 18.1504C193.473 18.2901 194.066 18.4647 194.66 18.744C195.254 19.0234 195.812 19.3727 196.336 19.7568C196.86 20.1759 197.349 20.6648 197.733 21.2585H197.803V7.81323H200.213V35.5768ZM184.532 26.9858C184.532 27.9287 184.672 28.8367 184.986 29.6749C185.301 30.513 185.72 31.2464 186.278 31.8401C186.837 32.4687 187.536 32.9227 188.304 33.3068C189.107 33.6561 190.015 33.8307 190.993 33.8307C191.971 33.8307 192.879 33.6561 193.717 33.3068C194.555 32.9576 195.288 32.4687 195.917 31.8401C196.546 31.2115 197 30.513 197.349 29.6749C197.698 28.8367 197.873 27.9637 197.873 26.9858C197.873 26.0429 197.698 25.1349 197.349 24.2968C197 23.4586 196.511 22.7252 195.917 22.1316C195.288 21.5029 194.59 21.049 193.717 20.6648C192.879 20.3156 191.971 20.141 190.993 20.141C189.98 20.141 189.072 20.3156 188.304 20.6648C187.501 21.014 186.837 21.5029 186.278 22.1316C185.72 22.7602 185.266 23.4586 184.986 24.2968C184.672 25.1349 184.532 26.0429 184.532 26.9858Z"
          fill="white"
        />
        <path
          d="M202.342 10.7817C202.342 10.2578 202.482 9.73398 202.726 9.27999C202.971 8.82599 203.32 8.47676 203.739 8.19738C204.193 7.918 204.647 7.81323 205.171 7.81323C205.695 7.81323 206.149 7.95292 206.603 8.19738C207.057 8.47676 207.371 8.82599 207.616 9.27999C207.86 9.73398 208 10.2229 208 10.7817C208 11.3055 207.86 11.8293 207.616 12.2833C207.371 12.7373 207.022 13.0866 206.603 13.3659C206.184 13.6453 205.695 13.7501 205.171 13.7501C204.647 13.7501 204.193 13.6104 203.739 13.3659C203.32 13.0866 202.971 12.7373 202.726 12.2833C202.447 11.7944 202.342 11.3055 202.342 10.7817ZM207.476 10.7817C207.476 10.3277 207.371 9.94352 207.162 9.55937C206.952 9.17522 206.673 8.89584 206.323 8.65138C205.974 8.44184 205.555 8.30215 205.136 8.30215C204.717 8.30215 204.333 8.40692 203.949 8.61645C203.599 8.82599 203.32 9.1403 203.111 9.52445C202.901 9.9086 202.796 10.3277 202.796 10.7817C202.796 11.2357 202.901 11.6547 203.111 12.0389C203.32 12.423 203.599 12.7373 203.949 12.9469C204.298 13.1564 204.717 13.2612 205.136 13.2612C205.555 13.2612 205.939 13.1564 206.323 12.912C206.673 12.7024 206.987 12.3881 207.162 12.004C207.371 11.6198 207.476 11.2007 207.476 10.7817ZM204.577 11.061V12.3881H203.984V9.00061H205.101C205.52 9.00061 205.835 9.10537 206.044 9.27999C206.289 9.4546 206.393 9.69906 206.393 10.0483C206.393 10.3626 206.219 10.6071 205.904 10.7467C206.079 10.8166 206.184 10.9214 206.254 11.096C206.323 11.2357 206.358 11.4103 206.358 11.6547C206.358 11.8643 206.358 12.0389 206.358 12.1087C206.358 12.2135 206.393 12.2833 206.428 12.3183V12.3881H205.8C205.765 12.2833 205.765 12.0389 205.765 11.6198C205.765 11.4103 205.73 11.2706 205.625 11.2007C205.52 11.096 205.381 11.061 205.171 11.061H204.577ZM204.577 10.5372H205.136C205.311 10.5372 205.485 10.5023 205.59 10.3975C205.73 10.3277 205.765 10.188 205.765 10.0483C205.765 9.87367 205.73 9.73398 205.625 9.66414C205.52 9.59429 205.346 9.55937 205.101 9.55937H204.542V10.5372H204.577Z"
          fill="white"
        />
        <path
          d="M22.9095 10.3625C28.2177 10.3625 32.7926 13.8897 33.8403 18.8139L34.434 21.6775L37.5421 21.8871C40.7201 22.0617 43.1647 24.5761 43.1647 27.5096C43.1647 30.6527 40.4407 33.2369 37.0881 33.2369H10.7564C6.28626 33.2369 2.6543 29.8145 2.6543 25.6238C2.6543 21.7124 5.76242 18.4646 9.88331 18.0456L12.0485 17.836L13.0613 16.02C14.9471 12.5278 18.7188 10.3625 22.9095 10.3625Z"
          fill="white"
        />
        <path
          d="M9.42914 14.2119C11.9087 9.76149 16.8677 6.73657 22.5601 6.73657C29.7542 6.73657 35.726 11.5347 37.088 17.8975C40.6501 18.1409 43.7233 20.1227 45.225 22.9738C45.225 22.9043 45.225 22.8695 45.225 22.7999C45.225 10.3178 35.0624 0.199951 22.5252 0.199951C10.826 0.23472 1.15245 9.10087 0 20.5052C1.71121 17.0978 5.23841 14.6292 9.42914 14.2119Z"
          fill="#38A6DE"
        />
        <path
          d="M10.7215 36.3252C7.47372 36.3252 4.54021 35.1083 2.375 33.1265C6.14666 40.428 13.7598 45.4 22.5603 45.4C30.6275 45.4 37.7168 41.1929 41.7329 34.8649C40.1963 35.8037 38.3454 36.3252 36.3898 36.3252H10.7215Z"
          fill="#38A6DE"
        />
      </svg>
    </span>
  )
}

export default SplashLogoIcon
