import React from "react"

function CallQueueLogInIcon() {
  return (
    <span className="anticon anticon-callqueuelogout">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16 "
      >
        <path
          d="M8.75977 11.7008L10.6798 9.78084L8.75977 7.86084"
          stroke="#38A6DE"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3 9.78082H10.6275"
          stroke="#38A6DE"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.75977 11.7008L10.6798 9.78084L8.75977 7.86084"
          stroke="#38A6DE"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3 9.78088H10.6275"
          stroke="#38A6DE"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 3.73584C12.315 3.73584 15 5.98584 15 9.73584C15 13.4858 12.315 15.7358 9 15.7358"
          stroke="#38A6DE"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  )
}

export default CallQueueLogInIcon
