import TypingIndicatorStyle from "./TypingLoader.style"

function TypingIndicator(props: any) {
  const { className, children, ...restProps } = props
  return (
    <TypingIndicatorStyle>
      <div className="dots-3"></div>
    </TypingIndicatorStyle>
  )
}

export default TypingIndicator
