import styled from "styled-components"

const EmptyDataStyle = styled.div`
  .emptydata {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 600px;
    margin: 50px auto;
    &.webinar {
      justify-content: center;
    }
    &__left {
      .anticon {
        font-size: 270px !important;
      }
      p {
        font-size: 14px;
        line-height: 21px;
        color: ${(props) => props.theme.textcolordblack};
        max-width: 220px;
        display: block;
        white-space: normal;
        margin: 0 auto;
      }
    }
    &__right {
      max-width: 194px;
      text-align: center;
      .socialbtn {
        min-width: 194px;
        margin-bottom: 20px;
        display: flex;
        justify-content: flex-start;
      }
      .head {
        font-size: 14px;
        line-height: 21px;
        color: ${(props) => props.theme.textcolordblack};
        margin-bottom: 24px;
        display: block;
        font-weight: 700;
      }
      .google {
        background: transparent;
        border: 1px solid ${(props) => props.theme.bordercolorblue1};
        color: ${(props) => props.theme.bordercolorblue1};
        width: 100%;
        margin-bottom: 10px;
      }
      .microsoft {
        border: 1px solid ${(props) => props.theme.borderorange};
        background: transparent;
        color: ${(props) => props.theme.borderorange};
        width: 100%;
        margin-bottom: 35px;
      }
      .divider {
        margin-bottom: 35px;
        font-size: 14px;
        color: ${(props) => props.theme.textcolordarkgrey};
        position: relative;
        &:after,
        &:before {
          content: "";
          width: 82px;
          height: 1px;
          background-color: ${(props) => props.theme.colorsharpgrey};
          position: absolute;
          left: 0;
          top: 50%;
        }
        &:before {
          right: 0;
          left: auto;
        }
      }
    }
  }
  .noresult,
  .nocontact {
    max-width: 250px;
    margin: 50px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &.no-internet {
      max-width: 280px;
      .detail {
        text-align: center;
      }
    }
    .detail {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: ${(props) => props.theme.textcolordarkgrey};
      max-width: 100% !important;
    }
    .lable {
      font-weight: 700;
      font-size: 20px;
      line-height: 32px;
      margin: 20px 0;
      color: ${(props) => props.theme.textcolordblack};
    }
    > .anticon {
      font-size: 200px !important;
    }
  }
`
export default EmptyDataStyle
