import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import BrandLogo from "../../components/BrandLogo/BrandLogo"
import Button from "../../components/Button/Button"
import ArrowLeftIcon from "../../components/Icons/ArrowleftIcon"
import Loader from "../../components/Loader/Loader"
import Paragraph from "../../components/Paragraph/Paragraph"
import LoadingStyle from "./Loading.style"
import {
  setMeetingId,
  setMeetingToken,
  setShowMeeting
} from "../MeetingWrapper/MeetingSlice"

interface LoadingProps {
  isMessage?: boolean
  message?: string
  allowSettings?: boolean
  onTestMicSpeakerClick?: () => void
}

function Loading(props: LoadingProps): JSX.Element {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    isMessage = false,
    message = t("oc_waiting_lobby_msg"),
    allowSettings = false,
    onTestMicSpeakerClick
  } = props

  const dispatch = useDispatch()

  const handleTestClick = () => {
    onTestMicSpeakerClick()
  }

  const handleGoBack = () => {
    if (localStorage.getItem("access-token") !== null) {
      dispatch(setMeetingId(""))
      dispatch(setMeetingToken(""))
      dispatch(setShowMeeting(false))
      navigate("/meetings/upcoming")
    } else {
      dispatch(setMeetingId(""))
      dispatch(setMeetingToken(""))
      dispatch(setShowMeeting(false))
      navigate("/")
    }
  }

  return (
    <LoadingStyle>
      <div className="loader-wrapper">
        <Loader />
      </div>
      {isMessage && <Paragraph>{message}</Paragraph>}
      {allowSettings && (
        <Button size="small" onClick={handleTestClick} className="testspeaker">
          {t("oc_test_speaker_mic")}
        </Button>
      )}

      {isMessage && (
        <Button
          icon={<ArrowLeftIcon />}
          size="small"
          type="text"
          onClick={handleGoBack}
          className="mt"
        >
          {t("oc_go_to_home")}
        </Button>
      )}

      <BrandLogo />
    </LoadingStyle>
  )
}
export default Loading
