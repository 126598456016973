import SunnyAiWrapper from "./SunnyAI.style"
import { useContext, useEffect } from "react"
import { get } from "../../services/api"
import SunnyAIContext from "./context/SunnyAIContext"
import SunnyAIChatWindow from "./ChatWindow/SunnyAIChatWindow"
import { message } from "antd"

export default function SunnyAI() {
  const [messageApi, contextHolder] = message.useMessage()
  const {
    selectedRoom,
    setSelectedRoom,
    roomMessages,
    setRoomMessages,
    setIsAIChatLoading
  } = useContext(SunnyAIContext)
  useEffect(() => {
    const keys = Object.keys(selectedRoom)
    async function getAIRoomData() {
      try {
        const id = selectedRoom[keys[0]]
        const response = await get(`/api/v1/chat/airooms/${id}/`)
        if (response?.data?.status === 200) {
          setRoomMessages(response?.data?.data?.results.reverse())
          setIsAIChatLoading(false)
        } else {
          setIsAIChatLoading(false)
          setRoomMessages([])
          messageApi.error("Something went wrong")
        }
      } catch (error) {
        setIsAIChatLoading(false)
        setRoomMessages([])
        setSelectedRoom({})
        messageApi.error("Something went wrong")
        console.warn({ error })
      }
    }
    if (!keys.length) {
      setIsAIChatLoading(false)
    }
    if (keys.length) getAIRoomData()
  }, [selectedRoom])

  return (
    <>
      {contextHolder}
      <SunnyAiWrapper>
        {
          <SunnyAIChatWindow
            roomMessages={roomMessages}
            setRoomMessages={setRoomMessages}
          />
        }
      </SunnyAiWrapper>
    </>
  )
}
