import React, { useState, useEffect, useContext } from "react"

import cons from "../../client/state/cons"
import navigation from "../../client/state/navigation"

import InviteList from "../invite-list/InviteList"
import PublicRooms from "../public-rooms/PublicRooms"
import InviteUser from "../invite-user/InviteUser"
import Settings from "../settings/Settings"
import SpaceSettings from "../space-settings/SpaceSettings"
import SpaceManage from "../space-manage/SpaceManage"
import ChatWrapperContext from "../../ChatWrapper2/ChatWrapper2Context"

function Windows() {
  // const [isInviteList, changeInviteList] = useState(false);
  const [publicRooms, changePublicRooms] = useState({
    isOpen: false,
    searchTerm: undefined
  })
  const [inviteUser, changeInviteUser] = useState({
    isOpen: false,
    roomId: undefined,
    term: undefined,
    isSMS: false
  })
  const { setSelectedUser } = useContext(ChatWrapperContext)

  // function openInviteList() {
  //   changeInviteList(true)
  // }
  function openPublicRooms(searchTerm) {
    changePublicRooms({
      isOpen: true,
      searchTerm
    })
  }
  function openInviteUser(roomId, searchTerm, isSMS) {
    changeInviteUser({
      isOpen: true,
      roomId,
      searchTerm,
      isSMS
    })
  }

  useEffect(() => {
    // navigation.on(cons.events.navigation.INVITE_LIST_OPENED, openInviteList)
    navigation.on(cons.events.navigation.PUBLIC_ROOMS_OPENED, openPublicRooms)
    navigation.on(cons.events.navigation.INVITE_USER_OPENED, openInviteUser)
    return () => {
      // navigation.removeListener(
      //   cons.events.navigation.INVITE_LIST_OPENED,
      //   openInviteList
      // )
      navigation.removeListener(
        cons.events.navigation.PUBLIC_ROOMS_OPENED,
        openPublicRooms
      )
      navigation.removeListener(
        cons.events.navigation.INVITE_USER_OPENED,
        openInviteUser
      )
    }
  }, [])

  return (
    <>
      {/* <InviteList
        isOpen={isInviteList}
        onRequestClose={() => changeInviteList(false)}
      /> */}
      <PublicRooms
        isOpen={publicRooms.isOpen}
        searchTerm={publicRooms.searchTerm}
        onRequestClose={() =>
          changePublicRooms({ isOpen: false, searchTerm: undefined })
        }
      />
      <InviteUser
        isOpen={inviteUser.isOpen}
        roomId={inviteUser.roomId}
        searchTerm={inviteUser.searchTerm}
        isSMS={inviteUser.isSMS}
        onRequestClose={() => {
          changeInviteUser({ isOpen: false, roomId: undefined })
          setSelectedUser([])
        }}
      />
      {/* <Settings /> */}
      <SpaceSettings />
      <SpaceManage />
    </>
  )
}

export default Windows
