import { useEffect } from "react"
import useConnectivityTest from "../../hooks/useConnectivityTest"

const Host = ({ setLogs, setErrorLogs, setIsLoading }) => {
  const hostMessage = useConnectivityTest("host")

  useEffect(() => {
    setLogs((prevState) => ({
      ...prevState,
      connectivityHost: hostMessage?.message
    }))

    if (hostMessage?.message) {
      setIsLoading((prevState) => ({
        ...prevState,
        connectivityHost: false
      }))
    }
  }, [hostMessage?.message])

  useEffect(() => {
    setErrorLogs((prevState) => ({
      ...prevState,
      connectivityRelay: hostMessage?.errorMessage
    }))
  }, [hostMessage?.errorMessage])

  return (
    <>
      <div>Host</div>
      {/* {hostMessage?.message.map((mes) => (
        <div>{mes}</div>
      ))} */}
    </>
  )
}
export default Host
