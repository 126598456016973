import { configureStore } from "@reduxjs/toolkit"
import { persistReducer, persistStore } from "redux-persist"
import storage from "redux-persist/es/storage"
import homeReducer from "../pages/home/HomeSlice"
import AuthLoginReducer from "../components/AuthLogin/AuthLoginSlice"
import ThemeReducer from "../pages/Accounts/SelectTheme/ThemeSlice"
import chatReducer from "../pages/Chat/ChatSlice"
import MeetingSlice from "../pages/MeetingWrapper/MeetingSlice"
import voicemailReducer from "../components/Call/voicemail/store/voicemailSlice"

const themePersistConfig = {
  key: "theme",
  storage
}

const meetingDataPersistConfig = {
  key: "meetingdata",
  storage,
  blacklist: [
    "meetingToken",
    "meetingId",
    "hideMeeting",
    "showMeeting",
    "jitsiApi"
  ]
}

export const store = configureStore({
  reducer: {
    meetingdata: persistReducer(meetingDataPersistConfig, MeetingSlice),
    home: homeReducer,
    auth: AuthLoginReducer,
    theme: persistReducer(themePersistConfig, ThemeReducer),
    chat: chatReducer,
    voicemail: voicemailReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export const persistor = persistStore(store)
