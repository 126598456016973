/* import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
); */

/* eslint-disable no-console */
/* @typescript-eslint/no-explicit-any */
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import { GoogleOAuthProvider } from "@react-oauth/google"
import { PublicClientApplication } from "@azure/msal-browser"
import { MsalProvider } from "@azure/msal-react"
import { BrowserRouter } from "react-router-dom"
import App from "./App"
import i18n from "./utils/i18"
import { store, persistor } from "./store/store"
import { msalConfig } from "./authConfig"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"
import reportWebVitals from "./reportWebVitals"
// import LDProvider from "./config/featureFlag"

const lang = localStorage.getItem("language")
/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
const msalInstance = new PublicClientApplication(msalConfig)

function changeLanguage(data: any) {
  i18n.addResourceBundle(data.language, data.namespace, data.resource)
  i18n.changeLanguage(data.language)
}

const container = document.getElementById("root")

const root = createRoot(container)

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <GoogleOAuthProvider
          clientId={import.meta.env?.VITE_GOOGLE_CLIENT_ID || ""}
        >
          <MsalProvider instance={msalInstance}>
            {/* <LDProvider> */}
            <App />
            {/* </LDProvider> */}
          </MsalProvider>
        </GoogleOAuthProvider>
      </PersistGate>
    </Provider>
  </BrowserRouter>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
