const CustomTheme = {
  Default: {
    primary: "#38A6DE",
    bgcolorskyblue: "#38A6DE",
    // Darkblue
    textcolordarkblue: "#18508D",
    bgcolordarkblue: "#18508D",
    bordercolordarkblue: "#18508D",
    colordarkblue1: "#18508D",
    colordarkblue: "#18508D",
    bordercolorblue1: "#4285F4",
    // SkyBlue
    textcolordskyblue: "#64C4F5",
    bgcolordskyblue: "#64C4F5",
    bordercolordskyblue: "#64C4F5",
    widgetbgcolorskyblue: "#f8fafd",

    // LightBlue
    textcolordlightblue: "#E2F6FF",
    bgcolordlightblue: "#E2F6FF",
    bgcolordlightblue1: "#A8E4FF",
    bordercolordlightblue: "#E2F6FF",
    // Whites
    textcolordwhite: "#FFFFFF",
    textcolordwhite2: "#FFFFFF",
    textcolordwhite3: "#FFFFFF",
    bgcolordwhite: "#FFFFFF",
    bgcolordwhiteblack: "#FFFFFF",
    bordercolordwhite: "#FFFFFF",
    colordwhitedark: "#FFFFFF",
    // Blacks
    textcolordblack: "#000000",
    bgcolordblack: "#000000",
    bordercolordblack: "#000000",
    colordblack: "#000000",
    textcolorlightblack: "#242424",
    // Gray-Shades
    textcolorlightgrey: "rgb(71, 71, 71 , 50%)",
    textcolorlightgrey1: "rgba(71, 71, 71 , 50%)",
    blockcolororange: "#8083A3",
    textcolordarkgrey: "#8083A3",
    bordercolorgrey: "#D5D6DC",
    bordercolorsharpgrey: "#D5D6DC",
    // Comman Colors
    colorlightgrey: "#CCCCCC",
    colorsharpgrey: "#ECEEF5",
    colordanger: "#FF455C",
    colordangerhover: "#B70F0F",
    bordercolordanger: "#DC3E15",
    colorsuccess: "#60B527",
    colorlightblack: "#474747",
    colordarkgrey: "#cccccc",
    colorbluegrey: "#8083A3",
    colorbluegreylight: "#ECEEF5",
    colortileblack: "#1c2731",

    // Extra Colors

    logotextcolor: "#18508D",
    logoiconcolor: "#18508D",
    logocongratulationcolor: "#EFF6FF",
    logocongratulationstopcolor: "#E3F6FF",
    logocongratulationoffcolor: "#F4FBFF",
    bglightblue: "#f8fcff",
    bgborderlight: "#e2f6ff",
    testblock: "#F7FAFD",
    audiosound: "#D5D6DC",
    camscreen: "#F3F4F5",
    customselect: "#474747",
    customtabtext: "#000000",
    multipleselectcolor: "#ECEEF5",
    borderorange: "#DC3E15",
    bordercolorlightgrey: "#CCCCCC",
    contactlist: "#FAFBFF",
    orangeopacity: "#D98103",
    blueopacity: "#18508D",
    greenopacity: "#296F00",
    socialbtnbg: "#ffffff",
    socialbtncolor: "#757575",
    colortextgray: "#757575",
    colorbluegray: "#18508D",
    colorgrayblue: "#8083A3"
  },

  DarkMode: {
    primary: "#38A6DE",
    bgcolorskyblue: "#1B2028",
    // Darkblue
    textcolordarkblue: "#18508D",
    bgcolordarkblue: "#18508D",
    bordercolordarkblue: "#18508D",
    colordarkblue: "#080A0B",
    colordarkblue1: "#38A6DE",
    bordercolorblue1: "#4285F4",
    // SkyBlue
    textcolordskyblue: "#64C4F5",
    bgcolordskyblue: "#64C4F5",
    bordercolordskyblue: "#64C4F5",
    widgetbgcolorskyblue: "#080a0b",
    // LightBlue
    textcolordlightblue: "#E2F6FF",
    bgcolordlightblue: "#222F3F",
    bgcolordlightblue1: "#A8E4FF",
    bordercolordlightblue: "#222F3F",
    // Whites
    textcolordwhite: "#000000",
    textcolordwhite2: "#9FA0A5",
    textcolordwhite3: "#FFFFFF",
    bgcolordwhite: "#FFFFFF",
    bgcolordwhiteblack: "#1B2028",
    bordercolordwhite: "#FFFFFF",
    colordwhitedark: "#1B2028",
    // Blacks
    textcolordblack: "#ffffff",
    bgcolordblack: "#ffffff",
    bordercolordblack: "#ffffff",
    colordblack: "#000000",
    textcolorlightblack: "#242424",
    // Gray-Shades
    textcolorlightgrey: "rgb(223 ,223, 223 , 50%)",
    textcolordarkgrey: "#9FA0A5",
    blockcolororange: "#9FA0A5",
    bordercolorgrey: "#D5D6DC",
    bordercolorsharpgrey: "#5D636C",
    // Comman Colors
    colorlightgrey: "#868686",
    colorsharpgrey: "#ECEEF5",
    colordanger: "#FF455C",
    colordangerhover: "#B70F0F",
    bordercolordanger: "#DC3E15",
    colorsuccess: "#60B527",
    colorlightblack: "#DFDFDF",
    colordarkgrey: "#cccccc",
    colorbluegrey: "#9FA0A5",
    colorbluegreylight: "#2E343F",
    colortileblack: "#000000",

    // Extra Colors

    logotextcolor: "#FFFFFF",
    logoiconcolor: "#1B2028",
    logocongratulationcolor: "#868686",
    logocongratulationstopcolor: "#5B636F",
    logocongratulationoffcolor: "#1B2028",
    bglightblue: "#10171B",
    bgborderlight: "#222F3F",

    testblock: "#080A0B",
    audiosound: "#5D636C",
    camscreen: "#353940",
    customselect: "#DFDFDF",
    customtabtext: "#868686",
    multipleselectcolor: "#2E343F",
    borderorange: "#DC3E15",
    bordercolorlightgrey: "#2E343F",
    contactlist: "#080A0B",
    orangeopacity: "#D98103",
    blueopacity: "#18508D",
    greenopacity: "#296F00",
    socialbtnbg: "#4285F4",
    socialbtncolor: "#ffffff",
    colortextgray: "#8D8D8D",
    colorbluegray: "#9FA0A5",
    colorgrayblue: "#18508D"
  }
}

export default CustomTheme
