import { Col, Row, Typography } from "antd"
import ChatJoinVideoIcon from "../Icons/ChatJoinVideo"
import ChatScheduleDemoIcon from "../Icons/ChatScheduleDemo"
import ChatStartVideoIcon from "../Icons/ChatStartVideo"
import {
  ChatDemoWidgetStyle,
  ChatDemoWidgetTitleStyle
} from "./ChatDemoWidget.style"

function ChatDemoWidget() {
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <>
      <ChatDemoWidgetTitleStyle>
        <Typography.Title level={5}>
          Exploring OneCloud Connect Features
        </Typography.Title>
        <p>Try the Feature Now, Experience the Excellence of Superior AI</p>
      </ChatDemoWidgetTitleStyle>
      <ChatDemoWidgetStyle>
        <Row>
          <Col md={8} xs={24} style={{ padding: "20px" }}>
            <div className="widget-block">
              <ChatStartVideoIcon />
              <Typography.Title level={5}>
                Exploring OneCloud Connect Features
              </Typography.Title>
              <p className="details-chat">
                Learn about the main features of OneCloud Connect, the
                all-in-one communications application.
              </p>
            </div>
          </Col>
          <Col md={8} xs={24} style={{ padding: "20px" }}>
            <div className="widget-block">
              <ChatJoinVideoIcon />
              <Typography.Title level={5}>
                Email to Fax with SecureFax
              </Typography.Title>
              <p className="details-chat">
                Learn how to send faxes via email using SecureFax.
              </p>
            </div>
          </Col>
          <Col md={8} xs={24} style={{ padding: "20px" }}>
            <div className="widget-block">
              <ChatScheduleDemoIcon />
              <Typography.Title level={5}>
                Accessing OneCloud Support Resources
              </Typography.Title>
              <p className="details-chat">
                Discover how to find help and support for OneCloud services.
              </p>
            </div>
          </Col>
        </Row>
      </ChatDemoWidgetStyle>
    </>
  )
}

export default ChatDemoWidget
