import { Avatar } from "antd"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import {
  CallButton,
  CallDetails,
  CallEndButton,
  CallMuteButton,
  CallTime,
  CallType,
  CardFooter,
  CardHeader,
  IncomingCallCard,
  IncomingCallList,
  UserName
} from "./IncomingMeet.style"
import CallIncoming from "../../../components/Icons/CallIncoming.tsx"
import CallIcon from "../../../components/Icons/Call"
import CallSlash from "../../../components/Icons/CallSlash"
import { ProfilePic } from "../../../components/Dialpad/DialpadHeader/DialpadHeader.style"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { RootState } from "../../../store/store"
import { useEffect, useState } from "react"
import { post, postWithoutLogin } from "../../../services/api"
import {
  setConfig,
  setHasJoined,
  setIsFromJoinMeeting,
  setMeetingId,
  setMeetingToken,
  setShowMeeting
} from "../../MeetingWrapper/MeetingSlice"
import updatedMedia from "../../../utils/useMedia/updatedMedia"

const IncomingCaller = ({
  call,
  setMeetingDetailsFromSocket,
  pauseIncommingMeetSound
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const {
    webcamRef,
    isShowVideo,
    setIsShowVideo,
    enableCheckVideo,
    disableCheckVideo,
    enableCheckAudio,
    disableCheckAudio,
    listAudioDevices,
    listVideoDevices
  } = updatedMedia()

  const showMeeting = useSelector(
    (state: RootState) => state.meetingdata.showMeeting
  )
  const scheduleId = useSelector(
    (state: RootState) => state.meetingdata.scheduleId
  )
  const isModerator = useSelector(
    (state: RootState) => state.meetingdata.isModerator
  )
  const userLoggedin = localStorage.getItem("access-token")
  const [meetingNameValue, setMeetingNameValue] = useState<string>(
    localStorage.getItem("ocgo-join-name")?.toString() || ""
  )
  const [meetingEmailValue, setMeetingEmailValue] = useState<string>(
    localStorage.getItem("ocgo-join-email")?.toString() || ""
  )
  const [isMute, setIsMute] = useState(
    !JSON.parse(localStorage.getItem("audio-preview-preference"))
  )
  const [audioInputSelectedDevices, setAudioInputSelectedDevices] = useState<{
    label: string
    value: string
    deviceInfo: any
  }>({ label: "Default", value: "default", deviceInfo: {} })
  const [audioOutputSelectedDevices, setAudioOutputSelectedDevices] = useState<{
    label: string
    value: string
    deviceInfo: any
  }>({ label: "Default", value: "default", deviceInfo: {} })
  const [videoInputSelectedDevices, setVideoInputSelectedDevices] = useState<{
    label: string
    value: string
    deviceInfo: any
  }>({ label: "Default", value: "default", deviceInfo: {} })
  const [videoInputDevices, setVideoInputDevices] = useState([])
  const [audioInputDevices, setAudioInputDevices] = useState([])
  const [audioOutputDevices, setAudioOutputDevices] = useState([])

  // useEffect(() => {
  //   // enabledAudio()
  //   // enabledCamera()
  //   listAudioDevices().then(({ audioInput, audioOutput }) => {
  //     setAudioInputDevices(audioInput)
  //     setAudioOutputDevices(audioOutput)
  //     const defaultAudioInputDevice = JSON.parse(
  //       localStorage.getItem("audio-input-selected-device")
  //     )
  //     const defaultAudioOutputDevice = JSON.parse(
  //       localStorage.getItem("audio-output-selected-device")
  //     )
  //     const isAudioInput = audioInput.some(
  //       (inputDevice) => inputDevice?.label === defaultAudioInputDevice?.label
  //     )
  //     const isAudioOutput = audioOutput.some(
  //       (outputDevice) =>
  //         outputDevice?.label === defaultAudioOutputDevice?.label
  //     )

  //     setAudioInputSelectedDevices(
  //       isAudioInput
  //         ? JSON.parse(localStorage.getItem("audio-input-selected-device"))
  //         : audioInput[0]
  //         ? audioInput[0]
  //         : { label: "Default", value: "default", deviceInfo: {} }
  //     )
  //     setAudioOutputSelectedDevices(
  //       isAudioOutput
  //         ? JSON.parse(localStorage.getItem("audio-output-selected-device"))
  //         : audioOutput[0]
  //         ? audioOutput[0]
  //         : { label: "Default", value: "default", deviceInfo: {} }
  //     )
  //     if (JSON.parse(localStorage.getItem("audio-preview-preference"))) {
  //       enableCheckAudio(
  //         isAudioInput
  //           ? JSON.parse(localStorage.getItem("audio-input-selected-device"))
  //               ?.value
  //           : ""
  //       ).then((stream) => {
  //         if (stream !== "error") {
  //           setAudiogramStream(stream.current)
  //           setAuidioPermission(true)
  //           audioStreamRef.current = stream.current
  //         } else {
  //           setIsMute(true)
  //           localStorage.setItem(
  //             "audio-preview-preference",
  //             JSON.stringify(false)
  //           )
  //           setAuidioPermission(false)
  //         }
  //       })
  //     }
  //   })

  //   listVideoDevices().then(({ videoInput }) => {
  //     setVideoInputDevices(videoInput)
  //     const defaultVideoDevice = JSON.parse(
  //       localStorage.getItem("video-selected-device")
  //     )
  //     const isVideoDevice = videoInput.some(
  //       (videoDevice) => videoDevice?.label === defaultVideoDevice?.label
  //     )
  //     setVideoInputSelectedDevices(
  //       isVideoDevice
  //         ? JSON.parse(localStorage.getItem("video-selected-device"))
  //         : videoInput[0]
  //         ? videoInput[0]
  //         : { label: "Default", value: "default", deviceInfo: {} }
  //     )
  //   })
  //   if (JSON.parse(localStorage.getItem("video-preview-preference"))) {
  //     enableCheckVideo()
  //   }
  //   if (meetingId) {
  //     validateMeetingId(meetingId)
  //   }
  //   if (meetingIdFromAuth) {
  //     validateMeetingId(meetingIdFromAuth)
  //   }

  //   return () => {
  //     // disableCheckVideo()
  //     // disabledMedia()
  //     disableCheckAudio()
  //   }
  // }, [])

  const handleJoin = async () => {
    pauseIncommingMeetSound()
    if (call?.meeting_id) {
      try {
        let joinMeetingResponse
        if (localStorage.getItem("access-token")) {
          joinMeetingResponse = await post("/api/v1/meeting/join_meeting/", {
            room_id: call?.meeting_id,
            is_moderator: true
          })
        }

        if (joinMeetingResponse?.status === 200) {
          dispatch(setHasJoined(true))
          const has_started = joinMeetingResponse?.data?.data?.has_started
          const jwtToken = joinMeetingResponse?.data?.data?.jwt_token
          const is_moderator =
            joinMeetingResponse?.data?.data?.is_moderator ?? false

          if (jwtToken) {
            // Save that token in localStorage
            // localStorage.setItem(
            //   "meeting-token",
            //   jwtToken.replace(/['"]+/g, "")
            // )
            dispatch(setMeetingToken(jwtToken.replace(/['"]+/g, "")))

            if (isModerator || is_moderator) {
              dispatch(setMeetingId(call?.meeting_id))
              dispatch(setIsFromJoinMeeting(true))
              dispatch(
                setConfig({
                  from: "joinmeeting",
                  micmute: isMute,
                  // videoMute: showVideoPreview,
                  videoMute: isShowVideo,
                  audio: audioInputSelectedDevices,
                  video: videoInputSelectedDevices,
                  audioOutput: audioOutputSelectedDevices,
                  meetingNameValue: meetingNameValue,
                  meetingEmailValue: meetingEmailValue
                })
              )
              dispatch(setShowMeeting(true))
              if (userLoggedin) {
                navigate("/meetings")
              } else {
                navigate("/guestmeeting")
              }
            }
          }
        }
        setMeetingDetailsFromSocket((prevState) => {
          let newState = [...prevState]
          newState = newState?.filter(
            (data) =>
              data.room_id != call.room_id && data.meeting_id != call.meeting_id
          )
          return newState
        })
        return null
      } catch (error) {
        console.log(error)
      }
    } else {
      // setMeetingInputStatus("error")
    }

    setMeetingDetailsFromSocket((prevState) => {
      let newState = [...prevState]
      newState = newState?.filter(
        (data) =>
          data.room_id != call.room_id && data.meeting_id != call.meeting_id
      )
      return newState
    })
  }

  return (
    <IncomingCallCard id="icommingcallcard">
      <CardHeader>
        <CallDetails>
          <CallType>
            {/* <CallIncoming stroke="#FFFFFF" width="18" height="18" />{" "} */}
            {call.message}
            {/* <UserName>{getName(call?.remote_identity?.uri?.user)}</UserName> */}
          </CallType>
          {/* <CallTime>{call?.remote_identity?.uri?.user}</CallTime> */}
        </CallDetails>
      </CardHeader>
      <CardFooter>
        <CallMuteButton
          type="default"
          shape="circle"
          onClick={() => {
            pauseIncommingMeetSound()
          }}
          icon={<CallSlash width="12" height="12" />}
        >
          Silence
        </CallMuteButton>
        <CallEndButton
          danger
          shape="circle"
          type="primary"
          onClick={() => {
            setMeetingDetailsFromSocket((prevState) => {
              let newState = [...prevState]
              newState = newState?.filter(
                (data) =>
                  data.room_id != call.room_id &&
                  data.meeting_id != call.meeting_id
              )
              return newState
            })
            pauseIncommingMeetSound()
          }}
          icon={<CallIcon width="12" height="12" className="rotate-135" />}
        >
          Decline
        </CallEndButton>
        <CallButton
          shape="circle"
          type="primary"
          className="ant-btn-success"
          onClick={handleJoin}
          icon={<CallIcon width="12" height="12" />}
        >
          Accept
        </CallButton>
      </CardFooter>
    </IncomingCallCard>
  )
}

const IncomingMeet = ({
  calls,
  setMeetingDetailsFromSocket,
  pauseIncommingMeetSound
}) => {
  return (
    <IncomingCallList>
      <TransitionGroup>
        {calls?.map((call) => (
          <CSSTransition
            timeout={400}
            classNames="incoming-call"
            // key={prepareCallerId(call.attendees)}
          >
            <div>
              <IncomingCaller
                call={call}
                setMeetingDetailsFromSocket={setMeetingDetailsFromSocket}
                pauseIncommingMeetSound={pauseIncommingMeetSound}
              />
            </div>
          </CSSTransition>
        ))}
      </TransitionGroup>
    </IncomingCallList>
  )
}

export default IncomingMeet
