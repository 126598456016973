import styled from "styled-components"

const SunnyAIChatWindowStyle = styled.div`
  background: ${(props) => props.theme.bgcolordwhiteblack};
  border-radius: 10px;
  height: 100%;
  .hide-thread {
    display: none;
  }
  .m6zwb4v {
    color: ${(props) => props.theme.primary};
    background: transparent;
    font-weight: 600;
  }
  .ant-row,
  .ant-col {
    height: 100%;
  }
  .border-b {
    border-bottom: 1px solid ${(props) => props.theme.colorbluegreylight};
  }
  ul {
    list-style: none;
  }

  /* style for meeting data */
  .personalmsg-data {
    padding-left: 24px;
    padding-right: 24px;
    height: 100%;
    .ant-tabs,
    .ant-tabs-tabpane,
    .ant-tabs-content {
      height: 100%;
    }
  }
  .ant-tabs-nav {
    border-bottom: none;
    margin: 0;
    &:before {
      border-bottom-color: ${(props) => props.theme.colorbluegreylight};
    }

    .ant-tabs-tab {
      font-size: 16px;
      padding: 26px 0;
      .ant-tabs-tab-btn {
        color: ${(props) => props.theme.textcolordblack};
        font-weight: 700;
      }
    }
    .ant-tabs-ink-bar {
      display: none;
    }
    .left-content {
      font-size: 16px;
      font-weight: 700;
      margin: 0 20px 0 8px;
      display: flex;
      align-items: center;
      gap: 5px;
      .badge-wrapper {
        position: relative;
        .presence-room {
          position: absolute;
          bottom: 0;
          right: 5px;
          display: flex;
          .anticon {
            font-size: 10px !important;
            background: ${(props) => props.theme.colordwhitedark};

            color: ${(props) => props.theme.colordwhitedark};
            border-radius: 100%;
          }
        }
      }
      .profile-pic {
        width: 24px;
        min-width: 24px;
        height: 24px;
        border-radius: 100%;
        background: transparent;
        font-size: 12px;
        color: ${(props) => props.theme.textcolordwhite3};
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        font-size: 6px;
        margin-right: 8px;
        .bg-color {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
        }
        .ant-avatar {
          font-size: 11px;
          font-weight: normal;
        }
        .ant-avatar {
          font-size: 11px;
          font-weight: normal;
          background-color: transparent;
          border-radius: 100%;
          padding: 0;
          border: none;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        &.two {
          span {
            width: 50%;
            display: flex;
            align-items: center;
            height: 100%;
            justify-content: center;
          }
        }
        &.three {
          > div {
            width: 50%;
            display: flex;
            align-items: center;
            height: 100%;
            justify-content: center;
            &.part {
              display: flex;
              flex-direction: column;
              span {
                width: 100%;
                display: flex;
                align-items: center;
                height: 50%;
                justify-content: center;
              }
            }
          }
        }
      }
      .person-name {
        margin-left: 8px;
      }
    }
    .right-content {
      .right-detail-content {
        display: flex;
        align-items: center;
        padding-right: 10px;
        .video-call {
          display: flex;
          align-items: center;
          border-right: 1px solid ${(props) => props.theme.colorbluegreylight};
          padding-right: 20px;
          .ant-btn {
            width: 38px;
            height: 38px;
            margin-left: 10px;
            font-size: 18px;
            border: 1px solid ${(props) => props.theme.bgborderlight};
            background-color: ${(props) => props.theme.bglightblue};
            color: ${(props) => props.theme.primary};
          }
          .new-msg-dot {
            font-size: 0;
            width: 8px;
            height: 8px;
            border-radius: 100%;
            background-color: ${(props) => props.theme.colordanger};
            position: absolute;
            top: 10px;
            right: 10px;
          }
        }
        .actions {
          margin-left: 20px;
          display: flex;
          .ant-dropdown-trigger {
            margin-left: 15px;
            display: flex;
            .anticon {
              font-size: 19px;
            }
          }
          .infouser {
            display: flex;
            cursor: pointer;
          }
          .anticon {
            font-size: 24px;
            color: ${(props) => props.theme.blockcolororange};
          }
        }
      }
    }
  }
  .chat-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    .prev-msg-wrapper {
      .new-message {
        position: absolute;
        right: 9px;
        bottom: 140px;
        background-color: ${(props) => props.theme.bgcolordarkblue};
        opacity: 0.75;
        padding: 0 11px;
      }
      &.seen-overflow {
        overflow: hidden;
      }
      flex-grow: 1;
      height: 100%;
      overflow: auto;
    }
  }
  .pinned-msg {
    flex-grow: 1;
    height: 100%;
    overflow: auto;
  }
  /* style for thread msg */
  /* .thread-msg {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 24px;
    border-left: 1px solid ${(props) => props.theme.colorbluegreylight};
    .thread-head {
      display: flex;
      justify-content: space-between;
      padding: 24px 0;
      border-bottom: 1px solid ${(props) => props.theme.colorbluegreylight};
      &__left {
        font-size: 16px;
        font-weight: 700;
        color: ${(props) => props.theme.textcolordblack};
      }
      &__right {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        padding-left: 20px;
        border-left: 1px solid ${(props) => props.theme.colorbluegreylight};
        .anticon {
          font-size: 24px;
          color: ${(props) => props.theme.blockcolororange};
          cursor: pointer;
        }
      }
    }
    .thread-msg-wrapper {
      flex-grow: 1;
      height: 100%;
      overflow: auto;
    }
  } */
`
export default SunnyAIChatWindowStyle
