import { useEffect } from "react"
import useDataChannelThroughputTest from "../../hooks/useDataChannelThroughputTest"

const Throughput = ({ setLogs, setErrorLogs, setIsLoading }) => {
  const throughputMessage = useDataChannelThroughputTest()

  useEffect(() => {
    setLogs((prevState) => ({
      ...prevState,
      throughput: throughputMessage?.message
    }))

    if (throughputMessage?.message) {
      setIsLoading((prevState) => ({
        ...prevState,
        throughput: false
      }))
    }
  }, [throughputMessage?.message])

  useEffect(() => {
    setErrorLogs((prevState) => ({
      ...prevState,
      throughput: throughputMessage?.errorMessage
    }))
  }, [throughputMessage?.errorMessage])

  return (
    <>
      <div>Throughput</div>
      {/* <div style={{ height: "100px", overflow: "scroll" }}>
        {throughputMessage?.message.map((mes) => (
          <div>{mes}</div>
        ))}
      </div> */}
    </>
  )
}

export default Throughput
