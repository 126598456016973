const userConfigConstants = {
  /* flag enables the park feature in call */
  PORTAL_WEB_PHONE_SHOW_PARKS: "PORTAL_WEB_PHONE_SHOW_PARKS",

  /* flag will contain a comma separated list of phone numbers that
should populate the Caller ID drop down and allow it to be selected  */
  ACCOUNT_FEATURES_CALLERID: "ACCOUNT_FEATURES_CALLERID",

  /* flag enables the view/download recordings via the call history */
  PORTAL_CALL_HISTORY_SHOW_RECORDINGS: "PORTAL_CALL_HISTORY_SHOW_RECORDINGS",

  /* flag enables the note  */
  PORTAL_CALL_POPUP_ENABLED: "PORTAL_CALL_POPUP_ENABLED",

  /* flag enables dropdown for disposition and reason */
  PORTAL_CALL_POPUP_SHOW_NOTES: "PORTAL_CALL_POPUP_SHOW_NOTES",

  /* flag enables the chat module within OneCloud Connect */
  CONNECT_CHAT_ENABLED: "CONNECT_CHAT_ENABLED",

  /* flag enables the call module within OneCloud Connect */
  CONNECT_PHONE_ENABLED: "CONNECT_PHONE_ENABLED",

  /* flag enables the Meet module within OneCloud Connect */
  CONNECT_MEET_ENABLED: "CONNECT_MEET_ENABLED",

  /* flag enable the webinar feature within OneCloud Connect */
  CONNECT_WEBINAR_ENABLED: "CONNECT_WEBINAR_ENABLED",

  /* flag enable the fax feature within OneCloud Connect */
  CONNECT_FAX_ENABLED: "CONNECT_FAX_ENABLED",

  /* flag enable the sunnyai feature within OneCloud Connect */
  CONNECT_SUNNYAI_ENABLED: "CONNECT_SUNNYAI_ENABLED",

  /* flag enable the call park feature within OneCloud Connect */
  CONNECT_PARK_ENABLED: "CONNECT_PARK_ENABLED",

  /* flag enable the call centerfeature within OneCloud Connect */
  PORTAL_MENU_SHOW_CALL_CENTER_AGENT: "PORTAL_MENU_SHOW_CALL_CENTER_AGENT",

  WS_SERVERS: "WS_SERVERS"
}
export default userConfigConstants
