import React, { useEffect } from "react"
import { useOutletContext } from "react-router-dom"

const BaseWaitingLobby = () => {
  const { setIsWaitingOn } = useOutletContext()

  useEffect(() => {
    setIsWaitingOn(true)
  }, [])

  return <div></div>
}

export default BaseWaitingLobby
