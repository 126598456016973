import React, { FC } from "react"
import ErrorIcon from "../Icons/NoResultIcon"
import EmptyDataStyle from "./EmptyData.style"

interface ErrorFoundProps {}

const ErrorFound: FC<ErrorFoundProps> = () => (
  <EmptyDataStyle>
    <div className="noresult">
      <ErrorIcon />
      <span className="lable">Oops!</span>
      <p className="detail">The page you are looking for doesn't exist</p>
    </div>
  </EmptyDataStyle>
)

export default ErrorFound
