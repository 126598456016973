import React from "react"

function ChatBotAvatarIcon() {
  return (
    <span className="anticon anticon-pinchat ">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.2988 2H14.5958V5.27645H13.2988V2ZM7.63358 4.38904C8.42403 5.07685 9.18628 5.80496 9.92027 6.57334C9.7228 6.90957 9.46109 7.21674 9.13529 7.49484C9.06703 7.54035 8.99877 7.54035 8.93051 7.49484L6.8486 5.41293C6.80307 5.34467 6.80307 5.27641 6.8486 5.20815C7.12416 4.94405 7.3858 4.67102 7.63358 4.38904ZM21.1486 5.27643C20.8514 4.9337 20.5329 4.61516 20.193 4.3208L17.9404 6.64162L18.8278 7.59725C19.6242 6.8464 20.3978 6.07279 21.1486 5.27643ZM20.1934 10.8737H23.4698V12.2388H20.1934V10.8737ZM13.0258 6.36858C15.7808 6.10137 17.7262 7.21628 18.862 9.71329C19.5288 11.8579 19.0851 13.7351 17.531 15.3447C18.7335 15.6383 19.6095 16.3322 20.1589 17.4266C20.7241 19.4176 20.0756 20.8624 18.2135 21.7611C17.9662 21.84 17.716 21.9082 17.4627 21.9658C12.8438 22.0114 8.22495 22.0114 3.60605 21.9658C1.01241 21.2957 -0.182127 19.6233 0.022431 16.9488C0.705543 14.7196 2.21862 13.5706 4.56168 13.5017C4.83244 13.0937 5.10548 12.6841 5.38079 12.273C5.06306 12.2389 4.74451 12.2276 4.42516 12.2389V10.8737C5.26733 10.8851 6.1092 10.8737 6.95075 10.8396C7.56045 10.4467 8.22031 10.1736 8.93027 10.0205C9.64788 8.06212 11.0131 6.84483 13.0258 6.36858ZM13.4351 7.66555C15.6821 7.57606 17.1269 8.59995 17.7695 10.7372C18.0336 12.4369 17.4761 13.7794 16.0972 14.7645C15.6561 12.7421 14.4957 11.2974 12.6159 10.4301C11.91 10.1625 11.1819 9.9805 10.4316 9.88398C11.0851 8.65992 12.0862 7.92044 13.4351 7.66555Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.6611 13.5352C12.1617 13.5352 12.6623 13.5352 13.1628 13.5352C13.1628 15.6285 13.1628 17.7217 13.1628 19.815C12.6623 19.815 12.1617 19.815 11.6611 19.815C11.6611 17.7217 11.6611 15.6285 11.6611 13.5352Z"
          fill="#38A6DE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.10109 13.6034C8.57229 13.5364 8.03761 13.5137 7.497 13.5352C6.80792 15.6138 6.12533 17.6957 5.44922 19.7809C5.97402 19.8376 6.49734 19.8263 7.01918 19.7468C7.12656 19.3905 7.24034 19.0378 7.36048 18.6888C7.99713 18.6546 8.63427 18.6433 9.27174 18.6546C9.34307 19.0471 9.43406 19.4339 9.54478 19.815H11.1147C10.4784 17.7352 9.80717 15.6647 9.10109 13.6034ZM8.86158 17.4943C8.70936 16.8331 8.50458 16.196 8.24725 15.583C8.09319 16.2046 7.91114 16.8189 7.70117 17.426C8.08534 17.4939 8.47209 17.5167 8.86158 17.4943Z"
          fill="#38A6DE"
        />
      </svg>
    </span>
  )
}

export default ChatBotAvatarIcon
