import React from "react"
import PropTypes from "prop-types"
import "./RoomTile.scss"

import { twemojify } from "../../util/twemojify"

import colorMXID from "../../util/colorMXID"

import Text from "../../atoms/text/Text"
import Avatar from "../../atoms/avatar/Avatar"
import initMatrix from "../../client/initMatrix"
import { formatNumber } from "../../../WebPhone2/utils/call"

function RoomTile({
  avatarSrc,
  name,
  id,
  inviterName,
  memberCount,
  desc,
  options,
  phoneNumber = false
}) {
  const invitersName = () => {
    if (inviterName !== null) {
      return `Invited by ${inviterName}`
    } else if (phoneNumber) {
      return formatNumber(phoneNumber)
    } else {
      // ` to ${id}${
      //     memberCount === null ? "" : ` • ${memberCount} members`
      //   }`
      return (
        id?.replace(":matrix.ocgo.us", "") +
        (memberCount === null ? "" : ` • ${memberCount} members`)
      )
    }
  }

  return (
    <div className="room-tile">
      <div className="room-tile__avatar">
        <Avatar imageSrc={avatarSrc} bgColor={colorMXID(id)} text={name} />
      </div>
      <div className="room-tile__content">
        <Text variant="s1">{twemojify(name)}</Text>
        <Text variant="b3">
          {invitersName()}
        </Text>
        {desc !== null && typeof desc === "string" ? (
          <Text className="room-tile__content__desc" variant="b2">
            {twemojify(desc, undefined, true)}
          </Text>
        ) : (
          desc
        )}
      </div>
      {options !== null && <div className="room-tile__options">{options}</div>}
    </div>
  )
}

RoomTile.defaultProps = {
  avatarSrc: null,
  inviterName: null,
  options: null,
  desc: null,
  memberCount: null
}
RoomTile.propTypes = {
  avatarSrc: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  inviterName: PropTypes.string,
  memberCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  desc: PropTypes.node,
  options: PropTypes.node
}

export default RoomTile
