import React from "react"

function SmsIcon() {
  return (
    <span className="anticon anticon-plus ">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
      >
        <path
          d="M5.67389 12.653H5.34155C2.68286 12.653 1.35352 11.9883 1.35352 8.66492V5.34155C1.35352 2.68286 2.68286 1.35352 5.34155 1.35352H10.6589C13.3176 1.35352 14.647 2.68286 14.647 5.34155V8.66492C14.647 11.3236 13.3176 12.653 10.6589 12.653H10.3266C10.1205 12.653 9.92115 12.7527 9.79486 12.9188L8.79785 14.2482C8.35917 14.8331 7.64132 14.8331 7.20264 14.2482L6.20563 12.9188C6.09928 12.7726 5.85335 12.653 5.67389 12.653Z"
          stroke="white"
          stroke-width="1.2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10.6637 7.33333H10.6696"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.99609 7.3335H8.00208"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5.32967 7.33333H5.33566"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </span>
  )
}

export default SmsIcon
